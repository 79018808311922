import { Button} from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { createEvent, updateEvent } from '../../../../redux/actions/eventAction'

const FinalStep = ({ step, onStepChange, formData, setFormData }) => {

    const { modal, auth } = useSelector(state => state);

    const dispatch = useDispatch();
  
    const handleSubmit = (e) => {

        if(modal.event){
            dispatch(updateEvent({id:modal.event._id, content: formData, images:[], auth})) 

        }else{
            dispatch(createEvent({ content: formData, auth})) 

        }

        e.preventDefault() 
    }

    return (
        <Content>

            <Header>

                <h2>{modal.event?'Modifier':'Créer'} un évènement</h2>
                <h3>Finalisation</h3>

            </Header>

            {/* <DealPreviewCard  deal={formData} mode="preview"/> */}

            <Button size="large" shape="round" type="primary"  onClick={(e)=>handleSubmit(e)}>{modal.event?'Mettre-à-jour': 'Poster'}</Button>

        </Content>
    )
}

const Content = styled.div`
    display:flex;
    width: 100%;
    flex: 1;

    flex-direction: column;
    justify-content: space-around;
    align-items: center;


    .selectAll{
        display:flex;
        justify-content: end;
        width:100%;
    }



`

const Header = styled.div`
    display:flex;
    flex-direction: column;

    h2{
        font-size:1.6em;
        font-weight: 900;
        color: #585858;
        text-align:center;
    }

    h3{
        font-size:1.3em;
        font-weight: 500;
        color: #585858;
        text-align:center;
    }

`

export default FinalStep