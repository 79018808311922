import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Logo from '../../../assets/logo-pink.png'
import { Button, Form, Input } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../utils/Modal/Modal';
import { activateSubscription, getSubscriptions } from '../../../redux/actions/subscriptionAction'
import { GLOBALTYPES } from '../../../redux/actions/globalTypes'

const ModalActivateSubscription = () => {

    const {auth,subscriptions} = useSelector(state=> state);
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({});
    const [step, setStep] = useState(1)


    

    const handleChangeInput = e => {
        const { id, value } = e.target
        setFormData({ ...formData, [id]: value })
    }

    const handleSubmit =  () =>{

        if(formData.code.startsWith('TMP_')){
            console.log("tmp")
            dispatch(activateSubscription({ content:formData, auth }))

        }
        else{
            let newfilter = {code:formData.code };
            dispatch(getSubscriptions({ filter: newfilter, auth }))
            setStep(2);
        }
        
      



    }

    const activateAccount =  () =>{

     
        
        dispatch(activateSubscription({ content:formData, auth }))



    }


    return (
        <Modal modalName={"activateSubModal"}  >
        {auth.user.subscription?.status !== 'pause' &&    <Container>
                <img className="logo" src={Logo} alt='logo' />

    {step ===1 &&            <div className='text-header'>
                    <h2>Bonjour!</h2>
                    <h3>Rentre le code que tu as reçu par mail de la part de ton employeur</h3>
                </div>}

                {(step ===2 && subscriptions.subscriptions.length > 0 )&&            <div className='text-header'>
                    <h2>Bonjour!</h2>
                    <h3>C'est bien toi?</h3>
                </div>}

                {step ===3 &&            <div className='text-header'>
                    <h2>Ce n'est pas grave!</h2>
                    <h3>Pour accéder aux nombreux avantages Behandy, il faut que ton entreprise soit membre du programme</h3>
                </div>}

                <ContentForm>
        {  step ===1 &&          <Form
                        onFinish={() => { activateAccount() }}
                        layout="vertical"
                        style={{ width: "100%", height:"100%", display:"flex", flexDirection:"column", justifyContent: "space-around" }}
                    >
                        <div >
                        <Form.Item
                            label="Code d'activation"
                            name="code"
                            style={{marginBottom:'10px'}}
                            onChange={(e) => handleChangeInput(e)}
                            rules={[
                                {
                                    required: true,
                                    message: 'Entrez le code!',
                                },
                                {
                                    max: 16,
                                    message: 'Le code ne peut pas dépasser 16 caractères!',
                                }
                            ]}
                        >
                            <Input size="large" maxLength={16} />
                        </Form.Item>

{/*                         <span onClick={()=>setStep(2)} className='nocode' style={{marginBottom:'24px !important'}}>Vous n'avez pas de code?</span>
 */}                        </div>
                        <Form.Item className="item-next" style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button size="large" shape="round"  type="primary" htmlType="submit">
                                Activer
                            </Button>
                        </Form.Item>
                    </Form>
}

            {
            (step === 2 && subscriptions.subscriptions.length > 0) && <VerificationDiv>

                            <VerificationCard>
                                <h3>{subscriptions.subscriptions[0].firstname} {subscriptions.subscriptions[0].lastname}</h3>
                                <span>{subscriptions.subscriptions[0].job}</span>
                                <span className='company'>{subscriptions.subscriptions[0].company.name}</span>

                            </VerificationCard>
                             <Button onClick={activateAccount} size="large" shape="round" type="primary" >
                                Valider
                            </Button>
            </VerificationDiv>
            }

            {
            (step === 2 && !subscriptions.loading && subscriptions.subscriptions.length  === 0) && <ErrorDiv>
                        <svg width="150" height="150" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg">
                            <path fill="#d62934" d="m29.582 8.683l-.129.12L8.3 29.954a3.308 3.308 0 0 0-.547.688c-2.04-2.639-3.233-6-3.233-9.701c0-8.797 6.626-15.482 15.421-15.482c3.691 0 7.014 1.185 9.641 3.224M10.937 33.704c.189-.117.388-.287.606-.507l21.151-21.151l.041-.04c1.74 2.518 2.746 5.602 2.746 8.994c0 8.785-6.696 15.541-15.481 15.541c-3.432 0-6.546-1.035-9.063-2.837M.5 21C.5 31.775 9.235 40.5 20 40.5c10.767 0 19.501-8.725 19.501-19.5s-8.734-19.5-19.5-19.5S.5 10.225.5 21"/>
                        </svg>

                        <span>Aucun abonnement ne correspond à ce code!</span>
                            
            </ErrorDiv>
            }
                </ContentForm>


            </Container>}

            {
                auth.user.subscription?.status === 'pause' &&

                <Container>
                <img className="logo" src={Logo} alt='logo' />



                <ContentForm>
            {  step ===1 &&          <div className='text-center'>
                        <h2>Votre abonnement est en pause</h2>
                        <h3>Pour accéder aux nombreux avantages Behandy, il faut que ton entreprise soit membre du programme</h3>
                    </div>
    }

          
                </ContentForm>


            </Container>
            }
        </Modal>
    )
}

const Container = styled.div`
    display:flex;
    position: relative;
    flex-direction: column;
    align-items:center;
    height: 100%;
    width: 100%;
  
    padding: 2% 15%;

    h2{
        font-size:1.2em;
        font-weight: 900;
        color: #585858;
        text-align:center;
        margin:0px;

    }

    .text-header{
        display:flex;
        flex-direction: column;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 30px;

        h3{
            margin:0px;
            font-size:1em;
            font-weight: 500;
            color: #585858;
            margin-top: 15px;
        }
    }

    .logo{
        width: 150px;
    }
`

const ContentForm = styled.div`
    display:flex;
    flex-direction:column; 
    width: 100%;
    flex: 1;

    @media screen and (max-width: 768px) {
        padding: 3% 0%;
    }

     .text-center{
        height: 100%;
        display:flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 30px;

        h3{
            margin:0px;
            font-size:1em;
            font-weight: 500;
            color: #585858;
            margin-top: 15px;
        }
    }

    .nocode{
        margin-bottom: 24px;
        font-weight: 900;

        &:hover{
            cursor: pointer;
        }
    }

    
`

const VerificationDiv = styled.div`
    display:flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: space-around;

`

const VerificationCard = styled.div`
    display:flex;
    flex-direction: column;
    text-align :center;
    justify-content: center;
    background :white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

    padding: 5%;
    aspect-ratio: 1/1;
    border-radius: 10px;

      h3{
        color: #6076E7;
        margin: 0px;
        padding: 0px;
        font-size: 1.3em;
        font-weight: 900;
        margin-bottom: 5px;
    }

    span{
        margin:0px;
        padding: 0px;
        font-size: 0.9em;
        color: #585858;
    }

    .company{
        font-style: italic;
        margin-top: 2px;
    }
  

`

const ErrorDiv= styled.div`
    display:flex;
    flex-direction: column;
    text-align :center;
    align-items:center;
    justify-content: center;
    background :white;
    padding: 5%;
    height:100%;
  
  

    span{
        margin:0px;
        padding: 0px;
        font-size: 0.9em;
        color: #585858;
        margin-top: 20px;
    }


  

`
export default ModalActivateSubscription