import React from 'react'
import { useHistory } from 'react-router-dom';
import styled from "styled-components"

const Avatar = ({src, size, title, url}) => {
    const history = useHistory();

    const redirection = (url) =>{ 
        history.push(url)
    }
    return (
        
        <Image src={src} alt="avatar" title={title} sizeImg={size} onClick={ url?redirection(url):null }/>
    )
}

const Image = styled.img`
    width: ${props => props.sizeImg ? props.sizeImg : "50px"};
    height: ${props => props.sizeImg ? props.sizeImg : "50px"};
    border-radius: 100%;

    &:hover{
        cursor: pointer;
    }


`
export default Avatar
