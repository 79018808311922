
import { DATA_TYPES } from '../actions/dataAction';
import { DeleteData, EditData } from '../actions/globalTypes';

const initialState = {
    loading: false,
    companyTypes: [],
    workTypes: [],
    filters: [],

    days: []
}



const dataReducer = (state = initialState, action) => {
    switch (action.type){
        case DATA_TYPES.GET_DATAS:
            return {
                ...state,  
                companyTypes:  action.payload.data.companyTypes,
                workTypes:  action.payload.data.workTypes,
                filters:  action.payload.data.filters,

                days: action.payload.data.days

            }
        


        case DATA_TYPES.LOADING:
                return {
                    ...state,
                    loading: action.payload
                };

        default:
            return state;
    }
}


export default dataReducer
