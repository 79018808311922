import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { GLOBALTYPES } from '../../redux/actions/globalTypes';

import Modal from '../utils/Modal/Modal';
import { Button, Form, Input, Radio } from 'antd';
import { addAdmin } from '../../redux/actions/pageAction';
const AdminSearchModal = ({ page }) => {

  const { auth } = useSelector(state => state)
  const [formData, setFormData] = useState(null)
  const dispatch = useDispatch();


  const handleChangeInput = e => {
    const { id, value } = e.target
    setFormData({ ...formData, [id]: value })
    console.log(formData)
  }

  const onChange = (e) => {
    setFormData({ ...formData, ["role"]: e.target.value })
    console.log(formData)
  }

  return (
    <Modal modalName={"adminModal"} widthModal="400px" >


      <Content>
        <h1>Ajout administrateur</h1>



        <Form
          onFinish={() => {
            dispatch(addAdmin({ page, formData, auth }))
              .then(data => {
                // Handle success. 'data' contains the response from the successful operation
                console.log("Admin added successfully", data);
                dispatch({ type: GLOBALTYPES.MODAL, payload: { open: false } })

                // You can perform additional operations here
              })
              .catch(error => {
                // Handle error. 'error' contains the error response
                console.error("Failed to add admin", error);
              });
          }} layout="vertical"
          style={{ width: "100%" }}

        >

          <Form.Item
            label="Email"
            name='email'
            rules={[{ required: true, message: 'Entrez un email!', }]}

            onChange={(e) => handleChangeInput(e)}

          >
            <Input size="large" />
          </Form.Item>

          <Form.Item
            label="Rôle"
            name='role'
            id='role'
            rules={[{ required: true, message: 'Sélectionnez un rôle!', }]}

            onChange={onChange}

          >
            <Radio.Group >
              <Radio value={"admin"}>Administrateur</Radio>
              <Radio value={"editor"}>Editeur</Radio>
            </Radio.Group>
          </Form.Item>



          <Form.Item className="item-next" style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }} >
            <Button size="large" shape="round" type="primary" htmlType="submit">
              Ajouter
            </Button>
          </Form.Item>
        </Form>



      </Content>





    </Modal>

  )
}



const Content = styled.div`

    position: relative;
    width: 100%;
    height: 100%;
    display:flex;
    justify-content: start;
    align-items:center;
    flex-direction: column;
    padding: 15% 5%;

    h1{
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%) translateY(10px);
        top: 0px;
        color: black;
        font-size: 1.2em;
        width: 100%;
        text-align: center;
    }
    @media screen and (max-width: 768px) {
        justify-content: start;
        padding: 20% 2%;

        h1{
            font-size: 1.1em;
          

        }

     } 
`


export default AdminSearchModal