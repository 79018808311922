const validFormLanguage = ({language}) => {
    const err = {}

    if(!language) {
        err.language = "S'il vous plaît, ajoutez une langue"
    }else if(language.length > 50){
        err.language = "Le nom est trop grand (<50)!"
    }

 

    return {
        errMsg: err,
        errLength: Object.keys(err).length
    }
}


  
export default validFormLanguage