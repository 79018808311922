import React, { useEffect } from 'react'
import { isInArray } from '../../../utils/function'
import { PanelExplanation } from '../BigScreenCompanyPage'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { getSubscriptions } from '../../../redux/actions/subscriptionAction'
import { NoResults } from '../AdminsManagementComponents'
import { Player } from '@lottiefiles/react-lottie-player'
import EmptyAnimation from '../../../assets/empty.json'
import LoadIcon from '../../../images/loading.gif'
import EmployeeItem from './EmployeeItem'
import { ShowMore } from '../../../pages/home'
const MyEmployees = ({ company }) => {
    const { auth, subscriptions } = useSelector(state => state)
    const dispatch = useDispatch();

    useEffect(() => {

        let newfilter = { company: company._id, status:"active" };
        dispatch(getSubscriptions({ filter: newfilter, auth }))

        return () => {

        }
    }, [])

    return (
        <Container>

            {isInArray(company.admins, auth.user._id) && <PanelExplanation>
                <p>Dans cet espace, vous pourrez retrouver vos salariés qui possèdent un abonnement Behandy. Pour gérer les abonnements de vos salariés (ajouter ou supprimer), rendez-vous dans l'onglet "  {company.typeCompany === 'multi' ? "gestion compte" : "gestion page"}
".</p>
            </PanelExplanation>}


            <EmployeesDiv>

                {
                    subscriptions.loading
                        ? <div className="icon-loading"><img src={LoadIcon} alt="loading" /></div>
                        : (subscriptions.count === 0)
                            ? <NoResults>
                                <Player
                                    autoplay
                                    loop
                                    src={EmptyAnimation}
                                    style={{ height: '100%', width: '100%' }}
                                >
                                </Player>
                            </NoResults>
                            : <ItemsContainer>
                                {
                                    subscriptions.subscriptions.map((subscription) => (
                                      <> {subscription.user && <EmployeeItem subscription={subscription} />}</> 
                                    ))
                                }

                            </ItemsContainer>
                }
                {
                    (subscriptions.page * subscriptions.n) < subscriptions.count &&
                    <ShowMore>Voir plus</ShowMore>

                }
            </EmployeesDiv>





        </Container>
    )
}

const Container = styled.div`
  display:flex;
  margin-top: 80px;


  flex-direction:column;
  gap: 20px;
h3{
  color: #F87778;
}



  
  @media only screen and (max-width: 768px) {  
    margin-top: 0px;
  }

`
const EmployeesDiv = styled.div`
    display:flex;
    flex-direction:column;
    gap: 15px;

    .icon-loading{
        margin: 50px auto;
    }
`

const ItemsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, auto);
    grid-gap: 20px;

        @media (max-width: 768px) {

            grid-template-columns: repeat(2, 1fr);

        }

  

        @media (min-width: 769px) and (max-width: 1280px) {
            /* Add your styles here */
            grid-template-columns: repeat(3, 1fr); /* Example: 3 columns */
        }

`



export default MyEmployees