import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import styled from "styled-components"
import { createExperience, updateExperience } from '../../../../redux/actions/experienceAction'
import { GLOBALTYPES } from '../../../../redux/actions/globalTypes'
import { getDataAPI } from '../../../../utils/fetchData'
import { v4 as uuidv4 } from 'uuid';
import Modal from '../../../utils/Modal/Modal'
import { Button, Checkbox, DatePicker, Form, Input, Select } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/fr';
import locale from 'antd/es/date-picker/locale/fr_FR';
import SearchAddress from '../../../utils/SearchAddress'
import { contractDatas } from '../../../../utils/datas'

const AddExperience = () => {

    const { auth, modal } = useSelector(state => state)
    const dispatch = useDispatch()
    const [form] = Form.useForm(); // Using Form hook to create an instance
    const [formData, setFormData] = useState(null)
    const [companies, setCompanies] = useState([])
    const [modalCompanies, setModalCompanies] = useState(false)
    const [company, setCompany] = useState(null)
    const [localisationText, setLocalisationText] = useState("")
    const [localisationObject, setLocalisationObject] = useState(null)


    useEffect(() => {

        
        if(modal.experience){
            setFormData(modal.experience)
            setLocalisationObject(modal.experience.localisation)
         }else{
            setFormData({currentjob:false})
         }
        dispatch({ type: GLOBALTYPES.ALERT, payload: {} })

        return () => {
            dispatch({ type: GLOBALTYPES.MODAL, payload: { open: false } })
        }
    }, [])

    useEffect(() => {
        if (localisationObject) {
            setLocalisationText(`${localisationObject.formatted_address}   `)
        }
    }, [localisationObject])



    useEffect(() => {
        if(company){
            form.setFieldsValue({companyname: company.name})
       
        }
    }, [company])


    const changeCompany = (value) => {
        setCompany(value)
        console.log(value._id)
        setModalCompanies(false)
    }

    const handleChangeInputCompany = async (e) => {
        const { id, value } = e.target
        setFormData({ ...formData, [id]: value })
        const res = await getDataAPI(`pages/search?name=${value}&typePage=company`, auth.token)
        setModalCompanies(true)
        setCompanies(res.data.pages)
    }


    const onDateChange = (date, dateString, value) => {
        setFormData({ ...formData, [value]: dateString })

    };


    const handleChangeInput = e => {
        const { id, value } = e.target
        setFormData({ ...formData, [id]: value })
    }

    const handleAddressChange = (newAddress) => {

        setLocalisationText(newAddress.formatted_address)
        const { formatted_address, address_components, geometry } = newAddress;
        const newObject = { formatted_address, address_components, geometry };
        setLocalisationObject(newObject)

    }

    const handleCheckboxChange = (e) => {
        const { id, checked } = e.target;
        setFormData({ ...formData, [id]: checked })
    };


    const submit = (e) => {
        formData.localisation = localisationObject

        if(company){
            formData.companyname = company.name
            formData.companyid = company._id
            formData.companyavatar = company.avatar
        }    
        console.log(formData)
          if(formData.currentjob)
            formData.end = null
         
         const data= formData
         if(modal.onEdit){
             dispatch(updateExperience({id: modal.experience._id, data, auth}))
         }else{
             dispatch(createExperience({data, auth}))
 
         }  
    }


    return (
        <Modal modalName={"addExperience"} >
            <Container>

                <h2>{modal.onEdit ? "Modifier une expérience" : "Ajouter une expérience"}</h2>


                <Form
                    onFinish={() => { submit() }}
                    layout="vertical"
                    style={{ width: "100%" }}
                    initialValues={{ // Setting initial values here
                        start: modal.experience && dayjs(modal.experience.start),
                        end: modal.experience && dayjs(modal.experience.end),
                        jobname: modal.experience && (modal.experience.jobname),
                        jobtype: modal.experience && (modal.experience.jobtype),
                        companyname: modal.experience && (modal.experience.companyname),
                        localisation: modal.experience && '(modal.experience.localisation.formatted_address)',
                        description: modal.experience && modal.experience.description,

                    }}                   
                     form={form}

                >

                    <Form.Item name="checkbox-group" valuePropName="checked">
                        <Checkbox
                            id="currentjob"
                            name="currentjob"

                            onChange={handleCheckboxChange}
                            checked={ modal.experience?modal.experience.currentjob:false}

                        >
                            Emploi Actuel?
                        </Checkbox>
                    </Form.Item>

                    <Form.Item
                        label="Intitulé du poste"
                        name="jobname"
                        onChange={(e) => handleChangeInput(e)}
                        rules={[
                            {
                                required: true,
                                message: 'Entrez l\'intitulé!',
                            },
                            {
                                max: 140,
                                message: 'L\'intitulé ne peut pas dépasser 140 caractères!',
                            }
                        ]}
                    >
                        <Input size="large" maxLength={140} />
                    </Form.Item>


                    <Form.Item
                        label="Entreprise"
                        name="companyname"
                        onChange={(e) => handleChangeInputCompany(e)}
                        rules={[
                            {
                                required: true,
                                message: 'Entrez le nom de l\'entreprise!',
                            },
                            {
                                max: 140,
                                message: 'Le nom ne peut pas dépasser 140 caractères!',
                            }
                        ]}
                    >
                        <Input size="large" maxLength={140} />
                                    
          
         
                    </Form.Item>

                    {
                        (formData && formData.companyname  && modalCompanies) &&
                        <ResultsCompanies>

                        {
                            companies.map((companie) => (
                                <ResultCompany  key={uuidv4()} onClick={()=>changeCompany(companie)}>{companie.name}</ResultCompany>
                            ))
                        }
    
                        </ResultsCompanies> 
                    }
                    <Form.Item
                        label="Localisation"
                        name="localisation"
                        required={true}
                        rules={[
                            {
                                validator: (_, value) =>
                                    localisationText ? Promise.resolve() : Promise.reject(new Error('Veuillez entrer une localisation!'))
                            }
                        ]}

                    >
                        <SearchAddress onAddressChange={handleAddressChange} address={localisationText} setAddress={setLocalisationText} />

                    </Form.Item>

                    <Form.Item name="jobtype" label="Contrat" rules={[{ required: true, message: 'Sélectionnez le type de contrat!' }]}>
                        <Select onChange={(e) => { setFormData({ ...formData, ['jobtype']: e }) }} size="large" placeholder="Sélectionnez le type de contrat" dropdownStyle={{ zIndex: "100000" }}>
                            {
                                contractDatas.map((contract) => (
                                    <Select.Option value={contract.value}>{contract.label}</Select.Option>

                                ))
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item label="Période" style={{ marginBottom: 0 }} rules={[
                        {
                            required: true,
                            message: 'Entrez une période!',
                        },
                    ]}>
                        <Form.Item
                            name="start"
                            style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                        >
                            <DatePicker onChange={(date, dateString) => onDateChange(date, dateString, 'start')}
                                placeholder='Début' style={{ width: "100%" }} size='large' locale={locale} />

                        </Form.Item>

                        {
                            ( formData && (formData.currentjob == false || formData.currentjob == undefined))  &&
                            <Form.Item
                            onChange={(e) => handleChangeInput(e)}
                            name="end"
                            style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                        >
                            <DatePicker onChange={(date, dateString) => onDateChange(date, dateString, 'end')} placeholder='Fin' size='large' style={{ width: "100%" }} locale={locale} />

                        </Form.Item>
                        }
      
                    </Form.Item>





                    <Form.Item
                        label="Description"
                        name="description"
                        onChange={(e) => handleChangeInput(e)}
                        hasFeedback
                        rules={[
                            {
                                max: 500,
                                message: 'La description ne peut pas dépasser 500 caractères!',
                            }
                        ]}

                    >
                        <Input.TextArea size='large' maxLength={500} showCount />
                    </Form.Item>




                    <Form.Item className="item-next" style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button size="large" shape="round" type="primary" htmlType="submit">
                            Ajouter
                        </Button>
                    </Form.Item>
                </Form>
            </Container>
        </Modal>
    )
}

export const Container = styled.div`

    display:flex;
    flex-direction: column;
    padding: 5% 5%;
    height: 100%;
    width: 100%;

    h2{
        margin: 0px;
        font-size: 1.2em;
        font-weight: 700;
        margin-bottom: 5%;
    }

`

export const FormCV = styled.div`
    width: 50%;
    height: 90vh;
    background: white;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 40px;
    border-radius: 15px;
    overflow: auto;

    /* width */
    ::-webkit-scrollbar {
    width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: #F87778; 
    border-radius: 10px;
    
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background: #b30000; 
    }

    h1{
        font-size: 25px;
        color: black;
        font-weight: 700;
        margin-bottom: 25px;
    }

    h2 {
        font-size: 15px;
        color: #F87778;
        font-weight: 600;
    }

    h3 {
        font-size: 13px;
        color: #585858;
        font-weight: 600;
    }

    @media (max-width: 768px) {
        width: 90%;
        height: 80vh;
        padding: 2% 5%;

        h1{
            font-size: 1.2em;
            margin-bottom: 0%;
        }

        h2 {
            font-size: 1em;
        }
    }
`


export const HeaderCV = styled.div`
    display: flex;
    justify-content: center;
    
    
    align-items: center;
    transform: translateY(-13px);

`

export const CrossCV = styled.div`
    position:absolute;
    font-size: 2rem;
    font-weight: 900;
    cursor: pointer;
    transform: translateY(-5px);
    right: 0px;

    @media screen and (max-width: 768px) {
        top: 0px;
        right: -15px;
    } 
`
export const ContentCV = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    h1{
        font-size: 25px;
        color: black;
        font-weight: 700;
        margin-bottom: 25px;
    }

    h2 {
        font-size: 15px;
        color: #F87778;
        font-weight: 600;
    }

    h3 {
        font-size: 13px;
        color: #585858;
        font-weight: 600;
    }

    




    
`

export const InputGroupCV = styled.div`
    display:flex;
    width: 100%;
    justify-content: space-between;

    @media screen and (max-width: 768px) {

      flex-direction: column;
    }
`

export const DivInputGroupCV = styled.div`
    display:flex;
    flex-direction: column;
    width: 90%;
    max-width: 50%;

    @media screen and (max-width: 768px) {

        width: 100%;
    }


`

export const InputStyledCV = styled.input`
    background: #FAFAFA;
    border: none;
    width: 100%;
    outline: none;
    text-indent: 5px;
    padding: 10px 10px;
    margin-top: 5px;

    @media screen and (max-width: 768px) {

        
       font-size: 1em;
    }

    
`

export const InputSelected = styled.select`
    background: #FAFAFA;
    border: none;
    width: 100%;
    outline: none;
    text-indent: 5px;
    padding: 10px 10px;
    margin-top: 5px;
`



export const TextAreaStyledCV = styled.textarea`
    background: #FAFAFA;
    border: none;
    width: 100%;
    outline: none;
    text-indent: 5px;
    padding: 10px 10px;
    margin-top: 5px;
    margin-bottom: 15px;
`

export const ButtonValidate = styled.button`

    display: flex;
    background: #F87778;
    justify-content: center;
    align-items:center;
    outline: none;
    border: 1px solid #ddd;
    border-radius: 25px;
    padding: 12px 35px;

    span {
        padding-left: 20px;
        font-size: 14px;
        font-weight: 600;
        color: white;
    }

`
export const FooterCV = styled.div`
margin-top:20px;
    display: flex;
    button {
        flex: 1;
    }
`

const InputSearchList = styled.div`
    position: relative;

 

`

const Results = styled.div`
    position: absolute;
    width: 100%;
    min-width: 250px;
    background: #fafafa;
    max-height: 40vh;
    overflow: auto;
    top: 50px;
    border-radius: 5px;
`

const Result = styled.div`
    border-bottom: 1px solid #ddd;

    &:hover {
        background: #ddd;
        cursor: pointer;
    }
`

export const InputCompanyCV = styled.div`



`

const ResultsCompanies = styled.div`
    position: absolute;
    background: #fafafa;
    color: black;
    border: 1px gray solid;
    border-radius: 5px;
    z-index: 1000;
    width: 50%;


`
const ResultCompany = styled.div`
padding: 1% 5%;

&:hover {
    cursor:pointer;
    background:#e1dddd;
}
`
export const CheckboxDivCV = styled.div`
    display:flex;
    align-items:center;
    margin-top: 0px;
    margin-bottom: 15px;

    input {
        margin:0px;
        transform: translateY(1px);
        margin-left: 5px;
    }

    p{
        margin: 0px;
        color: #585858;
        font-weight: 500;
    }

    @media screen and (max-width: 768px) {

        margin-top: 8%;
        margin-bottom: 5%;
        p{
            font-size: 1em;
        }
    }
    
`

export default AddExperience
