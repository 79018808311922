import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { MdAccountCircle, MdEmojiPeople, MdLock, MdSecurity, MdSupervisorAccount, MdWork } from 'react-icons/md';
import { FaCircle} from 'react-icons/fa';
import { BsBellFill, BsThreeDots} from 'react-icons/bs';

import { ActionLink } from './accounts';
import useWindowDimensions from '../utils/useWindowsDimensions';
import { FaUser, FaUserFriends } from 'react-icons/fa';
import {AiOutlineRight} from 'react-icons/ai'
import {IoLogOut} from 'react-icons/io5'
import {RiLogoutBoxRFill, RiUser3Fill} from 'react-icons/ri'
import { useHistory } from 'react-router-dom';
import { logout } from '../redux/actions/authAction';
import { FiUser } from 'react-icons/fi';

const Settings = () => {

    const { auth} = useSelector(state=>state);
    const history = useHistory();
    const dispatch = useDispatch();
    const { height, width } = useWindowDimensions();

    const [choice, setChoice] = useState("account")
    
    return (
        <Container>
            <Main>
            
            {
                width >768 &&

                <Content>

           {/*      <PanelInfo>
                    <h1>Bonjour {auth.user.fullname}! </h1>
                    <p>Ici, vous pouvez gérer les paramètres de votre compte pour optimiser votre expérience Behandy. </p>
                </PanelInfo> */}

                <h3>Paramètres </h3>

                <ListActions >
                        <ActionLink to="/myaccount"><Action ><FaUserFriends className="icon" /> <span>Mon compte </span></Action> </ActionLink>
                        <ActionLink to="/accounts"><Action ><FaUserFriends className="icon"/> <span>Mes pages </span></Action> </ActionLink>

                </ListActions> 
           
{/*                 <ListChoices>
                    <ChoiceBtn onClick={() => setChoice("account")} active={choice==="account"? true: false}>
                            <span>Mon compte</span>
                            <FaCircle className='round' />
                    </ChoiceBtn>
                    <ChoiceBtn onClick={() => setChoice("pages")} active={choice==="pages"? true: false}>
                            <span>Mes pages</span>
                            <FaCircle className='round'/>
                    </ChoiceBtn>
                </ListChoices> */}
               

            </Content>
            
            }

            {
                width < 768 && 
                <ContentMobile>
                    <HeaderMobile>
                        <img src={ auth.user.avatar} alt=""/>
                        <HeaderRight>
                            
                            <h2>{auth.user.fullname}</h2>
                            <h3>{auth.user.titleJob}</h3>
                        </HeaderRight>
                    </HeaderMobile>

                    <BodyMobile>
                        <BodyItemMobile onClick={()=>history.push('/myaccount')} >
                            <FaUser className='icon-item' />
                            <h3>Mon compte</h3>
                            <AiOutlineRight className='icon-arrow'/>
                        </BodyItemMobile>
                        <BodyItemMobile onClick={()=>history.push('/accounts')}>
                            <FaUserFriends className='icon-item' />
                            <h3>Mes pages</h3>
                            <AiOutlineRight className='icon-arrow'/>
                        </BodyItemMobile>
         
                    </BodyMobile>

                    <FooterMobile>
                        <BodyItemMobile onClick={() => dispatch(logout())}>
                                <RiLogoutBoxRFill className='icon-item' />
                                <h3>Se déconnecter</h3>
                        </BodyItemMobile>
                    </FooterMobile>

                </ContentMobile>
            }
           
        </Main>
 
    </Container>
    )
}

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
   
`;


 const Main = styled.div`
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    .topbar{
        display:none;
            
    }
`;

 const Content = styled.div`
    width: 80%;
    display:flex;
    flex-direction: column;
    
    h3 {
        font-size: 20px;
        font-weight: 700;
        margin-top: 25px;
    }

 
`;


const PanelInfo = styled.div `
    
    background: #F87778;
    border-radius: 15px;
    color: white;

    padding: 10px;

    h1{
        font-size: 30px
    }


`
const ListActions = styled.div `
    display: flex;


    width: 100%;
    height: 100%;

`

const ListChoices = styled.div `
    display: flex;
    
`

const ChoiceBtn = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1% 2%;
    color: ${({ active }) => active ? '#F87778' : '#969696'};

    &:hover{
        cursor: pointer;
        span{
            font-weight: 700;

        }
    }
    span{
        margin: 0px;
        font-weight: ${({ active }) => active ? '700' : '400'};

        font-size: 1.0em;
    }
    .round {
        font-size: 0.7em;
        margin-top: 2px;
        color: ${({ active }) => active ? '#F87778' : 'transparent'};
    }

`

const Action = styled.div `

    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    width:${props => props.isMobile ? "100px" : "200px"};
    height:${props => props.isMobile ? "100px" : "200px"};
    border-radius: 15px;

    margin-left: 25px;
    margin-right: 25px;
    margin-top: 25px;

    span{
        width: 80%;
        
        display:${props => props.isMobile ? " none" : " flex"};
        justify-content : center;

    }
    .icon {
        font-size:${props => props.isMobile ? "70px" : "90px"};

        color: #F87778;
    }

    &:hover {
        background: #F87778;
        color: white;

        .icon {
            color: white;
        }
    }

    @media screen and (max-width: 768px) {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
`

const ContentMobile = styled.div`
    display:flex;
    flex-direction: column;
    width: 90%;
    
`
const HeaderMobile = styled.div`
    display:flex;
    width: 100%;
    align-items:center;
    padding: 2% 2%;
    img{
        width: 15vw;
        height: 15vw;
        margin-right: 10px;
        border-radius: 50%;
    }

    border-bottom: 1px solid #eeeeee;
`
const HeaderRight = styled.div`
    display:flex;
    flex-direction: column;

    h2{
        margin: 0px;
        font-size: 1em;
        color: #F87778;
        font-weight: 700;
    }
    h3{
        margin: 0px;
        font-size: 0.8em;
        color: #717171;
        font-weight: 400;
        
    }



`

const BodyMobile = styled.div`
    display:flex;
    flex-direction: column;
    margin-top: 10%;

`

const BodyItemMobile = styled.div`
    position: relative;
    display:flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    .icon-item{
        color: #F87778;
        background: #F0F4F7;
        font-size: 2.5em;
        padding: 10px;
        border-radius: 5px;
    }

    .icon-arrow{
        position: absolute;
        right: 0px;
        color: #F87778;
        font-size: 1.5em;
        top: 50%;
        transform: translateY(-50%);

    }

    h3{
        margin:0px;
        color: #F87778;
        font-size: 1em;
        margin-left: 4%;
    }

    &:hover{
        cursor: pointer;
        bakcground: #F0F4F7;
    }

`

const FooterMobile=styled.div`
    display:flex;
    width: 100%;;
    border-top: 1px solid #eeeeee;
    margin-top: 5%;

`

  
export default Settings
