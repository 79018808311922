import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { deleteSkill, recommendSkill, unrecommendSkill } from '../../../../redux/actions/skillAction'
import { Link, useParams } from 'react-router-dom'

import RecommendButton from './RecommendButton';
import Avatar from '../../../Avatar';
import { v4 as uuidv4 } from 'uuid';
import { EditIcon, MenuEdit } from '../experience/ExperienceCard'
import { MdCreate, MdDelete } from 'react-icons/md'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { GLOBALTYPES } from '../../../../redux/actions/globalTypes'
import useWindowDimensions from '../../../../utils/useWindowsDimensions';
import { isMobile } from "react-device-detect";

const SkillCard = ({skill}) => {
    const { auth, socket } = useSelector(state => state)
    const dispatch = useDispatch()
    const {id} = useParams();
    const [isRecommended, setIsRecommended] = useState(false)
    const [loadRecommendations, setLoadRecommendations] = useState(false)
    const [menuEdit, setMenuEdit] = useState(false);
    const { height, width } = useWindowDimensions();

    const [amountUsers, setAmountUserShown] = useState(4)

    // Recommendation
    useEffect(() => {
        if(width<768){
            setAmountUserShown(0)
        }else{
            setAmountUserShown(4)
        }
    }, [width])

    useEffect(() => {
        if(skill.recommendation.find(user => user._id === auth.user._id)){
            setIsRecommended(true)
        }else{
            setIsRecommended(false)


        }
    }, [skill.recommendation, auth.user._id])


 

    const handleRecommend = async () => {
        if(loadRecommendations) return;
        
        setLoadRecommendations(true)
        await dispatch(recommendSkill({skill, auth, socket}))

        setLoadRecommendations(false)

    }

    const handleUnrecommend= async () => {
        if(loadRecommendations) return;

        setLoadRecommendations(true)
        await dispatch(unrecommendSkill({skill, auth, socket}))
        setLoadRecommendations(false)
    }


    const handleDelete = ()  => {
        if(window.confirm("Êtes-vous sûr de vouloir supprimer cette compétence?")){
            dispatch(deleteSkill({id:skill._id,skill , auth}))   
             }
    }

    const handleEdit = () => {
        dispatch({ type: GLOBALTYPES.MODAL, payload: {skill:skill, typeModal:"addSkill", open:true, onEdit: true}}) 
    }

    return (
        <Container key={uuidv4()}>
            <Content>
                <AlbumLink>
                </AlbumLink>
               <SkillName>
                    {skill.name}
                </SkillName>
            <UsersList>
 {/*                {
                    skill.recommendation.slice(0, amountUsers).map(user => (
                        <Link style={{marginRight:"10px"}} to={`/profile/${user._id}`} key={uuidv4()} >
                            <Avatar className="avatar" src={user.avatar} title={user.fullname} key={user._id} size={width >768?"50px": "30px"} />
                        </Link>
                    ))
                }
                {
                    skill.recommendation.length - amountUsers > 0 && 
                    <>
                        <AmountCount>
                            <span>+{skill.recommendation.length - amountUsers }</span>
                        </AmountCount>
                    </>
                } */}
 
            </UsersList>

   {/*          {
                id !== auth.user._id && <>
                    <RecommendButton  isRecommended={isRecommended} handleRecommend={handleRecommend}  handleUnrecommend={handleUnrecommend}/>
                </>
            } */}
             {
                skill.user._id  === auth.user._id && 
                <EditIcon>
                    <BsThreeDotsVertical className="icon"  onClick={() => {setMenuEdit(!menuEdit); console.log(menuEdit)}} />
                    { menuEdit && <>
                        
                        <MenuEdit>
                        <MdCreate  onClick={()=> handleEdit()}className="icon"/>
                        <MdDelete onClick={()=> handleDelete()} className="icon"/>
                        </MenuEdit>
                    </>
                    }


            </EditIcon>
            }
            </Content>
        </Container>
    )
}

const Container = styled.div`
    display:flex;
    margin-bottom: 10px;
    padding: 1% 5%;

    @media (max-width: 768px) {
        padding: 5px;

    }

`

const Content = styled.div`
    display:flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

`

const AlbumLink = styled.div`
    display:flex;
    width:60px;
    height:60px;
    background: #F87778;
    border-radius: 100%;
    margin-right: 20px;

    
    @media (max-width: 768px) {
      
        width:40px;
        height:40px;


    }

`

const SkillName = styled.div `
    display:flex;
    width: 25%;
    text-overflow: ellipsis;
    @media (max-width: 768px) {
      
        width: 60%;
        font-size: 0.9em;

    }
`
const AmountCount = styled.div `
    display:flex;
    width:50px;
    height:50px;
    background: #E5E5E5;
    border-radius: 100%;
    justify-content: center;
    align-items: center;

    span {
        font-size: 20px;
        font-weight: bold;  
        color: #F87778;  
    }
    
    @media (max-width: 768px) {
      
        width:30px;
        height:30px;


    }
`
const UsersList = styled.div `
    display flex;
    justify-content: flex-start;
    flex: 1;
    padding: 0px 30px;
    width: 50%;

    .avatar{
        margin-right: 15px;
    }

    @media (max-width: 768px) {
        padding: 0px;
        justify-content: center;
    }
`

export default SkillCard;
