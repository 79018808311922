import { DeleteData, EditData } from '../actions/globalTypes';
import { LANGUAGES_TYPES } from '../actions/languageAction'

const initialState = {
    loading: false,
    languages: [],
    result: 0,
    page: 2
}

const languageReducer = (state = initialState, action) => {
    switch (action.type){
        case LANGUAGES_TYPES.CREATE_LANGUAGE:
            return {
                ...state,
                languages: [action.payload, ...state.languages]
            };
        case LANGUAGES_TYPES.LOADING_LANGUAGE:
            return {
                ...state,
                loading: action.payload
            };

        case LANGUAGES_TYPES.GET_LANGUAGES:
            return {
                ...state,
                languages: action.payload.languages,
                result: action.payload.result,
                page: action.payload.page
            };
        case LANGUAGES_TYPES.GET_LANGUAGES_USER:
                return {
                    ...state,
                    languages: action.payload.languages,
                    result: action.payload.result,
                    page: action.payload.page
                };
        case LANGUAGES_TYPES.UPDATE_LANGUAGE:
            return {
                ...state,
                languages: EditData(state.languages, action.payload._id, action.payload)
            };
        case LANGUAGES_TYPES.DELETE_LANGUAGE:
                return {
                    ...state,
                   languages: DeleteData(state.languages, action.payload._id)
               };
           case LANGUAGES_TYPES.UPDATED_LANGUAGE:
                   return {
                       ...state,
                       languages: EditData(state.languages, action.payload._id, action.payload)
                   };
        default:
            return state;
    }
}

export default languageReducer