import { GLOBALTYPES } from './globalTypes'
import { postDataAPI, getDataAPI, patchDataAPI, deleteDataAPI, putDataAPI } from '../../utils/fetchData'

import { imageUpload } from '../../utils/imageUpload'
import { getParamsFilter } from './userAction'

export const PARTNERSHIP_TYPES = {
    ANSWER_PARTNERSHIP: 'ANSWER_PARTNERSHIP',

    CREATE_PARTNERSHIP: 'CREATE_PARTNERSHIP',
    GET_PARTNERSHIP: 'GET_PARTNERSHIP',
    LOADING: 'LOADING',
    DELETE_PARTNERSHIP: 'DELETE_PARTNERSHIP'
}


/* export const createAds = ({content,logo,imgBackground, auth}) => async (dispatch) => {
    let logoImg = [];
    let imgBackgroundImg = [];
    try {


        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })
        
        if(logo.length > 0){
            logoImg = await imageUpload(logo)
        } 
    
        content.logo = logoImg[0].url;

        if(imgBackground.length > 0){
            imgBackgroundImg = await imageUpload(imgBackground)
        } 
        content.imgBackground = imgBackgroundImg[0].url;

  
        const res = await postDataAPI('mainAdvertisement', content, auth.token)

        dispatch({ 
            type: ADS_TYPES.CREATE_ADS, 
            payload: {...res.data.newAds, user: auth.user} 
        })

        dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: false} })

        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} });


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}
 */

export const getPartnerships= ({filter,auth}) => async (dispatch) => {
    try {
        dispatch({type: PARTNERSHIP_TYPES.LOADING, payload: true})

        let param = getParamsFilter(filter)
      
    
        const res = await getDataAPI(`/partnershipInvitation?`+ param, auth.token)


        dispatch({
            type: PARTNERSHIP_TYPES.GET_PARTNERSHIP,
            payload: res
        }) 
 
        dispatch({type: PARTNERSHIP_TYPES.LOADING, payload: false})


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
    }
    
}


export const answerInvitation = ({content, auth}) => async (dispatch) => {

    try {


        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })
        
     
        const res = await postDataAPI('partnershipInvitation/answer', content, auth.token)

        dispatch({ 
            type: PARTNERSHIP_TYPES.ANSWER_PARTNERSHIP, 
            payload: {...res.data.newAds, user: auth.user} 
        })

        dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: false} })

        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} });


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

 export const deletePartnership= ({invitation, auth}) => async (dispatch) => {

    console.log("jjj")
    try {
        const res = await putDataAPI(`/partnership/delete/${invitation._id}`, { requestingCompanyId: invitation.sender._id, partnerCompanyId: invitation.receiver._id} , auth.token)
        dispatch({ type: PARTNERSHIP_TYPES.DELETE_PARTNERSHIP, payload: invitation })

        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} });

        
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}




