import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components';
import { useHistory } from 'react-router-dom'
import { Button, Tag } from 'antd'
import { convertIdToLabel } from '../../../utils/function';
import { useSelector } from 'react-redux';

const DealPartnerCard = ({ deal, heightCard }) => {
    const [showContact, setShowContact] = useState(false);
    const [showOffer, setShowOffer] = useState(false);
    const {datas} = useSelector(state => state)
    const history = useHistory();

    const  [tags, setTags] = useState([])

    const [URL, setURL] = useState("");

    useEffect(() => {
        if(deal && deal.tags){
            let tagsStr = []
            deal.tags.forEach(tag => {
                tagsStr.push(convertIdToLabel(datas.filters, tag))
            });
            setTags(tagsStr);

        }
        if (process.env.REACT_APP_URL) {
            setURL(process.env.REACT_APP_URL);
        }
    }, []);

    const handleShowDeal = () => {
        setShowOffer(!showOffer)
    }

    const openInNewTab = (url) => {
        console.log(url)
        window.open(url);
    };

    const handleRedirect = () => {
        // Add any logic you want to execute before redirecting here
        
        window.location.href = deal.filePdf[0].url;
      };

    return (
        <Card height={heightCard}>
            <Top >

                {
                    deal.images && deal.images[0] ? <img  className='imgback' effect="blur"  src={deal.images[0].url} />
                        : <img  className='imgback'  effect="blur"  src="https://behandy-front.s3.eu-west-3.amazonaws.com/color-gd67b3d48d_640.png" alt="avatar" />

                }

<div style={{position: "absolute", top: "5px", left: "5px", display:"flex", gap:"2px" , marginTop:'5px'}}>
                        {
                            tags.map(tag =>(
                                <Tag style={{padding:"1px 10px" , color:"white", borderRadius:"50px"}} color="rgba(0, 0, 0, 0.5)" bordered={false}>{tag}</Tag>
                            ))
                        }
                        </div>
            </Top>

            <Bottom>
                {!showOffer && <img src={deal.company.avatar} className="img-company" alt={'logo entreprise'} onClick={() => history.push(`/company/${deal.company._id}`)} />}                <div>
                    {!showOffer && <>
                        <h2 className='title' onClick={() => history.push(`/company/${deal.company._id}`)}>{deal.company.name}</h2>
                        <h3>{deal.offer}</h3>
               

                        <p>{deal.description}</p>

                        {deal.tagsDescription && <div style={{display:"flex", gap:"2px" , marginTop:'5px'}}>
                        {
                            deal.tagsDescription.map(tag =>(
                                <Tag style={{padding:"1px 10px" , color:"#1DB4B4", borderRadius:"50px"}} color="cyan" bordered={false}>{tag}</Tag>
                            ))
                        }
                        </div>}

                 


                        <h5>{deal.localisation && deal.localisation.formatted_address}</h5>
                    </>}



                </div>




                <Button onClick={() => handleShowDeal()} type="default" size="large" style={{ margin: "0px auto" }}>J'en profite!</Button>


                {showOffer && <ModalityDiv>

<Content>
                <p>Pour profiter de cette promo:</p>

                { deal.filePdf && deal.filePdf.length > 0 && <PdfButton onClick={handleRedirect}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
                                        <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 7.91V16a6 6 0 0 0 6 6v0a6 6 0 0 0 6-6V6a4 4 0 0 0-4-4v0a4 4 0 0 0-4 4v9.182a2 2 0 0 0 2 2v0a2 2 0 0 0 2-2V8"/>
                                    </svg>
                                </PdfButton>}


                <Box>
                    {
                        deal.mode === "code" && <>
                            <span className='text'>Code promo:</span>
                            <span className='code'> {deal.modeInfos}</span>
                        </>
                    }
                </Box>

                {deal.website && <Button className='button' onClick={() => openInNewTab(deal.website)} type="default" size="large" style={{ margin: "0px auto" }}>Y aller!</Button>
                }

                {!deal.website && <div></div>
                }

            </Content>          </ModalityDiv>}

            </Bottom>


        </Card>
    )
}


const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;


const Card = styled.div`
    display:flex;
    flex-direction: column;
    height: 100%;
    background : transparent;
    .imgback{
        width: 100%;
        height: 100%;

        object-fit: cover;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

    }
    @media screen and (max-width: 768px) {
        min-height: 400px;
    }
`

const PdfButton = styled.div`
     position: absolute;
     top: 5px;
     right: 5px;

     &:hover{
        cursor: pointer;
     }
`

const ModalityDiv = styled.div`
  display: flex;
  background: white;
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;

   
  animation: ${slideUp} 0.5s forwards; // 0.5s is the duration, adjust as needed
`;

const Content = styled.div`
    display:flex;
    width: 100%;
    height: 100%;
    justify-content:space-around;
    align-items:center;
    flex-direction:column;

    text-align: center;
    padding: 4% 8%;

*

    p{
        margin:0px;
        padding: 0px;
        color: #585858 !important;
        font-size: 1.4em !important;
        font-weight: 900 !important;
    }




`

const Box = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    justify-content: center;
    align-items: center;
    padding: 30px;

    .text {
        font-style: italic;
        font-size: 0.9em;  // Corrected property from font-weight to font-size
    }
    .code {
        font-weight: 900;
        color: black;
        font-size: 1.1em;  // Corrected property from font-weight to font-size
    }
`;

const Top = styled.div`
    position: relative;
    height: 40%;
`

const Bottom = styled.div`
    overflow-wrap: break-word;
    position: relative;
    background : #F9F9F9;
    border: 1px solid #f2f2f2;
    height: 60%;
    flex: 1;
    padding: 2% 8%;
    display:flex;
    justify-content: space-between;
    flex-direction: column;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    .title{
        margin:0px;
        padding: 0px;
        font-size: 1.2em;
        font-weight: bold;
        color:#585858;
        &:hover{
            cursor: pointer;
        }
    }

    h3{
        margin:0px;
        padding: 0px;
        font-size: 1.1em;
        font-weight: 600;
        color: #1DB4B4 !important;
        font-style: italic;
        width: 82%;
    }

    h4{
        margin:0px;
        padding: 0px;
        color: #757474;

        font-size: 0.9em;
        font-weight: 400;
    }

    h5{
        margin:0px;
        padding: 0px;
        margin-top: 5%;
        color: black;
        font-size: 0.9em;
        font-weight: 700;
    }

    .amount{
        margin:0px;
        padding: 0px;
        font-size: 0.8em;
        font-weight: 700;
        color: #757474;
        margin-left: 5px;
    }

    p{
        margin:0px;
        margin-top: 5%;
        padding: 0px;
        font-size: 0.9em;
        font-weight: 400;
        color: #757474;
    }

    .icon-users{
        color:#757474;
        font-size: 1.2em;
    }

    img{
        position: absolute;
        width: 2.2em;
        height: 2.2em;
        top: 7px;
        right: 7px;
    }

    .img-company{
        position: absolute;
        top: 10px;
        right: 8%;
        width: 3.5em;
        height: 3.5em;
        border-radius: 50%;

        &:hover{
            cursor: pointer;
        }
    }

        margin-bottom: '50px';


    @media screen and (max-width: 768px) {
        .img-company{
           display:none;
        }

        .title{
            display:none;
        }

        h4{
            font-size: 0.8em;
        }
    } 

`



export default DealPartnerCard