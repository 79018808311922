import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Categories } from '../avantages/AvantagesComponents'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { getDataAPI } from '../../../utils/fetchData'
import { useDispatch, useSelector } from 'react-redux'
import InvitationPreviewCard from './InvitationPreviewCard'
import { getPartnerships } from '../../../redux/actions/partnershipActions'
import LoadIcon from '../../../images/loading.gif'
import InvitationPreviewCardMobile from './InvitationPreviewCardMobile'
import useWindowDimensions from '../../../utils/useWindowsDimensions'

const MyPartnersDemands = () => {
  const {auth, partnerships} = useSelector(state => state)
  const [choice, setChoice] = useState(1);
  const [invitations, setInvitations] = useState([]);
  const {width} = useWindowDimensions()
  const {id} = useParams()
  const dispatch = useDispatch()
  useEffect(async () => {
    if(choice === 1){
 
      let newfilter = {receiver: id, status:"pending"};
      dispatch(getPartnerships({ filter: newfilter, auth}))
    }else{
 
      let newfilter = {sender: id,  status:"pending"};
      dispatch(getPartnerships({ filter: newfilter, auth}))
    }

  }, [choice])
  
  return (
    <div style={{display:"flex", flexDirection:'column', gap:"15px"}}>
      <Categories>
        <Button shape="round" onClick={() => setChoice(1)} type={choice === 1 ? 'primary' : 'default'}>Reçues</Button>
        <Button shape="round" onClick={() => setChoice(2)} type={choice === 2 ? 'primary' : 'default'}>Envoyées</Button>
      </Categories>

 

{
          partnerships.loading
            ? <img style={{ margin: '10% 0%' }} src={LoadIcon} alt="loading" className="d-block mx-auto" />
            : (partnerships.count === 0)
              ? <div style={{ margin: '10% auto' }}> Aucune demande disponible</div>
              : <Container>

{
          partnerships.partnerships.map((invitation) => (
            <>{
              width > 768?
            <InvitationPreviewCard status={choice === 1?'received':'sended'} invitation={invitation} page={invitation.sender._id === id ? invitation.receiver : invitation.sender} />:
            <InvitationPreviewCardMobile  status={choice === 1?'received':'sended'} invitation={invitation} page={invitation.sender._id === id ? invitation.receiver : invitation.sender} />
           }
            </> ))
        }

              </Container>
        }


    </div>
  )
}

const Container = styled.div`
margin: 0px auto;
position: relative;
display: grid;
grid-template-columns: repeat(4, minmax(0, 1fr));

grid-gap: 20px;
@media only screen and (max-width: 768px) {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 900px) {
  grid-template-columns: repeat(2, 1fr );
  grid-template-rows: repeat(2, auto);
}

@media (min-width: 769px) and (max-width: 1280px) {
  /* Add your styles here */
  grid-template-columns: repeat(3, 1fr); /* Example: 3 columns */
}




width: 100%;
height: 100%;


`

export default MyPartnersDemands