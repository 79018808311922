import React from 'react'
import styled from 'styled-components'
import logo from '../assets/logo-violet.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const Cgu = () => {
    const history = useHistory();
    return (
        <Container>

<Logo onClick={()=> history.push('/')}>
            <img  className="logo" src={logo} alt="Logo" />

          </Logo>
            <h2>Conditions d’utilisation</h2>

            <h3>Date d'effet : 9 Octobre 2023</h3>

            <h4>Acceptation des Conditions d'Utilisation</h4>

            <div className='div-text'>
                En utilisant la <b>plateforme Behandy</b> (ci-après dénommée la "Plateforme"), vous acceptez
                sans réserve les présentes Conditions d'Utilisation.<br /><br />
                Dans le cadre des présentes Conditions d'Utilisation, les termes suivants auront les
                significations suivantes :<br /><br />
                <b>Utilisateurs</b> : Le terme "Utilisateurs" fait référence aux individus qui accèdent à la
                Plateforme Behandy et utilisent ses services. Il englobe à la fois les professionnels occupant
                un emploi dans le secteur de l'hôtellerie-restauration et les personnes motivées et
                disponibles pour travailler dans ce secteur, conformément aux critères d'adhésion spécifiés
                dans les présentes Conditions d'Utilisation.<br /><br />
                <b>Partenaires</b> : Le terme "Partenaire" désigne les établissements de l'hôtellerie-restauration
                tels que les Hôtels, Restaurants, Villages Vacances, Campings, Bars & Cafés, Parcs
                d'Attraction qui ont établi un partenariat avec Behandy pour trouver des talents à recruter.
                Les Partenaires utilisent la Plateforme pour offrir des Avantages et des Récompenses aux
                Utilisateurs dans le but de récolter leur CV virtuel et générer des opportunités
                professionnelles.<br /><br />
                <b>Plateforme </b>: La "Plateforme" fait référence à la plateforme en ligne Behandy, développée et
                mise à disposition par Behandy SAS, qui permet la mise en relation des Utilisateurs et des
                Partenaires. Elle englobe le site web, les applications mobiles, les services associés et toute
                autre fonctionnalité ou technologie fournie par Behandy pour faciliter les interactions entre
                Utilisateurs et Partenaires.

            </div>


            <h4>Description de la Plateforme</h4>

            <div className='div-text'>
                La Plateforme Behandy met en relation des professionnels des métiers de
                l'hôtellerie-restauration et des personnes motivées pour travailler dans ce secteur avec des
                établissements tels que des Hôtels, Restaurants, Villages Vacances, Campings, Bars &
                Cafés, Parcs d'Attraction (ci-après dénommés les "Partenaires") pour générer des
                opportunités professionnelles. Cette mise en relation se fait par le biais d'un premier contact
                sincère et unique, à savoir un avantage ou une récompense envoyé par le Partenaire à son
                public cible.
            </div>

            <h4>Utilisateurs et Admissibilité
            </h4>

            <div className='div-text'>
                La Plateforme est ouverte à tout professionnel occupant un emploi dans le secteur de
                l'hôtellerie-restauration ou à toute personne motivée et disponible pour travailler dans ce
                secteur, âgée de plus de quinze ans. Tout utilisateur ne correspondant pas à ces critères
                d'adhésion ne peut pas s'inscrire sur Behandy. Les comptes non conformes seront signalés
                et supprimés par les équipes de Behandy.<br /><br />
                Les utilisateurs sont responsables de l'exactitude de leurs informations et doivent se
                comporter de manière respectueuse envers les autres utilisateurs et les Partenaires sur la
                Plateforme.<br /><br />
                Behandy SAS agit en tant que plateforme de mise en relation entre les Utilisateurs et ses
                Partenaires. Bien que nous mettions en place des mesures pour inciter à l'authenticité des
                profils des Utilisateurs, nous ne pouvons garantir l'exactitude des informations fournies par
                les Utilisateurs. Ainsi, Behandy SAS ne peut en aucun cas être tenu responsable si un
                établissement partenaire collecte un profil qui a fourni des informations trompeuses,
                erronées ou mensongères sur ses expériences professionnelles ou qui ne correspond pas
                aux critères d'adhésion spécifiés dans les présentes Conditions d'Utilisation.<br /><br />
                Les Partenaires sont encouragés à exercer leur propre diligence raisonnable lors de
                l'évaluation des profils des Utilisateurs et à vérifier les informations fournies par les
                candidats, notamment en effectuant des vérifications d'emploi, des entretiens et d'autres
                procédures de sélection. Behandy SAS n'a pas la capacité de garantir l'exactitude des
                déclarations faites par les Utilisateurs et ne peut être tenu responsable des conséquences
                découlant de l'embauche d'un Utilisateur dont les informations se révèlent ultérieurement
                incorrectes ou trompeuses. Les Partenaires sont responsables de leurs propres décisions
                d'embauche et sont invités à exercer leur discernement lors de l'évaluation des candidatures
                reçues par le biais de la Plateforme Behandy.

            </div>

            <h4>Partenariats
            </h4>

            <div className='div-text'>
                Behandy établit des partenariats avec des entreprises qui souhaitent attirer des talents sur
                les métiers de l’hôtellerie et de la restauration. Ce partenariat donne lieu, à compter de
                l'ouverture de la Plateforme, à une phase de gratuité sans engagement dont la durée est
                définie par les dirigeants de Behandy SAS et susceptible d’évoluer.<br /><br />
                Behandy SAS se réserve le droit discrétionnaire de refuser l'accès à la Plateforme à toute
                entreprise ou entité sans avoir à justifier sa décision. Elle peut exercer ce droit si elle
                considère qu'une entreprise ne répond pas à ses critères d'adhésion, ne respecte pas ses
                valeurs éthiques, ou si elle émet des préoccupations concernant ses pratiques
                professionnelles.<br /><br />
                Behandy SAS peut également refuser l'accès à la Plateforme à une entreprise si elle des
                raisons de croire que sa présence pourrait compromettre la sécurité, l'intégrité ou la
                réputation de la Plateforme ou de ses Utilisateurs. Cette décision peut être prise à son
                entière discrétion et sans préavis.<br /><br />
                L’objectif de ces mesures est de garantir un environnement sûr, respectueux et professionnel
                sur la Plateforme. Cette politique vise à préserver l'intérêt des Utilisateurs et à maintenir un
                niveau de qualité élevé dans les opportunités professionnelles offertes par les Partenaires.

            </div>

            <h4>Paiements et Abonnements

            </h4>

            <div className='div-text'>
                La Plateforme n'est pas encore en mesure de collecter les paiements éventuels. Les
                partenaires seront informés du délai de mise en place des abonnements et des tarifs
                associés.<br /><br />
                Des conditions particulières seront proposées aux Partenaires “Fondateurs”, qui ont rejoint la
                plateforme dès son lancement sur proposition des dirigeants de Behandy SAS.<br /><br />
                L'accord du Partenaire pour l'inscription sur la Plateforme et le démarrage de son activité sur
                Behandy ne vaut en aucun cas accord pour la souscription à un éventuel abonnement
                payant. Le Partenaire peut se désengager à tout moment de la phase de gratuité.
            </div>


            <h4>Protection des Données Personnelles des Utilisateurs

            </h4>

            <div className='div-text'>
                Behandy SAS s'engage à protéger les données personnelles de ses Utilisateurs
                conformément à la réglementation en vigueur, notamment le Règlement Général sur la
                Protection des Données (RGPD). Dans le cadre de l'utilisation de la Plateforme, nous
                collectons uniquement les informations essentielles au fonctionnement de celle-ci et à la
                mise en relation entre les Utilisateurs et les Partenaires.<br /><br />
                Pour la création d'un compte, nous demandons aux Utilisateurs de fournir les informations
                suivantes : nom, date de naissance, ville de résidence, adresse e-mail et numéro de
                téléphone. Lors de la création du CV virtuel, les Utilisateurs peuvent ajouter des détails sur
                leurs expériences professionnelles, leurs formations et leurs compétences. Ces données
                sont stockées de manière sécurisée et ne sont en aucun cas vendues à des tiers.<br /><br />
                Lorsque les données du CV virtuel ou les coordonnées de l'Utilisateur sont sur le point d'être
                transmises à un Partenaire dans le cadre d'une opportunité professionnelle, nous informons
                préalablement l'Utilisateur de cette transmission imminente. Pour assurer la transparence et
                le consentement de l'Utilisateur, nous générons son accord via un bouton d'action clairement
                indiqué. Cette procédure garantit que l'Utilisateur est pleinement conscient de la
                transmission de ses données et a donné son consentement éclairé avant toute
                communication avec un Partenaire.<br /><br />
                Nous prenons des mesures strictes pour sécuriser toutes les données collectées et nous
                nous engageons à maintenir la confidentialité et l'intégrité des informations personnelles de
                nos Utilisateurs. Si vous avez des préoccupations spécifiques concernant la confidentialité
                ou la sécurité de vos données, n'hésitez pas à nous contacter à l'adresse e-mail
                <b>contact@behandy.fr</b> pour obtenir des informations supplémentaires ou pour exercer vos
                droits en matière de protection des données.

            </div>

            <h4>Comptes Entreprise


            </h4>

            <div className='div-text'>
                La création d'un compte entreprise est ouverte à tous. Behandy fournit l'infrastructure
                technique et son support, mais n'est pas responsable des agissements d'un utilisateur
                décidant de créer un compte correspondant à une marque, un groupe ou un établissement
                dont il n'est pas un représentant habilité.<br /><br />
                Behandy collaborera techniquement avec toute entreprise se sentant lésée et ayant prouvé
                le tort qui lui aurait été causé par un tiers, afin de supprimer le compte en question.


            </div>

            <h4>Protection des Données des Partenaires</h4>
            <div className='div-text'>

                Behandy SAS s'engage à protéger les données personnelles de ses Partenaires
                conformément à la réglementation en vigueur, notamment le Règlement Général sur la
                Protection des Données (RGPD). Dans le cadre de l'utilisation de la Plateforme, nous
                collectons uniquement les informations essentielles au fonctionnement de celle-ci et à la
                mise en relation entre les Utilisateurs et les Partenaires.<br /><br />
                Pour la création d'un compte Entreprises, nous demandons aux Partenaires et à leur
                représentant habilité de fournir les informations suivantes : Dénomination sociale de la
                maison-mère, Dénomination sociale de l'entreprise, N°Siret, Adresse Postale, Nom de
                l'établissement, Type d'établissement, Adresse de l'établissement, Email, Site Web;
                Spécialités, Description. Ces données sont stockées de manière sécurisée et ne sont en
                aucun cas vendues à des tiers.<br /><br />
                A noter : la création d’un compte Utilisateur personnel est nécessaire pour pouvoir créer un
                compte Entreprise. En aucun cas les informations du compte Utilisateur personnel ne sont
                visibles par les autres administrateurs du compte Entreprise commun à plusieurs Utilisateurs.
                Nous prenons des mesures strictes pour sécuriser toutes les données collectées et nous
                nous engageons à maintenir la confidentialité et l'intégrité des informations personnelles de
                nos Utilisateurs. Si vous avez des préoccupations spécifiques concernant la confidentialité
                ou la sécurité de vos données, n'hésitez pas à nous contacter à l'adresse e-mail
                contact@behandy.fr pour obtenir des informations supplémentaires ou pour exercer vos
                droits en matière de protection des données.<br /><br />
            </div>
            <h4>Litiges et Signalement</h4>

            <div className='div-text'>

                En cas de litige entre utilisateurs et Partenaires, un bouton de signalement sera implémenté.
                Les équipes support sont également joignables via contact@behandy.fr. Behandy n'est pas
                responsable des conditions d'utilisation et de vente des Avantages promus sur la Plateforme,
                ni de la bonne réalisation des Récompenses publiées.<br /><br />
                Behandy SAS n'est pas responsable des litiges, différends ou conflits survenant entre les
                Utilisateurs et les Partenaires, que ce soit concernant un Avantage ou une Récompense
                proposé sur la Plateforme ou dans le cadre de leur relation professionnelle subséquente. Les
                Utilisateurs et les Partenaires sont tenus de gérer leurs litiges entre eux, conformément à la
                loi française en vigueur.<br /><br />
                Behandy SAS n'a aucune obligation de médiation ou d'intervention dans les conflits
                survenant entre les Utilisateurs et les Partenaires, que ces conflits résultent des conditions
                d'un Avantage, d'une Récompense ou de toute interaction professionnelle ultérieure. En
                utilisant la Plateforme, les Utilisateurs et les Partenaires acceptent de résoudre eux-mêmes
                tout litige ou désaccord et de ne pas impliquer Behandy SAS dans ces questions.<br /><br />
                Behandy SAS encourage les Utilisateurs et les Partenaires à communiquer de manière
                ouverte et à résoudre tout différend de manière professionnelle et respectueuse. Si un litige
                ne peut être résolu de manière amiable, les parties concernées sont invitées à recourir aux
                procédures légales appropriées en vertu de la loi française. Behandy SAS se dégage de
                toute responsabilité découlant des litiges entre Utilisateurs et Partenaires, et ne peut être
                tenu pour responsable des conséquences résultant de ces litiges.<br /><br />
                La Plateforme développée et mise à disposition par Behandy SAS vise à (re)donner envie
                aux Talents de travailler dans l'hôtellerie-restauration en offrant la possibilité aux Partenaires
                de se démarquer via des Avantages et des Récompenses attractifs. Nous encourageons
                utilisateurs et Partenaires à respecter cette mission en faisant preuve d'un état d'esprit de
                collaboration et de respect mutuel, dans la droite ligne de l'état d'esprit promu par le projet et
                ses fondateurs.
            </div>
            <h4>Modifications des Conditions d'Utilisation</h4>
            <div className='div-text'>

                Toute modification des conditions d'utilisation sera notifiée aux utilisateurs et aux Partenaires
                via leurs comptes respectifs et/ou par e-mail.</div>
        </Container>
    )
}

const Container = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;

    padding: 30px 15%;

    h2{
        font-weight: 900;

    }


    h3{
        align-self: flex-start;
        font-weight: 500;
        font-size: 1.3em;

    }
    h4{
        align-self: flex-start;
        font-weight: 900;
        margin: 20px 0px;

    }
    .div-text{
    }

`

const Logo = styled.div`
    &:hover{
        cursor: pointer;
    }
    
    margin-bottom: 30px;
    .logo{
        width:300px;
    }
`

export default Cgu