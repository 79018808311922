import React, { useState, useEffect } from 'react'
import {useHistory } from 'react-router-dom'
import { login } from '../redux/actions/authAction'
import { useDispatch, useSelector } from 'react-redux'
import styled from "styled-components";
import logo from '../assets/logo-violet.png';
import imgLogin from '../assets/login.png';
import CreateAccountModal from '../components/login/CreateAccountModal';
import { GLOBALTYPES } from '../redux/actions/globalTypes';
import {StyledLink} from "../components/Form/FormElements";
import { Button, Input } from 'antd';

const Login = () => {
    const initialState = { email: '', password: '' }
    const [userData, setUserData] = useState(initialState)
    const { email, password } = userData
    const [typePass, setTypePass] = useState(false)
    const { auth, alert } = useSelector(state => state)

    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        if(auth.token) history.push("/")
    }, [auth.token, history])

    const handleChangeInput = e => {
        const { name, value } = e.target
        setUserData({...userData, [name]:value})
    }

    const handleSubmit = e => {
        e.preventDefault()
        dispatch(login(userData))
        
    }

    const [address, setAddress] = useState("");


    return (
        <Container>

          {   <CreateAccountModal/>}

      <LeftSide>
          <Logo onClick={()=> history.push('/')}>
            <img  className="logo" src={logo} alt="Logo" />

          </Logo>
          <LeftText><p>L’appli qui connecte et récompense <span>les professions culinaires</span> </p></LeftText>

          <img alt="login" src={imgLogin}/>
      </LeftSide>

      <RightSide>
{/*        <SearchAddress onAddressChange={handleAddressChange}/>
      <p>Selected Address: {address}</p> */}
       
      <LogoMobile onClick={()=> history.push('/')}>
            <img  className="logo" src={logo} alt="Logo" />

          </LogoMobile>
          <Form>
            
            <div style={{display: "flex", justifyContent:"center", textAlign: "center"}}>
              <h3 style={{fontSize: "25px", marginBottom:"7%", fontWeight:"700"}}>Se connecter à Behandy</h3>
            </div>


           <InputForm>
              <span>Email</span>
              <Input placeholder="Entrez votre email" type="email"  onChange={handleChangeInput} value={email} name="email" required  />
              <small className="form-text text-danger">
                        {alert.email ? alert.email : ''}
              </small>
           </InputForm>


           <InputForm>
              <span>Mot de passe</span>
              <Input placeholder="Entrez votre mot de passe" type={ typePass ? "text" : "password" }  onChange={handleChangeInput} value={password} name="password" required />
              <small className="form-text text-danger">
                        {alert.password ? alert.password : ''}
              </small>
           </InputForm>

           <div style={{display:"flex", justifyContent:"space-between", marginBottom: "5%"}}>
           <RememberMe>
              <input type="checkbox" id="rememberme" name="rememberme"
                    checked/>
              <label for="rememberme">Se souvenir de moi</label>
            </RememberMe>

            <StyledLink to="/forgotPassword" style={{textAlign: "center !important"}}><span style={{color:"var(--color-accent)", fontWeight:"600",fontSize:"14px"}}>Mot de passe oublié?</span></StyledLink>
           </div>



           <Button size="large" shape="round" type="primary" onClick={handleSubmit}>
             <span>Se connecter</span>
           </Button>

           <div>
            <p style={{fontWeight:"600", fontSize:"14px"}}>
              Pas de compte? <ButtonCreateAccount onClick={() =>           dispatch({ type: GLOBALTYPES.MODAL,payload: {typeModal:"createAccountModal", open:true}})
}>Créer un compte!</ButtonCreateAccount>
            </p>
          </div>

          </Form>
      </RightSide>
 

    </Container>
    )
}


const Container = styled.div`
    display:flex;
    width: 100vw;
    height: 100vh;
  

 
  
`

const LeftSide = styled.div`
    display:flex;
    background: #FAFAFA;
    flex-direction: column;
    padding-left: 5%;
    padding-top: 5%;
    width: 40%;
    height: 100vh;

    @media (max-width: 768px) {
      display: none;
      
    }
`
const Logo = styled.div`
  display:flex;
  align-items:center;

  .logo {
    width:250px;
    transform: translateX(-35px);
  }

  h1 {
      font-size: 30px;
  }

  &:hover{
    cursor: pointer;
  }

`;

const LogoMobile = styled.div`
  position:absolute;
  top: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  display:none;
  .logo {
    width:200px;
  }

  &:hover{
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
   display:flex;
  }

`;

const LeftText = styled.div`
  margin-top: 5%;
  width: 70%;
  font-size: 30px;
  font-weight: 700;
  span {
    color: var(--color-accent);
  }
  
`
const RightSide = styled.div`
    display:flex;
    padding-top: 7%;

    background: white;
    flex-direction: column;
    align-items: center;
    flex: 1;
    height: 100vh;

    @media screen and (max-width: 768px) {
      padding-top: 0px;
      justify-content: center;
    }

`

const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 80%;

  }




`

const ButtonIcon = styled.button`
    display: flex;
    background: white;
    justify-content: center;
    align-items:center;
    outline: none;
    border: 1px solid #ddd;
    border-radius: 25px;
    padding: 12px 35px;
    margin-bottom: 5%;
    
    span {
      padding-left: 20px;
      font-size: 14px;
      font-weight: 600;
    }


`

export const ButtonLogin = styled.button`
    display: flex;
    background: #F87778;
    justify-content: center;
    align-items:center;
    outline: none;
    border: 1px solid #ddd;
    border-radius: 25px;
    padding: 12px 35px;
    margin-bottom: 5%;
    
    span {
      padding-left: 20px;
      font-size: 14px;
      font-weight: 600;
      color: white;
    }


`

const DivMailConnection = styled.div`
    display:flex;
    justify-content: space-between;
    align-items:center;
    margin-bottom: 5%;

    
`

const TextMailConnection = styled.div`
    display:flex;
    span {
      font-size: 14px;
      color: #969696;
    }

    
`
const Line = styled.div`
    width: 20%;
    background: #969696;
    height:0.5px;
    @media screen and (max-width: 768px) {
      width: 10%;

    }

`

const InputForm = styled.div`
    display:flex;
    flex-direction: column;
    margin-bottom: 5%;
    span {
      font-size: 14px;
      font-weight: 600;
      color: #585858;
    }

    input {
      background: white;
      border: 0.5px solid #969696;
      border-radius: 25px;
      width: 100%;
      outline: none;
      text-indent: 5px;

      padding: 10px 10px;
    }

`

const RememberMe = styled.div `
    display: flex;
    align-items: center;
    text-align: center;
    label {
      margin-left: 10px;
      font-size: 14px;
      font-weight: 600;
      color: #585858;
      transform: translateY(2px);
    }


`

export const ButtonCreateAccount = styled.span`

    color:var(--color-accent);
    font-weight: 600;
    font-size: 14px;

    cursor: pointer;


`

export default Login
