import React from 'react'
import {useParams} from 'react-router-dom'
import NotFound from '../components/NotFound'
import { useSelector } from 'react-redux'
import Login from '../pages/login'
import ForgotPassword from '../pages/forgotPassword'
import ResetPassword from '../pages/resetPassword'
import Confirm from '../pages/confirm'
import Cgu from '../pages/cgu'
const generatePage = (pageName) => {
    const component = () => require(`../pages/${pageName}`).default
    try {
        return React.createElement(component())
    } catch (err) {
        return <NotFound />
    }
}

const PageRender = () => {
    const {page, id} = useParams()
    const { auth } = useSelector(state => state)
    //EXCEPTION
    if(page === "login")
        return <Login/>
    if(page === "confirm")
        return <Confirm/>
    if(page === "cgu")
        return <Cgu/>
    else if(page=== "forgotPassword"){
        return <ForgotPassword/>
    }
    else if(page=== "resetPassword"){
        return <ResetPassword/>
    } 

    let pageName = "";
    if(auth.token){
        if(id){
            pageName = `${page}/[id]`
        }else{
            pageName = `${page}`
        }
    }
    return generatePage(pageName)
}
export default PageRender
