
import { FILTER_TYPES } from '../actions/filterActions';

const initialState = {
   filter: null
}




const filterReducer = (state = initialState, action) => {
    switch (action.type){
     
        case FILTER_TYPES.UPDATE_FILTERS:
                return {
                     ...state,
                    filter: action.payload.filter
                };
        default:
            return state;
    }
}


export default filterReducer
