import { Button, Form, Input } from 'antd'
import React from 'react'
import SearchAddress from '../../utils/SearchAddress'
import { useState } from 'react'
import { useEffect } from 'react'
import { checkImage } from '../../../utils/imageUpload'
import { GLOBALTYPES } from '../../../redux/actions/globalTypes'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
const FormManagementCompanyPart1 = ({avatar, setAvatar, current, setCurrent, formData, setFormData, company}) => {
    const [localisationText, setLocalisationText] = useState("");
    const [localisationObject, setLocalisationObject] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if(formData.postalAddress){
            setLocalisationText(formData.postalAddress.formatted_address)
            setLocalisationObject(formData.postalAddress)
        }

    }, [])
    


    const handleChangeInput = e => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })

    }

          
  const handleAddressChange = (newAddress) => {

    setLocalisationText(newAddress.formatted_address) 
    const { formatted_address,address_components, geometry, geoFormat } = newAddress;
    const newObject = { formatted_address,address_components, geometry, geoFormat };
    setLocalisationObject(newObject)


  }

  const changeAvatar = (e) => {
    const file = e.target.files[0]

    const err = checkImage(file)
    if (err) return dispatch({
        type: GLOBALTYPES.ALERT, payload: { error: err }
    })

    setAvatar(file)
}

  return (
    <Form
    onFinish={ () => {setCurrent(current + 1)}}
    layout="vertical"
    style={{width: "100%"}}
    initialValues={{registeredName: formData.registeredName, siret: formData.siret, postalAddress: formData.postalAddress}}
  >

<InfoAvatar>
                    <img src={avatar ? URL.createObjectURL(avatar) : formData.avatar}
                        alt="avatar" />
                    <span>
                        <i className="fas fa-camera" />
                        <p>Change</p>
                        <input type="file" name="file" id="file_up"
                             accept=".jpg, .jpeg, .png"  onChange={changeAvatar} />
                    </span>
                              </InfoAvatar>
         <Form.Item
             label={company.typeCompany === "inde"?"Dénomination sociale de l’entreprise": "Dénomination sociale de la maison mère*" }
             name="registeredName"
             onChange={(e)=>handleChangeInput(e)}
             hasFeedback

             rules={[
                 {
                 required: true,
                 message: 'Entrez votre dénomination sociale!',

                 },
                 {
                   max: 120,
                   message: 'La dénomination sociale ne peut pas dépasser 120 caractères!',
                 }
             ]}
             >
             <Input  name="registeredName"  size="large" maxLength={120}/>
         </Form.Item>




         
         <Form.Item
             label="N° SIRET"
             name="siret"
             rules={[
                 {
                 required: true,
                 message: 'Entrez votre SIRET!',
                 },
                 {
                 validator: (_, value) =>
                     value && /^\d{14}$/.test(value) 
                     ? Promise.resolve() 
                     : Promise.reject(new Error('SIRET doit contenir exactement 14 chiffres!')),
                 },
             ]}
             hasFeedback
             onChange={(e)=>handleChangeInput(e)}
             >
             <Input name="siret" size='large'/>
             </Form.Item>

             <Form.Item
                 label="Adresse postale"
                 name="postalAddress"

                 >
                 <SearchAddress onAddressChange={handleAddressChange} address={localisationText} setAddress={setLocalisationText} />
             

         </Form.Item>





             <Form.Item className="item-next"  style={{display:'flex', justifyContent:'center' , marginTop:'35px'}}>
                 <Button size="large" shape="round" type="primary" htmlType="submit">
                     Suivant
                 </Button>
             </Form.Item>
         </Form>
  )
}


const InfoAvatar = styled.div`

    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    margin: 15px auto;
    border: 1px solid #ddd;
    cursor: pointer;

    img{
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
    }

    span{
        position: absolute;
        bottom: -100%;
        left: 0;
        width: 100%;
        height: 50%;
        text-align: center;
        color: orange;
        transition: 0.3s ease-in-out;
        background: #fff5;
    }

    &:hover span{
        bottom: -15%;
    }

    #file_up{
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
    }

    @media screen and (max-width: 768px) {
        width: 80px;
        height: 80px;
    } 
    

`

export default FormManagementCompanyPart1