import React from 'react'

import { Input, Form, Button, Select } from 'antd';
import SearchAddress from '../../../utils/SearchAddress';
import { useState } from 'react';
import { useEffect } from 'react';
import { ContainerForm } from './FormCompany1';
import { useSelector } from 'react-redux';


const FormCompany2 = ({ company, onChange, step, onStepChange }) => {

    const  {datas} = useSelector(state=>state);

    const [localisationText, setLocalisationText] = useState("")
    const [localisationObject, setLocalisationObject] = useState(null)

    useEffect(() => {
        if (localisationObject) {
            setLocalisationText(localisationObject.formatted_address)
            const updatedCompany = { ...company, ['address']: localisationObject };
            onChange(updatedCompany);
        }
    }, [localisationObject])


    const handleChangeInput = e => {
        const { name, value } = e.target
        const updatedCompany = { ...company, [name]: value };
        console.log(updatedCompany)
        onChange(updatedCompany);

    }


    const handleAddressChange = (newAddress) => {

        setLocalisationText(newAddress.formatted_address)
        const { formatted_address, address_components, geometry } = newAddress;
        const newObject = { formatted_address, address_components, geometry };
        setLocalisationObject(newObject)


    }

    return (
        <ContainerForm>
            <h3>C’est parti, créons la page de votre établissement !</h3>


            <Form
                onFinish={() => { onStepChange(step + 1) }}
                layout="vertical"
                style={{ width: "100%" }}
            >
                <Form.Item
                    label="Nom d'établissement"
                    name="name"
                    onChange={(e) => handleChangeInput(e)}
                    hasFeedback

                    rules={[
                        {
                            required: true,
                            message: "Entrez le nom de l'entreprise!",

                        },
                        {
                            max: 120,
                            message: 'Le nom de l\'entreprise ne peut pas dépasser 120 caractères!',
                          }
                    ]}
                >
                    <Input name="name" size="large" maxLength={120} />
                </Form.Item>


                <Form.Item name="sector" label="Type d'établissement" rules={[{ required: true, message: 'Sélectionnez le type!', }]}>
                    <Select onChange={(e) => { onChange({ ...company, ['sector']: e }) }} size="large" placeholder="Sélectionnez type" dropdownStyle={{ zIndex: "100000" }}>

                        {
                            datas.companyTypes.map((sector) => (
                                <Select.Option value={sector.id}>{sector.name}</Select.Option>

                            ))
                        }
                    </Select>
                </Form.Item>



                <Form.Item
                    label="Adresse de l'établissement"
                    name="address"

                >
                    <SearchAddress onAddressChange={handleAddressChange} address={localisationText} setAddress={setLocalisationText} />


                </Form.Item>


                <Form.Item
                    label="Email"
                    name="email"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: "Entrez l'adresse email",
                        },
                        {
                            type: 'email',
                            message: "Entrez une adresse email valide",
                        },
                        {
                            max: 120,
                            message: 'L`\'email ne peut pas dépasser 120 caractères!',
                          }
                    ]}
                    onChange={(e) => handleChangeInput(e)}
                >
                    <Input name="email" size="large" maxLength={120}/>
                </Form.Item>


                <Form.Item
                    label="Téléphone"
                    name="phone"
                    rules={[
           
                        {
                            pattern: /^0\d{9}$/,
                            message: 'Le numéro de téléphone doit comporter 10 chiffres et commencer par 0',
                        }
                    ]}
                    hasFeedback
                    onChange={(e) => handleChangeInput(e)}
                >
                    <Input name="phone" size="large" />
                </Form.Item>


                <Form.Item
                    label="Site web"
                    name="website"


                    rules={[
                        {
                            message: "Entrez le site web",
                        },
                        {
                            max: 120,
                            message: 'L\' adresse du site ne peut pas dépasser 120 caractères!',
                          }
                    ]}

                    onChange={(e) => handleChangeInput(e)}

                >
                    <Input name="website" size="large" maxLength={120}/>
                </Form.Item>



                <Form.Item className="item-next"  style={{display:'flex', justifyContent:'center' , marginTop:'35px'}}>
                    <Button size="large" shape="round" type="primary" htmlType="submit">
                        Suivant
                    </Button>
                </Form.Item>
            </Form>
        </ContainerForm>
    );
};

export default FormCompany2;

