import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from "styled-components"
import { register } from '../../redux/actions/authAction'

import Modal from '../utils/Modal/Modal'
import LogoImg from '../../assets/logo-violet.png'

import {
    Form,
    Input,
    Button,
    Select,
    Checkbox,
} from 'antd';
import { AiOutlineArrowLeft } from 'react-icons/ai'
import SearchAddress from '../utils/SearchAddress'
import AccountCreation from './AccountCreation'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const { Option } = Select;


const CreateAccountModal = () => {
    const { status, modal } = useSelector(state => state)
    const dispatch = useDispatch()
    const history = useHistory();

    const initialState = {
        lastname: '', firstname: '', email: '', mobile: '', password: '', cf_password: '', titleJob: '', companyName: ''
    }


    const [localisationText, setLocalisationText] = useState("")
    const [localisationObject, setLocalisationObject] = useState(null)
    const [step, setStep] = useState(1)
    const [choiceCompany, setChoiceCompany] = useState(null)

    const [formData, setFormData] = useState(initialState)
    const { lastname, firstname, email, gender, mobile, city, country, password, cf_password, sector, experience, titleJob } = formData


    useEffect(() => {
        if (localisationObject) {
            setLocalisationText(localisationObject.formatted_address)
        }
        setFormData({ ...formData, ['address']: localisationObject })

    }, [localisationObject])




    useEffect(() => {
        if (status.success) {
            if (choiceCompany === 1) {
                setStep(11)
            } else {
                setStep(6)
            }


        }
    }, [status])

    useEffect(() => {
        if (modal.open) {
            setStep(1)
            setLocalisationText("")
            setLocalisationObject(null)
            setFormData(initialState)
        }
    }, [modal])

    const handleChangeInput = e => {
        const { id, value } = e.target
        setFormData({ ...formData, [id]: value })
    }



    const handleAddressChange = (newAddress) => {

        setLocalisationText(newAddress.formatted_address)
        const { formatted_address, address_components, geometry } = newAddress;
        const newObject = { formatted_address, address_components, geometry };
        setLocalisationObject(newObject)

    }


    const handleRedirect = () => {
        history.push('/cgu');
    };




    const handleSubmit = (e) => {
        e.preventDefault()
        formData.fullname = firstname + " " + lastname
        formData.address = localisationObject
        formData.target = 'working'
        dispatch(register(formData))

    }


    const submit = (e) => {
        setChoiceCompany(2);
        handleSubmit(e)


    }


    const validateMessages = {
        required: '${label} requis!',
        types: {
            email: "${label} invalide!",
            number: '${label} is not a valid number!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    };

    return (
        <Modal modalName={"createAccountModal"} heightModal={"90vh"} widthModal={"40vw"}  title={"Créer un compte"}>
            <Container>
                {(step !== 1 && step !== 6) && <AiOutlineArrowLeft className="arrowBack" onClick={() => { if (step === 7) { setStep(5) } else { setStep(step - 1) } }} />}
                <Content>
                    <TopDiv>
                        <Logo src={LogoImg} alt={"Logo Behandy"} />
                        {
                            step === 1 &&<div className='text-header'> <h3>C’est parti ! Créons-votre compte en quelques clics !
                            </h3></div>
                        }
                        {
                            step === 2 && <div className='text-header'>
                                <h3>Faisons connaissance !</h3>
                                <h4> Comment vous appelez-vous ?</h4>
                            </div>
                        }
                      
                    </TopDiv>
                    <FormDiv>
                        {step === 1 &&
                            <Form
                                onFinish={() => { setStep(step + 1) }}
                                layout="vertical"
                                style={{ width: "100%"  , height:"100%"}}
                                initialValues={{ email: formData.email, password: formData.password, cf_password: formData.cf_password }}
                                validateMessages={validateMessages}
                            >
                                <div>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        onChange={(e) => handleChangeInput(e)}
                                        rules={[
                                            {
                                                type: "email",
                                                required: true,
                                            },
                                            {
                                                max: 140,
                                                message: 'L\'email ne peut pas dépasser 140 caractères!',
                                            }
                                        ]}
                                    >
                                        <Input size="large" maxLength={140} />
                                    </Form.Item>





                                    <Form.Item
                                        label="Mot de passe"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Entrez votre mot de passe!',
                                            },
                                        ]}
                                        hasFeedback
                                        onChange={(e) => handleChangeInput(e)}

                                    >
                                        <Input.Password size='large' />
                                    </Form.Item>

                                    <Form.Item
                                        label="Mot de passe de confirmation"
                                        name="cf_password"
                                        dependencies={['password']}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Entrez le mot de passe de confirmation!',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('Les mots de passe ne correspondent pas!'));
                                                },
                                            }),
                                        ]}

                                        onChange={(e) => handleChangeInput(e)}

                                    >
                                        <Input.Password size="large" />
                                    </Form.Item>

                                    <Form.Item
                                        name="agreement"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                validator: (_, value) =>
                                                    value ? Promise.resolve() : Promise.reject(new Error('Acceptez les CGU pour continuer !')),
                                            },
                                        ]}
                                    >
                                        <Checkbox>
                                            J'accepte les{' '}
                                            <span onClick={handleRedirect} style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>
                CGU de Behandy
            </span>
                                        </Checkbox>
                                    </Form.Item>
                                </div>

                                <Form.Item className="item-next">
                                    <Button size="large" shape="round" type="primary" htmlType="submit">
                                        Suivant
                                    </Button>
                                </Form.Item>
                            </Form>
                        }

                        {step === 2 &&


                            <Form
                                onFinish={() => { setStep(step + 1) }}
                                layout="vertical"
                                style={{ width: "100%" }}
                                initialValues={{ firstname: formData.firstname, lastname: formData.lastname, mobile: formData.mobile }}

                            >
                                <Form.Item
                                    label="Prénom"
                                    name="firstname"
                                    onChange={(e) => handleChangeInput(e)}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Entrez votre prénom!',
                                        },
                                        {
                                            max: 100,
                                            message: 'Le prénom ne peut pas dépasser 100 caractères!',
                                        }
                                    ]}
                                >
                                    <Input size="large" maxLength={100} />
                                </Form.Item>

                                <Form.Item
                                    label="Nom"
                                    name="lastname"
                                    onChange={(e) => handleChangeInput(e)}

                                    rules={[
                                        {
                                            required: true,
                                            message: 'Entrez votre nom!',
                                        },
                                        {
                                            max: 100,
                                            message: 'Le nom ne peut pas dépasser 100 caractères!',
                                        }
                                    ]}
                                >
                                    <Input size="large" maxLength={100} />
                                </Form.Item>


                                <Form.Item
                                    label="Téléphone"
                                    name="mobile"
                                    onChange={(e) => handleChangeInput(e)}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Entrez votre numéro de télephone!',
                                        },
                                    ]}
                                >
                                    <Input size="large" />
                                </Form.Item>

        

                                <Form.Item className="item-next">
                                    <Button size="large" shape="round" type="primary" htmlType="submit">
                                        Suivant
                                    </Button>
                                </Form.Item>
                            </Form>
                        }
 

                        {
                            step === 3 &&
                            <AccountCreation formData={formData} setFormData={setFormData} />
                        }


                    </FormDiv>

                </Content>

            </Container>
        </Modal>
    )
}

const Container = styled.div`
    display:flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 5% 1%;

    .item-next{
        display:flex;
         justify-content: center;
      
    }

    .my-select .rc-select-dropdown {
        z-index: 101000;
        background: red;
      }

      .arrowBack{
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: 1.8em;
        color: #F87778;
        transition: all 0.3s ease-in-out;
        &:hover{
            cursor: pointer;
            transform: scale(1.1);
        }

      }
 

`


const TopDiv = styled.div`
    display:flex;
    align-items:center;
    justify-content: center;
    flex-direction: column;

        h3{
            font-size:1.4em !important;
            font-weight: 900;
            color: #585858;
            text-align:center;
            margin: 0px;
            
        }
        h4{
            margin: 0px;
            font-size:1.2em;
            font-weight: 500;
            font-style:italic;
            color: #585858;
            text-align:center;
        }
    
        .text-header{
            margin: 30px 0px;
        }

    
`

const FormDiv = styled.div`
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content: space-between;
    flex: 1;

    Form {
        display:flex;
        flex-direction: column;
        justify-content: center;
    }

`
const Logo = styled.img`
    margin: 0 auto;
    width: 200px;

`

const Content = styled.div`
display: flex;
flex-direction: column;
justify-content:space-between;
flex: 1;
padding: 0% 5%;
gap: 20px;


h1{
    font-size: 25px;
    color: black;
    font-weight: 700;
    margin-bottom: 25px;
}

h2 {
    font-size: 15px;
    color: #F87778;
    font-weight: 600;
}

h3 {
    font-size: 13px;
    color: #585858;
    font-weight: 600;
}


@media screen and (max-width: 768px) {
    h1{
        font-size: 1.2em;
        text-align: center;
    }
} 

`


export default CreateAccountModal
