
import { DeleteData } from '../actions/globalTypes';
import { VISITORS_TYPES } from '../actions/visitorAction';

const initialState = {
    loading: false,
    visitors: [],
    count: 0,
    page: 1,
    n: 0,
}



const visitorReducer = (state = initialState, action) => {
    switch (action.type){


            case VISITORS_TYPES.GET_VISITORS:
                    return {
                        ...state,  
                        visitors:  action.payload.data.visitors,
                        count: action.payload.data.count,
                        page:action.payload.data.page,
                        n:action.payload.data.n
            }
    
            case VISITORS_TYPES.GET_MORE_VISITORS:

            return {
                ...state,  
                visitors:  state.visitors.concat(action.payload.data.visitors),
                page:action.payload.data.page,
                n:action.payload.data.n,
                count: action.payload.data.count

            }
        case VISITORS_TYPES.LOADING:
                return {
                    ...state,
                    loading: action.payload
                };

        case VISITORS_TYPES.DELETE_VISITOR:
                return {
                    ...state,
                    visitors: DeleteData(state.visitors, action.payload._id)
                };
        case VISITORS_TYPES.UPDATE_VISITOR_DEALS:
                    return {
                        ...state,
                        visitors: state.visitors.map(visitor => {
                            if (visitor.deal._id === action.payload._id) {
                                // Replace the deal of the visitor with the updated deal
                                return { ...visitor, deal: action.payload };
                            }
                            return visitor;
                        })
                    };

        case VISITORS_TYPES.UPDATE_VISITOR_EVENTS:
                        return {
                            ...state,
                            visitors: state.visitors.map(visitor => {
                                if (visitor.event._id === action.payload._id) {
                                    // Replace the deal of the visitor with the updated deal
                                    return { ...visitor, event: action.payload };
                                }
                                return visitor;
                            })
                        };
                

        default:
            return state;
    }
}


export default visitorReducer
