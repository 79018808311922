import axios from 'axios'
import React, {useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
const ResetPassword = () => {

  const [status, setStatus] = useState(null)
  

  const [id, setId] = useState("")
  const [token, setToken] = useState("")
  const [url, setURL] = useState(null)
  const [password, setPassword] = useState("")
  const [passwordConfirm, setPasswordConfirm] = useState("")
  const history = useHistory()

  
  useEffect(() => {
    if (process.env.REACT_APP_URL) {
      setURL(process.env.REACT_APP_URL);
    }
  }, []);
  
  useEffect(() => {
  

    const urlParams = new URLSearchParams(window.location.search);
    setId(urlParams.get('id'))
    setToken(urlParams.get('token'))

    return () => {
    }
  }, [])

  const updatePassword = async () => {

    if(password !== passwordConfirm){
      setStatus({status: 400, data:"Mot de passe différent!"})
      return;
    }
    try{
        const res = await axios.post(`${url}/api/resetPassword/${id}/${token}`, {password: password})
        if(res.status === 200){
            setStatus(res)
        }
    }
    catch(e){
   
        if (e.response.status === 400){
            setStatus(e.response)
        }
    }

}
  
   return (
        <Container>
           
               <Content>
                    <h2>Réinitialisation mot de passe?</h2>

                    <InputStyled type="password" placeholder='Mot de passe' onChange={(e)=>setPassword(e.target.value)}/>
                    <InputStyled type="password" placeholder='Mot de passe de confirmation' onChange={(e)=>setPasswordConfirm(e.target.value)}/>

                    {status && status.status === 200  && <p className='msgResponse valid'>Votre mot de passe a été changé! Vous pouvez quitter cette page!</p>}
                    {status && status.status === 400 && <p className='msgResponse error'> {status.data}</p>}

                    <ButtonValid onClick={updatePassword} disabled={status && status.status === 200}>
                        <span>Valider</span>
                    </ButtonValid>
                    <ReturnButton onClick={()=> history.push("/login")}>Retourner à l'accueil</ReturnButton>
                </Content>
        </Container>
    )
}

const Container = styled.div`

    display:flex;
    justify-content: center;
    width:100vw;
    height: 100vh;
    align-items: center;
    background :#F0F0F0;
    overflow: hidden;


`

const Content = styled.div`
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    width: 25%;
    background: white;
    padding: 2% 2%;
    border-radius:25px;

    h2{
        font-size: 1.5em;
        margin:0px;
        margin-bottom: 8%;
        font-weight: 700;
    }

    p{
        margin: 0px;


    }

    .msgResponse{
        margin-top: 25px;
        margin-bottom: 25px;

    
    }

    .error {
        color: red;
    }

    .valid {
        color: green;
    }
    
    @media (max-width: 768px) {
        width: 80%;
        
      }

`

const InputStyled = styled.input `
    background: #FAFAFA;
    border: none;
    outline: none;
    text-indent: 5px;
    padding: 10px 10px;
    margin-top: 10px;
    width: 100%;

    @media screen and (max-width: 768px) {
        font-size: 0.9em;
    }


`

const ButtonValid = styled.button`
    margin-top: 5%;
    display: flex;
    background: #F87778;
    justify-content: center;
    align-items:center;
    outline: none;
    border: 1px solid #ddd;
    border-radius: 25px;
    padding: 12px 35px;
    margin-bottom: 5%;
    width: 100%;
    
    span {
      font-size: 14px;
      font-weight: 600;
      color: white;
    }

    &:disabled,
    &[disabled]{
    border: none;
    background-color: #cccccc;
    color: #666666;
    }


`
const ReturnButton = styled.span`
    color: #F87778;
    font-weight: 600;
    &:hover{
        cursor: pointer;
        font-weight: 700;

    }

`



 
export default ResetPassword