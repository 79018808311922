import { useEffect, useState } from 'react'
import {BrowserRouter as Router, Route} from 'react-router-dom'
import styled from "styled-components";

import PageRender from './customRouter/PageRender'
import Home from './pages/home'
import Alert from './components/alert/Alert'
import './global.css'

import { useSelector, useDispatch } from 'react-redux'
import { refreshToken } from './redux/actions/authAction'
import NavbarHome from './components/Navbar/NavbarHome';
import { getPagesByUserID } from './redux/actions/pageAction';
import CreateDealModal from './components/deal/CreateDealModal';
import BottomBarMobile from './components/Navbar/BottomBarMobile';
import useWindowDimensions from './utils/useWindowsDimensions';
import Sidebar from './components/Navbar/Sidebar';
import ModalDelete from './components/utils/Modal/ModalDelete';
import { ConfigProvider, message } from 'antd';
import ModalCreateSubscription from './components/company/management/subscription/ModalCreateSubscription';
import ModalActivateSubscription from './components/profile/subscription/ModalActivateSubscription';
import Login from './pages/login';
import WebFont from 'webfontloader';
import {  useHistory } from 'react-router-dom'
import { getCompanyTypes } from './redux/actions/dataAction';
import { LoadScript } from '@react-google-maps/api';
import Accounts from './pages/accounts';


function App() {
  const REACT_APP_API_PLACE = process.env.REACT_APP_API_PLACE;
  const [libraries] = useState(['places']);
  const { width } = useWindowDimensions();
  const { auth, modal,theme} = useSelector(state => state)
  const dispatch = useDispatch()
  
  let conditionShowSearch = (!window.location.pathname.includes("/admin") && window.location.pathname !=="/settings"  && window.location.pathname !== "/pageCreation"  && window.location.pathname !=="/qr-reader");
 
  let conditionShowSidebar = !window.location.pathname.includes("/admin");

  

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Sansita']
      }
    });

    
    

    dispatch(getCompanyTypes())
   }, []);
 
  useEffect(() => {
    dispatch(refreshToken())

  },[dispatch])






  useEffect(() => {
    if (theme.theme ==='company') {
      document.documentElement.style.setProperty('--color-accent', '#6076E7'); // Accent color for dark mode
      document.documentElement.style.setProperty('--color-accent-hover', '#6077e7d6'); // Accent color for dark mode

    } else {
      document.documentElement.style.setProperty('--color-accent', '#6076E7'); // Accent color for light mode
      document.documentElement.style.setProperty('--color-accent-hover', '#6077e7d6'); // Accent color for dark mode

    }
  }, [theme]);


  useEffect(() => {
    if(auth.token) {
      if( !window.location.pathname.includes("/admin")){
        dispatch(getPagesByUserID({auth}))
      }
      if(window.location.pathname.includes("/admin") && auth.user.role !== 'admin'){
        console.log("redirecting to root");
        window.location.href = '/';
      }
      //
      if(width < 768){
        document.documentElement.style.setProperty('--color-background', 'white'); // Accent color for dark mode

      }else{
        document.documentElement.style.setProperty('--color-background', '#F0F4F7'); // Accent color for dark mode

      }


    }else{
      document.documentElement.style.setProperty('--color-background', 'white'); // Accent color for dark mode

    }
  }, [dispatch, auth.token])

  useEffect(() => {
    if(modal.open){
      document.body.style.overflow = 'hidden';
    }else{
      document.body.style.overflow = 'unset';
    }
   
  }, [modal])
  

 


  return (

    <Router>
            <LoadScript
        googleMapsApiKey={REACT_APP_API_PLACE}
        libraries={libraries}
      >
        <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#6076E7',
            colorSecondary: '#6076E7"',
         },
        }}
  >
     <Appli>
      
     {((auth.token) && conditionShowSidebar) &&<Sidebar/> }
       
     {((auth.token) && conditionShowSearch)  &&<Top  id="menu">
      
        <NavbarHome/>
      </Top>} 
     {modal.typeModal==="createDealModal" &&  <CreateDealModal />}
     {(modal.typeModal ===  "createSubModal" && modal.open) && <ModalCreateSubscription/>}
     {(modal.typeModal ===  "activateSubModal" && modal.open) && <ModalActivateSubscription/>}

     <Alert /> 
      <Main>
      <ModalDelete  auth={auth}/>


        <Center marginTop={conditionShowSearch?"7vh":"2vh"} id="center">

        
          <Route exact path="/" component={auth.token   ? Accounts : Login} />

          <Route exact path="/:page" component={PageRender} />
          <Route exact path="/:page/:id" component={PageRender} />

          


      </Center>


      </Main>

      {((auth.token) && width < 768 && window.location.pathname !=="/qr-reader")   &&<Bottom><BottomBarMobile/></Bottom> } 


      </Appli>

      </ConfigProvider>
      </LoadScript>
    </Router>
  );
}

const Appli = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background: white;
    min-height: 100vh;
    height: 100% !important; 

    @media only screen and (max-width: 768px) {
      color-background: white !important;
      background: white !important;
    }
    

`;

const Main = styled.div`
    display: flex;
    flex: 1;
    background: var(--color-background);
`;

const Top = styled.div`
    margin-bottom:  10vh;
    z-index: 1000;
    @media only screen and (max-width: 768px) {
      margin-bottom:  3vh;

  
    }

`

const Bottom = styled.div`
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    bottom: 0px;
    left: 0px;
    width: 100vw;
    z-index: 1500;
`




const Center = styled.div`


    width: ${({ token }) => token ? '95%' : '100%'};
    display:flex;
    justify-content: center;
    @media (max-width: 768px) {
      width: 100%;
      margin-top:  ${props => props.marginTop ? props.marginTop : "0vh"};
      margin-bottom: 8vh;
    }
`


export default App;
