import React from 'react'
import { useState } from 'react'

import styled from 'styled-components'
import PageChoice from '../../promo/PageChoice'
import EventForm from './EventForm'
import FinalStep from '../FinalStep'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

const GlobalEvent = ({ formData, setFormData }) => {
    const [step, setStep] = useState(1)

    const {modal} = useSelector(state=>state)

    useEffect(() => {
      if(modal.event){
        setStep(2);
      }
    
    }, [])
    
    const handleStepChange = (step) => {
        setStep(step);
      };

    return (
    <Content>
        
        {step === 1 && <PageChoice  type="event" step={step} onStepChange={handleStepChange} formData={formData} setFormData={setFormData}/>}
        {step === 2 && <EventForm  step={step} onStepChange={handleStepChange} formData={formData} setFormData={setFormData}/>}
        {step === 3 && <FinalStep  step={step} onStepChange={handleStepChange} formData={formData} setFormData={setFormData}/>}

    </Content>
  )
}

const Content = styled.div`
    display:flex;
    width: 100%;
    flex: 1;

    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    h2{
        font-size:1.6em;
        font-weight: 900;
        color: #585858;
        text-align:center;
    }

`

export default GlobalEvent