import { GLOBALTYPES } from './globalTypes'
import { postDataAPI, getDataAPI, patchDataAPI, deleteDataAPI, putDataAPI } from '../../utils/fetchData'

import { imageUpload } from '../../utils/imageUpload'
import { getParamsFilter } from './userAction'

export const CODE_TMP_TYPES = {
    CREATE_CODE_TMP: 'CREATE_CODE_TMP',
    GET_CODES_TMP: 'GET_CODES_TMP',
    DELETE_CODE: 'DELETE_CODE',

    LOADING: 'LOADING',
}


export const createCodeTmp = ({content, auth}) => async (dispatch) => {

    try {


        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })

        const res = await postDataAPI('subscriptionTmp', content, auth.token)

        dispatch({ 
            type: CODE_TMP_TYPES.CREATE_CODE_TMP, 
            payload: {...res.data.newCodeTmp, user: auth.user} 
        })

        dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: false} })

        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} });


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}



export const getCodesTmp = ({filter,auth}) => async (dispatch) => {
    try {
        dispatch({type: CODE_TMP_TYPES.LOADING, payload: true})

        let param = getParamsFilter(filter)
      
    
        const res = await getDataAPI(`/subscriptionTmp?`+ param, auth.token)


        dispatch({
            type: CODE_TMP_TYPES.GET_CODES_TMP,
            payload: res
        }) 
 
        dispatch({type: CODE_TMP_TYPES.LOADING, payload: false})

        return res;


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
    }
    
}




export const deleteCode= ({code, auth}) => async (dispatch) => {

    try {
        const res = await deleteDataAPI(`subscriptionTmp/${code._id}`, auth.token)
        dispatch({ type: CODE_TMP_TYPES.DELETE_CODE, payload: code })

        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} });

        
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}




