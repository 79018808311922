import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import LoadIcon from '../../../../images/loading.gif'
import { getDataAPI } from '../../../../utils/fetchData'
import { EXPERIENCES_TYPES } from '../../../../redux/actions/experienceAction'
import styled from "styled-components"
import ExperienceCard from './ExperienceCard'
import { useParams } from 'react-router-dom'
import LoadMoreBtn from '../../LoadMoreBtn'

const Experiences = ({id}) => {
    const { experiences, auth,portfolio } = useSelector(state => state)
    const dispatch = useDispatch()
    const [exp, setExp] = useState([])
    const [load, setLoad] = useState(false)

    useEffect(() => {
        setExp(experiences.experiences)
    }, [experiences.experiences])

    const handleLoadMore = async () => {
        setLoad(true)
    
        const res = await getDataAPI(`user_experiences/${id}?limit=${experiences.page * 3}`, auth.token)

        console.log(res)
        dispatch({
            type: EXPERIENCES_TYPES.GET_EXPERIENCES_USER, 
            payload: {...res.data, page: experiences.page + 1}
        })

        setLoad(false)
    }

    return (
        <ContainerExperiences>
            {
            !portfolio.portfolio ?    exp.map(experience => (
                    <ExperienceCard  key={experience._id} experience={experience} />
                )): exp.slice(0,2).map(experience => (
                    <ExperienceCard key={experience._id} experience={experience} />
                ))
            }

            {
                load && <img src={LoadIcon} alt="loading" className="d-block mx-auto" />
            }
                     
            
{   !portfolio.portfolio  &&         <LoadMoreBtn limit={3} result={experiences.result} page={experiences.page}
            load={load} handleLoadMore={handleLoadMore} />}
        </ContainerExperiences>
    )
}

const ContainerExperiences = styled.div`
    display: flex;
    flex-direction: column;
    
`


export default Experiences
