import { PAGE_TYPES } from '../actions/pageAction'

const initialState = {
    loading: false,
    pages: [],
    result: 0,
    total: 0,
    page: 2,
    count: 0,
    n: 0,
    activePage: null,

}

const pageReducer = (state = initialState, action) => {
    switch (action.type){
        case PAGE_TYPES.CREATE_PAGE:
            return {
                ...state,
                pages: [action.payload, ...state.pages]
            };
        case PAGE_TYPES.LOADING_PAGE:
            return {
                ...state,
                loading: action.payload
            };
        case PAGE_TYPES.GET_PAGES_BY_USER_ID:
                return {
                    ...state,
                    pages: action.payload.pages,
                    result: action.payload.result,
                    page: action.payload.page
            };

        case PAGE_TYPES.GET_PAGES:
                return {
                    ...state,
                    pages: action.payload.pages,
                    count: action.payload.count,
                    page: action.payload.page,
                    n: action.payload.n
            };

            case PAGE_TYPES.GET_MORE_PAGES:
            return {
                ...state,  
                pages:  state.pages.concat( action.payload.pages),
                count: action.payload.count,
                page:action.payload.page,
                n:action.payload.n
            }

        case PAGE_TYPES.GET_PAGE_BY_ID:
                return {
                    ...state,
                    activePage: action.payload.page,
            };
        case PAGE_TYPES.UPDATE_PAGE:
                return {
                     ...state,
                     activePage: action.payload
                };

        default:
            return state;
    }
}

export default pageReducer