import { Button } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { GLOBALTYPES } from '../../../redux/actions/globalTypes'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { MdDelete } from 'react-icons/md'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { convertIdToLabel } from '../../../utils/function'


const InvitationPreviewCard = ({ invitation, page, status }) => {
    const dispatch = useDispatch();
    const  {datas} = useSelector(state =>state);
    const { id } = useParams()
    const history = useHistory();
    const [showOptions, setShowOptions] = useState(false);
    return (
        <Card>

            {page && <><Top>
                <img className='img-back' src={page.imgBackground} alt={"image entreprise"} />
                <img onClick={() => window.location.href = `/company/${page._id}`} className='img-user' src={page.avatar} alt={"image user"} />

              { (invitation.status === "accepted" || ( status==="sended" && invitation.sender._id === id) ) && <ChoiceDiv>

                    {
                        (showOptions) ? <>
                            <MdDelete onClick={(e) => { e.stopPropagation(); dispatch({ type: GLOBALTYPES.MODAL, payload: { type: "invitationPartner", typeModal: "delete", title: "Etes-vous sûr de vouloir supprimer ce partenariat?", open: true, invitation: invitation } }) }} />
                        </> : <></>
                    }
                    <BsThreeDotsVertical onClick={(e) => { e.stopPropagation(); setShowOptions(!showOptions) }} />


                </ChoiceDiv>}


            </Top>

                <Bottom>
                    <h3 className='title' onClick={() => window.location.href = `/company/${page._id}`}>{page.name}</h3>
                    <span className='subtitle'>{convertIdToLabel(datas.companyTypes, page.sector)}</span>

                    {
                        status && status === 'received' &&
                        <Button type="primary" onClick={() => dispatch({ type: GLOBALTYPES.MODAL, payload: { typeModal: "invitationModal", status: status, invitation: invitation, open: true } })}>Voir proposition</Button>

                    }

                    {(invitation && invitation.status === 'pending' && status === 'sended') && <span className='answer'>En attente</span>}
                </Bottom></>}
        </Card>
    )
}


const Card = styled.div`
    display:flex;
    flex-direction:column;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 15px;
    min-height: 200px;
    overflow: hidden;


`

const Top = styled.div`
    position: relative;
    display:flex;
    background:red;
    height: 30%;
    z-index: 2;
    .img-back{
        width: 100%;
        height:100%;
        object-fit: cover;
    }
    .img-user{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -30px;
        background: white;
        width: 60px;
        height: 60px;
        border: 3px solid white;
        border-radius: 50%;

        &:hover{
            cursor: pointer;
        }
    }
`

const ChoiceDiv = styled.div`
    position:absolute;
    top: 5px;
    right: 5px;
    display:flex;
    justify-content: center;
    align-items:center;
    background : white;

    font-size: 1.5em;
    padding: 5px;
    border-radius: 50px;

   &:hover{
    cursor:pointer;
    background:
   }

`

const Bottom = styled.div`
position: relative;
    z-index: 1;
    height: 70%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-top: 30px;
    text-align-center;
    line-height: 1;
    background: white;

    .title{
        color: var(--color-accent);
        margin: 0px;
        padding: 0px;
        font-size: 1.1em;
        font-weight: 900;
        margin-bottom: 5px;
        text-align:center;
        &:hover{
            cursor: pointer;
        }
    }

    .subtitle{
        font-size:0.9em;
        font-style:italic;
        color:#969696;
    }

    .answer{
        position: absolute;
        bottom: 5px;
        right: 5px;
        color: #757474;
        font-size: 0.9em;
        font-style: italic;
    }

    .company{
        font-style: italic;
        margin-top: 2px;
    }

    @media (max-width: 768px) {

        .title{
          
            font-size: 1.2em;
          
        }
    

    
        .company{
            font-style: italic;
        }


    }

`
export default InvitationPreviewCard