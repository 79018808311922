import React from 'react'

import styled from "styled-components"


import Modal from '../../utils/Modal/Modal'
import FormCreatePageCompanyV2 from './page-creation-v2/FormCreatePageCompanyV2'
 


const CreatePageModal = () => {
    




  

    return (
        <Modal modalName={"createPageModal"} widthModal={"45vw"} heightModal={"85vh"} title={"Créer une page"}>

            <FormCreatePageCompanyV2/>
                
 
        </Modal>
    )
}




const Container = styled.div`
    display:flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    h1{
        font-size: 1.5em;
        font-weight: bold;
        margin: 0px;
    }
    padding: 2% 5%;
`

export default CreatePageModal
