import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import StarRate from './StarRate'
import { Container, EditIcon, MenuEdit } from '../experience/ExperienceCard'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { MdCreate, MdDelete } from 'react-icons/md'
import { deleteLanguage } from '../../../../redux/actions/languageAction'
import { GLOBALTYPES } from '../../../../redux/actions/globalTypes'



const LanguageCard = ({language}) => {
    const { auth, socket } = useSelector(state => state)
    const dispatch = useDispatch()
    const {id} = useParams();
    const [menuEdit, setMenuEdit] = useState(false);

    const handleDelete = ()  => {
        if(window.confirm("Êtes-vous sûr de vouloir supprimer cette langue?")){
            dispatch(deleteLanguage({id:language._id,language , auth}))   
             }
    }

    const handleEdit = () => {
        dispatch({ type: GLOBALTYPES.MODAL, payload: {language: language, typeModal:"addLanguage", open:true, onEdit: true}}) 
    }
 

 


    return (
        <Container>
            <Content>
            <LanguageName>
            {language.language}
            </LanguageName>

            <StarRate rate={language.rating}/>
            {language.user._id === auth.user._id && <EditIcon>
                    <BsThreeDotsVertical className="icon"  onClick={() => {setMenuEdit(!menuEdit); console.log(menuEdit)}} />
                    { menuEdit && <>
                        
                        <MenuEdit>
                        <MdCreate  onClick={()=> handleEdit()}className="icon"/>
                        <MdDelete onClick={()=> handleDelete()} className="icon"/>
                    </MenuEdit>
                    </>
                    }


            </EditIcon>}
            </Content>
        </Container>
    )
}



const Content = styled.div`
    display:flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

`


const LanguageName = styled.div `
    display:flex;
    font-size: 20px;
    margin-left: 5%;
    text-overflow: ellipsis;

    @media (max-width: 768px) {
        font-size: 0.9em;

    }
`



export default LanguageCard;
