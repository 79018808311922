import { Button } from 'antd'
import React from 'react'
import calendar from '../../../assets/offers/calendar.png'
import promo from '../../../assets/offers/promo.png'

import styled from 'styled-components'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom"
import { GLOBALTYPES } from '../../../redux/actions/globalTypes'

const FormCreateDeal1 = ({ step, onStepChange, typeOffer, onTypeOfferChange }) => {

    const { pages } = useSelector(state => state)
    const [active, setActive] = useState('');
    const history = useHistory();
    const dispatch = useDispatch();
    const handleClick = (type) => {
        setActive(type)
    }

    const handleNext = () => {
        if (active === '') return;
        onTypeOfferChange(active);
        onStepChange(2);
    }

    const redirection = (url) => {
        dispatch({ type: GLOBALTYPES.MODAL, payload: { open: false } })
        history.push(url)
    }

    return (
        <Content>

            {pages.pages.length > 0 && <><h2>Que voulez-vous poster?</h2>

                <ChoicesDiv>
                    <Choice onClick={() => handleClick("promo")} active={active === 'promo' ? true : false}>
                        <div className='div-icon'>
                            <img src={promo} alt="promo" />
                        </div>
                        <span>Promotion</span>
                    </Choice>
    
                    <Choice onClick={() => handleClick("event")} active={active === 'event' ? true : false}>
                        <div className='div-icon'>
                            <img src={calendar} alt="events" />
                        </div>
                        <span>
                            Évènement
                        </span>
                    </Choice>

                </ChoicesDiv>


                <Button onClick={() => { handleNext() }} size="large" shape="round" type="primary" htmlType="submit">
                    Suivant
                </Button></>}



            {
                pages.pages.length === 0 && <>
                    <h2>Aucune page existante</h2>

                    <svg width="100" height="100" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#fcc21b" d="M-.2 93.42c0 13.88 28.65 25.11 63.99 25.11s64-11.23 64-25.11c0-13.87-1.47-83.95-64-83.95C1.27 9.47-.2 79.55-.2 93.42z" />
                        <path fill="#2f2f2f" d="M31.12 63.44c-.25 0-.49 0-.74-.01a2.748 2.748 0 0 1-2.64-2.85c.06-1.52 1.33-2.71 2.85-2.64c6.19.24 11.65-3.79 13.26-9.79c.39-1.47 1.9-2.34 3.37-1.94c1.47.39 2.34 1.9 1.94 3.37c-2.21 8.24-9.57 13.86-18.04 13.86zm65.35 0c-8.47 0-15.83-5.62-18.04-13.88a2.76 2.76 0 0 1 1.94-3.37c1.47-.39 2.98.48 3.37 1.94c1.61 6 7.07 10.03 13.26 9.79a2.76 2.76 0 0 1 2.85 2.64c.06 1.52-1.12 2.8-2.64 2.85c-.25.03-.49.03-.74.03z" />
                        <path fill="#fff" d="M6.93 67.54c1.04 4.69 4.1 7.61 6.68 8.41c2.89.89 5.51.46 7.38-1.2c2.37-2.11 3.19-5.84 2.33-10.51c-1.57-8.39-.46-23.17-.45-23.31c.04-.57-.24-1.09-.76-1.33c-.5-.26-1.1-.16-1.52.22C6.25 52.86 5.87 62.78 6.93 67.54z" />
                        <path fill="#ed6c30" d="M84.84 68.79c-9.48 0-12.61 4.66-21.05 4.66s-11.57-4.66-21.05-4.66s-17.16 6.66-17.16 14.88s7.68 14.88 17.16 14.88s12.61-4.66 21.05-4.66s11.57 4.66 21.05 4.66S102 91.88 102 83.66c0-8.21-7.68-14.87-17.16-14.87z" />
                    </svg>
                    <Button onClick={() => { redirection('/accounts') }} size="large" shape="round" type="primary" htmlType="submit">
                        Mes pages
                    </Button>
                </>
            }

        </Content>
    )
}

const Content = styled.div`
    display:flex;
    width: 100%;
    flex: 1;

    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    h2{
        font-size:1.6em;
        font-weight: 900;
        color: #585858;
        text-align:center;
    }

`
const ChoicesDiv = styled.div`
    
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
        width: 100%

        @media screen and (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);

        } 

    


`

const Choice = styled.div`
    
    padding: 10px;
    aspect-ratio: 1 / 1; /* This makes the item a square */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    width: 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background: ${({ active }) => active ? 'var(--color-accent);' : ''};


    .div-icon{
       
        img{
            width:40px;
            heigth: 40px;
        }

    }
    span{
        margin-top:5px;
        font-size: 1em;
        color: ${({ active }) => active ? 'white' : ''};

    }
    transition: all 0.2s ease-in;

    &:hover{
        cursor: pointer;
        transform: scale(1.1);
    }

`
export default FormCreateDeal1