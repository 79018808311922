import { GLOBALTYPES } from './globalTypes'
import { postDataAPI, getDataAPI, patchDataAPI, deleteDataAPI } from '../../utils/fetchData'
import validFormLanguage from '../../utils/formValidation/validFormLanguage'

export const LANGUAGES_TYPES = {
    CREATE_LANGUAGE: 'CREATE_LANGUAGE',
    LOADING_LANGUAGE: 'LOADING_LANGUAGE',
    GET_LANGUAGES: 'GET_LANGUAGES',
    GET_LANGUAGES_USER: 'GET_LANGUAGES_USER',
    UPDATE_LANGUAGE: 'UPDATE_LANGUAGES',
    DELETE_LANGUAGE: 'DELETE_LANGUAGE',
    UPDATED_LANGUAGE: 'UPDATED_LANGUAGE',


}


export const createLanguage= ({data, auth}) => async (dispatch) => {
    const check = validFormLanguage(data)
    if(check.errLength > 0)
    return dispatch({type: GLOBALTYPES.ALERT, payload: check.errMsg})
    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })

        const res = await postDataAPI('languages', data, auth.token)

        dispatch({ 
            type: LANGUAGES_TYPES.CREATE_LANGUAGE, 
            payload: {...res.data.newLanguage, user: auth.user} 
        })

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: false} })
        dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})
        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} })


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const getLanguages = (token) => async (dispatch) => {
    try {
        dispatch({ type: LANGUAGES_TYPES.LOADING_LANGUAGE, payload: true })
        const res = await getDataAPI('languages', token)
        
        dispatch({
            type: LANGUAGES_TYPES.GET_LANGUAGES,
            payload: {...res.data, page: 2}
        })

        dispatch({ type: LANGUAGES_TYPES.LOADING_LANGUAGE, payload: false })
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const getLanguagesUser = (token, id) => async (dispatch) => {
    try {
        dispatch({ type: LANGUAGES_TYPES.LOADING_LANGUAGE, payload: true })
        const res = await getDataAPI(`user_languages/${id}`, token)
        
        dispatch({
            type: LANGUAGES_TYPES.GET_LANGUAGES_USER,
            payload: {...res.data, page: 2}
        })

        dispatch({ type: LANGUAGES_TYPES.LOADING_LANGUAGE, payload: false })
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const updateLanguage = ({id, data, auth}) => async (dispatch) => {
    const check = validFormLanguage(data)
    if(check.errLength > 0)
    return dispatch({type: GLOBALTYPES.ALERT, payload: check.errMsg})
 
    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })

        console.log(data)
        const res = await patchDataAPI(`language/${id}`, data, auth.token)

        dispatch({ type: LANGUAGES_TYPES.UPDATED_LANGUAGE, payload: res.data.newLanguage })
        dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})
        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} })
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const deleteLanguage = ({id, language, auth}) => async (dispatch) => {
    dispatch({ type: LANGUAGES_TYPES.DELETE_LANGUAGE, payload: language })

    try {
        const res = await deleteDataAPI(`language/${id}`, auth.token)
        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} })
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}



