import React from 'react'
import styled from 'styled-components'


const EmployeeItem = ({subscription}) => {
  return (
    <Card>

        {subscription && <><Top>
            <img className='img-back' src={subscription.company.imgBackground} alt={"image entreprise"}/>
            <img className='img-user' src={subscription.user.avatar} alt={"image user"}/>
        </Top>

        <Bottom>
            <h3>{subscription.user.fistname} {subscription.user.lastname}</h3>
            <span>{subscription.job}</span>
            <span className='company'>{subscription.company.name}</span>

        </Bottom></>}
    </Card>
  )
}


const Card = styled.div`
    display:flex;
    flex-direction:column;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 15px;
    min-height: 200px;
    overflow: hidden;


`

const Top = styled.div`
    position: relative;
    display:flex;
    background:red;
    height: 30%;
    .img-back{
        width: 100%;
        height:100%;
        object-fit: cover;
    }
    .img-user{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -30px;
        background: white;
        width: 60px;
        height: 60px;
        border: 3px solid white;
        border-radius: 50%;
    }
`

const Bottom = styled.div`
    height: 70%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    text-align-center;
    line-height: 1;
    background: white;

    h3{
        color: #6076E7;
        margin: 0px;
        padding: 0px;
        font-size: 1.3em;
        font-weight: 900;
        margin-bottom: 5px;
    }

    span{
        margin:0px;
        padding: 0px;
        font-size: 0.9em;
        color: #585858;
    }

    .company{
        font-style: italic;
        margin-top: 2px;
    }

    @media (max-width: 768px) {

        h3{
          
            font-size: 1.2em;
          
        }
    
        span{
       
            font-size: 0.8em;
        }
    
        .company{
            font-style: italic;
        }
    }

`
export default EmployeeItem