import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import 'moment/locale/fr'

import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { GLOBALTYPES } from '../../../../redux/actions/globalTypes'

import useWindowDimensions from '../../../../utils/useWindowsDimensions'
import { MdDelete, MdModeEdit } from 'react-icons/md'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { AiOutlineFieldNumber } from "react-icons/ai";
import { isInArray } from '../../../../utils/function'
import { FaPlay } from 'react-icons/fa'


moment.locale('fr')

const SubscriptionItem = ({ subscription }) => {


    const { width } = useWindowDimensions();
    const { pages, auth, stripe } = useSelector(state => state)
    const dispatch = useDispatch();
    const [showOptions, setShowOptions] = useState(false)







    return (
        <Card>

            <div className='header'>

                <div className='item'>
                    <img src={subscription.user ? subscription.user.avatar : 'https://behandy-front.s3.eu-west-3.amazonaws.com/user_fjtiqh.png'} className="avatar" />
                </div>

                <div className='item'>
                    <h2>{subscription.user? `${subscription.user.firstname} ${subscription.user.lastname}` : subscription.email}</h2>
                </div>

                <div className='item'>
                    <h2>{subscription.user? (subscription.user.titleJob?subscription.user.titleJob:"Non renseigné" ): "En attente"}</h2>

                </div>


                <div className='item bigscreen'>
                    <h2>{subscription.company.name}</h2>
                </div>



                <div className='action bigscreen'>

                    {subscription.status === 'active' && <span>Actif</span> }
                    {subscription.status === 'disabled' && <span>En attente ...</span> }
                    {subscription.status === 'pause' && <span>Désactivé</span> }
                    {subscription.status === 'unpaid' && <span>Non payé</span> }

                </div>

            </div>


            <ActionsButtons>

                <ChoiceDiv>

                    {
                        showOptions ? <>
                            {isInArray(pages.activePage.admins, auth.user._id, 'admin') && <MdDelete onClick={(e) => { e.stopPropagation(); dispatch({ type: GLOBALTYPES.MODAL, payload: { type: "subscription", typeModal: "delete", title: "Etes-vous sûr de vouloir supprimer cet abonnement?", open: true, subscription: subscription } }) }} /> }                            
                            {isInArray(pages.activePage.admins, auth.user._id, 'admin') && subscription.status === 'pause' && <FaPlay size={"18"} onClick={() => dispatch({ type: GLOBALTYPES.MODAL, payload: { typeModal: "reactivateSubModal", open: true, subscription: subscription } })} />  }                            
{     stripe.subscriptions && stripe.subscriptions.data.length > 0 &&                       <AiOutlineFieldNumber onClick={() => dispatch({ type: GLOBALTYPES.MODAL, payload: { typeModal: "redeemCodeModal", open: true, code: subscription.code } })} />
}                        </> : <></>

                    }

                    <BsThreeDotsVertical onClick={(e) => { e.stopPropagation(); setShowOptions(!showOptions) }} />


                </ChoiceDiv>
            </ActionsButtons>





        </Card>
    )
}

const Card = styled.div`
    position: relative;
   
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 15px;
    align-items:center;
    overflow: hidden;

    display:flex;
    flex-direction:column;
    .header{
      padding: 2% 3%;
      background: white;

      display:flex; display: grid;
      grid-template-columns: 10% 25%  25%  30%  10%; 
      width: 100%;
      height: 100%;
      border:none;

      .item{
        display:flex;
        align-items: center;
        justify-content: start;

      }
  
      .item-like{
        display:flex;
        align-items: center;
        justify-content: end;
      }
  
  
      h2{
        margin: 0px;
        color: #585858;
        font-size: 1em;
        font-weight: 700;
      }
  
      span{
        margin: 0px;
        font-size: 0.8em;
        font-weight: 600;
      }
  
      img{
          width: 50px;
          height: 50px;
  
         border-radius: 50%;
  
      }

      .action{
        align-items:center;
        display:flex;
        
        .multiline-button{
          white-space: normal;
          display:flex: 
          color:red;
          height: fit-content;

          color: #BEDD4B;
          outline:none;
          &:hover{
            color: #BEDD4B;
            border-color:#BEDD4B;
          }
        }
        
 

      }
    }

    @media only screen and (max-width: 768px) {
      .header{
        grid-template-columns: 15% 40%  35%    10% !important; 
        padding: 2px 3% !important;
        min-height: 60px;

        img{
          width: 35px;
          height: 35px;
        }

        h2{
          font-size: 0.9em;
        }

              h2{
          font-size: 0.9em;
        }
      }

      .bigscreen{
        display:none !important;
      }
    }

    


`
const ActionsButtons = styled.div`
position: absolute;
top: 5px;
right: 5px;

`
const ChoiceDiv = styled.div`
    display:flex;
    justify-content: center;
    align-items:center;
    background : white;
    gap: 10px;
    font-size: 1.7em;
    padding: 3px;
    border-radius: 50px;
    cursor: pointer;

   

`


export default SubscriptionItem