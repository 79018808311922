import { postDataAPIWithoutToken } from "./fetchData"

export const checkImage = (file) => {
    let err = ""
    if(!file) return err = "File does not exist."

    if(file.size > 1024 * 1024) // 1mb
    err = "The largest image size is 1mb."

    if(file.type !== 'image/jpeg' && file.type !== 'image/png' )
    err = "Image format is incorrect."
    
    return err;
}


export const imageUpload = async (images) => {
    console.log("image uplaod")
    let imgArr = [];
    for(const item of images){
        const formData = new FormData()
        const formDataS3 = new FormData()
        console.log(URL.createObjectURL(item))
        if(item.camera){
            formData.append("file", item.camera)
        }else{
            formData.append("file", item)
        }

        if(item.camera){
            formDataS3.append("image", item.camera)
        }else{
            formDataS3.append("image", item)
        }
        
        formData.append("upload_preset", "bb5qshs3")
        formData.append("cloud_name", "behandy")

console.log(formDataS3)

       const resS3 =  await postDataAPIWithoutToken('uploadImage', formDataS3)
   

        imgArr.push({url: resS3.data.uploadLocation})  
    }
    return imgArr;
}


export const pdfUpload = async (pdfFiles) => {
    console.log("PDF upload");
    console.log(pdfFiles)
    let pdfArr = [];
    for(const item of pdfFiles){
        const formDataS3 = new FormData();
        console.log(URL.createObjectURL(item));

        // Directly append the PDF file to formData
        formDataS3.append("file", item, item.name);
        
        // Here, adjust the parameters as necessary for your backend requirements
        formDataS3.append("upload_preset", "bb5qshs3"); // Adjust or remove if not needed
        formDataS3.append("cloud_name", "behandy"); // Adjust or remove if not needed

        console.log(formDataS3)
        // Replace 'uploadFile' with your actual backend endpoint for file uploads
        const resS3 = await postDataAPIWithoutToken('uploadFile', formDataS3);

        // Ensure the response handling matches your backend's response structure
        pdfArr.push({url: resS3.data.uploadLocation});
    }
    return pdfArr;
}

