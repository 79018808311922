import {GLOBALTYPES } from './globalTypes'


export const FILTER_TYPES = {
    UPDATE_FILTERS: 'UPDATE_FILTERS',
}



export const updateFilter = ({filter}) => async (dispatch) => {
    try {

        dispatch({ type: FILTER_TYPES.UPDATE_FILTERS, payload: {filter: filter} })


    } catch (err) {
        dispatch({ 
            type: GLOBALTYPES.ALERT, 
            payload: {
                error: err.response.data.msg
            } 
        })
    }
}




