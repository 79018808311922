import { GLOBALTYPES } from './globalTypes'
import { postDataAPI, getDataAPI, patchDataAPI, deleteDataAPI } from '../../utils/fetchData'
import { getParamsFilter } from './userAction'
import { DEAL_TYPES } from './dealAction'
import { VISITORS_TYPES } from './visitorAction'

export const EVENT_TYPES = {
    CREATE_EVENT: 'CREATE_EVENT',
    UPDATE_EVENT: 'UPDATE_EVENT',

    LOADING: 'LOADING',
    GET_EVENTS:  'GET_EVENTS',
    GET_MORE_EVENTS:  'GET_MORE_EVENTS',
    GET_EVENTS_BY_USER: 'GET_EVENTS_BY_USER',
    GET_EVENTS_BY_COMPANY: 'GET_EVENTS_BY_COMPANY',
    GET_EVENT:  'GET_EVENT',
    DELETE_EVENT: 'DELETE_EVENT'
}


export const createEvent = ({content, auth, redirect}) => async (dispatch) => {
    try {


        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })
        


        if(content.sector){
            content.profil = content.sector
            content.sector = content.sector.join(',')
        }
        const res = await postDataAPI('event', content, auth.token)

        dispatch({ 
            type: EVENT_TYPES.CREATE_EVENT, 
            payload: {...res.data.newEvent, user: auth.user} 
        })

        dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: false} })

        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} });

        if(redirect){
            window.location.href = redirect
        }

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const updateEvent = ({id,content,images, auth, redirect}) => async (dispatch) => {
    try {



        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })
        if(content.sector){
            content.profil = content.sector
            content.sector = content.sector.join(',')
        }

        const res = await patchDataAPI('event/'+id, content, auth.token)

        dispatch({ 
            type: EVENT_TYPES.UPDATE_EVENT, 
            payload: {...res.data.newEvent, user: auth.user} 
        })

        dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: false} })

        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} });

        if(redirect){
            window.location.href = redirect
        }
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}



export const getEvents = ({auth}) => async (dispatch) => {
    try {
        dispatch({type: EVENT_TYPES.LOADING, payload: true})
        const res = await getDataAPI(`/event?n=9`, auth.token)

        console.log(res)

        dispatch({
            type: EVENT_TYPES.GET_EVENTS,
            payload: res
        }) 
 
        dispatch({type: EVENT_TYPES.LOADING, payload: false})


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
    }
    
}

const getParamsFilter2 =  (filterData) => {
    console.log(filterData)
    let param = ""

    if(filterData.search && filterData.search !== ""){
        
            if(param === ""){
                param += "search=" + filterData.search
            }
            else {
                param += "&&search=" + filterData.search
            }
        
    }

    if(filterData.sector && filterData.sector !== ""){
        
        if(param === ""){
            param += "sector=" + filterData.sector
        }
        else {
            param += "&&sector=" + filterData.sector
        }
    
    }




    return param
    
}


export const searchEvents = ({filter,auth}) => async (dispatch) => {

    try {
        console.log(filter)
        dispatch({type: EVENT_TYPES.LOADING, payload: true})

        let param = getParamsFilter2(filter)
      
        let url = `/event?`+ param
        console.log(url)
        const res = await getDataAPI(`/event?`+ param, auth.token)

        


         dispatch({
            type: EVENT_TYPES.GET_EVENTS,
            payload: res
        }) 
 
        dispatch({type: EVENT_TYPES.LOADING, payload: false}) 


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err}
        })
    }
    
}

export const searchEventsFilter = ({filterEvent,auth}) => async (dispatch) => {
    let param = getParamsFilter2(filterEvent.filter)
    console.log("seachevent")
    if(param){
            let url = `/event?n=9&`+ param
    console.log(url)
    dispatch({type: EVENT_TYPES.LOADING, payload: true})


    const res = await getDataAPI(url, auth.token)

    


     dispatch({
        type: EVENT_TYPES.GET_EVENTS,
        payload: res
    }) 

    dispatch({type: EVENT_TYPES.LOADING, payload: false}) 
    }

    
}

export const searchEventsFilterDetailled = ({filterEvent,auth}) => async (dispatch) => {
    console.log("action")
    console.log(filterEvent)

    let keys = Object.keys(filterEvent)
    console.log(keys)
    let param =""
    keys.forEach(key => {
            if(filterEvent[key] !== '' && filterEvent[key]){
                param+=key+"="+ filterEvent[key] + "&&"

            }

    
    });

    param = param.replace(' ', "+")
    param = param.slice(0, -2) 
    console.log(param)
     
    if(param){
           let url = `/eventFilter?`+ param
    console.log(url)
    dispatch({type: EVENT_TYPES.LOADING, payload: true})


    const res = await getDataAPI(url, auth.token)

    


     dispatch({
        type: EVENT_TYPES.GET_EVENTS,
        payload: res
    }) 

    dispatch({type: EVENT_TYPES.LOADING, payload: false})   
    }

    
}

export const getEventsNew = ({filter,more=false, auth}) => async (dispatch) => {

    try {
        let param = getParamsFilter(filter)

        console.log(filter)
        let url = `/events?${param}`

        dispatch({type: EVENT_TYPES.LOADING, payload: true})

        const res = await getDataAPI(url, auth.token)

        console.log(res)

        if(!more){
            dispatch({
                type: EVENT_TYPES.GET_EVENTS,
                payload: res
            }) 
     
        }else{
            dispatch({
                type: EVENT_TYPES.GET_MORE_EVENTS,
                payload: res
            }) 
     
        }

        dispatch({type: EVENT_TYPES.LOADING, payload: false})


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
    }
    
}
export const getEventsByCompany = ({auth, id, target}) => async (dispatch) => {

    try {
        dispatch({type: EVENT_TYPES.LOADING, payload: true})
        const res = await getDataAPI(`/eventsByCompany/${id}?target=${target}`, auth.token)

        console.log(res)

        dispatch({
            type: EVENT_TYPES.GET_EVENTS_BY_COMPANY,
            payload: res
        }) 
 
        dispatch({type: EVENT_TYPES.LOADING, payload: false})


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
    }
    
}

export const getEventsByUser = ({auth}) => async (dispatch) => {


    try {
        dispatch({type: EVENT_TYPES.LOADING, payload: true})
        const res = await getDataAPI(`/eventsByUser`, auth.token)


        dispatch({
            type: EVENT_TYPES.GET_EVENTS_BY_USER,
            payload: res
        }) 
 
        dispatch({type: EVENT_TYPES.LOADING, payload: false})


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
    }
    
}



export const getEvent = ({auth, id}) => async (dispatch) => {

    try {
        dispatch({type: EVENT_TYPES.LOADING, payload: true})
        const res = await getDataAPI(`/event/${id}`, auth.token)

        dispatch({
            type: EVENT_TYPES.GET_EVENT,
            payload: res
        }) 
 
        dispatch({type: EVENT_TYPES.LOADING, payload: false})


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
    }
    
}

export const likeEvent = ({event, auth, typePage}) => async (dispatch) => {
    const newEvent = {...event, likes: [...event.likes, auth.user]}
    console.log(typePage)
    if(typePage === "all"){
        dispatch({ type: DEAL_TYPES.UPDATE_DEAL, payload: newEvent})

    }else if(typePage === "visitorEvent"){
        dispatch({ type: VISITORS_TYPES.UPDATE_VISITOR_EVENTS, payload: newEvent})
    }
    
    else{
        dispatch({ type: EVENT_TYPES.UPDATE_EVENT, payload: newEvent})

    }
    console.log(newEvent)

    try {
        await patchDataAPI(`event/${event._id}/like`, null, auth.token)
        
    
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const unLikeEvent = ({event, auth, typePage}) => async (dispatch) => {
    const newEvent = {...event, likes: event.likes.filter(like => like._id !== auth.user._id)}
    if(typePage === "all"){
        dispatch({ type: DEAL_TYPES.UPDATE_DEAL, payload: newEvent})

    }else if(typePage === "visitorEvent"){
        dispatch({ type: VISITORS_TYPES.UPDATE_VISITOR_EVENTS, payload: newEvent})
    }
    
    else{
        dispatch({ type: EVENT_TYPES.UPDATE_EVENT, payload: newEvent})

    }

    try {
        await patchDataAPI(`event/${event._id}/unlike`, null, auth.token)

   

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const deleteEvent= ({event, auth}) => async (dispatch) => {

    try {
        const res = await deleteDataAPI(`event/${event._id}`, auth.token)
        dispatch({ type: EVENT_TYPES.DELETE_EVENT, payload: event })
        dispatch({ type: DEAL_TYPES.DELETE_DEAL, payload: event })

        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} });

        
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}


