import React from 'react'

import {  Form, Button, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useState } from 'react';
import { useEffect } from 'react';
import { createPage } from '../../../../redux/actions/pageAction';
import { useDispatch, useSelector } from 'react-redux';
import { ContainerForm } from './FormCompany1';


const FormCompany3 = ({ company, onChange, step, onStepChange }) => {
    const {auth, modal} = useSelector(state=>state);
    const [tags, setTags] = useState([]);
    const dispatch = useDispatch();
  useEffect(() => {
    const updatedCompany = { ...company, ['specialities']: tags };
    onChange(updatedCompany);
  }, [tags])
  

  const handleChangeInput = e => {
    const { name, value } = e.target
    const updatedCompany = { ...company, [name]: value };
    console.log(updatedCompany)
    onChange(updatedCompany);
  }

  const handleTagChange = (value) => {
    setTags(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      const { value } = e.target;
      if (value) {
        setTags([...tags, value]);
  
        e.target.value = '';
      }
      e.preventDefault();
    }
  };

  const handleSubmit = () => {

    if(modal.type==="partner"){
      company.typePage="partner"
    }
    
    const data= company

    console.log(data)
    dispatch(createPage({content:data,type:"company", auth}))

}

  return (
    <ContainerForm>
      <h3>C’est parti, créons la page de votre établissement !</h3>


<Form
                           onFinish={handleSubmit}
                           layout="vertical"
                           style={{width: "100%"}}
                         >
                                <Form.Item
                                    label="Spécialités"
                                    name="specialities"
                                    >
                                    <Select
                                    mode="tags"
                                    placeholder="Enter tags"
                                    value={tags}
                                    size='large'
                                    onChange={handleTagChange}
                                    onKeyDown={handleKeyDown}
                                    />
                                </Form.Item>



                                                   
                                <Form.Item
                                    label="Description"
                                    name="description"
                                    rules={[
                                    {
                                        required: true,
                                        message: "Entrez une description!",
                                        
                                    },
                                    {
                                      max: 1000,
                                      message: 'La description ne peut pas dépasser 1000 caractères!',
                                    }
                                    ]}
                                    hasFeedback
                                    onChange={(e)=>handleChangeInput(e)}

                                >
                                    <TextArea name="description" size='large' maxLength={1000} showCount />
                                </Form.Item>

        

                                    <Form.Item className="item-next" style={{display:'flex', justifyContent:'center' , marginTop:'35px'}}>
                                        <Button size="large" shape="round" type="primary" htmlType="submit">
                                           {modal.type === "partner" ?"Créer compte partenaire" :'Créer mon compte'}
                                        </Button>
                                    </Form.Item>
                                </Form>
    </ContainerForm>
  );
};

export default FormCompany3;

