import { DeleteData, GLOBALTYPES } from './globalTypes'
import { postDataAPI, getDataAPI, patchDataAPI, deleteDataAPI } from '../../utils/fetchData'

import { imageUpload } from '../../utils/imageUpload'
import { getParamsFilter } from './userAction'

export const PAGE_TYPES = {
    CREATE_PAGE: 'CREATE_PAGE',
    DELETE_PAGE:'DELETE_PAGE',
    LOADING_PAGE: 'LOADING_PAGE',
    GET_PAGES_BY_USER_ID:'GET_PAGES_BY_USER_ID',
    GET_PAGES:'GET_PAGES',
    GET_PAGE_BY_ID:'GET_PAGE_BY_ID',
    FOLLOW: 'FOLLOW',
    UNFOLLOW: 'UNFOLLOW',
    UPDATE_BACKGROUND: 'UPDATE_BACKGROUND',
    UPDATE_PAGE: 'UPDATE_PAGE',
    GET_MORE_PAGES:"GET_MORE_PAGES"

}


export const createPage = ({content, type, auth}) => async (dispatch) => {
    try {
        console.log(type)
/*         if(type === "company"){
            const check = validCreatePageCompany(content)
            console.log(check)
            if(check.errLength > 0)
            return dispatch({type: GLOBALTYPES.ALERT, payload: check.errMsg})
        }else if(type ==="school"){
            const check = validCreatePageSchool(content)
            if(check.errLength > 0)
            return dispatch({type: GLOBALTYPES.ALERT, payload: check.errMsg})
        }else {
            const check = validCreatePageOther(content)
            if(check.errLength > 0)
            return dispatch({type: GLOBALTYPES.ALERT, payload: check.errMsg})
        } */

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })
        
        const res = await postDataAPI('page', content, auth.token)

        dispatch({ 
            type: PAGE_TYPES.CREATE_PAGE, 
            payload: {...res.data.company} 
        })

        dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: false} })

        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} })
;

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const getPagesByUserID = ({auth} ) => async (dispatch) => {
    try {
        dispatch({ type: PAGE_TYPES.LOADING_PAGE, payload: true })
        const res = await getDataAPI(`/pagesByUserId`, auth.token)
        dispatch({
            type: PAGE_TYPES.GET_PAGES_BY_USER_ID,
            payload: {...res.data, page: 2}
        }) 

        dispatch({ type: PAGE_TYPES.LOADING_PAGE, payload: false })
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}



export const getPages = ({filterPages,auth}) => async (dispatch) => {
    let param = getParamsFilter(filterPages)
    if(param){
        let url = `pages?`+ param
        console.log(url)
        dispatch({type: PAGE_TYPES.LOADING_PAGE, payload: true})

        const res = await getDataAPI(url, auth.token)

        dispatch({
            type: PAGE_TYPES.GET_PAGES,
            payload: res.data
        }) 

        dispatch({type: PAGE_TYPES.LOADING_PAGE, payload: false}) 
    }

    
}
export const getPageById = ({id, auth} ) => async (dispatch) => {
    try {
        dispatch({ type: PAGE_TYPES.LOADING_PAGE, payload: true })
        const res = await getDataAPI(`/page/${id}`, auth.token)
        
        dispatch({
            type: PAGE_TYPES.GET_PAGE_BY_ID,
            payload: {...res.data}
        })

        dispatch({ type: PAGE_TYPES.LOADING_PAGE, payload: false })
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const follow = ({page,auth}) => async (dispatch) => {


    let newPage;

    newPage = {...page, followers: [...page.followers, auth.user._id]}

    dispatch({ type: PAGE_TYPES.FOLLOW, payload: newPage })

    dispatch({
        type: GLOBALTYPES.AUTH, 
        payload: {
            ...auth,
            user: {...auth.user, followingPage: [...auth.user.followingPage, page._id]}
        }
    }) 


    try {
         await patchDataAPI(`page/${page._id}/follow`, null, auth.token)
        //socket.emit('follow', res.data.newUser)

       /*  // Notify
        const msg = {
            id: auth.user._id,
            text: 'a commencé à vous suivre.',
            recipients: [newUser._id],
            url: `/profile/${auth.user._id}`,
        }

        dispatch(createNotify({msg, auth, socket})) */

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
    }
}


export const unfollow = ({page, auth}) => async (dispatch) => {


    let newPage;

    newPage = {...page, followers:DeleteData(page.followers, auth.user._id)}
    dispatch({ type: PAGE_TYPES.UNFOLLOW, payload: newPage })
/* 
    dispatch({
        type: GLOBALTYPES.AUTH, 
        payload: {
            ...auth,
            user: { 
                ...auth.user, 
                followingPage: DeleteData(auth.user.followingPage, page._id) 
            }
        }
    }) 
    */

    try {
        await patchDataAPI(`page/${page._id}/unfollow`, null, auth.token)

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
    }
}

export const updatePageBackgroundImage = ({imgBackground, id, auth}) => async (dispatch) => {
    console.log(imgBackground)
    console.log(id)
    if(!imgBackground)
    return dispatch({type: GLOBALTYPES.ALERT, payload: {error: "Please add an image"}})

 
    try {
        dispatch({type: GLOBALTYPES.ALERT, payload: {loading: true}})

        const res = await patchDataAPI(`page/${id}/changeBackground`, {
            imgBackground,
        }, auth.token)




        dispatch({type: GLOBALTYPES.ALERT, payload: {success: res.data.msg}})
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
    }
}


export const updatePage = ({id,pageData, avatar, auth, pages}) => async (dispatch) => {
/*     if(!pageData.fullname)
    return dispatch({type: GLOBALTYPES.ALERT, payload: {error: "Please add your full name."}})

    if(pageData.fullname.length > 25)
    return dispatch({type: GLOBALTYPES.ALERT, payload: {error: "Your full name too long."}})

    if(pageData.story.length > 200)
    return dispatch({type: GLOBALTYPES.ALERT, payload: {error: "Your story too long."}}) */

    try {
        let media;
        dispatch({type: GLOBALTYPES.ALERT, payload: {loading: true}})

        if(avatar) media = await imageUpload([avatar])
        console.log(pageData)
        const res = await patchDataAPI(`page/${id}`, {
            ...pageData,
            avatar: avatar ? media[0].url : pages.activePage.avatar
        }, auth.token)


        dispatch({
            type: GLOBALTYPES.AUTH,
            payload: {
                ...auth,
                pages: {
                    ...pages,
                    
                }
            }
        })

        dispatch({type: GLOBALTYPES.ALERT, payload: {success: res.data.msg}})
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
    }
}

export const updatePageWithoutImage = ({id,pageData, auth}) => async (dispatch) => {

    
        try {
            dispatch({type: GLOBALTYPES.ALERT, payload: {loading: true}})
    
       
            const res = await patchDataAPI(`page/${id}`, {
                ...pageData,
            }, auth.token)
    
    
            dispatch({
                type: GLOBALTYPES.AUTH,
                payload: {
                    ...auth,
                    pages: {
                        ...pageData,
                        
                    }
                }
            })
    
            dispatch({type: GLOBALTYPES.ALERT, payload: {success: res.data.msg}})
        } catch (err) {
            dispatch({
                type: GLOBALTYPES.ALERT, 
                payload: {error: err.response.data.msg}
            })
        }
    }



export const saveDeal = ({deal, auth}) => async (dispatch) => {
    const newUser = {...auth.user, savedDeals: [...auth.user.savedDeals, deal._id]}
    dispatch({ type: GLOBALTYPES.AUTH, payload: {...auth, user: newUser}})

    try {
        await patchDataAPI(`saveDeal/${deal._id}`, null, auth.token)
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const unSaveDeal = ({deal, auth}) => async (dispatch) => {
    const newUser = {...auth.user, savedDeals: auth.user.savedDeals.filter(id => id !== deal._id) }
    dispatch({ type: GLOBALTYPES.AUTH, payload: {...auth, user: newUser}})

    try {
        await patchDataAPI(`unSaveDeal/${deal._id}`, null, auth.token)
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}


export const saveEvent = ({event, auth}) => async (dispatch) => {
    const newUser = {...auth.user, savedEvents: [...auth.user.savedEvents, event._id]}
    dispatch({ type: GLOBALTYPES.AUTH, payload: {...auth, user: newUser}})

    try {
        await patchDataAPI(`saveEvent/${event._id}`, null, auth.token)
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}


export const unSaveEvent = ({event, auth}) => async (dispatch) => {
    const newUser = {...auth.user, savedEvents: auth.user.savedEvents.filter(id => id !== event._id) }
    dispatch({ type: GLOBALTYPES.AUTH, payload: {...auth, user: newUser}})

    try {
        await patchDataAPI(`unSaveEvent/${event._id}`, null, auth.token)
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}


export const saveJobOffer = ({joboffer, auth}) => async (dispatch) => {
    const newUser = {...auth.user, savedJobOffers: [...auth.user.savedJobOffers, joboffer._id]}
    dispatch({ type: GLOBALTYPES.AUTH, payload: {...auth, user: newUser}})

    try {
        await patchDataAPI(`saveJobOffer/${joboffer._id}`, null, auth.token)
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const unSaveJobOffer= ({joboffer, auth}) => async (dispatch) => {
    const newUser = {...auth.user, savedJobOffers: auth.user.savedJobOffers.filter(id => id !== joboffer._id) }
    dispatch({ type: GLOBALTYPES.AUTH, payload: {...auth, user: newUser}})

    try {
        await patchDataAPI(`unSaveJobOffer/${joboffer._id}`, null, auth.token)
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const deletePage = ({id,auth}) => async (dispatch) => {
    try {
        await deleteDataAPI('pagePromo/'+id, auth.token)
    } catch (err) {
        dispatch({ 
            type: GLOBALTYPES.ALERT, 
            payload: {
                error: err.response.data.msg
            } 
        })
    }
    
}

export const addAdmin = ({page, formData, auth}) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await patchDataAPI(`page/${page._id}/addAdmin`, {email: formData.email, role: formData.role}, auth.token);
            // Handle success
/*            console.log(res.data.newPage)
           let newPage = {...page, admins:res.data.newPage.admins }
           dispatch({ type: PAGE_TYPES.UPDATE_PAGE, payload: newPage })  */
            console.log(res.data.newPage.admins)
            let newPage = {...page, admins:res.data.newPage.admins }
            console.log(newPage)
            dispatch({ type: PAGE_TYPES.UPDATE_PAGE, payload: newPage })
            dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} });
            resolve(res.data); // Resolve the promise with response data
        } catch (err) {
            // Handle error
            dispatch({ type: GLOBALTYPES.ALERT, payload: {error: err.response.data.msg} });
            reject(err.response.data); // Reject the promise with error data
        }
    });
};


/* export const addAdmin = ({page,formData,auth}) => async (dispatch) => {

    try {
        
      const res = await patchDataAPI(`page/${page._id}/addAdmin`, {email: formData.email, role: formData.role}, auth.token)
      let newPage;

      newPage = {...page, admins: [...page.admins, user]}
  
      dispatch({ type: PAGE_TYPES.UPDATE_PAGE, payload: newPage }) 
      dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} });

  
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
    }
}
 */
export const deleteAdmin = ({page,userId,auth}) => async (dispatch) => {

    

    try {
          const res = await patchDataAPI(`page/${page._id}/deleteAdmin`, {userId: userId}, auth.token)

    
        const    newPage = {...page, admins: page.admins.filter(admin => admin.user._id !== userId)
        }
        console.log(newPage)

        dispatch({ type: PAGE_TYPES.UPDATE_PAGE, payload: newPage })
        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} });

    
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}
