import { EXPERIENCES_TYPES } from '../actions/experienceAction'
import { EditData, DeleteData } from '../actions/globalTypes'

const initialState = {
    loading: false,
    experiences: [],
    result: 0,
    page: 2
}

const experienceReducer = (state = initialState, action) => {
    switch (action.type){
        case EXPERIENCES_TYPES.CREATE_EXPERIENCE:
            return {
                ...state,
                experiences: [action.payload, ...state.experiences]
            };
        case EXPERIENCES_TYPES.LOADING_EXPERIENCE:
            return {
                ...state,
                loading: action.payload
            };

        case EXPERIENCES_TYPES.GET_EXPERIENCES:
            return {
                ...state,
                experiences: action.payload.experiences,
                result: action.payload.result,
                page: action.payload.page
            };
        case EXPERIENCES_TYPES.GET_EXPERIENCES_USER:
                return {
                    ...state,
                    experiences: action.payload.experiences,
                    result: action.payload.result,
                    page: action.payload.page
                };
        case EXPERIENCES_TYPES.DELETE_EXPERIENCE:
             return {
                 ...state,
                experiences: DeleteData(state.experiences, action.payload._id)
            };
        case EXPERIENCES_TYPES.UPDATE_EXPERIENCE:
                return {
                    ...state,
                    experiences: EditData(state.experiences, action.payload._id, action.payload)
                };
        default:
            return state;
    }
}

export default experienceReducer