import React, { useState } from 'react'
import { Button, Modal, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { deleteDeal } from '../../../../redux/actions/dealAction';
import { BiEdit } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import FormCreateDeal from './FormCreateDeal';
import { GLOBALTYPES } from '../../../../redux/actions/globalTypes';



//dispatch(deleteDeal({deal:modal.deal, auth}))

const PartnerDealsTab = () => {

  const {deals, modal,auth} = useSelector(state=>state);
  const history = useHistory();
  const dispatch = useDispatch();
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeal, setOpenDeal] = useState(false);

  const [currentDeal, setCurrentDeal] = useState(null);

  const columns = [
    {
      title: 'Offre',
      width: 300,
      dataIndex: 'offer',
      key: 'offer',
    },
    {
      title: 'Mode',
      width: 150,
      dataIndex: 'mode',
      key: 'mode',
    },
    {
      title: 'Mode Infos.',
      width: 150,
      dataIndex: 'modeInfos',
      key: 'modeInfos',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'sector',
    },
  
  
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: 150,
      render: (text, record) =><div style={{display:"flex", gap: "5px"}}>
      <Button  onClick={()=>edit(record)} size="large" icon={<BiEdit />}  />
      <Button onClick={showModalDelete} type="primary" danger size="large" icon={<MdDelete />}  />

   </div>,
    },
  ];

  const edit = (record) => {
    if(record){
    dispatch({ type: GLOBALTYPES.MODAL, payload: { typeModal: "createDealPartnerModal", open: true, deal: record } })
    }
}

  const showModalDelete = () => {
    if(currentDeal){
      setOpenDelete(true);
    }
  };
  const handleDeleteOk = () => {

    setOpenDelete(false);
    dispatch(deleteDeal({deal:currentDeal, auth}))

  };

  const handleDeleteCancel = () => {
    setOpenDelete(false);
  };

  const showModalDeal = () => {
    if(currentDeal){
      setOpenDeal(true);
    }
  };


  const handleDealCancel = () => {
    setOpenDeal(false);
  };
  return (
    <>
    <Table
    columns={columns}
    dataSource={deals.deals}
    scroll={{
      x: 1500,
     
    }}
    onRow={(record, rowIndex) => {
      return {
        onClick: (event) => {setCurrentDeal(record)}, // click row
      
      };
    }}

    
  />
    <Modal
        open={openDelete}
        title="Supprimer offre"
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >

      Êtes-vous sûr de vouloir supprimer cette offre?
    </Modal>

    <Modal title="Modifier Deal" open={openDeal} onCancel={handleDealCancel} footer={null} >
                <FormCreateDeal deal={currentDeal}/>
     </Modal>

  </>
  )
}

export default PartnerDealsTab