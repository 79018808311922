import React, { useEffect, useState } from 'react'
import styled from "styled-components"
import { FaFilter } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

import { updateFilterDeal2 } from '../../redux/actions/filterDealActions'
import { v4 as uuidv4 } from 'uuid';
import { GLOBALTYPES } from '../../redux/actions/globalTypes'
import { FILTER_DEAL_TYPES } from '../../redux/actions/filterDealActions'
import useWindowDimensions from '../../utils/useWindowsDimensions'
import bed from '../../assets/offers/bed.png'
import eat from '../../assets/offers/traiteur.png'
import drink from '../../assets/offers/bar.png'
import spa from '../../assets/offers/spa.png'
import heart from '../../assets/offers/heart.png'
import laugh from '../../assets/offers/laugh.png'
import { convertIdToLabel } from '../../utils/function'
import FilterImageIcon from './FilterImageIcon'

const FilterBar = ({ type }) => {

    const { filterDeal, datas } = useSelector(state => state)
    const { width } = useWindowDimensions();
    const [saveChoice, setSaveChoice] = useState(null)
    const dispatch = useDispatch();
    const [filtersTerms, setFiltersTerms] = useState([])



    useEffect(() => {

        if (filterDeal.filter !== null) {
            let filters = []
            Object.entries(filterDeal.filter).map(([key, value]) => {


                if (value) {
                    filters.push({ key: key, value: value })

                }
            })
            setFiltersTerms(filters)

        }


    }, [filterDeal])




    const clickFilterButton = (id) => {
        if (saveChoice !== id) {
            let index = filterButtons.findIndex(x => x.id === id);
            updateFilter(id);
            setSaveChoice(id)
        } else {
            updateFilter("");

            setSaveChoice(null)

        }

    }


    const filterButtons = [
        { id: 1, text: 'Séjourner', type: "sleep", svg: bed, width: "25px" },
        { id: 2, text: 'Se restaurer', type: "eat", svg: eat, width: "25px" },
        { id: 3, text: 'Boire un verre', type: "drink", svg: drink, width: "25px" },
        { id: 4, text: 'S\'amuser', type: "fun", svg: laugh, width: "25px" },
        { id: 5, text: 'Se détendre', type: "relax", svg: spa, width: "25px" },
        { id: 6, text: 'Se faire plaisir', type: "pleasure", svg: heart, width: "25px" },
    ]


    const redirectFilter = () => {
        if (width > 768) {
            dispatch({ type: GLOBALTYPES.MODAL, payload: { typeModal: "filterModal", open: true } })
        } else {
            dispatch({ type: GLOBALTYPES.MODAL, payload: { typeModal: "filterModalMobile", open: true } })
        }
    }


    const updateFilter = (sector) => {

        let filter = {};

        console.log(filterDeal)
        if (filterDeal.filter) {
            filter = filterDeal.filter
            filter["tags"] = sector
        } else {
            filter["tags"] = sector
        }

        dispatch(updateFilterDeal2({ filter }))

    }

    return (
        <Container>
            <Filters className='filter'>
                <ButtonDiv >
                    <ButtonFilter onClick={() => redirectFilter()} background="#F87778" color="white" ><FaFilter /></ButtonFilter>
                    <span>Filtres</span>
                </ButtonDiv>


                {
                    datas.filters.map((filter) => (
                        <ButtonDiv key={uuidv4()}>
                            <ButtonFilter key={uuidv4()} onClick={() => clickFilterButton(filter.id)} active={saveChoice === filter.id ? true : false} background="white" color="#C0C0C0" fontsize="2em">
                                {/*                             <img src={filter.svg}  width={50} height="50" title="Menu" />
 */}                       
                                    <FilterImageIcon type={filter.type} />
                             </ButtonFilter>
                            <span>{filter.name}</span>
                        </ButtonDiv>

                    ))
                }



            </Filters>

            <FiltersTerms>
                {
                    filtersTerms.length > 0 ? <>
                        {
                            filtersTerms.map(element => (
                                <>
                                    <>  {(element.key !== 'date' && element.key !== 'tags') && <div key={uuidv4()}>{element.value !== "" && <Tag key={uuidv4()}>{element.value} </Tag>}</div>} </>
                                    <>  {element.key === 'date' && element.value.split(',').map(day => (
                                        <div key={uuidv4()}>{element.value !== "" && <Tag key={uuidv4()}>{convertIdToLabel(datas.days, day)}</Tag>}</div>
                                    ))} </>
                                    <>  {element.key === 'tags' && element.value.split(',').map(tag => (
                                        <div key={uuidv4()}>{element.value !== "" && <Tag key={uuidv4()}>{convertIdToLabel(datas.filters, tag)}</Tag>}</div>
                                    ))} </>
                                </>
                            ))
                        }
                    </> : <Tag>Aucun filtre</Tag>
                }
                {filtersTerms.length > 0 && <BtnDelete onClick={() => { setSaveChoice(null); setFiltersTerms([]); dispatch({ type: FILTER_DEAL_TYPES.UPDATE_FILTER, payload: null }) }}>Effacer</BtnDelete>}
            </FiltersTerms>

        </Container>

    )
}
const Container = styled.div`

    display:flex;
    flex-direction: column;


`
const Filters = styled.div`
    display:flex;
    justify-content: flex-start;
 
    padding: 10px;

    overflow-x: scroll; 

        /* width */
    ::-webkit-scrollbar {
        height:5px;
        cursor:pointer;

    }

    /* Track */
    ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
    
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: #F87778; 
    border-radius: 10px;
    
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background: #f56466; 
     cursor: pointer;
    }

    @media only screen and (max-width: 768px) {  
        width: 105%;
        overflow-x: scroll;

    }
    
`

const FiltersTerms = styled.div`

    display:flex;
    margin-top: 2%;

`

const ButtonFilter = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
  
    background: ${({ active }) => active ? '#F87778' : 'white'};

    color: ${({ active }) => active ? '#F87778' : '#969696'} ;
    font-weight: ${({ active }) => active ? '700' : '400'};

    border: 1px solid #E5E5E5;
    width: 60px;
    height: 60px;
    border-radius: 8px;
    font-size: ${props => props.fontsize ? props.fontsize : "1.8em"};

    img {
        width: 35px;
        height: 35px;

    
    }



    @media only screen and (max-width: 768px) {  
        width: 45px;
        height: 45px;
        border-radius: 8px;
        font-size: ${props => props.fontsize ? props.fontsize : "1.4em"};

    }
    

`

const ButtonDiv = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    justity-content: center;
    span{
        
        color: #C0C0C0;
        font-size: 0.7em;
        max-width: 80px;
        text-align:center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: calc(100%);
    }

    margin-right: 2%;

    &:hover{
        cursor: pointer;
    }

    @media only screen and (max-width: 768px) {  
       span{
            font-size: 0.8em;
       }

    }


`

const Tag = styled.span`
    display:flex;
    justify-content: center;
    align-items:center;

    border: 1px solid #C4C4C4;
    font-size: 0.9em;
    padding: 2px 8px;
    border-radius: 25px;
    margin-right: 15px;
    background: white;

`

const BtnDelete = styled.span`
    display:flex;
    justify-content: center;
    align-items:center;
    color: #F87778;
    border: 1px solid #F87778;
    font-size: 0.9em;
    padding: 2px 8px;
    border-radius: 25px;
    margin-right: 15px;
    font-weight: 600;
    &:hover{
        cursor: pointer;
        background: #F87778;
        color: white;
    }

`
export default FilterBar