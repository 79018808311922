
import { DeleteData, EditData } from '../actions/globalTypes';
import { STRIPE_TYPES } from '../actions/stripeAction';


const initialState = {
    loading: false,
    subscriptions: [],
    products: [],
    checkoutLink: "",
    count: 0,
    page: 1,
    n: 0,
}



const stripeReducer = (state = initialState, action) => {
    switch (action.type) {
      
        case STRIPE_TYPES.GET_STRIPE_SUBSCRIPTIONS:
            return {
                ...state,

                subscriptions: action.payload.data.subscriptions,
               
            }
        case STRIPE_TYPES.GET_STRIPE_PRODUCTS:
                return {
                    ...state,
                    products: action.payload.data.products,
                   
                }
        case STRIPE_TYPES.GET_CHECKOUT_LINK:
                return {
                    ...state,
                    checkoutLink: action.payload.session.url,
                    loading: false
    
                }



        case STRIPE_TYPES.LOADING:
            return {
                ...state,
                loading: action.payload
            };


     

        default:
            return state;
    }
}


export default stripeReducer
