import { GLOBALTYPES } from './globalTypes'
import { patchDataAPI, postDataAPI } from '../../utils/fetchData'
import validLoginForm from '../../utils/formValidation/validLoginForm'
import valid from '../../utils/formValidation/valid'
import { activateFirstConnection } from './profileAction'
import { PAGE_TYPES } from './pageAction'


export const login = (data) => async (dispatch) => {
    const check = validLoginForm(data)
    if(check.errLength > 0)
    return dispatch({type: GLOBALTYPES.ALERT, payload: check.errMsg})
    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })
        const res = await postDataAPI('login', data)
        dispatch({ 
            type: GLOBALTYPES.AUTH, 
            payload: {
                token: res.data.access_token,
                user: res.data.user
            } 
        })

        localStorage.setItem("firstLogin", true)
        localStorage.setItem("token", res.data.access_token)
        localStorage.setItem("user", res.data.user)

        dispatch({ 
            type: GLOBALTYPES.ALERT, 
            payload: {
                success: res.data.msg
            } 
        })
        let auth = {
            token: res.data.access_token,
            user: res.data.user
        }
        dispatch(activateFirstConnection(auth))


        
    } catch (err) {
        dispatch({ 
            type: GLOBALTYPES.ALERT, 
            payload: {
                error: err.response.data.msg
            } 
        })
    }
}


export const refreshToken = () => async (dispatch) => {
    const firstLogin = localStorage.getItem("firstLogin")
    const token = localStorage.getItem("token")

    if(firstLogin){
        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })

        try {
            const res = await postDataAPI('refresh_token', token)
            dispatch({ 
                type: GLOBALTYPES.AUTH, 
                payload: {
                    token: res.data.access_token,
                    user: res.data.user
                } 
            })

            dispatch({ type: GLOBALTYPES.ALERT, payload: {} })

        } catch (err) {
            dispatch({ 
                type: GLOBALTYPES.ALERT, 
                payload: {
                    error: err.response.data.msg
                } 
            })
        }
    }
}

export const register = (data) => async (dispatch) => {
    const check = valid(data)
    if(check.errLength > 0){
        dispatch({type: GLOBALTYPES.ALERT, payload: check.errMsg})
        return false;

    }

    try {
        dispatch({type: GLOBALTYPES.ALERT, payload: {loading: true}})

        const res = await postDataAPI('register', data)
        dispatch({type: GLOBALTYPES.ALERT, payload: {loading: false}})


            dispatch({ type: GLOBALTYPES.TEMP, payload: res})

/*             localStorage.setItem("firstLogin", true)
 */    

       
        return true;
       
    } catch (err) {
        dispatch({ 
            type: GLOBALTYPES.ALERT, 
            payload: {
                error: err.response.data.msg
            } 
        }) 


        dispatch({ 
            type: GLOBALTYPES.STATUS, 
            payload: {
                error: err.response.data
            } 
        }) 

        return false;
    }
}

export const registerAndCreatePage = ({userData, companyData}) => async (dispatch) => {


    try {
        dispatch({type: GLOBALTYPES.ALERT, payload: {loading: true}})

        const res = await postDataAPI('register', userData)


        companyData.user = res.data.user._id
        const res2 = await postDataAPI('page', companyData, res.data.access_token)
        
        dispatch({ 
            type: PAGE_TYPES.CREATE_PAGE, 
            payload: {...res.data.newPage, user: res.user} 
        })

    
        dispatch({ type: GLOBALTYPES.STATUS, payload: false})
        dispatch({ type: GLOBALTYPES.TEMP, payload: res})


            dispatch({ 
                type: GLOBALTYPES.STATUS, 
                payload: {
                    success: res.data.msg
                } 
            })
            dispatch({ 
                type: GLOBALTYPES.ALERT, 
                payload: {
                    success: "Utilisateur et Page créés avec succès"
                } 
            })
       
       
    } catch (err) {
        dispatch({ 
            type: GLOBALTYPES.ALERT, 
            payload: {
                error: err.response.data.msg
            } 
        }) 


        dispatch({ 
            type: GLOBALTYPES.STATUS, 
            payload: {
                error: err.response.data
            } 
        }) 
    }
}

export const logout = () => async (dispatch) => {
    try {
        localStorage.removeItem('firstLogin')
        await postDataAPI('logout')
        window.location.href = "/"
    } catch (err) {
        dispatch({ 
            type: GLOBALTYPES.ALERT, 
            payload: {
                error: err.response.data.msg
            } 
        })
    }
}

export const activateAccount = (id) => async (dispatch) => {

    console.log(id)
    try {

        const res = await patchDataAPI(`user/active/${id}`)

        dispatch({ 
            type: GLOBALTYPES.ACTIVATION, 
            payload: {
                activation: res
            } 
        })


     
   
    } catch (err) {
        dispatch({ 
            type: GLOBALTYPES.ACTIVATION, 
            payload: {
                error: err.response.data.msg
            } 
        })
    }
}