import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from "styled-components"
import { useLocation } from "react-router-dom";
import { getPageById } from '../../redux/actions/pageAction'
import useWindowDimensions from '../../utils/useWindowsDimensions';
import BigScreenCompanyPage from '../../components/company/BigScreenCompanyPage'
import {  getDealsByCompany2 } from '../../redux/actions/dealAction'
import DealCardModal from '../../components/home/DealCardModal'
import { GLOBALTYPES } from '../../redux/actions/globalTypes'
import { isInArray } from '../../utils/function'
import EventCardModal from '../../components/home/event/EventCardModal'

const Company = () => {
  const { auth, pages,modal } = useSelector(state => state)
  const location = useLocation();
  const dispatch = useDispatch()

  const [company, setCompany] = useState(null);
  const { id } = useParams()
  const {  width } = useWindowDimensions();

   useEffect(() => {
    if(pages.activePage){
        setCompany(pages.activePage)
    }
 
  }, [pages.activePage]);

  useEffect(() => {
    dispatch({ type: GLOBALTYPES.MODAL,payload: { open:false}})

    if(company && isInArray(company.admins, auth.user._id)){
      dispatch({ type: GLOBALTYPES.THEME, payload: {theme: 'company'} })

    }
  }, [company])
  

  useEffect(() => {
   if(location.state && location.state.company){ 
        setCompany(location.state.company)
   }else{
     dispatch(getPageById({auth, id}))
   }  

   dispatch(getDealsByCompany2({ auth, id, filter: {} }))

   return () => {
    dispatch({ type: GLOBALTYPES.THEME, payload: {theme: 'normal'} })
  }
 },[])


  return (
    <Container>
            {(modal.typeModal ===  "dealCardModal" && modal.open && width > 768) && <DealCardModal />}
            {(modal.typeModal ===  "eventCardModal" && modal.open && width > 768) && <EventCardModal />}

       {
          company &&

          <BigScreenCompanyPage company={company}/>
        }
 

    </Container>
  )
}

const Container = styled.div`
  display:flex;
  flex-direction: column;
  width: 100%;
  margin-left: 70px;

  @media screen and (max-width: 768px) {
    margin-left: 0px;
    padding: 10px;
  }
`



export default Company; 