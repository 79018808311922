import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from "styled-components"
import HeaderDealCompany from '../../components/deal/HeaderDealCompany'
import { getEvent } from '../../redux/actions/eventAction'
import EventCard from '../../components/event/EventCard'
const Event = ({eventProp}) => {
  const { auth, events } = useSelector(state => state)
  const dispatch = useDispatch()

  const [event, setEvent] = useState(null);
  const { id } = useParams()

  useEffect(() => {

      if(events.current_event){
        setEvent(events.current_event)
        console.log(event)

      }
  },[events.current_event])
  
  useEffect(() => {
    
    if(event){

    }
     let findEvent = events.events.find(item => item._id === id)
    if(findEvent){
      setEvent(findEvent)

    }else{
      dispatch(getEvent({auth, id}))
      
    } 
  },[])

  return (
    <Container>
        {
          event ? 
           <>
           <div className='header'>
           <HeaderDealCompany  company={event.company[0]?event.company[0]:event.company}/>

           </div>
            <EventCard event={event}/>

           
           </> 
          
          
          
          : "pas ok"
        }

    </Container>
  )
}

const Container = styled.div`
  display:flex;
  flex-direction: column;
  width: 90%;
  margin-bottom: 5%;

  @media (min-width: 768px) {
    .header{
      display:none;
    }
    justify-content: center;
    margin-bottom: 0%;

    width:40%;
    min-width: 400px;
    padding: 30px 0px;
  }   

`

export default Event; 