import { combineReducers } from 'redux'

import auth from './authReducer'
import alert from './alertReducer'
import status from './statusReducer'
import modal from './modalReducer'
import pages from './pageReducer'
import deals from './dealReducer'
import profile from './profileReducer'
import sidebar from './sidebarReducer'
import filterDeal from './filterDealReducer'
import experiences from './experienceReducer'
import formations from './formationReducer'
import skills from './skillReducer'
import languages from './languageReducer'
import temp from './tempReducer'
import theme from './themeReducer'
import filter from './filterReducer'
import users from './userReducer'
import visitors from './visitorReducer'
import datas from './dataReducer'
import events from './eventReducer'
import subscriptions from './subscriptionReducer'
import stripe from './stripeReducer'
import ads from './adsReducer'
import partnerships from './partnershipReducer'

export default combineReducers({
    alert,
    auth,
    deals,
    datas,
    events,
    experiences,
    formations,
    skills,
    languages,
    modal,
    pages,
    partnerships,
    status,
    profile,
    sidebar,
    filterDeal,
    temp,
    theme,
    filter,
    users,
    visitors,
    subscriptions,
    stripe,
    ads
})