import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CurriculumVitae from '../../components/profile/cv/CurriculumVitae'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import useWindowDimensions from '../../utils/useWindowsDimensions'
import Avatar from '../../components/Avatar'
import { getDataAPI } from '../../utils/fetchData'
import { InfoAvatar } from '../myaccount'
import { extractLocalityAndCountry } from '../../utils/function'
const Resume = () => {
    const {auth} = useSelector(state=>state)
    const {width} = useWindowDimensions()
    const { id } = useParams()
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      if(auth.user._id === id){
        setUser(auth.user)
      }else{
        getUserById(id)
      }
    }, [])

    const getUserById = async (id) => {
        setLoading(true)
        const res = await getDataAPI(`user/${id}`, auth.token)
        setUser(res.data.user)
        setLoading(false)

    }
    


  return (
    <>

        {
            (width >= 768 && user)  && <ContainerDesktop>

            <LeftPart>
                <UserPart>
                      <InfoAvatar>
                                <img src={user.avatar} alt="avatar" />
            
                       </InfoAvatar> 
                      <h2>{user.fullname}</h2>
                      <h3>{user.titleJob}</h3>

                      <Description>
                          <h5>Bio:</h5>
                          <p>{user.story ? user.story : "Aucune description"}</p>
                          <h5>Localisation:</h5>
                          <p>{extractLocalityAndCountry(user.address)}</p>
 
                      </Description>
                </UserPart>
            </LeftPart>

            <RightPart>
                 <CurriculumVitae id={id}/>
            </RightPart>

           

            </ContainerDesktop>
        }


        {
           ( width < 768 && user) && <ContainerMobile>
            <HeaderMobile>
                <Avatar size={"65px"} src={user.avatar}/>
                <h2>{user.fullname}</h2>
                <span>{user.titleJob}</span>
                <Line/>
            </HeaderMobile>
            

            <CurriculumVitae  id={id}/> 

            
            </ContainerMobile>
        }
    {/*     <AiOutlineArrowLeft className='icon-left' onClick={() =>  history.push('/settings')}/>
        {auth.user._id === id ? <h1>Mon compte</h1> : <h1>Profil de ...</h1> }
        <CurriculumVitae/> */}


    
    </>
    
  )
}

const ContainerDesktop = styled.div`
    display:flex;
    width: 80%;
    padding-bottom: 2%;

`

const Line = styled.div`

    margin-top: 10%;
    width: 100%;
    background: #E5E5E5;
    height:0.5px;
    @media screen and (max-width: 768px) {
      width: 90%;

    }

`

const ContainerMobile = styled.div`
    display:flex;
    flex-direction column;
    width: 100%;
    height: 100%;

    h1{
        margin: 0px;
        color: #F87778;
        font-size: 1.1em;
        font-weight: 500;
    }

    .icon-left{
        position: absolute;
        top: 0px;
        left: 0px;
        color: #F87778;
        font-size: 1.5em;
      }
  
    


`

const HeaderMobile = styled.div`

      display:flex;
      flex-direction: column;
      align-items: center;

      h2{
        margin: 0px;
        margin-top: 3%;

        font-size: 1.1em;
        font-weight: 600;
        color: #F87778;
      }

      span{
        margin: 0px;
        font-size: 0.8em;
        font-weight: 400;
        color: #757474;
      }


`

const LeftPart = styled.div`
    display:flex;
    flex-direction: column;
    
    width: 25%;
    padding: 2% 2%;
    
`

const UserPart = styled.div`
display:flex;
flex-direction: column;
align-items: center;
background :#FAFAFA;

border-radius: 25px;
min-height: 70vh;
max-height: 80vh;

h2{
  margin: 0px;
  margin-top: 5%;
  font-size: 1.5em;
  font-weight: 700;
  color: #757474;
  text-align: center;
}

h3{
  margin: 0px;
  margin-top: 2%;
  font-size: 1.2em;
  font-weight: 400;
  color: #757474;
  text-align: center;

}


`

const Description = styled.div`
    display:flex;
    flex-direction: column;
    width: 100%;
    padding: 0% 5%;
    h5{
      margin: 0px;
      margin-top: 5%;
      font-size: 1em;
      font-weight: 600;
      color: #F87778;
    }

    p{
      margin: 0px;
      margin-top: 0%;
      font-size: 1em;
      font-weight: 400;
      color: #757474;
    }

`

const RightPart = styled.div`
  display:flex;
  flex-direction: column;
  width: 70%;
`

export default Resume