import React, { useEffect, useState } from 'react'
import Modal from '../../../utils/Modal/Modal'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'antd'
import { getCheckoutLink, getPreviewInvoice } from '../../../../redux/actions/stripeAction'
import { reactivateSubscription } from '../../../../redux/actions/subscriptionAction'
import Logo from '../../../../assets/logo-violet.png'
import { Player } from '@lottiefiles/react-lottie-player'
import Animation from '../../../../assets/activate.json'
import { GLOBALTYPES } from '../../../../redux/actions/globalTypes'

const ModalReactivateSubscription = () => {
    const { auth, pages, modal, stripe } = useSelector(state => state)
    const dispatch = useDispatch();

    const [previewInvoice, setPreviewInvoice] = useState(null);


    useEffect(async () => {
      if(stripe.subscriptions && stripe.subscriptions.data.length > 0){
       let res = await  dispatch(getPreviewInvoice({id:pages.activePage.customerId, content:{price:"price_1ORwJFDYl0FIlCROQU6FZHYo", qty:stripe.subscriptions.data[0].quantity + 1}, auth}))
       setPreviewInvoice(res)
        }else{
            let res = await  dispatch(getPreviewInvoice({id:pages.activePage.customerId, content:{price:"price_1ORwJFDYl0FIlCROQU6FZHYo", qty:1}, auth}))
            setPreviewInvoice(res)
        }
    }, [])
    

    const handleReactivation = async () => {
        if (stripe.subscriptions.data.length > 0) {

            dispatch(reactivateSubscription({ id:modal.subscription._id, content:{subscriptionStripe:stripe.subscriptions.data[0].id }, auth }))

            //update sub
        } else {
            //take sub
            const content = { customerId: pages.activePage.customerId, priceId: "price_1ORwJFDYl0FIlCROQU6FZHYo", qty: 1, accountId: modal.subscription._id }
            console.log(content)
            let resLink = await dispatch(getCheckoutLink({ content, auth }));
            console.log(resLink)
            window.location.href = resLink.data.session.url
        }
    }


    return (
        <Modal modalName={"reactivateSubModal"} heightModal={"80vh"} widthModal={"35vw"}>
            {modal.subscription && <Container>
{/*                 <Top>

                    <svg width="70" height="70" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#f87778" d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6" />
                    </svg>

                    <span className='title'>Compte </span>
                    <span className='subtitle'>Êtes-vous sûr de vouloir renouveler l'abonnement de: </span>
                    <span className='name'>{modal.subscription.firstname} {modal.subscription.lastname}</span>

                </Top>

                <Bottom>
                    <Button size="large" type="primary" onClick={handleReactivation}>
                        Valider
                    </Button>
                </Bottom>

                
 */}
                 <img className="logo" src={Logo} alt='logo' />


                <Top>
                    <span className='title'>Compte désactivé </span>
                    <Player
                                    autoplay
                                    loop
                                    src={Animation}
                                    style={{ height: 'auto', width: '50%'}}
                                >
                                </Player>

                    <div className='price-div'>
                                
                    {previewInvoice && <div className='price'>
                            <span className='price-span'>{previewInvoice.amount_due/100}€</span>
                            <span className='month-span'>/mois</span>

                        </div>}
                   {stripe.subscriptions && stripe.subscriptions.data.length > 0 ? 
                   
                   <><span className='subtitle'>Ceci est le prix mis-à-jour de votre abonnement (seul les comptes actifs sont pris en compte).</span>
                   </> :
                    
                    <div style={{display:"flex", flexDirection:"column", padding:'0px 5px', margin:'5px 0px'}}><span className='subtitle'>Pour le réactiver vous devez <b>souscrire à un nouvel abonnement</b> </span>
                    <span className='subtitle' style={{fontSize:"0.9em", fontStyle:'italic'}}>Vous serez redirigé vers un formulaire de paiement Stripe sécurisé.</span> </div>}

                    </div>
                </Top>
                    <Button size="large" type="primary" className='btn-blue' style={{marginTop:"15px"}} onClick={handleReactivation} shape="round">
                         {stripe.subscriptions && stripe.subscriptions.data.length > 0 ?"Réactiver le compte": "Souscrire à un abonnement"}
                    </Button>

            </Container>}
        </Modal>
    )
}

const Container = styled.div`
    display:flex;
    position: relative;
    flex-direction: column;
    align-items:center;
    justify-content:space-around;
    height: 100%;
    width: 100%;
  
    padding: 2% 5%;

    .logo{
        width: 150px;
    }
`

const Top = styled.div`
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    text-align: center;
    .title{
        font-size: 1.2em;
        font-weight: 900;
        margin: 10px 0px;
    }

    .price-div{
        display:flex;
        align-items:center;
    }
    .subtitle{
        margin: 0px;
        padding: 0px;
        color: #969696;

    }

    .price{
        background: linear-gradient(90deg, rgba(255,160,0,1) 0%, rgba(255,120,181,1) 100%);
                    width: fit-content;
                    height: fit-content;
                    margin: 0px auto;
                    padding: 10px;
                    border-radius: 10px;
        
                    .price-span{
                        color:white;
                        font-size: 2em;
                        font-weight: 900;
        
                    }
                    .month-span{
                        color:white;
                        font-size: 1em;
                        margin-left: 5Px;
                        
                    }
                }
`

const Bottom = styled.div`
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    padding: 25px 0px;


    .title{
        color:#969696;
    }

    .code{
        font-weight: 900;
        font-size: 1.2em;
    }
`


export default ModalReactivateSubscription