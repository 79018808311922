import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { Button, Input } from 'antd'


const ForgotPassword = () => {

    const [email, setEmail] = useState("")
    
    const [status, setStatus] = useState(0)
    const [url, setURL] = useState(null)



    const history = useHistory()

    useEffect(() => {
        if (process.env.REACT_APP_URL) {
          setURL(process.env.REACT_APP_URL);
        }
      }, []);
    
      useEffect(() => {
        if (url) {
          console.log(url);
        }
      }, [url]);
    const sendMail = async () => {
        try{
            const res = await axios.post(`${url}/api/sendResetPassword`, {email: email})
            if(res.status === 200){
                setStatus(1)
            }
        }
        catch(e){
            console.log(e.response.status)
            if (e.response.status === 400){
                setStatus(2)
            }else if (e.response.status === 500){
                setStatus(3)
            }
        }

    }

    return (
        <Container>
           
               <Content>
                    <h2>Mot de passe oublié?</h2>
                    <p>Entrez l'adresse email utilisée pour votre compte </p>

                    <Input maxLength={140} size="large" shape="round" type="primary" placeholder='Entrez votre email'  style={{marginTop: '5%'}} onChange={(e)=>setEmail(e.target.value)}/>
                    {status === 1 && <p className='msgResponse valid'>Un email contenant un lien de réinitialisation vous a été envoyé à l'adresse indiquée.</p>}
                    {status === 2 && <p className='msgResponse error'>Cet email n'existe pas!</p>}
                    {status === 3 && <p className='msgResponse error'>Une erreur est survenue! Veuillez réessayer!</p>}

                    <Button size="large" shape="round" type="primary" style={{width: '100%', marginTop: '5%'}} onClick={sendMail} disabled={status === 1}>
                        <span>Envoyer</span>
                    </Button>

                    <ReturnButton onClick={()=> history.push("/login")}>Retourner à l'accueil</ReturnButton>
                </Content>
        </Container>
    )
}

const Container = styled.div`

    display:flex;
    justify-content: center;
    width:100vw;
    height: 100vh;
    align-items: center;
    background :#F0F0F0;
    @media (max-width: 768px) {
       height: 100vh;
    
    }


`

const Content = styled.div`
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    width: 25%;
    background: white;
    padding: 2% 2%;
    border-radius:25px;

    h2{
        font-size: 1.5em;
        margin:0px;
        margin-bottom: 8%;
        font-weight: 700;
    }

    p{
        margin: 0px;


    }

    .msgResponse{
        margin-top: 25px;
        margin-bottom: 25px;

    
    }

    .error {
        color: red;
    }

    .valid {
        color: green;
    }
    

    @media (max-width: 768px) {
        width: 80%;

        h2{
            font-size: 1.2em;
            margin:0px;
            margin-bottom: 8%;
            font-weight: 700;
        }
    
        p{
            margin: 0px;
            font-size: 0.9em;

    
        }
    
        
    }

`

const InputStyled = styled.input `
    background: #FAFAFA;
    border: none;
    outline: none;
    text-indent: 5px;
    padding: 10px 10px;
    margin-top: 10px;
    width: 100%;

    @media screen and (max-width: 768px) {
        font-size: 0.9em;

        
    }


`

const ButtonValid = styled.button`
    margin-top: 5%;
    display: flex;
    background: #F87778;
    justify-content: center;
    align-items:center;
    outline: none;
    border: 1px solid #ddd;
    border-radius: 25px;
    padding: 12px 35px;
    margin-bottom: 5%;
    width: 100%;
    
    span {
      font-size: 14px;
      font-weight: 600;
      color: white;
    }

    &:disabled,
    &[disabled]{
    border: none;
    background-color: #cccccc;
    color: #666666;
    }


`
const ReturnButton = styled.span`
    color: #F87778;
    font-weight: 600;
    &:hover{
        cursor: pointer;
        font-weight: 700;

    }

`



 

  
export default ForgotPassword
