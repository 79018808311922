import { GLOBALTYPES } from './globalTypes'
import {  getDataAPI, patchDataAPI,  } from '../../utils/fetchData'
export const USER_TYPES = {
    GET_USERS: 'GET_USERS',

    GET_USER: 'GET_USER',

    LOADING: 'LOADING',
    UPDATE_USER : 'UPDATE_USER'

}




export const getParamsFilter =  (filterData) => {
    let param = ""

    if(!filterData) return "";

    param = Object.entries(filterData)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
   



    return param
    
}

export const getUser = ({auth, id}) => async (dispatch) => {

    try {
        dispatch({type: USER_TYPES.LOADING, payload: true})
        const res = await getDataAPI(`/user/${id}`, auth.token)

        dispatch({
            type: USER_TYPES.GET_USER,
            payload: res
        }) 
 
        dispatch({type: USER_TYPES.LOADING, payload: false})


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
    }
    
}



export const getUsers = ({filter,auth}) => async (dispatch) => {
    console.log()
    let param = getParamsFilter(filter.filter)
    console.log(param)

  
            let url = `/getUsersFilter?`+ param
            console.log(url)
            console.log("hee")
            dispatch({type: USER_TYPES.LOADING, payload: true})


            const res = await getDataAPI(url, auth.token)
            console.log(res)
            dispatch({
                type: USER_TYPES.GET_USERS,
                payload: res.data
            }) 

            dispatch({type: USER_TYPES.LOADING, payload: false}) 
    

    
}



export const updateUser = ({content, auth}) => async (dispatch) => {
    try {
 
        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })
        

        const res = await patchDataAPI(`/user`, content, auth.token)


        
       dispatch({ 
            type: USER_TYPES.UPDATE_USER, 
            payload: {...res.data, user: auth.user} 
        })

    

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: false} }) 


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
        throw err; // This will propagate the error to the calling function

    }
}




