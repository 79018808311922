import { Button, Form, Input, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import SearchAddress from '../../../utils/SearchAddress'

const CreatePartner1 = ({ formData, setFormData, step, setStep}) => {

    const [localisationText, setLocalisationText] = useState("");
    const [localisationObject, setLocalisationObject] = useState(null);

    useEffect(() => {
        if (formData && formData.postalAddress) {
            setLocalisationText(formData.postalAddress.formatted_address)
            setLocalisationObject(formData.postalAddress)
        }

    }, [])
    const handleChangeInput = e => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })

    }

    const handleAddressChange = (newAddress) => {

        setLocalisationText(newAddress.formatted_address)
        const { formatted_address, address_components, geometry } = newAddress;
        const newObject = { formatted_address, address_components, geometry };
        setLocalisationObject(newObject)
        setFormData({ ...formData, ["postalAddress"]: newObject })



    }

    const onFinish = () => {
        console.log(formData);
        setStep(step + 1)
    };
    return (
        <>

            <Form
                name="basic"
                layout='vertical'
                onFinish={onFinish}
                autoComplete="off"
                initialValues={formData?{...formData}: null}

            >
            

                <Form.Item
                    label={"Dénomination sociale de l’entreprise"}
                    name="registeredName"
                    onChange={(e) => handleChangeInput(e)}
                    hasFeedback

                    rules={[
                        {
                            required: true,
                            message: 'Entrez votre dénomination sociale!',

                        },
                        {
                            max: 120,
                            message: 'La dénomination sociale ne peut pas dépasser 120 caractères!',
                        }
                    ]}
                >
                    <Input name="registeredName" size="large" maxLength={120} />
                </Form.Item>

                <Form.Item
                    label="N° SIRET"
                    name="siret"
                    rules={[
                        {
                            required: true,
                            message: 'Entrez votre SIRET!',
                        },
                        {
                            validator: (_, value) =>
                                value && /^\d{14}$/.test(value)
                                    ? Promise.resolve()
                                    : Promise.reject(new Error('SIRET doit contenir exactement 14 chiffres!')),
                        },
                    ]}
                    hasFeedback
                    onChange={(e) => handleChangeInput(e)}
                >
                    <Input name="siret" size='large' />
                </Form.Item>

                <Form.Item
                    label="Adresse postale"
                    name="postalAddress"

                >
                    <SearchAddress onAddressChange={handleAddressChange} address={localisationText} setAddress={setLocalisationText} />


                </Form.Item>

             {/*    <Form.Item
                    label="Code Partenariat"
                    name="codePartnership"
       
                    onChange={(e) => handleChangeInput(e)}
                >
                    <Input name="codePartnership" size='large' />
                </Form.Item> */}


                <Form.Item
                    style={{ display: 'flex', justifyContent: 'end' }}
                >
                 
                   <Button size="large" type="primary"  htmlType="submit" >
                        Suivant
                    </Button>
                     
                </Form.Item>

            </Form>

        </>
    )
}

export default CreatePartner1