import React from 'react'
import { useState } from 'react'

import styled from 'styled-components'
import Target from './Target'

import GlobalPromo from './Global/GlobalPromo'
import TargetedPromo from './Targeted/TargetedPromo'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import PartnersPromo from './Partners/PartnersPromo'
const PromoCreation = () => {

    const [formData, setFormData] = useState({});

    
    
    const {modal} = useSelector(state=>state)

    useEffect(() => {
/*         setFormData({ ...formData, target: 'global' });
 */
        if(modal.deal){
           setFormData(modal.deal);
        }
    }, [])





      
    return (
    <Content>
        {
        !formData.target && <Target formData={formData} setFormData={setFormData}/>
        }
        
        {
        formData.target === 'global' && <GlobalPromo formData={formData} setFormData={setFormData}/>
        }

        {
        formData.target === 'partner' && <PartnersPromo formData={formData} setFormData={setFormData}/>
        }

    </Content>
  )
}

const Content = styled.div`
    display:flex;
    width: 100%;
    flex: 1;

    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    h2{
        font-size:1.6em;
        font-weight: 900;
        color: #585858;
        text-align:center;
    }

`

export default PromoCreation