import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components';
import { useHistory } from 'react-router-dom'
import { Button, Tag } from 'antd'
import { convertIdToLabel } from '../../../utils/function';
import { useDispatch, useSelector } from 'react-redux';
import { answerInvitation } from '../../../redux/actions/partnershipActions';

const InvitationCard = ({ invitation, page, heightCard }) => {
   
    const history = useHistory();
    const {auth} = useSelector(state=>state)
    const dispatch = useDispatch();

    const answerInvitationSubmit = (answer) =>{
        let content = {
            invitationId: invitation._id,
            answer: answer
        }
        dispatch(answerInvitation({content, auth }))
    }

    return (
        <Card height={heightCard}>
            <Top >

                {
                    page.imgBackground ? <img src={page.imgBackground} />
                        : <img src="https://behandy-front.s3.eu-west-3.amazonaws.com/color-gd67b3d48d_640.png" alt="avatar" />
                }

            </Top>

            <Bottom>

                <div>
                <div className='header'>
                    <h2 className='title' onClick={() => history.push(`/company/${page._id}`)}>{page.name}</h2>
                    <img src={page.avatar} className="img-company" alt={'logo entreprise'} onClick={() => history.push(`/company/${page._id}`)} />           
                </div>
                <div>
                <p>{invitation.message?invitation.message:"Aucun message"}</p>
                </div>  

                </div>

                <div className='bottom'>
                    <Button onClick={()=>answerInvitationSubmit('refused')} >Refuser</Button>
                    <Button onClick={()=>answerInvitationSubmit('accepted')}  type='primary' >Accepter</Button>

                </div>




{/*                 <Button onClick={() => handleShowDeal()} type="default" size="large" style={{ margin: "0px auto" }}>J'en profite!</Button>
 */}


            </Bottom>


        </Card>
    )
}


const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;


const Card = styled.div`
    display:flex;
    flex-direction: column;
    height: 100%;
    background : transparent;

    img{
        width: 100%;
        height: 100%;

        object-fit: cover;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

    }
    @media screen and (max-width: 768px) {
        min-height: 400px;
    }
`

const Top = styled.div`
    position: relative;
    height: 40%;
`

const Bottom = styled.div`
    overflow-wrap: break-word;
    position: relative;
    background : #F9F9F9;
    border: 1px solid #f2f2f2;
    height: 60%;
    flex: 1;
    padding: 2% 8%;
    display:flex;
    justify-content: space-between;
    flex-direction: column;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    .header{
        display:flex;
        justify-content: space-between;
        align-items: center;
    }

    .bottom{
        display:flex;
        gap:15px;
        justify-content: center;
    }
    .title{
        margin:0px;
        padding: 0px;
        font-size: 1.2em;
        font-weight: bold;
        color:#585858;
        &:hover{
            cursor: pointer;
        }
    }

   

    p{
        margin:0px;
        margin-top: 5%;
        padding: 0px;
        font-size: 0.9em;
        font-weight: 400;
        color: #757474;
    }




    .img-company{
       
        width: 3.5em;
        height: 3.5em;
        border-radius: 50%;

        &:hover{
            cursor: pointer;
        }
    }

        margin-bottom: '50px';


    @media screen and (max-width: 768px) {
        .img-company{
           display:none;
        }



        h4{
            font-size: 0.8em;
        }
    } 

`



export default InvitationCard