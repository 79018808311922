import React from 'react'
import styled from 'styled-components'
import Logo from '../../../../assets/logo-violet.png'
import { useState } from 'react'
import TypeCompany from './TypeCompany'
import FormCompany1 from './FormCompany1'
import FormCompany2 from './FormCompany2'
import FormCompany3 from './FormCompany3'
import TypePage from './TypePage'
const FormCreatePageCompanyV2 = () => {
    const [step , setStep] = useState(0);

    const [company, setCompany] = useState({
        typeCompany: '',
    })


    const handleCompanyChange = (updatedCompany) => {
        setCompany(updatedCompany);
      };

      const handleStepChange = (step) => {
        setStep(step);
      };
    

    return (
        <Container>
            <img src={Logo} alt='logo'/>

            <ContentForm>
                {
                    step === 0 && 
                    <TypePage  company={company} onChange={handleCompanyChange} step={step} onStepChange={handleStepChange}/>
                }

                {
                    (step === 1) && 
                    <TypeCompany  company={company} onChange={handleCompanyChange} step={step} onStepChange={handleStepChange}/>
                }

                {
                    step === 2 &&
                    <FormCompany1 company={company} onChange={handleCompanyChange} step={step} onStepChange={handleStepChange}/>
                }

                {
                    step === 3 &&
                    <FormCompany2 company={company} onChange={handleCompanyChange} step={step} onStepChange={handleStepChange}/>
                }

                
                {
                    step === 4 &&
                    <FormCompany3 company={company} onChange={handleCompanyChange} step={step} onStepChange={handleStepChange}/>
                }


            </ContentForm>



        </Container>
    )
}

export default FormCreatePageCompanyV2

export const Container = styled.div`
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content: space-between;

    height: 100%;
    width: 100%;
    padding: 20px 10%;
    justify-content: space-between;

    
  

    img{
        width: 200px;
    }


    @media screen and (max-width: 768px) {
        overflow-y:scroll;


    } 

`

const ContentForm = styled.div`
    display:flex;
    flex-direction:column; 
    width: 100%;
    flex: 1;


    @media screen and (max-width: 768px) {
        padding: 3% 0%;
       

    } 
`


export const ButtonForm = styled.button`

    display: flex;
    background: var(--color-accent);
    justify-content: center;
    align-items:center;
    outline: none;
    border: 1px solid #ddd;
    border-radius: 25px;
    padding: 2% 10%;

    span {
        font-size: 1em;
        font-weight: 600;
        color: white;
    }

    &:hover{
        background: var(--color-accent-hover)
    }


    @media screen and (max-width: 768px) {
        padding: 3% 20%;
        font-size: 1.2em;

    } 
`