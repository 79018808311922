import { Button } from 'antd'
import React, { useEffect } from 'react'
import { IoMdAdd } from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { GLOBALTYPES } from '../../../../redux/actions/globalTypes'
import { getSubscriptions } from '../../../../redux/actions/subscriptionAction'
import { NoResults } from '../../AdminsManagementComponents'
import SubscriptionItem from './SubscriptionItem'
import { Player } from '@lottiefiles/react-lottie-player'
import EmptyAnimation from '../../../../assets/empty.json'
import LoadIcon from '../../../../images/loading.gif'
import ModalRedeemCode from './ModalRedeemCode'
import { isInArray } from '../../../../utils/function'
import { getPortalLink, getStripeSubscriptions } from '../../../../redux/actions/stripeAction'
import { FaPlay } from 'react-icons/fa'
import ModalReactivateSubscription from './ModalReactivateSubscription';
import { ShowMore } from '../../../../pages/home';
import AccountsTable from './AccountsTable';
const MySubscriptions = () => {
  const { auth, pages, modal, subscriptions, stripe } = useSelector(state => state)
  const dispatch = useDispatch();

  useEffect(() => {

    let newfilter = { subscriptionCompany: pages.activePage._id, status: 'active' };
    dispatch(getSubscriptions({ filter: newfilter, auth }))
    return () => {

    }
  }, [])

  const  handleAdd = async () => {

     if(stripe.subscriptions.data.length > 0 &&  stripe.subscriptions.data[0].cancel_at_period_end){
      const content = {customerId:pages.activePage.customerId, url: `${window.location.href}`}
      console.log(content) 
     let resLink = await dispatch(getPortalLink({content  ,auth}));
     window.location.href = resLink
    }else{
      dispatch({ type: GLOBALTYPES.MODAL, payload: { typeModal: "createSubModal", open: true } })

    } 

    dispatch({ type: GLOBALTYPES.MODAL, payload: { typeModal: "createSubModal", open: true } })


    
  }




  return (
    <Container>
{/*       {isInArray(pages.activePage.admins, auth.user._id, 'admin') &&
        <div style={{ display: "flex", gap: '5px', alignSelf: 'flex-end', marginBottom: "20px" }}>
          <Button type="default" icon={<IoMdAdd />} size={'large'} onClick={() => handleAdd()
          }></Button>
        </div>
      } */}
      {(modal.typeModal === "redeemCodeModal" && modal.open) && <ModalRedeemCode />}
      {(modal.typeModal === "reactivateSubModal" && modal.open) && <ModalReactivateSubscription />}


{/*       <Header>
        <div></div>
        <div><h2>UTILISATEUR</h2></div>
        <div><h2>POSTE</h2></div>
        <div><h2>ETABLISSEMENT</h2></div>
        <div><h2>STATUT</h2></div>
      </Header> */}

{/*       <SubscriptionsDiv>

        {
          subscriptions.loading
            ? <div className="icon-loading"><img src={LoadIcon} alt="loading" /></div>
            : (subscriptions.subscriptions.length === 0)
              ? <NoResults>
                <Player
                  autoplay
                  loop
                  src={EmptyAnimation}
                  style={{ height: '100%', width: '100%' }}
                >
                </Player>
              </NoResults>
              : <>
                {
                  subscriptions.subscriptions.map((subscription, index) => (
                    <SubscriptionItem subscription={subscription} key={index} />
                  ))
                }

              </>
        }
        {
          (subscriptions.page * subscriptions.n) < subscriptions.count &&
          <ShowMore>Voir plus</ShowMore>

        }
      </SubscriptionsDiv> */}

      
<AccountsContent>
                    <div className='accounts-top'>
                        <h3>Gestion des comptes</h3>
                        <span>Ici vous pouvez gérer les comptes de vos employés</span>
                    </div>
                  {pages.activePage.subscription &&  <div className='accounts-details'>
                        <span>Code: <b>{pages.activePage.subscriptionCodes[0].code}</b></span>
                        <span>Nombre de comptes actifs: {subscriptions.countActive}</span>
                        <span>Nombre de comptes maximum: {pages.activePage.subscription.max_account} </span>

                    </div>}

                    <AccountsTable subscriptions={subscriptions.subscriptions}/>
           {/*          <InvoiceTable invoices={invoices} />

                    {
                        invoicesHasMore && <Button style={{alignSelf:'center'}} onClick={()=>showMoreInvoice()}>Voir plus</Button>
                    } */}

                 
                </AccountsContent>

    </Container>
  )
}

const Container = styled.div`
    display:flex;
    flex-direction: column;
    
    .add{
        display:flex;
        justify-content: center;
        align-items:center;
    
        margin-bottom: 20px;
        .icon{
            font-size:10px;
        }
    }

`
const Header = styled.div`

display: grid;
grid-template-columns: 10% 25%  25%  30%  10%; 
padding: 0% 3%;
margin: 10px;
  div{
    display:flex;
    justify-content: start;
    align-items: center;
  }

  h2{
    color: #585858;

    margin: 0px;
    font-size: 0.7em;
    font-weight: 500;
  }

  @media only screen and (max-width: 768px) {
    display:none;
  }

`

const SubscriptionsDiv = styled.div`
    display:flex;
    flex-direction:column;
    gap: 15px;

    .icon-loading{
        margin: 50px auto;
    }
`

const AccountsContent = styled.div`
    width: 100%;
    background: white;
    display: flex;
    gap:20px;
    flex-direction:column;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 15px;
    padding: 20px 20px;

    
    .accounts-top{
        display:flex;
        flex-direction: column;

        h3{
            color:#757474;
            font-weight:900;
            margin: 0px;
            padding: 0px;
            font-size: 1.1em;
        }

        span{

                color:#757474;
                font-weight:500;
                margin: 0px;
                padding: 0px;
                font-size: 0.9em;
                font-style: italic;
        }
    }

    .accounts-details{
      display:flex;
      flex-direction:column;

      span{

        color:#757474;
        font-weight:500;
        margin: 0px;
        padding: 0px;
        font-size: 0.9em;
        font-style: italic;
}
    }


    @media (max-width: 768px) {

        .hide-on-mobile {
            display: none;
          }
    }
`


export default MySubscriptions