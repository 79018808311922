import React, { useEffect, useState } from 'react'
import LayoutAdmin from '../components/admin/LayoutAdmin'
import { Button, Modal, theme } from 'antd';
import { Content } from 'antd/es/layout/layout';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import CreatePageModal from '../components/settings/accounts/CreatePageModal';
import AdsTab from '../components/admin/ads/AdsTab';
import FormCreateAds from '../components/admin/ads/form/FormCreateAds';
import { getAds } from '../redux/actions/adsActions';
import FormCreateCode from '../components/admin/codesTmp/form/FormCreateCode';
import { getCodesTmp } from '../redux/actions/codeTmpActions';
import CodesTab from '../components/admin/codesTmp/CodesTab';

const AdminCodeTmp = () => {
    const dispatch = useDispatch();
    const {modal, auth} = useSelector(state=>state)
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [codes, setCodes] = useState(null)



    
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    useEffect(async() => {
    let filter = {}
        console.log("hello")
      let res = await dispatch(getCodesTmp({filter,auth}))
      console.log(res)
      setCodes(res.data)

    }, [])
    


    return (
        <LayoutAdmin title={"Codes Temporaires"}>
            <Content
                style={{
                    margin: '24px 16px 0',
                }}
            >
                <div
                    style={{
                        padding: 24,
                        minHeight: 360,
                        background: colorBgContainer,
                        borderRadius: borderRadiusLG,
                    }}
                >
                    <Container>
                        <Button  style={{alignSelf:"end"}} type="primary" onClick={() =>{ showModal()  /*  dispatch({ type: GLOBALTYPES.MODAL,payload: {typeModal:"createPageModal",type:"partner", open:true}}) */}}>
                            Créer un code
                        </Button>
                        { codes?.subscriptionsTmp.length > 0 && <CodesTab codes={codes} />}
                    </Container>
                </div>
            </Content>


   
   
            <Modal title="Créer  code" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null} >
                <FormCreateCode/>
            </Modal>

        </LayoutAdmin>
    )
}

const Container = styled.div`
    display:flex;
    flex-direction: column;
    gap: 15px;
`
export default AdminCodeTmp