import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import useWindowDimensions from '../utils/useWindowsDimensions';
import { GLOBALTYPES } from '../redux/actions/globalTypes';
import { useDispatch, useSelector } from 'react-redux';
import { checkImage } from '../utils/imageUpload';
import { updateProfileUser } from '../redux/actions/profileAction';
import { useHistory } from 'react-router-dom';
import FormResetPassword from '../components/settings/myaccount/FormResetPassword';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import CurriculumVitae from '../components/profile/cv/CurriculumVitae';
import { sectorsDatas } from '../utils/datas';
import { Button, Form, Input, Select } from 'antd';
import { extractLocalityAndCountry } from '../utils/function';
import { Tag, TagsChoices } from '../components/login/Working/SkillsForm';
import SearchAddress from '../components/utils/SearchAddress';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';


const MyAccount = () => {

  const [userData, setUserData] = useState(null)

  const [avatar, setAvatar] = useState('')

  const [status, setStatus] = useState(0)
  const [choice, setChoice] = useState(1)
  const [localisationText, setLocalisationText] = useState("")
  const [localisationObject, setLocalisationObject] = useState(null)

  const [selectedTags, setSelectedTags] = useState([])
  const { auth , datas} = useSelector(state => state)
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation();




  useEffect(() => {
    if (localisationObject) {
      setLocalisationText(`${localisationObject.formatted_address}   `)
    }
  }, [localisationObject])

   useEffect(() => {
      if(auth.user){
        console.log("Inside useEffect: ", auth.user);  // Debug line
        setUserData(auth.user);
        console.log(userData)
        setSelectedTags(auth.user.profil || []);
        setLocalisationObject(auth.user.address || null);
      }

    
  }, []); 



  useEffect(() => {


    const queryParams = new URLSearchParams(location.search);
    const activate = queryParams.get('activateAccount');
    if (activate) {
        if(!auth.user.subscription){
          dispatch({ type: GLOBALTYPES.MODAL, payload: { typeModal: "activateSubModal", open: true }})
        }

    }
  }, [location]);
  

/*   useEffect(() => {
    if(auth.user){
      setUserData(auth.user);
      setSelectedTags(auth.user.profil);
      setLocalisationObject(auth.user.address);
    }
  }, [auth.user]);    */
  
  useEffect(() => {
    if (userData && 'target' in userData && userData.target === 'working') {
      setUserData({ ...userData, situation: 'experience' });
    }


  }, [userData?.target]);

  useEffect(() => {
    
    if(userData && 'profil' in userData ){
      setUserData({ ...userData, profil: [] });
    }

  }, [userData?.situation])
  
  
  





  const { width } = useWindowDimensions();


  const changeAvatar = (e) => {
    const file = e.target.files[0]

    const err = checkImage(file)
    if (err) return dispatch({
      type: GLOBALTYPES.ALERT, payload: { error: err }
    })

    setAvatar(file)
  }

  const handleChangeInput = e => {
    const { id, value } = e.target
    setUserData({ ...userData, [id]: value })
}

const handleChangeTarget= e => {

  setUserData({ ...userData, ['target']: e }) 

}
  const handleAddressChange = (newAddress) => {

    setLocalisationText(newAddress.formatted_address)
    const { formatted_address, address_components, geometry } = newAddress;
    const newObject = { formatted_address, address_components, geometry };
    setLocalisationObject(newObject)

}


const handleTagClick = (tag) => {
  const newTags = selectedTags.filter(t => t !== tag);
  if (newTags.length === selectedTags.length) {
      newTags.push(tag);
  }
  setSelectedTags(newTags);
};


const handleCheckboxChange = (e) => {
  const { id, checked } = e.target;
 
  setUserData({ ...userData, [id]: checked })

};


  const handleSubmit = e => {
    userData.address = localisationObject;
    userData.sector= JSON.stringify(selectedTags)
    userData.profil = selectedTags

    console.log(userData)
    dispatch(updateProfileUser({ userData, avatar, auth }))
  }

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setSelectedTags(value)
  };
  
  const works = datas.workTypes.map(day => ({ value: day.id, label: day.name }));

  return (
    <Container>

      {
        width < 768 &&
        <ContentMobile>
          <AiOutlineArrowLeft className='icon-left' onClick={() => (status === 0) ? history.push('/settings') : setStatus(0)} />
          {status === 0 && <h1>Mon compte</h1>}

          {
                    (status === 0 && userData )&& <Form
                      onFinish={() => { handleSubmit() }}
                      layout="vertical"
                      style={{ width: "100%" }}
                      initialValues={{ // Setting initial values here
                        fullname:auth.user && auth.user.fullname,
                        email: auth.user && auth.user.email,
                        mobile: auth.user && auth.user.mobile,
                        website: auth.user && auth.user.website,
                        experience: auth.user && auth.user.experience,
                        titleJob: auth.user && auth.user.titleJob,
                        story: auth.user && auth.user.story,
                        target: auth.user && auth.user.target,
                        situation: auth.user && auth.user.situation,
                        companyName: auth.user && auth.user.companyName,

                      }}

                    >

                      <Form.Item
                        label="Nom/Prénom"
                        name="fullname"
                        onChange={(e) => handleChangeInput(e)}
                        rules={[
                          {
                            required: true,
                            message: 'Entrez l\'intitulé!',
                          },
                          {
                            max: 140,
                            message: 'L\'intitulé ne peut pas dépasser 140 caractères!',
                          }
                        ]}
                      >
                        <Input size="large" maxLength={140} disabled />
                      </Form.Item>


                      <Form.Item
                        label="Email"
                        name="email"
                        onChange={(e) => handleChangeInput(e)}
                        rules={[
                          {
                            type: "email",
                            required: true,
                          },
                          {
                            max: 140,
                            message: 'L\'email ne peut pas dépasser 140 caractères!',
                          }
                        ]}
                      >
                        <Input size="large" maxLength={140} />
                      </Form.Item>


                      <Form.Item
                        label="Téléphone"
                        name="mobile"
                        rules={[
                          {
                            required: true,
                            message: "Entrez le numéro de téléphone",
                          },
                          {
                            pattern: /^0\d{9}$/,
                            message: 'Le numéro de téléphone doit comporter 10 chiffres et commencer par 0',
                          }
                        ]}
                        hasFeedback
                        onChange={(e) => handleChangeInput(e)}
                      >
                        <Input name="mobile" size="large" />
                      </Form.Item>

     

                      <Form.Item
                        label="Entreprise"
                        name="companyName"
                        onChange={(e) => handleChangeInput(e)}
                        rules={[
                    
                          {
                            max: 140,
                            message: 'L\'intitulé ne peut pas dépasser 140 caractères!',
                          }
                        ]}
                      >
                        <Input size="large" maxLength={140} />
                      </Form.Item>



{/* 
                      <Form.Item
                        label="Localisation"
                        name="localisation"
                        required={true}
                        rules={[
                          {
                            validator: (_, value) =>
                              localisationText ? Promise.resolve() : Promise.reject(new Error('Veuillez entrer une localisation!'))
                          }
                        ]}

                      >
                        <SearchAddress onAddressChange={handleAddressChange} address={localisationText} setAddress={setLocalisationText} />

                      </Form.Item> */}


                      <Form.Item
                        label="Poste"
                        name="titleJob"
                        onChange={(e) => handleChangeInput(e)}
                        rules={[
                    
                          {
                            max: 140,
                            message: 'L\'intitulé ne peut pas dépasser 140 caractères!',
                          }
                        ]}
                      >
                        <Input size="large" maxLength={140} />
                      </Form.Item>

        





{/*                       <Form.Item name="checkbox-group" valuePropName="checked">
                    <Checkbox
                        id="opentowork"
                        onChange={handleCheckboxChange}
                        checked={userData && userData.opentowork}

                    >
                        A l'écoute d'opportunités
                    </Checkbox>
                    <Checkbox
                        id="isPro"
                        name="isPro"

                        onChange={handleCheckboxChange}
                        checked={userData && userData.isPro}

                    >
                        Je suis un professionnel
                    </Checkbox>
                </Form.Item>
 */}




                      <Form.Item className="item-next" style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button size="large" shape="round" type="primary" htmlType="submit">
                          Modifier
                        </Button>
                      </Form.Item>
                    </Form>
                  }

          {
            status === 1 && <FormResetPassword />
          }
        </ContentMobile>
      }


      {
        (width >= 768) &&
        <ParentContent>
          <PanelTop>
            <h2>Mon compte</h2>
            <p>
              Bonjour, vous vous trouvez dans votre espace personnel qui permet de gérer vos informations personnelles

            </p>
          </PanelTop>

          <h3>Paramètres</h3>

          <SubMenu>
            <SubItemMenu onClick={() => setChoice(1)} active={choice === 1 ? true : false}>Mes infos</SubItemMenu>
{/*             <SubItemMenu onClick={() => setChoice(2)} active={choice === 2 ? true : false}>Mon CV</SubItemMenu>
 */}          </SubMenu>
          <Content>
            <LeftPart>
              <UserPart>
                <InfoAvatar>
                  <img src={avatar ? URL.createObjectURL(avatar) : auth.user.avatar}
                    alt="avatar" />
                  <span>
                    <i className="fas fa-camera" />
                    <p>Change</p>
                    <input type="file" name="file" id="file_up"
                       accept=".jpg, .jpeg, .png"  onChange={changeAvatar} />
                  </span>
                </InfoAvatar>
                <h2>{auth.user.fullname}</h2>
                <h3>{auth.user.titleJob}</h3>

                <Description>
                  <h5>Bio:</h5>
                  <p>{auth.user.story ? auth.user.story : "Aucune description"}</p>
         {/*          <h5>Localisation:</h5>
                  <p>{extractLocalityAndCountry(auth.user.address)}</p> */}

                  {
                    auth.user.sponsorCode &&
                    <>
                        <h5>Code parrainage:</h5>
                        <p>{(auth.user.sponsorCode)}</p>
                    </>
                  }

                  {
                    auth.user.subscription?.company &&
                    <>
                        <h5>Entreprise:</h5>
                        <p>{auth.user.subscription.company.name}</p>
                    </>
                  } 
                  {
                    auth.user.subscription?.status === 'active' &&
                    <>
                        <h5>Statut abonnement:</h5>
                        <p>Membre</p>
                    </>
                  }
                          {
                    auth.user.subscription?.enddate &&
                    <>
                        <h5>Fin abonnement:</h5>
                        <p>{auth.user.subscription.enddate}</p>
                    </>
                  }
                  {
                    auth.user.subscription?.status === 'pause' &&
                    <>
                        <h5>Statut abonnement:</h5>
                        <p>Désactivé</p>
                    </>
                  }

                </Description>
              </UserPart>
              {
                choice === 1 && <>
                  {status !== 0 && <Button onClick={() => setStatus(0)}>Modifier profil</Button>}

                  {status !== 1 && <Button onClick={() => setStatus(1)}>Changer Mot de passe</Button>}
                  {auth.user.subscription && <Button onClick={(e) => { e.stopPropagation(); dispatch({ type: GLOBALTYPES.MODAL, payload: { type: "deleteSub",subscription: auth.user.subscription, typeModal: "delete", title: "Etes-vous sûr de vouloir annuler votre abonnement?", auth: auth, open: true } }) }}>Annuler abonnement</Button>}
                  {!auth.user.subscription && <Button onClick={()=> dispatch({ type: GLOBALTYPES.MODAL, payload: { typeModal: "activateSubModal", open: true }})}>Activer abonnement</Button>}

                  {<Button onClick={(e) => { e.stopPropagation(); dispatch({ type: GLOBALTYPES.MODAL, payload: { type: "user", typeModal: "delete", title: "Etes-vous sûr de vouloir supprimer votre compte?", auth: auth, open: true } }) }}>Supprimer votre compte</Button>}

                </>
              }

            </LeftPart>

            <RightPart>
              {
                choice === 1 && <>

                  {
                    (status === 0 && userData )&& <Form
                      onFinish={() => { handleSubmit() }}
                      layout="vertical"
                      style={{ width: "100%" }}
                      initialValues={{ // Setting initial values here
                        fullname:auth.user && auth.user.fullname,
                        email: auth.user && auth.user.email,
                        mobile: auth.user && auth.user.mobile,
                        website: auth.user && auth.user.website,
                        titleJob: auth.user && auth.user.titleJob,
                        situation: auth.user && auth.user.situation,
                        companyName: auth.user && auth.user.companyName,


                      }}

                    >

                      <Form.Item
                        label="Nom/Prénom"
                        name="fullname"
                        onChange={(e) => handleChangeInput(e)}
                        rules={[
                          {
                            required: true,
                            message: 'Entrez l\'intitulé!',
                          },
                          {
                            max: 140,
                            message: 'L\'intitulé ne peut pas dépasser 140 caractères!',
                          }
                        ]}
                      >
                        <Input size="large" maxLength={140} disabled />
                      </Form.Item>

          {/*             <Form.Item
                        label="Localisation"
                        name="localisation"
                        required={true}
                        rules={[
                          {
                            validator: (_, value) =>
                              localisationText ? Promise.resolve() : Promise.reject(new Error('Veuillez entrer une localisation!'))
                          }
                        ]}

                      >
                        <SearchAddress onAddressChange={handleAddressChange} address={localisationText} setAddress={setLocalisationText} />

                      </Form.Item> */}



                      <Form.Item
                        label="Email"
                        name="email"
                        onChange={(e) => handleChangeInput(e)}
                        rules={[
                          {
                            type: "email",
                            required: true,
                          },
                          {
                            max: 140,
                            message: 'L\'email ne peut pas dépasser 140 caractères!',
                          }
                        ]}
                      >
                        <Input size="large" maxLength={140} />
                      </Form.Item>


                      <Form.Item
                        label="Téléphone"
                        name="mobile"
                        rules={[
                          {
                            required: true,
                            message: "Entrez le numéro de téléphone",
                          },
                          {
                            pattern: /^0\d{9}$/,
                            message: 'Le numéro de téléphone doit comporter 10 chiffres et commencer par 0',
                          }
                        ]}
                        hasFeedback
                        onChange={(e) => handleChangeInput(e)}
                      >
                        <Input name="mobile" size="large" />
                      </Form.Item>

  


                <Form.Item
                        label="Entreprise"
                        name="companyName"
                        onChange={(e) => handleChangeInput(e)}
                        rules={[
                    
                          {
                            max: 140,
                            message: 'L\'intitulé ne peut pas dépasser 140 caractères!',
                          }
                        ]}
                      >
                        <Input size="large" maxLength={140} />
                      </Form.Item>




                      <Form.Item
                        label="Poste"
                        name="titleJob"
                        onChange={(e) => handleChangeInput(e)}
                        rules={[
                    
                          {
                            max: 140,
                            message: 'L\'intitulé ne peut pas dépasser 140 caractères!',
                          }
                        ]}
                      >
                        <Input size="large" maxLength={140} />
                      </Form.Item>

        




{/*                       <Form.Item name="checkbox-group" valuePropName="checked">
                    <Checkbox
                        id="opentowork"
                        onChange={handleCheckboxChange}
                        checked={userData && userData.opentowork}

                    >
                        A l'écoute d'opportunités
                    </Checkbox>
                    <Checkbox
                        id="isPro"
                        name="isPro"

                        onChange={handleCheckboxChange}
                        checked={userData && userData.isPro}

                    >
                        Je suis un professionnel
                    </Checkbox>
                </Form.Item>
 */}




                      <Form.Item className="item-next" style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button size="large" shape="round" type="primary" htmlType="submit">
                          Modifier
                        </Button>
                      </Form.Item>
                    </Form>
                  }

                  {
                    status === 1 && <FormResetPassword />
                  }


                </>
              }

              {
                choice === 2 && <CurriculumVitae id={auth.user._id} />
              }

            </RightPart>
          </Content>
        </ParentContent>
      }

    </Container>
  )
}

const Container = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-left: 5%;
    padding: 2% 5%;
    .icon-left{
      position: absolute;
      top: 0px;
      left: 0px;
      color: var(--color-accent);
      font-size: 1.5em;
    }

    margin-bottom: 5%;
`;
const ParentContent = styled.div`
    display:flex;
    flex-direction:column;
    position: relative;
    width:100%;
    height: 100%;

    h3 {
      color: #585858;
      font-weight: 800;
      font-size: 1.3em;
      margin: 2% 0%;
    }
`

const PanelTop = styled.div`
  display: flex;
  background: var(--color-accent);
  flex-direction: column;
  padding: 1% 0%;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color: white;

  h2{
    font-weight: 900;
  }
`

const SubMenu = styled.div`
  display:flex;
  padding: 1% 0%;
`

const SubItemMenu = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: ${({ active }) => active ? 'var(--color-accent)' : '#757474'};;
    height: 100%;
    font-size: 1em;
    padding: 0% 2%;
    text-align:center;
    border-bottom: ${({ active }) => active ? '4px solid  var(--color-accent)' : '4px solid  transparent'};
    &:hover{
        cursor: pointer;
    }

`

const Content = styled.div`
    display:flex;
    position: relative;
    width:100%;
    height: 100%;


`

const LeftPart = styled.div`
    display:flex;
    flex-direction: column;
    gap: 10px;
    width: 25%;
    padding: 2% 0%;
    
`

const UserPart = styled.div`
display:flex;
flex-direction: column;
align-items: center;
background :#FAFAFA;

border-radius: 25px;
min-height: 70vh;
max-height: 80vh;
h2{
  margin: 0px;
  margin-top: 5%;
  font-size: 1.5em;
  font-weight: 700;
  color: #757474;
  text-align: center;
}

h3{
  margin: 0px;
  margin-top: 2%;
  font-size: 1.2em;
  font-weight: 400;
  color: #757474;
  text-align: center;

}


`

const Description = styled.div`
    display:flex;
    flex-direction: column;
    width: 100%;
    padding: 0% 5%;
    h5{
      margin: 0px;
      margin-top: 5%;
      font-size: 1em;
      font-weight: 600;
      color: var(--color-accent);
    }

    p{
      margin: 0px;
      margin-top: 0%;
      font-size: 1em;
      font-weight: 400;
      color: #757474;
    }

`

const RightPart = styled.div`
  display:flex;
  flex-direction: column;
  width: 70%;
  padding: 0% 5%;
`


const ContentMobile = styled.div`

  display:flex;
  flex-direction column;
  align-items: center;
  width: 100%;
  gap: 20px;
  h1{
    margin: 0px;
    color: var(--color-accent);
    font-size: 1.1em;
    font-weight: 500;
  }

`

export const InfoAvatar = styled.div`

    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    margin: 15px auto;
    border: 1px solid #ddd;
    cursor: pointer;

    img{
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
    }

    span{
        position: absolute;
        bottom: -100%;
        left: 0;
        width: 100%;
        height: 50%;
        text-align: center;
        color: orange;
        transition: 0.3s ease-in-out;
        background: #fff5;
    }

    &:hover span{
        bottom: -15%;
    }

    #file_up{
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
    }

    @media screen and (max-width: 768px) {
        width: 80px;
        height: 80px;
    } 
    

`


export const H2Title = styled.span`
    font-size: 1em;
    margin: 0px;
    color: var(--color-accent);
    font-weight: 600;
    margin-top: 2%;

    
    @media screen and (max-width: 768px) {
      font-size: 0.9em;

    } 


`
export const InputStyled = styled.input`
    background: #FAFAFA;
    border: none;
    width: 100%;
    outline: none;
    text-indent: 5px;
    padding: 10px 10px;
`

export const InputSelected = styled.select`
    background: #FAFAFA;
    border: none;
    width: 100%;
    outline: none;
    text-indent: 5px;
    padding: 10px 10px;
`
const TextAreaStyled = styled.textarea`
    background: #FAFAFA;
    border: none;
    width: 100%;
    outline: none;
    text-indent: 5px;
    padding: 10px 10px;

    text-justify: auto;
`

const ButtonNext = styled.button`

    display: flex;
    background: var(--color-accent);
    justify-content: center;
    align-items:center;
    outline: none;
    border: 1px solid #ddd;
    border-radius: 25px;
    padding: 12px 35px;

    span {
        padding-left: 20px;
        font-size: 14px;
        font-weight: 600;
        color: white;
    }


`


export default MyAccount