import { Button, Form, Input, Select } from 'antd'
import React from 'react'
import SearchAddress from '../../utils/SearchAddress'
import { useState } from 'react'
import { useEffect } from 'react'
import TextArea from 'antd/es/input/TextArea'
import { updatePage } from '../../../redux/actions/pageAction'
import { useDispatch, useSelector } from 'react-redux'

const FormManagementCompanyPart3 = ({avatar, formData, setFormData}) => {

    const {auth, pages} = useSelector(state=>state);
    const [tags, setTags] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        setTags(formData.specialities)
    }, [])
    

    useEffect(() => {
        const updatedCompany = { ...formData, ['specialities']: tags };
        setFormData(updatedCompany);
      }, [tags])
    

    const handleChangeInput = e => {
        const { name, value } = e.target
        const updatedCompany = { ...formData, [name]: value };
        setFormData(updatedCompany);

    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          const { value } = e.target;
          if (value) {
            setTags([...tags, value]);
      
            e.target.value = '';
          }
          e.preventDefault();
        }
      };

  const handleTagChange = (value) => {
    setTags(value);
  };

  const handleSubmit = (e) => {
    const id = formData._id

    dispatch(updatePage({ id, pageData: formData, avatar, auth, pages }))

}



  return (

<Form
    onFinish={(e)=>handleSubmit(e)}

                           layout="vertical"
                           style={{width: "100%"}}
                           initialValues={{specialities: formData.specialities, description: formData.description}}
                         >
                                <Form.Item
                                    label="Spécialités"
                                    name="specialities"
                                    >
                                    <Select
                                    mode="tags"
                                    placeholder="Enter tags"
                                    value={tags}
                                    size='large'
                                    onChange={handleTagChange}
                                    onKeyDown={handleKeyDown}
                                    />
                                </Form.Item>



                                                   
                                <Form.Item
                                    label="Description"
                                    name="description"
                                    rules={[
                                    {
                                        required: true,
                                        message: "Entrez une description!",
                                        
                                    },
                                    {
                                      max: 1000,
                                      message: 'La description ne peut pas dépasser 1000 caractères!',
                                    }
                                    ]}
                                    hasFeedback
                                    onChange={(e)=>handleChangeInput(e)}

                                >
                                    <TextArea name="description" size='large' maxLength={1000} showCount />
                                </Form.Item>

                                <Form.Item className="item-next"  style={{display:'flex', justifyContent:'center' , marginTop:'35px'}}>
                 <Button size="large" shape="round" type="primary" htmlType="submit">
                     Sauvegarder
                 </Button>
             </Form.Item>
                                </Form>
  )
}

export default FormManagementCompanyPart3