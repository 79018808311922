import React, { useState } from 'react'
import { Button, Modal, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { deleteAds } from '../../../redux/actions/adsActions';
import { deleteCode } from '../../../redux/actions/codeTmpActions';


const CodesTab = ({codes}) => {

  const {ads, auth} = useSelector(state=>state);
  const history = useHistory();
  const dispatch = useDispatch();
  const [openDelete, setOpenDelete] = useState(false);
  const [openPage, setOpenPage] = useState(false);
  const [currentCode, setCurrentCode] = useState(null)
  const columns = [
    {
      title: 'Nom',
      width: 50,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Code',
      width: 50,
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Début',
      dataIndex: 'startdate',
      key: 'startdate',
      width: 50,
    },
    {
      title: 'Fin',
      dataIndex: 'enddate',
      key: 'enddate',
      width: 50,
    },

    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: 30,
      render: (text, record) => <div style={{display:"flex", gap: "5px"}}>

         <Button  onClick={(e)=>{setCurrentCode(record); showModalDelete()}}  type="primary" danger size="large" icon={<MdDelete />}  />

      </div>,
    },
  ];
  const showModalDelete = () => {
    if(currentCode){
      setOpenDelete(true);
    }
  };
  const handleDeleteOk = () => {

    setOpenDelete(false);
    dispatch(deleteCode({code:currentCode, auth}))

  };

  const handleDeleteCancel = () => {
    setOpenDelete(false);
  };



  return (

    <>
    <Table
    columns={columns}
    dataSource={codes.subscriptionsTmp}
    scroll={{
      x: 1500,
     
    }}
    onRow={(record, rowIndex) => {
      return {
        onClick: (event) => {setCurrentCode(record);}, // click row
    
      };
    }}
  />

<Modal
        open={openDelete}
        title="Supprimer code"
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >

      Êtes-vous sûr de vouloir supprimer ce code?
    </Modal>


  </>
  )
}

export default CodesTab