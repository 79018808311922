import React from 'react'
import LayoutAdmin from '../components/admin/LayoutAdmin'
import { theme } from 'antd';
import { Content } from 'antd/es/layout/layout';



const Admin = () => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    return (
       <LayoutAdmin title={"Dashboard"}>
                    <Content
            style={{
                margin: '24px 16px 0',
            }}
        >
            <div
                style={{
                    padding: 24,
                    minHeight: 360,
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                }}
            >
                content
            </div>
        </Content>
       </LayoutAdmin>
    )
}



export default Admin

