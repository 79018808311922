import { GLOBALTYPES } from './globalTypes'
import { postDataAPI, getDataAPI, patchDataAPI, deleteDataAPI } from '../../utils/fetchData'
import validFormFormation from '../../utils/formValidation/validFormFormation'

export const FORMATIONS_TYPES = {
    CREATE_FORMATION: 'CREATE_FORMATION',
    LOADING_FORMATION: 'LOADING_FORMATION',
    GET_FORMATIONS: 'GET_FORMATIONS',
    GET_FORMATIONS_USER: 'GET_FORMATIONS_USER',
    UPDATE_FORMATION: 'UPDATE_FORMATION',
    DELETE_FORMATION: 'DELETE_FORMATION'

}


export const createFormation= ({data, auth}) => async (dispatch) => {


    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })

        const res = await postDataAPI('formations', data, auth.token)

        dispatch({ 
            type: FORMATIONS_TYPES.CREATE_FORMATION, 
            payload: {...res.data.newFormation, user: auth.user} 
        })

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: false} })
        dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})

        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {success: "Une nouvelle formation a été ajouté!"}
        })

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const getFormations = (token) => async (dispatch) => {
    try {
        dispatch({ type: FORMATIONS_TYPES.LOADING_FORMATION, payload: true })
        const res = await getDataAPI('formations', token)
        
        dispatch({
            type: FORMATIONS_TYPES.GET_FORMATIONS,
            payload: {...res.data, page: 2}
        })

        dispatch({ type: FORMATIONS_TYPES.LOADING_FORMATION, payload: false })
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const getFormationsUser = (token, id) => async (dispatch) => {
    try {
        dispatch({ type: FORMATIONS_TYPES.LOADING_FORMATION, payload: true })
        const res = await getDataAPI(`user_formations/${id}`, token)
        dispatch({
            type: FORMATIONS_TYPES.GET_FORMATIONS_USER,
            payload: {...res.data, page: 2}
        })

        dispatch({ type: FORMATIONS_TYPES.LOADING_FORMATION, payload: false })
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const updateFormation = ({id, data, auth}) => async (dispatch) => {

    
    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })

        const res = await patchDataAPI(`formation/${id}`, data, auth.token)

        dispatch({ type: FORMATIONS_TYPES.UPDATE_FORMATION, payload: res.data.newFormation })
        dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})
        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} })
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const deleteFormation = ({id, formation, auth}) => async (dispatch) => {
    dispatch({ type: FORMATIONS_TYPES.DELETE_FORMATION, payload: formation })

    try {
        const res = await deleteDataAPI(`formation/${id}`, auth.token)
        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} })
        console.log(res)
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}




