import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { getSubscriptions } from '../../../../redux/actions/subscriptionAction';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import { getCheckoutLink, getInvoices, getNextInvoice, getPortalLink } from '../../../../redux/actions/stripeAction';
import InvoiceTable from './InvoicesTable';
import { Button } from 'antd';

const MyFormula = () => {

    const {pages, auth,stripe, subscriptions, alert} = useSelector(state=>state);
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const [invoice, setInvoice] = useState(null)

    const [invoices, setInvoices] = useState([])
    const [invoicesHasMore, setInvoiceHasMore] = useState(false)
    const [invoicesLast, setInvoicesLast] = useState(null)


    const  [dateUpcomingInvoice, setDateUpcomingInvoice] = useState(null)
    const [link, setLink]=useState('')

    useEffect(async () => {

        console.log(window.location.href)

    /*     let newfilter = { subscriptor: pages.activePage._id, status:'active' };
        await dispatch(getSubscriptions({ filter: newfilter, auth })) */

        if(pages.activePage.customerId){

    
        let getinvoice = await dispatch(getNextInvoice({ id: pages.activePage.customerId , auth }))
        setInvoice(getinvoice)
        console.log(getinvoice)

  
        const date = new Date(getinvoice.next_payment_attempt * 1000); // Multiply by 1000 to convert from seconds to milliseconds

        const options = {
            day: 'numeric', // Two-digit day (e.g., 28)
            month: 'short', // Short month name (e.g., "janv." for January)
            year: 'numeric', // Four-digit year (e.g., 2024)
            timeZone: 'UTC', // Set the time zone to UTC to match the Unix timestamp
          };
          
          // Format the date
          const formattedDate = date.toLocaleDateString('fr-FR', options);
          setDateUpcomingInvoice(formattedDate)
          console.log(formattedDate);

        const content = {customerId:pages.activePage.customerId, url: `${window.location.href}`}
         let resLink = await dispatch(getPortalLink({content  ,auth}));


         setLink(resLink)


        let getinvoices = await dispatch(getInvoices({ id: pages.activePage.customerId, last: null , auth }))
        console.log(getinvoices.invoices)
        setInvoices(getinvoices.invoices.data);
        setInvoiceHasMore(getinvoices.invoices.has_more);
        setInvoicesLast(getinvoices.invoices.data[getinvoices.invoices.data.length - 1]);

    }
        
        return () => {
            
        }
    }, [])

    const addToUrlAndNavigate = () => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.delete('choiceAccount')

        queryParams.set('choiceSub', 2); // Set 'mode' to the new value
        let newPath = location.pathname + '?' + queryParams.toString();  
        history.push(newPath);
      };

     const redirect =() => {
        console.log(link)
        if(link){
            window.location.href = link

        }
     }

     const showMoreInvoice = async () => {
        let res = await dispatch(getInvoices({ id: pages.activePage.customerId, last: invoicesLast.id , auth }))
        setInvoices([...invoices,res.invoices.data]);
        setInvoiceHasMore(res.invoices.has_more);
        setInvoicesLast(res.invoices.data[res.invoices.data.length - 1]);
      };

      const generateCheckoutLink = async(product) => {
        const content = { company:pages.activePage._id,email:pages.activePage.email, name:pages.activePage.name, customerId: pages.activePage.customerId, priceId: product.priceId, qty: 1 }
        let resLink = await dispatch(getCheckoutLink({ content, auth }));
        if(resLink.data?.session?.url){
            window.location.href = resLink.data.session.url;

        }
      }
    
    return (
        <Container>
{
   !invoice  && !alert.loading &&         <div className='plans'>

                {
                 stripe.products.length > 0 && 
                 stripe.products.map((product) => (
                    <Plan>
                    <h2>{product.name}</h2>
                    <div className='price-month'>
                    <p className="price">5€</p>
                    <p className="month">/mois</p>
                    </div>
                    <Button type="primary" onClick={()=>generateCheckoutLink(product)} >
                        S'abonner
                    </Button>
                    </Plan>
                 ))
                }
            

            </div>}

             <div className='top'>


         
            {
                !subscriptions.loading && invoice && 
                <DatasItemLeft color='#6076E7'>
                    <div className='top'>
                        <div className='infos'>
                            <h3>Comptes actifs(facturés)</h3>
                            <span>Nombre de comptes salariés actifs</span>
                        </div>

                        <div className='infos'>
                            <h3>{invoice?.amount_due?invoice?.amount_due/100:0}€/mois</h3>
                            <span>{subscriptions.count} comptes actifs</span>
                        </div>


                    </div>

  

                    <p onClick={()=> addToUrlAndNavigate()}>Voir mes abonnements</p>


                </DatasItemLeft>
            }
 
           {
                invoice && 
                <DatasItem color='#6076E7'>
                <div className='infos'>
                     <h3>{invoice.lines.data[0].planName}</h3>
                   <span>Prochaine facture d'un montant de {invoice.amount_due/100}€ le {dateUpcomingInvoice}</span>
                    <p onClick={()=> redirect()}>Gérer l'abonnement</p>
                </div>

                </DatasItem>
            } 

            </div>

            {
            invoices.length > 0 && 

                <InvoicesContent>
                    <div className='invoices-top'>
                        <h3>Historique de Facturation</h3>
                        <span>Téléchargez vos précèdentes factures</span>
                    </div>

                    <InvoiceTable invoices={invoices} />

                    {
                        invoicesHasMore && <Button style={{alignSelf:'center'}} onClick={()=>showMoreInvoice()}>Voir plus</Button>
                    }

                 
                </InvoicesContent>
            }  


        </Container>
    )
}

const Container = styled.div`

display:flex;
flex-direction: column;
gap: 15px;

.plans{

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    width: 100%;
    
    @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);

     
    }

}

.top{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    width: 100%;
    
    @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);

     
    }
    
    
}

h1{
    font-size: 1.1em;
    margin-top: 2%;
}



`

const PlansContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 800px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Plan = styled.div`
  flex: 1;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: start;
  border-radius: 15px;
  padding: 20px;
  text-align: center;

  h2{
    font-size:1.2em;
  }

  .price-month{
    margin: 10px 0;
    display:flex;
    align-items: baseline;
  }
  .price {
    font-size: 2em;
   
  }

  .month{
    font-size: 1em;
  }

  Button {
    margin-top: auto;
  }
`
const DatasItem = styled.div`
    flex: 1;  /* This makes sure each item takes equal width */
    background: white;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 15px;
    padding: 20px 20px;
    min-width: 200px;
    max-height: 150px;

    .count{
        width: 100px;
        height: 100px;
        display:flex;
        align-items:center;
        justify-content:center;
        background: ${({ color }) => color ? color : '#757474'};;
        font-size: 2.5em;
        border-radius: 15px;
        font-weight: 900;
        color:white;
        aspect-ratio:1/1;


        @media screen and (max-width: 768px) {
            width: 30%;
            font-size: 2em;
        }
        
    }

    .infos{
        display:flex;
        flex:1;
        flex-direction:column;
        justify-content:center;
        padding: 0px 5px;

        @media screen and (max-width: 768px) {
            padding: 0px 10px;

        }
        h3{
            color:#757474;
            font-weight:900;
            margin: 0px;
            padding: 0px;
            font-size: 1.1em;
        }

        span{

                color:#757474;
                font-weight:500;
                margin: 0px;
                padding: 0px;
                font-size: 0.9em;
                font-style: italic;
        }

        p{
            text-align: right;
            font-size: 0.8em;
            margin: 0px;
            margin-top: 5%;
            color: var(--color-company);
            text-decoration: underline;

            &:hover{
                cursor:pointer;
            }
        }
    }

      @media screen and (max-width: 768px) {
        max-width: 100%;
        padding: 10px 10px;
        border-radius: 10px;

    }
    
`;

const DatasItemLeft = styled.div`
    flex: 1;  /* This makes sure each item takes equal width */
    background: white;
    display: flex;
    flex-direction :column;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 15px;
    padding: 20px 20px;
    min-width: 200px;
    max-height: 150px;

    p{
        text-align: right;
        font-size: 0.8em;
        margin: 0px;
        margin-top: 5%;
        color: var(--color-company);
        text-decoration: underline;

        &:hover{
            cursor:pointer;
        }
    }



    .top{
        display:flex;
        justify-content: space-between;
    }
    .infos{
        display:flex;
        flex-direction:column;
        justify-content:center;
        padding: 0px 5px;

        @media screen and (max-width: 768px) {
            padding: 0px 10px;

        }
        h3{
            color:#757474;
            font-weight:900;
            margin: 0px;
            padding: 0px;
            font-size: 1.1em;
        }

        span{

                color:#757474;
                font-weight:500;
                margin: 0px;
                padding: 0px;
                font-size: 0.9em;
                font-style: italic;
        }


    }

      @media screen and (max-width: 768px) {
        max-width: 100%;
        padding: 10px 10px;
        border-radius: 10px;

    }
    
`;

const InvoicesContent = styled.div`
    width: 100%;
    background: white;
    display: flex;
    gap:20px;
    flex-direction:column;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 15px;
    padding: 20px 20px;

    
    .invoices-top{
        display:flex;
        flex-direction: column;

        h3{
            color:#757474;
            font-weight:900;
            margin: 0px;
            padding: 0px;
            font-size: 1.1em;
        }

        span{

                color:#757474;
                font-weight:500;
                margin: 0px;
                padding: 0px;
                font-size: 0.9em;
                font-style: italic;
        }
    }


    .invoices-grid{
        display:flex;
        flex-direction:column;
    }

    .invoices-grid-header{
        display: grid;
        grid-template-columns: 35% 25%  25%  30%  10%; 

        
        h4{
            color:#757474;
            font-weight:900;
            margin: 0px;
            padding: 0px;
            font-size: 0.9em;
        }
    }

    @media (max-width: 768px) {

        .hide-on-mobile {
            display: none;
          }
    }
`
export default MyFormula