import React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import ChoiceActivity from './ChoiceActivity'
import WorkingForm from './Working/WorkingForm'
const AccountCreation = ({formData,setFormData}) => {
    
    const [step, setStep] = useState(2)
    return (
    <Content>
        
        {step === 1 && <ChoiceActivity step={step} setStep={setStep} formData={formData} setFormData={setFormData}/>}
        {(step === 2 ) && <WorkingForm formData={formData} setFormData={setFormData}/>}

    </Content>
  )
}

const Content = styled.div`
    display:flex;
    width: 100%;
    flex: 1;

    flex-direction: column;
    justify-content: space-around;
    align-items: center;


`

export default AccountCreation