import React from 'react'
import { BsHeartFill, BsHeart } from "react-icons/bs";
import styled from 'styled-components';
const LikeButton = ({isLike, handleLike, handleUnLike}) => {

    return (
        <>
            {
                isLike
                ?<Like onClick={handleUnLike}><BsHeartFill  className=' icon icon-like'/></Like> 
                
                :<Like  onClick={handleLike}><BsHeart className='icon'  /></Like> 
            }
        </>
    )
}

const Like = styled.div`
    background : white;
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 50%;

    .icon {
        font-size: 1.5em;
        transform: translateY(1px);
    }
    .icon-like{
        color: #F87778;
    }

    .icon-unlike{
        color: #F87778;
    }

    @media screen and (max-width: 768px) {
        font-size: 1.3em;

    }

`



export default LikeButton
