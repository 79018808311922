import React from 'react'
import styled from "styled-components"


const FormAlertMessage = ({message}) => {
    return (
        <Message>
            {message}
        </Message>
    )
}

const Message = styled.div`
    color: red;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 2%;
    padding: 0px;

    @media screen and (max-width: 768px) {
        margin-bottom: 5%;
    } 
`

export default FormAlertMessage
