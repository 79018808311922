import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Modal from '../../utils/Modal/Modal';
import EventCard from '../../event/EventCard';
const EventCardModal = () => {
 
  const {modal} = useSelector(state => state)

  const dispatch = useDispatch();

  return (
    <Modal  modalName={"eventCardModal"} widthModal={"30vw"} heightModal={"90vh"} >


            <Content>

              <EventCard event={modal.event}/>


            </Content>



    </Modal>

  )
}



const Content = styled.div`

    position: relative;
    width: 100%;
    height: 100%;
`




export default EventCardModal