
import { ADS_TYPES } from '../actions/adsActions';
import { DeleteData, EditData } from '../actions/globalTypes';

const initialState = {
    loading: false,
    ads: [],
    count: 0,
    page: 0,
    n: 0,
}



const adsReducer = (state = initialState, action) => {
    switch (action.type){
        case ADS_TYPES.GET_ADS:
            return {
                ...state,  
                ads:  action.payload.data.ads,
                count: action.payload.data.count,
                page:action.payload.data.page,
                n:action.payload.data.n

            }




        case ADS_TYPES.LOADING:
                return {
                    ...state,
                    loading: action.payload
                };

        case ADS_TYPES.DELETE_AD:
                    return {
                        ...state,
                        ads: DeleteData(state.ads, action.payload._id)
                    };

        default:
            return state;
    }
}


export default adsReducer
