import React, {useState} from 'react'
import styled from 'styled-components'
import { BsThreeDotsVertical } from 'react-icons/bs';
import { MdCreate, MdDelete } from 'react-icons/md';
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Moment from 'moment';
import { deleteExperience } from '../../../../redux/actions/experienceAction';
import { useDispatch } from 'react-redux'
import { GLOBALTYPES } from '../../../../redux/actions/globalTypes';
import shop from "../../../../assets/accounts/small_pink.png"
import { useHistory } from "react-router-dom"

const ExperienceCard = ({experience}) => {

    const [menuEdit, setMenuEdit] = useState(false);
    const {id} = useParams();
    const {portfolio, auth} = useSelector(state => state)
    const dispatch = useDispatch()
    const history = useHistory()

    const handleDelete = ()  => {
        if(window.confirm("Êtes-vous sûr de vouloir supprimer cette offre?")){
            dispatch(deleteExperience({id:experience._id,experience , auth}))        }
    }

    const handleEdit = () => {
        dispatch({ type: GLOBALTYPES.MODAL, payload: {experience: experience, typeModal:"addExperience", open:true, onEdit: true, experience: experience}}) 
    }

    const redirection = (url) =>{ 
        history.push(url)
    }



    return (
        <Container >

         
            <ImageCard  isPreview={portfolio.portfolio  ? true : false}  src={experience.companyid?experience.companyavatar: shop } onClick={() => {if(experience.companyid) redirection(`/company/${experience.companyid._id}`) } }/>
            <Informations>
                <Header>
                    <HeaderLeft>
                         <Title>
                             {experience.jobname} 
                        </Title>
                        {experience.currentjob && <CurrentJob>(Emploi actuel)</CurrentJob>}
                    </HeaderLeft>
                    {
                        experience.end ? <Date>{Moment(experience.start).format('MM/YYYY')} - {Moment(experience.end).format('MM/YYYY')}</Date>
                        :  <Date>{Moment(experience.start).format('MM/YYYY')}</Date>

                    }
                </Header>
                <SubHeader>
                    {experience.companyid?<StyledLink onClick={() => {if(experience.companyid) redirection(`/company/${experience.companyid._id? experience.companyid._id: experience.companyid}`) } }>{experience.companyname}</StyledLink>:experience.companyname}
                </SubHeader>
                <Description>
                    {experience.description}
                </Description>

            </Informations>

            {
                
                (( experience.user._id || experience.user) === auth.user._id && !portfolio.portfolio)&& 
                <EditIcon>
                <BsThreeDotsVertical className="icon"  onClick={() => {setMenuEdit(!menuEdit); console.log(menuEdit)}} />
                { menuEdit && <>
                    
                    <MenuEdit>
                    <MdCreate onClick={()=> handleEdit()}className="icon"/>
                    <MdDelete onClick={()=> handleDelete()} className="icon"/>
                </MenuEdit>
                 </>
                }


            </EditIcon>
            }
            
        </Container>
    )
}

export const Container = styled.div`
    display:flex;

    img {
        width: 80px;
        height: 80px;
        border: solid 1px black;
        border-radius: 50%;
    }
    padding: 1% 5%;
    margin-bottom: 10px;
    @media (max-width: 768px) {
        padding: 5px;

    }
    
    


`
const StyledLink = styled.div`
    &:hover{
        cursor: pointer;
        font-weight: 600;
    }

    text-decoration: underline;

`
export const ImageCard = styled.div`
    background-image:url(${(props) => props.src});
    background-size: cover;
    width: ${({ isPreview }) => isPreview ? '40px' : '60px'};
    height: ${({ isPreview }) => isPreview ? '40px' : '60px'};

    border-radius: 50%;
    margin-right: 2%;

    &:hover{
        cursor:pointer;
    }

    @media (max-width: 768px) {
        width: 40px;
        height: 40px;
        margin-right: 15px;
    }
`

export const Informations = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: start;
    flex: 1;


`

export const EditIcon = styled.div`
    display:flex;
    flex-direction: column;
    margin-left: 2%;
    font-size: 30px;
    .icon {
        padding: 5px;

    }
    .icon:hover{
        background: #e9f5f5;
        border-radius: 100%;
    }

`

export const MenuEdit = styled.div `
    display:flex;
    flex-direction: column;

`

export const Header = styled.div`
    display:flex;
    justify-content: space-between;
    align-items:baseline;
    color: black;

    @media (max-width: 768px) {
      
        flex-direction: column;
    }
`
export const HeaderLeft = styled.div`
    display:flex;

    p{
        padding: 0px;
    }
`
export const Title = styled.div `
    font-size: 15px;
    font-weight: bold;

    
    @media (max-width: 768px) {
        font-size: 0.9em;
    }
`
const CurrentJob = styled.div `
    margin-left: 5px;
    font-size: 15px;
    font-weight: 400;
    @media (max-width: 768px) {
        font-size: 0.9em;
    }
`
export const Date = styled.div `
    font-size: 13px;
    @media (max-width: 768px) {
        font-size: 0.7em;
    }
`

export const SubHeader = styled.div `
    display:flex;
    margin-top: -4px;
    color:#F87778;
    font-size: 12px;
    font-weight: thin;

`
export const Description = styled.div`
    font-size: 12px;
    text-align: justify;
`


export default ExperienceCard;
