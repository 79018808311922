import React, { useState } from 'react'
import { Button, Modal, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BiEdit } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import FormCreatePartner from './FormCreatePartner';
import { deletePage } from '../../../redux/actions/pageAction';
import { BsEye } from 'react-icons/bs';



const data = [];
for (let i = 0; i < 100; i++) {
  data.push({
    key: i,
    name: `Edward ${i}`,
    typeCompany: 'indé',
    sector: `Boulangerie`,
    email: `florent.ricciardi@gmail.com`,
    phone: `0785581204`,

  });
}
const PartnersTab = () => {

  const {pages, auth} = useSelector(state=>state);
  const history = useHistory();
  const dispatch = useDispatch();
  const [openDelete, setOpenDelete] = useState(false);
  const [openPage, setOpenPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(null)
  const columns = [
    {
      title: 'Nom',
      width: 100,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Type',
      width: 100,
      dataIndex: 'typeCompany',
      key: 'typeCompany',
    },
    {
      title: 'Secteur',
      dataIndex: 'sector',
      key: 'sector',
      width: 150,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 150,
    },
    {
      title: 'Téléphone',
      dataIndex: 'phone',
      key: 'phone',
      width: 150,
    },
  
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: 80,
      render: (text, record) => <div style={{display:"flex", gap: "5px"}}>
         <Button onClick={(e)=>{setCurrentPage(record); showModalPage()}} size="large" icon={<BiEdit />}  />
         <Button  onClick={(e)=>{ history.push(`/adminPartners/${record._id}`)}}  type="primary"  size="large" icon={<BsEye />}  />

         <Button  onClick={(e)=>{setCurrentPage(record); showModalDelete()}}  type="primary" danger size="large" icon={<MdDelete />}  />

      </div>,
    },
  ];
  const showModalDelete = () => {
    if(currentPage){
      setOpenDelete(true);
    }
  };
  const handleDeleteOk = () => {

    setOpenDelete(false);
    //dispatch(deleteDeal({deal:currentDeal, auth}))
    dispatch(deletePage({id: currentPage._id, auth}))

  };

  const handleDeleteCancel = () => {
    setOpenDelete(false);
  };

  const showModalPage = () => {
   if(currentPage){
    setOpenPage(true)
   }
  };


  const handlePageCancel = () => {
    setOpenPage(false);
  };

  return (

    <>
    <Table
    columns={columns}
    dataSource={pages.pages}
    scroll={{
      x: 1500,
     
    }}
    onRow={(record, rowIndex) => {
      return {
        onClick: (event) => {setCurrentPage(record);}, // click row
    
      };
    }}
  />

<Modal
        open={openDelete}
        title="Supprimer page"
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >

      Êtes-vous sûr de vouloir supprimer cette page?
    </Modal>

    <Modal title="Modifier Page" open={openPage} onCancel={handlePageCancel} footer={null} >
                <FormCreatePartner page={currentPage}/>
     </Modal>
  </>
  )
}

export default PartnersTab