
import { DEAL_TYPES } from '../actions/dealAction';
import { DeleteData, EditData } from '../actions/globalTypes';

const initialState = {
    loading: false,
    deals: [],
    count: 0,
    total: 0,
}



const dealReducer = (state = initialState, action) => {
    switch (action.type){
        case DEAL_TYPES.GET_DEALS:
            return {
                ...state,  
                deals:  action.payload.data.deals,
                count: action.payload.data.count,
                page:action.payload.data.page,
                n:action.payload.data.n,
                total: action.payload.data.total

            }
        
        case DEAL_TYPES.GET_MORE_DEALS:

                return {
                    ...state,  
                    deals:  state.deals.concat( action.payload.data.deals),
                    count: action.payload.data.count,
                    page:action.payload.data.page,
                    n:action.payload.data.n,
                    total: action.payload.data.total

    
                }

        case DEAL_TYPES.GET_DEALS_BY_COMPANY:
                return {
                    ...state,  
                    deals:  action.payload.data.deals,
                    count: action.payload.data.count
        }
        
        case DEAL_TYPES.GET_DEAL:
                return {
                    ...state,  
                    current_deal:  action.payload.data.deal,
                }

        case DEAL_TYPES.LOADING:
                return {
                    ...state,
                    loading: action.payload
                };
        case DEAL_TYPES.UPDATE_DEAL:
                return {
                     ...state,
                    deals: EditData(state.deals, action.payload._id, action.payload)
                };
        case DEAL_TYPES.DELETE_DEAL:
                    return {
                        ...state,
                        deals: DeleteData(state.deals, action.payload._id)
                    };
        case DEAL_TYPES.UPDATE_DEAL:
                        return {
                            ...state,
                            deals: EditData(state.deals, action.payload._id, action.payload)
                        };
        default:
            return state;
    }
}


export default dealReducer
