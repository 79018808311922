import React from 'react'
import { useState } from 'react'
import SkillsForm from './SkillsForm'
import ResponseRegistration from '../ResponseRegistration'

const WorkingForm = ({ formData, setFormData }) => {
    const [step, setStep] = useState(1)

    return (
    <>
        
        {step === 1 && <SkillsForm  step={step} setStep={setStep} formData={formData} setFormData={setFormData}/>}
        {step === 3 && <ResponseRegistration formData={formData} statusRes='error'/>}
        {step === 2 && <ResponseRegistration  formData={formData} statusRes='success'/>}

    </>
  )
}



export default WorkingForm