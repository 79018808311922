import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import LayoutAdmin from '../../components/admin/LayoutAdmin';
import styled from 'styled-components';
import { Content } from 'antd/es/layout/layout';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, theme } from 'antd';
import PartnerDealsTab from '../../components/admin/partners/deals/PartnerDealsTab';
import { GLOBALTYPES } from '../../redux/actions/globalTypes';
import FormCreateDeal from '../../components/admin/partners/deals/FormCreateDeal';
import { getDealsByCompany2 } from '../../redux/actions/dealAction';
import CreateDealModal from '../../components/admin/partners/deals/CreateDealModal';

const AdminPartner = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const {modal, auth} = useSelector(state=>state)
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        let newfilter = {typeDeal:"partner", n:150};
        dispatch(getDealsByCompany2({ auth, id: id, filter: newfilter }))
    }, [])
    


    
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <LayoutAdmin title={"Offres Partenaire"}>
            <Content
                style={{
                    margin: '24px 16px 0',
                }}
            >
                <div
                    style={{
                        padding: 24,
                        minHeight: 360,
                        background: colorBgContainer,
                        borderRadius: borderRadiusLG,
                    }}
                >
                    <Container>
                         <Button  style={{alignSelf:"end"}} type="primary" onClick={()=>dispatch({ type: GLOBALTYPES.MODAL,payload: {typeModal:"createDealPartnerModal", open:true}})}>
                            Créer une offre
                        </Button>
                        <PartnerDealsTab/>
                       
                    </Container>
                </div>
            </Content>

            <Modal title="Créer Deal Partenaire" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null} >
                <FormCreateDeal/>
            </Modal>

            {modal.typeModal==="createDealPartnerModal" &&  <CreateDealModal />}


        </LayoutAdmin>
    )
}

const Container = styled.div`
    display:flex;
    flex-direction: column;
    gap: 15px;
`

export default AdminPartner