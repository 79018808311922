import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from "styled-components"
import DealPreviewCardMobile from '../components/home/DealPreviewCardMobile'
import { getDealsByUser } from '../redux/actions/dealAction'
import LoadIcon from '../images/loading.gif'
import DealPreviewCard from '../components/home/DealPreviewCard'
import useWindowDimensions from '../utils/useWindowsDimensions';
import { v4 as uuidv4 } from 'uuid';
import DealCardModal from '../components/home/DealCardModal'
import { GLOBALTYPES } from '../redux/actions/globalTypes'

import { Player } from '@lottiefiles/react-lottie-player'
import EmptyAnimation from '../assets/empty.json'
import { Categories, SubItemMenu, SubMenu } from '../components/company/avantages/AvantagesComponents'
import { Button } from 'antd'
import { useState } from 'react'
import { getVisitors } from '../redux/actions/visitorAction'
import EventPreviewCard from '../components/home/event/EventPreviewCard'
import EventPreviewCardMobile from '../components/home/event/EventPreviewCardMobile'
import { getEventsByUser } from '../redux/actions/eventAction'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom'
import EventCardModal from '../components/home/event/EventCardModal'
import DealPartnerPreviewCardNew from '../components/home/card/DealPartnerPreviewCardNew'
import DealCardModalNew from '../components/home/card/DealCardModalNew'
import DealPartnerPreviewCardMobile from '../components/home/card/DealPartnerPreviewCardMobile'

const FavouritesDeals = () => {
  const {auth , modal, deals, events, visitors} = useSelector(state=>state)
  const dispatch = useDispatch()
  const { width } = useWindowDimensions();
  const [category, setCategory] = useState(1);
  const [choice, setChoice] = useState("save");

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {

      const queryParams = new URLSearchParams(location.search);
      const mode = queryParams.get('mode'); 
      if(mode){
        setCategory(Number(mode))

      } 
      // Add more logic here as needed
    }, [location]);



  const addToUrlAndNavigate = (number) => {
      const queryParams = new URLSearchParams(location.search);
      queryParams.set('mode', number); // Set 'mode' to the new value
      let newPath = location.pathname + '?' + queryParams.toString();
      setCategory(number)

      history.push(newPath);
    };

  useEffect(() => {
    
    if(category === 1 && choice === 'save'){
      dispatch(getDealsByUser({auth}))
    }
    if(category === 1 && choice === 'used'){
        let newfilter = {user:auth.user._id, source:'deal'};
        dispatch(getVisitors({filter:newfilter, auth}))  
    }

    if(category === 2 && choice === 'save'){
     // dispatch(searchEvents({auth}))
     dispatch(getEventsByUser({auth}))

    }
    if(category === 2 && choice === 'used'){
        let newfilter = {user:auth.user._id, source:'event'};
        dispatch(getVisitors({filter:newfilter, auth}))  
    }


    


    return () => {
      dispatch({ type: GLOBALTYPES.MODAL,payload: { open:false}})
    }
  }, [category,choice])


  
  

  
  return (
    <Container>
{/*       <FilterBar type="like"/>
 */}      
    <h1>Mes favoris</h1>

      <SubMenu>
        <SubItemMenu onClick={() => { addToUrlAndNavigate(1); setChoice('save') }} active={category === 1 ? true : false}>Mes promotions</SubItemMenu>
{/*         <SubItemMenu onClick={() => { addToUrlAndNavigate(2); setChoice('save') }} active={category === 2 ? true : false}>Mes évènements</SubItemMenu>
 */}      </SubMenu>

     <Categories>
        <Button shape="round" onClick={() => setChoice('save')} type={choice === 'save' ? 'primary' : 'default'}>Sauvegardées</Button>
        <Button shape="round" onClick={() => setChoice('used')} type={choice === 'used' ? 'primary' : 'default'}>Utilisées</Button>
      </Categories>

      {modal.typeModal=='dealCardModal' && <DealCardModal />}
      {modal.typeModal=='dealCardPartnerModal' && <DealCardModalNew />}

     {modal.typeModal=='eventCardModal' && <EventCardModal />}



      {(category === 1 && choice === 'save') && <>
      {
          deals.loading 
          ? <img src={LoadIcon} alt="loading" className="d-block mx-auto" />
          : (deals.count === 0)
              ?  <div style={{zIndex:0,position: "absolute", top:"0px", left:"0px",width:"100%", height:"100%", display:"flex",flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
              <Player
                autoplay
                loop
                src={EmptyAnimation}
                style={{ height: '100%', width: '100%' }}
              >
              </Player>
              </div>
              : <DealsContainer>
                {
                  width > 768?
                  deals.deals.map((deal) => (
                    <>{deal.typeDeal === "company" ?<DealPreviewCard typePage='deals' key={uuidv4()} deal={deal}/>:<DealPartnerPreviewCardNew typePage='deals' key={uuidv4()} deal={deal}/>}</> 
                  )): 
                  deals.deals.map((deal) => (
                    <>{deal.typeDeal === "company" ?<DealPreviewCardMobile typePage='deals' key={uuidv4()} deal={deal}/>:<DealPartnerPreviewCardMobile typePage='deals' key={uuidv4()} deal={deal}/>}</> 

                  ))
                }
              
              </DealsContainer>
                    }
      </> }

      {(category === 1 && choice === 'used') && <>
      {
          visitors.loading 
          ? <img src={LoadIcon} alt="loading" className="d-block mx-auto" />
          : (visitors.count === 0)
              ?  <div style={{zIndex:0, position: "absolute", top:"0px", left:"0px",width:"100%", height:"100%", display:"flex",flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
              <Player
                autoplay
                loop
                src={EmptyAnimation}
                style={{ height: '100%', width: '100%' }}
              >
              </Player>
              </div>
              : <DealsContainer>
                {
                  width > 768?
                  visitors.visitors.map((visitor) => (
                    <DealPreviewCard typePage='visitor' key={uuidv4()} deal={visitor.deal}/>
                  )): 
                  visitors.visitors.map((visitor) => (
                    <DealPreviewCardMobile typePage='visitor' key={uuidv4()} deal={visitor.deal}/>
                  ))
                }
              
              </DealsContainer>
                    }
      </> }

      {(category === 2 && choice === 'save') && <>
      {
          events.loading 
          ? <img src={LoadIcon} alt="loading" className="d-block mx-auto" />
          : (events.count === 0)
              ?  <div style={{zIndex:0,position: "absolute", top:"0px", left:"0px",width:"100%", height:"100%", display:"flex",flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
              <Player
                autoplay
                loop
                src={EmptyAnimation}
                style={{ height: '100%', width: '100%' }}
              >
              </Player>
              </div>
              : <DealsContainer>
                {
                  width > 768?
                  events.events.map((event) => (
                    <EventPreviewCard typePage={"events"} key={uuidv4()} event={event}/>
                  )): 
                  events.events.map((event) => (
                    <EventPreviewCardMobile typePage={"events"} key={uuidv4()} event={event}/>
                  ))
                }
              
              </DealsContainer>
                    }
      </> }

      {(category === 2 && choice === 'used') && <>
      {
          visitors.loading 
          ? <img src={LoadIcon} alt="loading" className="d-block mx-auto" />
          : (visitors.count === 0)
              ?  <div style={{zIndex:0,position: "absolute", top:"0px", left:"0px",width:"100%", height:"100%", display:"flex",flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
              <Player
                autoplay
                loop
                src={EmptyAnimation}
                style={{ height: '100%', width: '100%' }}
              >
              </Player>
              </div>
              : <DealsContainer>
                {
                  width > 768?
                  visitors.visitors.map((visitor) => (
                    <EventPreviewCard typePage={"visitorEvent"} key={uuidv4()} event={visitor.event}/>
                  )): 
                  visitors.visitors.map((visitor) => (
                    <EventPreviewCardMobile typePage={"visitorEvent"} key={uuidv4()} event={visitor.event}/>
                  ))
                }
              
              </DealsContainer>
                    }
      </> }
    </Container>
  )
}

const Container = styled.div`
  width: 80%;
  display:flex;
  flex-direction: column;
  gap: 20px;

  h1{
    font-size: 1.2em;
    font-weight: 700;
  }
  
  @media only screen and (max-width: 768px) {
    width: 90%;

  }

`

const DealsContainer = styled.div`
margin: 0px auto;
position: relative;
display: grid;
grid-template-columns: repeat(4, minmax(0, 1fr));
grid-auto-rows: 280px;
grid-gap: 20px;
@media only screen and (max-width: 768px) {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 900px) {
  grid-template-columns: repeat(2, 1fr );
  grid-template-rows: repeat(2, auto);
}

@media only screen and (max-width: 1080px) {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: repeat(3, auto);

}

width: 100%;
height: 100%;

`


export default FavouritesDeals