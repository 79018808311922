import React, { useEffect, useState } from 'react'
import { Categories } from '../../avantages/AvantagesComponents'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import MyFormula from './MyFormula';
import MySubscriptions from './MySubscriptions';
import ModalCreateSubscription from './ModalCreateSubscription';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getStripeProducts, getStripeSubscriptions } from '../../../../redux/actions/stripeAction';

const SubscriptionManagement = () => {
    const { pages, auth} = useSelector(state => state)
    const [choice, setChoice] = useState(1)
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {

        const queryParams = new URLSearchParams(location.search);
        const choice = queryParams.get('choiceSub');
        if (choice) {
            setChoice(Number(choice))

        }

        if(pages.activePage.customerId){
            dispatch(getStripeSubscriptions({id: pages.activePage.customerId, auth}))

        }
       dispatch(getStripeProducts({auth}))



    }, [location]);

    const addToUrlAndNavigate = (number) => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.delete('choiceAccount')
        queryParams.set('choiceSub', number); // Set 'mode' to the new value
        let newPath = location.pathname + '?' + queryParams.toString();
        setChoice(number)
  
        history.push(newPath);
      };


    return (
        <>

        
            <Categories>
                <Button shape="round" onClick={() => {addToUrlAndNavigate(1)}} type={choice === 1 ? 'primary' : 'default'}>Vue générale</Button>
                <Button shape="round" onClick={() => addToUrlAndNavigate(2)} type={choice === 2 ? 'primary' : 'default'}>Mes comptes</Button>

            </Categories>



        

            {choice === 1 && <MyFormula company={pages.activePage} />}
            {choice === 2 && <MySubscriptions company={pages.activePage} />}

            

        </>
    )
}

export default SubscriptionManagement