import { GLOBALTYPES } from '../actions/globalTypes'

const initialState = {}

const tempReducer = (state = initialState, action) => {
    switch (action.type){
        case GLOBALTYPES.TEMP:
            return action.payload;
        default:
            return initialState;
    }
}


export default tempReducer