import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import styled from "styled-components"
import { createSkill, updateSkill } from '../../../../redux/actions/skillAction'
import FormAlertMessage from '../../../FormAlertMessage'
import logo from '../../../../assets/logo.png'
import { GLOBALTYPES } from '../../../../redux/actions/globalTypes'
import { ButtonValidate, Container, ContentCV, CrossCV, FooterCV, FormCV, HeaderCV, InputStyledCV } from '../experience/AddExperience'
import { isMobile } from 'react-device-detect'
import { useEffect } from 'react'
import Modal from '../../../utils/Modal/Modal'



const AddSkill = () => {
    
    const { auth,modal, alert } = useSelector(state => state)
    const dispatch = useDispatch()


    const initialState = { 
        name:'', albumname:'' 
    }


    useEffect(() => {
        console.log(initialState)
        dispatch({ type: GLOBALTYPES.ALERT, payload: {}})
        setSkillData(initialState)
        return () => {
    
            dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})
        }
    }, [])



    
    const [skillData, setSkillData] = useState(initialState)
    const { name, albumname} = skillData

        
    useEffect(() => {
        if(modal.skill){
         
            setSkillData(modal.skill)
        }else{
            setSkillData(initialState)
        }
    }, [modal])
    

    const handleChangeInput = e => {
        const { name, value } = e.target
        setSkillData({...skillData, [name]:value})

    }

    const handleSubmit = (e) => {
        console.log(skillData);
        e.preventDefault()
        const data= skillData
        if(modal.onEdit){
            dispatch(updateSkill({id: modal.skill._id, data, auth}))

        }else{
            dispatch(createSkill({data, auth}))

        }
    }
  
   

    return (
        <Modal modalName={"addSkill"} heightModal={"50vh"}>
              
              <Container>
                <h2>{modal.onEdit?"Modifier une compétence":"Ajouter une compétence"}</h2>
                <ContentCV>

    

                <h2 style={{marginTop: isMobile?"5%": "0%"}}>Compétence*</h2>
                <InputStyledCV name="name" id="name" value={name} onChange={handleChangeInput} placeholder="Ex: Chocolaterie, Glaçage..." autoComplete={"off"} required/>
                <FormAlertMessage message={alert.name ? alert.name : ''}/>

                </ContentCV>

                <FooterCV>
                    <ButtonValidate onClick={handleSubmit}>
                    {
                            modal.onEdit?<span>Modifier</span>:<span>Ajouter</span>
                        }                    </ButtonValidate>
                </FooterCV>
                </Container>
            </Modal>
    )
}


export default AddSkill
