import { Button, DatePicker, Form, Input, TimePicker } from 'antd'
import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'
import locale from 'antd/es/date-picker/locale/fr_FR';
import 'dayjs/locale/fr';
import SearchAddress from '../../../../utils/SearchAddress'
import dayjs from 'dayjs';
import { createEvent, updateEvent } from '../../../../../redux/actions/eventAction';
const EventForm = ({ formData, setFormData }) => {

    const { modal, auth } = useSelector(state => state)
    const [localisationText, setLocalisationText] = useState("");
    const [localisationObject, setLocalisationObject] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        setFormData({ ...formData, ['localisation']: localisationObject })

    }, [localisationObject])

    useEffect(() => {

        if (modal.event) {
            setLocalisationText(modal.event.localisation.formatted_address)
            const { formatted_address, address_components, geometry } = modal.event.localisation;
            const newObject = { formatted_address, address_components, geometry };
            setLocalisationObject(newObject)

        }
    }, [])

    const handleAddressChange = (newAddress) => {

        setLocalisationText(newAddress.formatted_address)
        const { formatted_address, address_components, geometry } = newAddress;
        const newObject = { formatted_address, address_components, geometry };
        setLocalisationObject(newObject)

    }

    const handleChangeInput = e => {
        const { id, value } = e.target
        setFormData({ ...formData, [id]: value })
    }

    const handleChangeDate = (date, dateString) => {
        setFormData({ ...formData, ["date"]: dateString })
    }

    const handleChangeTime = (time, timeString) => {
        setFormData({ ...formData, ["time"]: timeString })
    }

    const handleSubmit = (e) => {

        if(modal.event){
            dispatch(updateEvent({id:modal.event._id, content: formData, images:[], auth})) 

        }else{
            dispatch(createEvent({ content: formData, auth})) 

        }

        e.preventDefault() 
    }

    return (
        <Content>

            <Header>
                <h2>{modal.event?'Modifier':'Créer'} un évènement</h2>
            </Header>


            <Form
                onFinish={(e) => { handleSubmit(e) }}
                layout="vertical"
                style={{ width: "100%" }}
                initialValues={{ // Setting initial values here
                    title: modal.event && modal.event.title,
                    date: modal.event && dayjs(modal.event.date),
                    time: modal.event && modal.event.time && dayjs(modal.event.time, "HH:mm"),
                    description: modal.event && modal.event.description,
                }}
            >

                <Form.Item
                    label="Nom de l'évènement"
                    name="title"
                    onChange={(e) => handleChangeInput(e)}
                    rules={[
                        {
                            required: true,
                            message: 'Entrez le nom!',
                        },
                        {
                            max: 140,
                            message: 'Le nom ne peut pas dépasser 140 caractères!',
                        }
                    ]}
                >
                    <Input size="large" maxLength={140} />
                </Form.Item>


                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Form.Item
                        label="Date"
                        name="date"
                        rules={[{ required: true, message: 'Entrez une  date!', }]}
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                    >
                        <DatePicker onChange={(date, dateString) => handleChangeDate(date, dateString)} locale={locale} style={{ width: "100%" }} size='large' />


                    </Form.Item>

                    <Form.Item
                        label="Horaire"
                        name="time"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                    >
                        <TimePicker onChange={(time, timeString) => handleChangeTime(time, timeString)} locale={locale} style={{ width: "100%" }} format="HH:mm" size='large' />
                    </Form.Item>
                </div>

                <Form.Item
                    label="Localisation"
                    name="localisation"
                    required={true}
                    rules={[
                        {
                            validator: (_, value) =>
                                localisationText ? Promise.resolve() : Promise.reject(new Error('Veuillez entrer une localisation!'))
                        }
                    ]}

                >
                    <SearchAddress onAddressChange={handleAddressChange} address={localisationText} setAddress={setLocalisationText} />

                </Form.Item>

                <Form.Item
                    label="Description"
                    name="description"
                    onChange={(e) => handleChangeInput(e)}
                    hasFeedback
                    rules={[
                        {
                            max: 500,
                            message: 'La description ne peut pas dépasser 500 caractères!',
                        }
                    ]}

                >
                    <Input.TextArea size='large' maxLength={500} showCount />
                </Form.Item>
                <Form.Item className="item-next" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button size="large" shape="round" type="primary" htmlType="submit">
                    {modal.event?'Mettre-à-jour': 'Poster'}
                    </Button>
                </Form.Item>
            </Form>
        </Content>
    )
}

const Content = styled.div`
    display:flex;
    width: 100%;
    flex: 1;

    flex-direction: column;
    justify-content: space-around;
    align-items: center;


    .selectAll{
        display:flex;
        justify-content: end;
        width:100%;
    }



`

const Header = styled.div`
    display:flex;
    flex-direction: column;

    h2{
        font-size:1.6em;
        font-weight: 900;
        color: #585858;
        text-align:center;
    }

    h3{
        font-size:1.3em;
        font-weight: 500;
        color: #585858;
        text-align:center;
    }

`





export default EventForm