import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'antd'
import { GLOBALTYPES } from '../../../redux/actions/globalTypes'
import { convertIdToLabel } from '../../../utils/function'

const CompanyPreviewCardMobile = ({page }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {datas} = useSelector(state=>state)

    return (
        <>
            {page && <Card >
                <Left>
                    {
                        page.avatar ? <img src={page.avatar} onClick={() => window.location.href = `/company/${page._id}`} />
                            : <img src="https://behandy-front.s3.eu-west-3.amazonaws.com/color-gd67b3d48d_640.png" />
                    }
                </Left>
                <Center>
                        <h2 className='title' onClick={() => window.location.href = `/company/${page._id}`} >{page.name}</h2>
                        <span className='subtitle'>{convertIdToLabel(datas.companyTypes, page.sector)}</span>

                        
                        

        
                </Center>

                <Right>                         <Button style={{alignSelf:'center'}}onClick={() => dispatch({ type: GLOBALTYPES.MODAL,payload: {typeModal:"companyModal", page:page, open:true}})}>Voir plus</Button>
</Right>

            </Card>}
        </>
    )
}


const Card = styled.div`
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;    display:flex;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    background: white;
    padding: 4% 4%;
    position:relative;

`

const Left = styled.div`

    img{
        width: 45px;
        height: 45px;
        object-fit: cover;
        border-radius: 10px;
        
    }

`

const Center = styled.div`
    display:flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin-left: 5%;
    text-overflow: ellipsis;
    position:relative;
    overflow: hidden; 
    white-space: nowrap;
    z-index: 10;
    height:100%;

    h2{
        font-size: 1em;
        font-weight: 800;
        margin: 0px;
        color:#F87778;
        line-height: 1.0;
    }

    span{
        font-size:0.9em;
        font-style:italic;
        color:#969696;
    }

   



`

const Right = styled.div`
     display:flex;
     align-items: flex-start;
     height: 100%;

`



export default CompanyPreviewCardMobile