import { GLOBALTYPES } from './globalTypes'
import { postDataAPI, getDataAPI, patchDataAPI, deleteDataAPI } from '../../utils/fetchData'
import { getParamsFilter } from './userAction'


export const STRIPE_TYPES = {
    GET_CHECKOUT_LINK: 'GET_CHECKOUT_LINK',
    GET_NEXT_INVOICE: 'GET_NEXT_INVOICE',
    GET_STRIPE_PRODUCTS: 'GET_STRIPE_PRODUCTS',
    GET_STRIPE_SUBSCRIPTIONS: 'GET_STRIPE_SUBSCRIPTIONS',
    LOADING: 'LOADING',
    DELETE_SUBSCRIPTION: 'DELETE_SUBSCRIPTION',
    ACTIVATE_SUBSCRIPTION: 'ACTIVATE_SUBSCRIPTION'
}


export const getStripeProducts= ({ auth}) => async (dispatch) => {
    try {

        dispatch({type: STRIPE_TYPES.LOADING, payload: true})
        let url = `/stripe/products`

        const res = await getDataAPI(url, auth.token)

        dispatch({
            type: STRIPE_TYPES.GET_STRIPE_PRODUCTS,
            payload: res
        }) 

 
        dispatch({type: STRIPE_TYPES.LOADING, payload: false}) 
        return true;

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
        return false;
    }
    
}


export const getStripeSubscriptions= ({id, auth}) => async (dispatch) => {
    try {

        dispatch({type: STRIPE_TYPES.LOADING, payload: true})
        let url = `/stripe/subscriptions/${id}`

        const res = await getDataAPI(url, auth.token)

        dispatch({
            type: STRIPE_TYPES.GET_STRIPE_SUBSCRIPTIONS,
            payload: res
        }) 

 
        dispatch({type: STRIPE_TYPES.LOADING, payload: false}) 
        return true;

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
        return false;
    }
    
}

export const getInvoices = ({id,last, auth}) => async (dispatch) => {
    try {

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })
        

        let url = last?`stripe/invoices/${id}?lastInvoiceId=${last}`:`stripe/invoices/${id}`
        const res = await getDataAPI(url, auth.token)

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: false} })

        return res.data;

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
        return false;
    }
}


export const getNextInvoice = ({id, auth}) => async (dispatch) => {
    try {

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })
        

        const res = await getDataAPI(`stripe/upcominginvoice/${id}`, auth.token)

     /*    dispatch({ 
            type: STRIPE_TYPES.GET_CHECKOUT_LINK, 
            payload: {...res.data} 
        })
 */
        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: false} })

        return res.data.upcomingInvoice;

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
        return false;
    }
}


export const getPreviewInvoice = ({id,content, auth}) => async (dispatch) => {
    try {
        let param = getParamsFilter(content)
        console.log(id)
        console.log(content)


        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })
        

        const res = await getDataAPI(`stripe/previewinvoice/${id}?${param}`, auth.token)

     /*    dispatch({ 
            type: STRIPE_TYPES.GET_CHECKOUT_LINK, 
            payload: {...res.data} 
        })
 */
        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: false} })

        return res.data.invoicePreview;

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
        return false;
    }
}


export const getCheckoutLink = ({content, auth}) => async (dispatch) => {
    try {

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })
        

        const res = await postDataAPI('stripe/checkout-link', content, auth.token)

     /*    dispatch({ 
            type: STRIPE_TYPES.GET_CHECKOUT_LINK, 
            payload: {...res.data} 
        })
 */
        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: false} })

        return res;

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
        return false;
    }
}

export const getPortalLink = ({content, auth}) => async (dispatch) => {
    try {

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })
        

        const res = await postDataAPI('stripe/portal-link', content, auth.token)

     /*    dispatch({ 
            type: STRIPE_TYPES.GET_CHECKOUT_LINK, 
            payload: {...res.data} 
        })
 */
        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: false} })

        return res.data.session.url;

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
        return false;
    }
}



