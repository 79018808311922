import React,{useState} from 'react'
import styled from 'styled-components'
import Moment from 'moment';
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { BsThreeDotsVertical } from 'react-icons/bs';
import { MdCreate, MdDelete } from 'react-icons/md';
import { deleteFormation } from '../../../../redux/actions/formationAction';
import { GLOBALTYPES } from '../../../../redux/actions/globalTypes';
import { useDispatch } from 'react-redux'
import shop from "../../../../assets/accounts/small_pink.png"
import { useHistory } from "react-router-dom"
import { Container, Date, Description, Header, HeaderLeft, ImageCard, Informations, MenuEdit, SubHeader, Title } from '../experience/ExperienceCard';

const ExperienceCard = ({formation}) => {

    const { auth, portfolio} = useSelector(state => state)
    const {id} = useParams();
    const [menuEdit, setMenuEdit] = useState(false);
    const dispatch = useDispatch()
    const history = useHistory()

    const handleDelete = ()  => {
        if(window.confirm("Êtes-vous sûr de vouloir supprimer cette offre?")){
            dispatch(deleteFormation({id:formation._id,formation , auth}))   
        }
    }

    const handleEdit = () => {
        dispatch({ type: GLOBALTYPES.MODAL, payload: {formation:formation, typeModal:"addFormation", open:true, onEdit: true}}) 
    }

    const redirection = (url) =>{ 
        history.push(url)
    }



    return (
        <Container>
                <ImageCard  isPreview={portfolio.portfolio  ? true : false} src={formation.schoolid?formation.schoolid.avatar: shop } onClick={() => {if(formation.schoolid) redirection(`/school/${formation.schoolid._id}`) } }/>            <Informations>
                <Header>
                    <HeaderLeft>
                         <Title>
                            {formation.formationname} 
                        </Title>
                        {formation.currentformation && <CurrentJob>(Formation actuelle)</CurrentJob>}
                    </HeaderLeft>
                    {
                        formation.end ? <Date>{Moment(formation.start).format('MM/YYYY')} - {Moment(formation.end).format('MM/YYYY')}</Date>
                        :  <Date>{Moment(formation.start).format('MM/YYYY')}</Date>

                    }                </Header>
                <SubHeader>
                {formation.schoolid?<StyledLink onClick={() => {if(formation.schoolid) redirection(`/school/${formation.schoolid._id}`) } }>{formation.schoolid.name}</StyledLink>:formation.schoolname}

                </SubHeader>
                <Description>
                    {formation.description}
                </Description>
            </Informations>
            
            {
                ( formation.user._id || formation.user) === auth.user._id && 
                <EditIcon>
                    <BsThreeDotsVertical className="icon"  onClick={() => {setMenuEdit(!menuEdit); console.log(menuEdit)}} />
                    { menuEdit && <>
                        
                        <MenuEdit>
                        <MdCreate  onClick={()=> handleEdit()}className="icon"/>
                        <MdDelete onClick={()=> handleDelete()} className="icon"/>
                    </MenuEdit>
                    </>
                    }


            </EditIcon>
            }
        </Container>
    )
}


const StyledLink = styled.div`
    &:hover{
        cursor: pointer;
        font-weight: 600;
    }

    text-decoration: underline;

`




export const EditIcon = styled.div`
    display:flex;
    flex-direction: column;
    margin-left: 2%;
    font-size: 30px;
    .icon {
        padding: 5px;

    }
    .icon:hover{
        background: #e9f5f5;
        border-radius: 100%;
    }

`




const CurrentJob = styled.div `
    margin-left: 5px;
    font-size: 15px;
    font-weight: 400;

    @media (max-width: 768px) {
        font-size: 0.9em;
    }
`


export default ExperienceCard;
