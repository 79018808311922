export const GLOBALTYPES = {
    AUTH: "AUTH",
    ALERT: "ALERT",
    STATUS: 'STATUS',
    MODAL: 'MODAL',
   
    ONLINE: 'ONLINE',
    OFFLINE: 'OFFLINE',
    ACTIVATION: 'ACTIVATION',
    REPLY: 'REPLY',
    TEMP: 'TEMP',
    THEME: 'THEME'
}

export const EditData = (data, id, post) => {
    const newData = data.map(item => 
        (item._id === id ? post : item)
    )
    return newData;
}




export const DeleteData = (data, id) => {
    const newData = data.filter(item => item._id !== id)
    return newData;
}