import { Button } from 'antd'
import React from 'react'
import { ContentModalAccount } from './ChoiceActivity'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const ResponseRegistration = ({ statusRes, formData }) => {

    const { status,  } = useSelector(state => state)
    const history = useHistory();


    return (
        <ContentModalAccount>
            <div className='titles'>
                {
                    statusRes === 'success' ? <h2>Félicitation! Votre compte a été créé avec succès!</h2>
                        : <h2>Attention! Une erreur est survenue!</h2>
                }

            </div>

            {
                statusRes === 'success' ?
                    <svg width="160" height="160" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                        <mask id="ipSSuccess0">
                            <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4">
                                <path fill="#fff" stroke="#fff" d="m24 4l5.253 3.832l6.503-.012l1.997 6.188l5.268 3.812L41 24l2.021 6.18l-5.268 3.812l-1.997 6.188l-6.503-.012L24 44l-5.253-3.832l-6.503.012l-1.997-6.188l-5.268-3.812L7 24l-2.021-6.18l5.268-3.812l1.997-6.188l6.503.012L24 4Z" />
                                <path stroke="#000" d="m17 24l5 5l10-10" />
                            </g>
                        </mask>
                        <path fill="#84cc16" d="M0 0h48v48H0z" mask="url(#ipSSuccess0)" />
                    </svg>
                    :
                    <svg width="200" height="200" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#ef4444" d="M12 17q.425 0 .713-.288T13 16q0-.425-.288-.713T12 15q-.425 0-.713.288T11 16q0 .425.288.713T12 17Zm-1-4h2V7h-2v6Zm1 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z" />
                    </svg>
            }
            <div className='titles'>
                {
                    statusRes === 'success' ? <h4>Veuillez confirmer votre compte via le lien envoyé par mail!</h4> :
                        <>
                            <h4>{status.error && status.error.msg}</h4>
 {/*                            {alert && Object.entries(alert).map(([key, value]) => (
                                <h4 key={key}>
                                    - {value.toString()}
                                </h4>
                            ))} */}

                        </>
                }

            </div>


{/* 
            {statusRes === 'success' ? <Button onClick={()=>{history.push('/login')}} size="large" shape="round" type="primary">
                Y aller !
            </Button> : <Button size="large" shape="round" type="primary">
                Retour
            </Button>}

 */}


        </ContentModalAccount>
    )
}


export default ResponseRegistration