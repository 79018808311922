import React from 'react'
import { useEffect } from 'react';
import styled from 'styled-components'
import { ButtonForm } from './FormCreatePageCompanyV2';

const TypePage = ({ company, onChange, step, onStepChange }) => {

    const handleTypeChange = (type) => {
        const updatedCompany = { ...company, typePage: type };
        if(type==="partner"){
            updatedCompany.typeCompany= 'inde'
        }
        onChange(updatedCompany);
      };
      useEffect(() => {
        console.log(company)
      }, [])
      

      const redirect= () => {
        if(company.typePage === 'company'){
            onStepChange(step + 1)
        }else{
            onStepChange(step + 2)
        }
      }


  return (
    <Container>
                        <div>
                            <h3><strong>Super! Allons-y</strong></h3>
                            <h3>Dites-moi en plus sur vous:</h3>
                        </div>
                      
                   
                        <DivTypeCompany>
                            <TypeCompanyItem onClick={() => handleTypeChange("company")} active={company.typePage === "company"?true:false}>
                                Je représente un établissement CHR
                            </TypeCompanyItem>
                            <TypeCompanyItem  onClick={() => handleTypeChange("partner")} active={company.typePage  === "partner"?true:false}>
                                Je représente un partenaire(marques...)
                            </TypeCompanyItem>
                        </DivTypeCompany> 

                        {company.typePage  &&<ButtonForm onClick={()=>redirect()}>
                            <span>Suivant</span>
                        </ButtonForm>}
    </Container>
  )
}

const Container = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    align-items:center;
    width: 100%;
    height: 100%;
    h3{
        strong{
            font-weight: 900;
        }
        font-size: 1.5em;
        font-weight: 500 !important;
        text-align: center;
        margin: 0px;

    }


    @media screen and (max-width: 768px) {
        h3{
            strong{
                font-weight: 900;
            }
            font-size: 1.2em;
            font-weight: 500 !important;
            text-align: center;
    
        }
    } 

`
const DivTypeCompany = styled.div`
    display:flex;
    width: 100%;
    justify-content: space-around;
    padding: 5% 0%;

    @media screen and (max-width: 768px) {
        flex-direction:column;
        align-items:center;
        gap: 15px;
    } 

`

const TypeCompanyItem = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    width: 200px;
    height: 200px;
    background: white;

    box-shadow: ${({ active }) => active ? 'rgb(96 118 231) 0px 3px 8px' : 'rgba(0, 0, 0, 0.24) 0px 3px 8px'};;
    font-weight: ${({ active }) => active ? '900' : '500'};;

    border-radius: 15px;
    color: var(--color-accent);
    text-align: center;

    &:hover{
       cursor: pointer;
       box-shadow: rgb(96 118 231) 0px 3px 8px;
       font-weight: 900;
    }

    &:disabled{
        background:red !important;
    }
    @media screen and (max-width: 768px) {
        width: 180px;
        height: 180px;
        
    } 
`
export default TypePage

