import { GLOBALTYPES } from './globalTypes'
import { postDataAPI, getDataAPI, patchDataAPI, deleteDataAPI } from '../../utils/fetchData'
import validFormExperience from '../../utils/formValidation/validFormExperience'

export const EXPERIENCES_TYPES = {
    CREATE_EXPERIENCE: 'CREATE_EXPERIENCE',
    LOADING_EXPERIENCE: 'LOADING_EXPERIENCE',
    GET_EXPERIENCES: 'GET_EXPERIENCES',
    GET_EXPERIENCES_USER: 'GET_EXPERIENCES_USER',
    DELETE_EXPERIENCE: 'DELETE_EXPERIENCE',
    UPDATE_EXPERIENCE:'UPDATE_EXPERIENCE'

}


export const createExperience= ({data, auth}) => async (dispatch) => {


    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })

        const res = await postDataAPI('experiences', data, auth.token)

        dispatch({ 
            type: EXPERIENCES_TYPES.CREATE_EXPERIENCE, 
            payload: {...res.data.newExperience, user: auth.user} 
        })

        
        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: false} })
        dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {success: "Une nouvelle expérience a été ajouté!"}
        })

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const getExperiences = (token) => async (dispatch) => {
    try {
        dispatch({ type: EXPERIENCES_TYPES.LOADING_EXPERIENCE, payload: true })
        const res = await getDataAPI('experiences', token)
        
        dispatch({
            type: EXPERIENCES_TYPES.GET_EXPERIENCES,
            payload: {...res.data, page: 2}
        })

        dispatch({ type: EXPERIENCES_TYPES.LOADING_EXPERIENCE, payload: false })
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const getExperiencesUser = (token, id) => async (dispatch) => {
    try {
        dispatch({ type: EXPERIENCES_TYPES.LOADING_EXPERIENCE, payload: true })
        const res = await getDataAPI(`user_experiences/${id}`, token)
        console.log(res)
        dispatch({
            type: EXPERIENCES_TYPES.GET_EXPERIENCES_USER,
            payload: {...res.data, page: 2}
        })

        dispatch({ type: EXPERIENCES_TYPES.LOADING_EXPERIENCE, payload: false })
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}


export const updateExperience = ({id, data, auth}) => async (dispatch) => {
  
    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })

        console.log(data)
        const res = await patchDataAPI(`experience/${id}`, data, auth.token)

        dispatch({ type: EXPERIENCES_TYPES.UPDATE_EXPERIENCE, payload: res.data.newExperience })
        dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {success: "Une nouvelle expérience a été ajouté!"}
        })
        } 
        catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const deleteExperience = ({id, experience, auth}) => async (dispatch) => {
    dispatch({ type: EXPERIENCES_TYPES.DELETE_EXPERIENCE, payload: experience })

    try {
        const res = await deleteDataAPI(`experience/${id}`, auth.token)
        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} })

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}


