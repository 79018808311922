import React, {useEffect} from 'react'
import { MdCheckCircle, MdError } from 'react-icons/md'
import styled from 'styled-components'

const Toast = ({msg, handleShow, bgColor, textColor}) => {
     useEffect(() => {
        setTimeout(() => {
            handleShow()
        }, 4000);
      }, [4000]); 
    return (

        <Panel bg={bgColor} text={textColor}>
            {msg.title === "Success"? <MdCheckCircle className='icon'/>: <MdError className='icon'/>}
            {msg.body} 
            
             <Cross 
                onClick={handleShow}>
                    &times;
                </Cross>
        </Panel>
    )
}

const Panel = styled.div`
    z-index: 12000;
    display:flex;
    padding: 1% 2%;
    align-items:center;
    border-radius: 15px;
    background:${props => props.bg ? props.bg : "white"}; 
    color:${props => props.text ? props.text : "white"}; 

    height: 10%;
    min-width: 250px;
    position: fixed;
    bottom: 4%;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 18px;
    .icon {
        font-size: 50px;
        margin-right: 10px;
    }

`
const Cross = styled.div`
    position:absolute;
    font-size: 1.5rem;
    font-weight: 900;
    cursor: pointer;
    transform: translateY(-5px);
    right: 5px;
    top: 0px;
`

export default Toast
