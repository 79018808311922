import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components';
import { useHistory } from 'react-router-dom'
import { Button, Tag } from 'antd'
import { useSelector } from 'react-redux';
import { convertIdToLabel } from '../../../utils/function';

const CompanyCard = ({ page, heightCard }) => {
    const { datas, pages, auth} = useSelector(state => state);
    const [showContact, setShowContact] = useState(false);
    const [showOffer, setShowOffer] = useState(false);
    const [formattedDate, setFormattedDate] = useState('')

    const history = useHistory();

    return (
        <Card height={heightCard} >
            <Top >

    
                {
                    page.imgBackground ? <img src={page.imgBackground} />
                        : <img src="https://behandy-front.s3.eu-west-3.amazonaws.com/color-gd67b3d48d_640.png" alt="avatar" />

                }

            </Top>

            <Bottom>
     

                
                <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                    <div>
                        <h2 className='title' onClick={() => window.location.href = `/company/${page._id}`}>{page.name}</h2>
                        <span className='subtitle'>{convertIdToLabel(datas.companyTypes, page.sector)}</span>
                    </div>
                    <img src={page.avatar} className="img-company" alt={'logo entreprise'} onClick={() => window.location.href = `/company/${page._id}`} />


                </div>

                <div style={{ display: "flex", gap: "2px", flexWrap:"wrap" }}>

                {
                    page.specialities.map(tag => (
                        <Tag style={{ padding: "0px 10px", color: "var(--color-accent)", background:'rgba(96, 118, 231, 0.19)', borderRadius: "50px", fontSize: "0.8em" }} color="red" bordered={false}>{tag}</Tag>
                    ))
                }
                </div>

                <p>{page.description}</p>

                <div className='infos'>

                   {(page.emailB2B ||page.email )&&  <div className='info-item'>
                        <svg width="25" height="25" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                            <path fill="var(--color-accent)" fillRule="evenodd" d="M14.95 3.684L8.637 8.912a1 1 0 0 1-1.276 0l-6.31-5.228A.999.999 0 0 0 1 4v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a.999.999 0 0 0-.05-.316M2 2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2m-.21 1l5.576 4.603a1 1 0 0 0 1.27.003L14.268 3z"/>
                        </svg>
                        <span>{page.emailB2B?page.emailB2B+" (B2B)":page.email}</span>              
                    </div> }

                    {(page.phoneB2B ||page.phone )&& <div className='info-item'>
                         <svg width="25" height="25" viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg">
                            <path fill="var(--color-accent)" d="M39.156 50.934c4.078 0 6.774-1.102 9.164-3.774c.188-.187.352-.398.54-.586c1.406-1.57 2.062-3.117 2.062-4.593c0-1.688-.984-3.258-3.07-4.712l-6.82-4.734c-2.11-1.453-4.571-1.617-6.54.328l-1.804 1.805c-.54.539-1.008.563-1.547.234c-1.242-.797-3.797-3.023-5.532-4.757c-1.828-1.805-3.609-3.82-4.523-5.297c-.328-.54-.281-.985.258-1.524l1.781-1.805c1.969-1.968 1.805-4.453.352-6.538l-4.758-6.82c-1.43-2.087-3-3.048-4.688-3.071c-1.476-.024-3.023.656-4.593 2.062c-.211.188-.399.352-.61.516c-2.648 2.39-3.75 5.086-3.75 9.14c0 6.704 4.125 14.86 11.696 22.43c7.523 7.524 15.703 11.696 22.382 11.696m.024-3.61c-5.977.117-13.64-4.476-19.711-10.523c-6.117-6.094-10.922-14.016-10.805-19.992c.047-2.579.938-4.805 2.79-6.399c.14-.14.28-.258.444-.375c.68-.61 1.454-.937 2.11-.937c.703 0 1.312.257 1.758.96l4.547 6.82c.492.727.539 1.548-.188 2.274l-2.062 2.063c-1.641 1.617-1.5 3.586-.328 5.156c1.335 1.805 3.656 4.43 5.437 6.211c1.805 1.805 4.64 4.336 6.445 5.695c1.57 1.172 3.563 1.29 5.18-.328l2.062-2.062c.727-.727 1.524-.68 2.25-.211l6.82 4.547c.704.468.985 1.054.985 1.758c0 .68-.328 1.43-.96 2.132a5.82 5.82 0 0 1-.352.446c-1.617 1.828-3.844 2.718-6.422 2.765"/>
                        </svg>
                        <span>{page.phoneB2B?page.phoneB2B+" (B2B)" :page.phone}</span>              
                    </div> }
                </div>





            </Bottom>



        </Card>
    )
}



const Card = styled.div`
    display:flex;
    flex-direction: column;
    height: 100%;
    background : transparent;

    img{
        width: 100%;
        height: 100%;

        object-fit: cover;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

    }
    @media screen and (max-width: 768px) {
        min-height: 400px;
    }
`




const Top = styled.div`
    position: relative;
    height: 40%;
`

const Bottom = styled.div`
    overflow-wrap: break-word;
    overflow-y: auto;
    position: relative;
    background : #F9F9F9;
    border: 1px solid #f2f2f2;
    height: 60%;
    flex: 1;
    padding: 2% 8%;
    display:flex;
    flex-direction: column;
    gap: 10px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    .img-company{
        width: 3.5em;
        height: 3.5em;
        border-radius: 50%;
        &:hover{
            cursor: pointer;
        }
    }

    .title{
        margin:0px;
        padding: 0px;
        font-size: 1.2em;
        font-weight: bold;
        color:var(--color-accent);
        &:hover{
            cursor: pointer;
        }
    }

    .subtitle{
        font-size: 1em;
        font-style: italic;
        color:#969696;

    }

    p{
        margin:0px;
        margin-top: 5%;
        padding: 0px;
        font-size: 0.9em;
        font-weight: 400;
        color: #757474;
    }

    .infos{
        display:flex;
        flex-direction: column;
        gap:10px;
        padding: 20px 0px;

        .info-item{
            display:flex;
            align-items: center;
            gap: 10px;
            color: #757474;
            font-style: italic;
        }
    }

    @media screen and (max-width: 768px) {

    
 
    } 

    /* width */
    ::-webkit-scrollbar {
    width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: #F87778; 
    border-radius: 10px;
    
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background: #b30000; 
    }


`



export default CompanyCard