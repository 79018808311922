import React, { useEffect, useState } from 'react'
import { isInArray } from '../../../utils/function'
import { PanelExplanation } from '../BigScreenCompanyPage'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { getSubscriptions } from '../../../redux/actions/subscriptionAction'
import { NoResults } from '../AdminsManagementComponents'
import { Player } from '@lottiefiles/react-lottie-player'
import EmptyAnimation from '../../../assets/empty.json'
import LoadIcon from '../../../images/loading.gif'
import { ShowMore } from '../../../pages/home'
import { PAGE_TYPES, getPages, updatePageWithoutImage } from '../../../redux/actions/pageAction'
import CompanyItem from './CompanyItem'
import CompanyModal from './CompanyModal'
import { Button, Input } from 'antd'
import useWindowDimensions from '../../../utils/useWindowsDimensions'
import CompanyPreviewCardMobile from './CompanyPreviewCardMobile'
import { getDataAPI } from '../../../utils/fetchData'
const PhoneBook = ({ company }) => {
    const { auth, pages ,modal} = useSelector(state => state)
    const dispatch = useDispatch();
    const [modifyEmail, setModifyEmail] = useState(false)
    const [emailB2B, setEmailB2B] = useState('')
    const [modifyPhone, setModifyPhone] = useState(false)
    const [phoneB2B, setPhoneB2B] = useState('')
    const {width} = useWindowDimensions();
    useEffect(() => {
        let filterPages = {order: 'ASC', n: 12}
          dispatch(getPages({filterPages,auth}))
          if(company.emailB2B){
            setEmailB2B(company.emailB2B)
          }
          if(company.phoneB2B){
            setPhoneB2B(company.phoneB2B)
          }
        }, [])

        
        const handleLoadMore = async () => {
            let param = "order=ASC"
            let page = parseInt(pages.page) + 1
            let request = ''
            if(param){
                request = `pages?n=12&page=${page}&${param}`
            }else{
                request = `pages?n=12&page=${page}`
            }
            const res = await getDataAPI(request, auth.token)
        
        
            dispatch({
              type: PAGE_TYPES.GET_MORE_PAGES,
              payload: res.data
            })  
        
        }
        
        

    const modifyEmailB2B = () => {
        if(!emailB2B)return;
        dispatch(updatePageWithoutImage({id:company._id, pageData:{emailB2B}, auth}))
    }

    const modifyPhoneB2B = () => {
        if(!emailB2B)return;
        dispatch(updatePageWithoutImage({id:company._id, pageData:{phoneB2B}, auth}))
    }

    return (
        <Container>
            {modal.typeModal=="companyModal" && <CompanyModal/>}
                
            {isInArray(company.admins, auth.user._id) && <PanelExplanation>
                <p>Dans cet espace, vous pourrez retrouver les entreprises CHR et les partenaires présents sur la plateforme. L’endroit idéal si vous voulez trouver des partenaires commerciaux ! </p>
            </PanelExplanation>}

            {isInArray(company.admins, auth.user._id) &&
            <div className='infos-b2b' >
                <span className='infos'><b>Adresse email B2B</b> (il s'agit de l'adresse email visible par les entreprises qui veulent vous contacter)</span>
                
                {
                    !modifyEmail &&
                    <div style={{display:"flex", gap:"10px"}} ><span className='email-b2b'>{company.emailB2B?company.emailB2B:'Aucun email renseigné'}</span> <span className='modify-email' onClick={()=>setModifyEmail(true)}>Modifier</span> </div>
                }

                {
                    modifyEmail &&
                    <div className='modify-input' style={{display:"flex", gap:"10px"}} >
                        <Input placeholder='Email B2B' value={emailB2B} onChange={(e) => setEmailB2B(e.target.value)} />
                        <Button onClick={()=>modifyEmailB2B()}>Modifier</Button>
                    </div>
                }

                <span className='infos'><b>Téléphone B2B</b> (il s'agit d'un numéro visible par les entreprises qui veulent vous contacter)</span>
                
                {
                    !modifyPhone &&
                    <div style={{display:"flex", gap:"10px"}} ><span className='email-b2b'>{company.phoneB2B?company.phoneB2B:'Aucun numéro de téléphone renseigné'}</span> <span className='modify-email' onClick={()=>setModifyPhone(true)}>Modifier</span> </div>
                }

                {
                    modifyPhone &&
                    <div className='modify-input' style={{display:"flex", gap:"10px"}} >
                        <Input placeholder='Email B2B' value={phoneB2B} onChange={(e) => setPhoneB2B(e.target.value)} />
                        <Button onClick={()=>modifyPhoneB2B()}>Modifier</Button>
                    </div>
                }
            </div>   
            }


            <CompaniesDiv>

                {
                    pages.loading
                        ? <div className="icon-loading"><img src={LoadIcon} alt="loading" /></div>
                        : (pages.count === 0)
                            ? <NoResults>
                                <Player
                                    autoplay
                                    loop
                                    src={EmptyAnimation}
                                    style={{ height: '100%', width: '100%' }}
                                >
                                </Player>
                            </NoResults>
                            : <ItemsContainer>
                                {
                                    pages.pages.map((page) => (
                                        <>
                                        {
                                            width > 768 ? <CompanyItem page={page}/> : <CompanyPreviewCardMobile page={page}/>
                                        }
                                        </>
                                 
                                    ))
                                }

                            </ItemsContainer>
                }
                {
                    (pages.page * pages.n) < pages.count &&
                    <ShowMore onClick={()=>handleLoadMore()}>Voir plus</ShowMore>

                } 
            </CompaniesDiv>





        </Container>
    )
}

const Container = styled.div`
  display:flex;
  margin-top: 80px;


  flex-direction:column;
  gap: 20px;

  .infos-b2b{
    font-size:0.9em !important;
    font-weight: 400;
    color: #969696;

    .modify-input{
        width: 50%;
    }
    .email-b2b{
        font-style:italic;
    }
    .modify-email{
        color: #6076E7;
        
        &:hover{
            cursor: pointer;
            text-decoration: underline;
            font-weight: 800;
        }
    }
   
  }
    h3{
    color: #F87778;
    }



  
  @media only screen and (max-width: 768px) {  
    margin-top: 0px;
    .modify-input{
        width: 100% !important;
    }
  }

`
const CompaniesDiv = styled.div`
    display:flex;
    flex-direction:column;
    gap: 15px;

    .icon-loading{
        margin: 50px auto;
    }
`

const ItemsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-gap: 20px;

        @media (max-width: 1200px) {

            grid-template-columns: repeat(3, 1fr);

        }

        @media (max-width: 1000px) {

            grid-template-columns: repeat(2, 1fr);

        }


        @media only screen and (max-width: 768px) {  
             grid-template-columns: repeat(1, 1fr);

        }
  

       

`



export default PhoneBook