import React from 'react'
import Modal from '../utils/Modal/Modal'
import styled from 'styled-components'
import logo from '../../assets/logo.png'
import FilterContent from './FilterContent'

const FilterModal = () => {
  return (
    <Modal  modalName={"filterModal"} heightModal={'90vh'} >
            <Container>
                <ModalHeader>
                    <img alt="logo" src={logo} style={{marginTop:"2%",width: "40px", height:"40px"}}/>
                </ModalHeader>
                <h1>Affiner</h1>
                <FilterContent/>
            </Container>

 



    </Modal>
  )
}

const Container = styled.div`
    display:flex;
    flex-direction: column;
    padding:0% 2%;
    padding-bottom: 2%;
    h1{
        font-size: 1.5em;
        font-weight: bold;
        margin: 0px;
        margin-bottom: 3%;
    }
    height: 100%;
    width: 100%;
`


const ModalHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`


export default FilterModal