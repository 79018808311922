import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import styled from "styled-components"

import logo from '../../../assets/logo-violet.png'


import Modal from '../../utils/Modal/Modal'
import { MdAdd } from 'react-icons/md'
import { IoMdAddCircle } from 'react-icons/io'
import FormCompany2 from './page-creation-v2/FormCompany2'
import FormCompany3 from './page-creation-v2/FormCompany3'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { isInArray } from '../../../utils/function'



const GroupeModal = () => {

    const { modal, auth } = useSelector(state => state)


    const [company, setCompany] = useState({parentPage:modal.page._id,typeCompany:'franchise', postalAddress: modal.page.postalAddress, siret: modal.page.siret, registeredName: modal.page.registeredName })

    const [step, setStep] = useState(1)
    const dispatch = useDispatch()
    const history = useHistory();


    const handleCompanyChange = (updatedCompany) => {
        setCompany(updatedCompany);
    };

    const handleStepChange = (step) => {
        setStep(step);
    };





    return (
        <Modal modalName={"groupeModal"} widthModal={"45vw"} heightModal={"85vh"} >
            {(modal.page) && <Container>

                <img src={logo} alt='logo' />

                {
                    step === 1 && <>
                        <h1>Gestion du compte entreprise</h1>

                        <div className='pages'>
                            <div className='div-page main-page'>
                                <h2>Maison mère :</h2>

                                <div className='items-div'>
                                    <Item  onClick={()=>  history.push(`/company/${modal.page._id}`)}style={{ margin: "0px auto" }}>
                                        <img src={modal.page.avatar} />

                                        <span>{modal.page.name}</span>

                                    </Item>
                                </div>

                            </div>

                      

                            <div className='div-page subpages'>
                                <div style={{ display: "flex", justifyContent: 'space-between' }}><h2>Pages établissements :</h2> {isInArray(modal.page.admins, auth.user._id, 'admin') && <IoMdAddCircle onClick={() => setStep(2)} className='add' />}</div>

                                <div className='items-div'>

                                    {
                                        modal.page.childrenPages.map((children) => (
                                            <Item onClick={()=> history.push(`/company/${children._id}`)
                                        }>
                                                  <img src={children.avatar} />
                                                   <span>{children.name}</span>
                                            </Item>
                                        ))
                                    }




                                </div>

                            </div>


                        </div>


                    </>
                }


{
                step === 2 && <FormCompany2 company={company} onChange={handleCompanyChange} step={step} onStepChange={handleStepChange} />
            }

            {
                step === 3 && <FormCompany3 company={company} onChange={handleCompanyChange} step={step} onStepChange={handleStepChange} />
            }


            </Container>

            }




        </Modal>
    )
}




const Container = styled.div`
    display:flex;
    flex-direction: column;
    align-items:center;
    
    height: 100%;
    width: 100%;
    h1{
        font-size: 1.5em;
        font-weight: 900 !important;
        margin: 0px;
        margin-top: 5%;

        margin-bottom: 3%;
    }

    img{
        width: 175px;
    }
    padding: 2% 5%;


    .pages{
        display:flex;
        flex-direction:column;
        gap: 20px;
        width: 100%;
        height:100%;

        .items-div{
            display:flex;
            justify-content:center;
            height: 100%;
            align-items:center;
            gap: 10px;
        }

        .div-page{
            display:flex;
            flex-direction: column;
            
            h2{
                font-size:1em;
                font-weight: 800;
                margin:0px;
                padding: 0px;
            }
        }
        .main-page{
            height: 40%;
            padding: 10px 20px;
            border-radius: 15px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        }

        .subpages{
            height: 60%;
            border-radius: 15px;
            padding: 10px 20px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

            .add{
                font-size: 1.5em;
                color: var(--color-accent);

                &:hover{
                    color: var(--color-accent-hover);
                    cursor: pointer;
                }
            }
        }
    }
`

const Item = styled.div`
    display:flex;
    flex-direction:column;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    gap: 5px;
    text-align:center;
    justify-content:center;
    align-items:center;
    min-height: 120px;
    min-width: 120px;
    max-width: 120px;
    line-break: auto;
    img{
        width: 50px;
        height: 50px;
    }

    padding: 2% 4%;

    span{
        font-size: 0.9em;
        margin: 0px;
        line-height: 1em;
    }

    &:hover{
        cursor: pointer;
    }

`

export default GroupeModal
