import { Button, Tooltip } from 'antd'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import target from '../../../../assets/offers-icons/target.png'
import global from '../../../../assets/offers-icons/general.png'
import { HeaderModal } from './PageChoice'


const Target = ({ step, onStepChange, formData, setFormData }) => {

    const [active, setActive] = useState('');

    const { modal } = useSelector(state => state)

    useEffect(() => {
        if (modal.deal) {
            setActive(modal.deal.target)
        }
    }, [])

    const handleClick = (choice) => {
        setActive(choice);
/*         setFormData({ ...formData, target: choice });
 */    }

    useEffect(() => {
        console.log(formData)
    }, [formData])


    return (
        <Content>
            <HeaderModal>
               
                <h2>Créer une promo</h2>

                <h3> Pour qui est la promo?</h3>

            </HeaderModal>

            <ChoicesDiv>
                <Choice key={Math.random()} onClick={() => handleClick("global")} active={active === 'global' ? true : false}>
                    <img src={global} alt=' target' />

                    <span>Communauté</span>

                    <Tooltip  className='help' title="Cette promo est destinée à l'ensemble de la communauté de Behandy. Parfait pour se faire connaître !" zIndex={100000}>

                    <svg  xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 14">
                        <path fill="currentColor" fillRule="evenodd" d="M7 14A7 7 0 1 0 7 0a7 7 0 0 0 0 14M5.5 9.375a.625.625 0 1 0 0 1.25h3a.625.625 0 1 0 0-1.25h-.875V6.5A.625.625 0 0 0 7 5.875H6a.625.625 0 1 0 0 1.25h.375v2.25zM8 4a1 1 0 1 1-2 0a1 1 0 0 1 2 0" clip-rule="evenodd"/>
                    </svg>

                    </Tooltip>

                </Choice>
                <Choice key={Math.random()} onClick={() => handleClick("partner")} active={active === 'partner' ? true : false}>

                    <img src={target} alt=' partner' />

                    <span>Partenaire</span>

                    <Tooltip  className='help' title="Cette promo est réservée aux salariés des entreprises avec qui vous avez nouer des partenariats sur Behandy. Gâtez-les ! " zIndex={100000}>

                    <svg  xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 14">
                        <path fill="currentColor" fillRule="evenodd" d="M7 14A7 7 0 1 0 7 0a7 7 0 0 0 0 14M5.5 9.375a.625.625 0 1 0 0 1.25h3a.625.625 0 1 0 0-1.25h-.875V6.5A.625.625 0 0 0 7 5.875H6a.625.625 0 1 0 0 1.25h.375v2.25zM8 4a1 1 0 1 1-2 0a1 1 0 0 1 2 0" clip-rule="evenodd"/>
                    </svg>

                    </Tooltip>
                </Choice>

            </ChoicesDiv>


            <Button onClick={() => { if (active === '') return; setFormData({ ...formData, target: active }); }} size="large" shape="round" type="primary" htmlType="submit">
                Suivant
            </Button>

        </Content>
    )
}

const Content = styled.div`
    display:flex;
    width: 100%;
    flex: 1;

    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    h2{
        font-size:1.6em;
        font-weight: 900;
        color: #585858;
        text-align:center;
    }

`
export const ChoicesDiv = styled.div`
    
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
        width: 100%

        @media screen and (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);

        } 

    


`

export const Choice = styled.div`
    position:relative;
    padding: 20px;
    aspect-ratio: 1/1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    width: 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background: ${({ active }) => active ? 'var(--color-accent);' : ''};


    img{
        width: 45px !important;
        height: 45px !important;
    }

    span{
        margin-top:10px;
        font-size:0.9em;
        text-align:center;
        color: ${({ active }) => active ? 'white' : '#717171'};

    }
    transition: all 0.2s ease-in;

    &:hover{
        cursor: pointer;
        transform: scale(1.1);
    }

    .help{
        position:absolute;
        top: 5px;
        right: 5px;
    }

`
export default Target