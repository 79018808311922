import {GLOBALTYPES } from './globalTypes'
import { deleteDataAPI, getDataAPI } from '../../utils/fetchData'
import { getParamsFilter } from './userAction'

export const VISITORS_TYPES = {
    GET_MORE_VISITORS: 'GET_MORE_VISITORS',
    GET_VISITORS:'GET_VISITORS',
    LOADING:'LOADING',
    DELETE_VISITOR: 'DELETE_VISITOR',
    UPDATE_VISITOR_DEALS: 'UPDATE_VISITOR_DEALS',
    UPDATE_VISITOR_EVENTS: 'UPDATE_VISITOR_EVENTS'

}




export const getVisitors = ({ filter, auth}) => async (dispatch) => {
    try {

        let param = getParamsFilter(filter)

        dispatch({type: VISITORS_TYPES.LOADING, payload: true}) 

        let url = `/visitors?${param}`

        const res = await getDataAPI(url, auth.token)

        dispatch({
            type: VISITORS_TYPES.GET_VISITORS,
            payload: res
        }) 
 
        dispatch({type: VISITORS_TYPES.LOADING, payload: false}) 
 

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
    }
    
}


export const getMoreVisitors = ({filter, auth}) => async (dispatch) => {
    try {
        let param = getParamsFilter(filter)

        dispatch({type: VISITORS_TYPES.LOADING, payload: true})
        let url = `/visitors?${param}`

        const res = await getDataAPI(url, auth.token)



        dispatch({
            type: VISITORS_TYPES.GET_MORE_VISITORS,
            payload: res
        }) 

 
        dispatch({type: VISITORS_TYPES.LOADING, payload: false}) 
        return true;

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
        return false;
    }
    
}


export const deleteVisitor= ({visitor, auth}) => async (dispatch) => {

    try {
        await deleteDataAPI(`visitor/${visitor._id}`, auth.token)
        dispatch({ type: VISITORS_TYPES.DELETE_VISITOR, payload: visitor })


        
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}
