import { Button, Checkbox, Form, Select } from 'antd'
import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import SearchAddress from '../../../../utils/SearchAddress'
import { useEffect } from 'react'
import { sectorsDatas } from '../../../../../utils/datas'

const TargetForm = ({ type, step, onStepChange, formData, setFormData }) => {

    const { modal } = useSelector(state => state);
    const [localisationText, setLocalisationText] = useState("");
    const [localisationObject, setLocalisationObject] = useState(null);



    const initialValuesReward = { // Setting initial values here
        jobtargeted: modal.reward && modal.reward.jobtargeted,
        sector: modal.reward && modal.reward.sector,
        profil: modal.reward && modal.reward.profil,

        experience: modal.reward && modal.reward.experience,
        only_pro: modal.reward && modal.reward.only_pro,
        open_to_work: modal.reward && modal.reward.open_to_work
    }

    const initialValuesDeal = { // Setting initial values here
        jobtargeted: modal.deal && modal.deal.jobtargeted,
        sector: modal.deal && modal.deal.sector,
        profil: modal.deal && modal.deal.profil,

        experience: modal.deal && modal.deal.experience,
        only_pro: modal.deal && modal.deal.only_pro,
        open_to_work: modal.deal && modal.deal.open_to_work
    }

    const initialValuesEvent = { // Setting initial values here
        jobtargeted: modal.event && modal.event.jobtargeted,
        sector: modal.event && modal.event.sector,
        profil: modal.event && modal.event.profil,

        experience: modal.event && modal.event.experience,
        only_pro: modal.event && modal.event.only_pro,
        open_to_work: modal.event && modal.event.open_to_work
    }
    useEffect(() => {
        if (modal.reward) {
            if(modal.reward.localisation_target){
                setLocalisationText(modal.reward.localisation_target.formatted_address)
                const { formatted_address, address_components, geometry } = modal.reward.localisation_target;
                const newObject = { formatted_address, address_components, geometry };
                setLocalisationObject(newObject)
            }

        } else if (modal.deal) {
            if(modal.deal.localisation_target){
                setLocalisationText(modal.deal.localisation_target.formatted_address)
                const { formatted_address, address_components, geometry } = modal.deal.localisation_target;
                const newObject = { formatted_address, address_components, geometry };
                setLocalisationObject(newObject)
            }

        } else if (modal.event) {
            if(modal.event.localisation_target){
                setLocalisationText(modal.event.localisation_target.formatted_address)
                const { formatted_address, address_components, geometry } = modal.event.localisation_target;
                const newObject = { formatted_address, address_components, geometry };
                setLocalisationObject(newObject)
            }
        }

    }, [])



    useEffect(() => {
        setFormData({ ...formData, ['localisation_target']: localisationObject })

    }, [localisationObject])

    const handleAddressChange = (newAddress) => {

        setLocalisationText(newAddress.formatted_address)
        const { formatted_address, address_components, geometry } = newAddress;
        const newObject = { formatted_address, address_components, geometry };
        setLocalisationObject(newObject)

        console.log(localisationObject)


    }

    const handleCheckboxChange = (e) => {
        const { id, checked } = e.target;
        console.log(e.target)
        console.log(id)
        setFormData({ ...formData, [id]: checked })

    };

    const getInitialValues = () => {
        if (modal.reward) {
            return initialValuesReward;
        } else if (modal.deal) {
            return initialValuesDeal;
        } else if (modal.event) {
            return initialValuesEvent;
        }
        return {};
    }

    return (
        <Content>

            <Header>

                {
                    (type === "promo") && <h2>{modal.deal?'Modifier':'Créer'} une promotion ciblée</h2>
                }
                {
                    (type === "reward") && <h2>{modal.reward?'Modifier':'Créer'}  un atout ciblé</h2>
                }




                <h3>Quelle est la cible?</h3>

            </Header>


            <Form
                onFinish={() => { onStepChange(step + 1) }}
                layout="vertical"
                style={{ width: "100%" }}
                initialValues={getInitialValues()}            >


                <Form.Item name="profil" label="Secteur" rules={[{ required: true, message: 'Sélectionnez un secteur!' }]}>
                    <Select       mode="multiple"
 onChange={(e) => { setFormData({ ...formData, ['profil']: e }) }} size="large" placeholder="Sélectionnez votre secteur" dropdownStyle={{ zIndex: "100000" }}>
                        {
                            sectorsDatas.map((sector) => (
                                <Select.Option value={sector.value}>{sector.label}</Select.Option>

                            ))
                        }
                       

                    </Select>
                </Form.Item>

                <Form.Item
                    label="Localisation"
                    name="localisation"

                >
                    <SearchAddress onAddressChange={handleAddressChange} address={localisationText} setAddress={setLocalisationText} />


                </Form.Item>


                <Form.Item name="experience" label="Niveau d'expériences" rules={[{ required: true, message: 'Sélectionnez un niveau!' }]}>
                    <Select onChange={(e) => { setFormData({ ...formData, ['experience']: e }) }} size="large" placeholder="Sélectionnez votre expérience" dropdownStyle={{ zIndex: "100000" }}>
                        <Select.Option value="Aucune expérience">Aucune expérience</Select.Option>
                        <Select.Option value="Étudiant/Apprenti">Étudiant/Apprenti</Select.Option>
                        <Select.Option value="0 - 2 ans">0 - 2 ans</Select.Option>
                        <Select.Option value="2 - 5 ans">2 - 5 ans</Select.Option>
                        <Select.Option value="5 - 10 ans">5 - 10 ans</Select.Option>
                        <Select.Option value="10 ans et +">10 ans et +</Select.Option>

                    </Select>
                </Form.Item>


                <Form.Item name="checkbox-group" valuePropName="checked">
                    <Checkbox
                        id="open_to_work"
                        onChange={handleCheckboxChange}
                        checked={formData.open_to_work}

                    >
                        A l'écoute d'opportunités
                    </Checkbox>
                    <Checkbox
                        id="only_pro"
                        name="only_pro"

                        onChange={handleCheckboxChange}
                        checked={formData.only_pro}

                    >
                        Je veux uniquement des professionnels
                    </Checkbox>
                </Form.Item>












                <Form.Item className="item-next" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button size="large" shape="round" type="primary" htmlType="submit">
                        Suivant
                    </Button>
                </Form.Item>
            </Form>








        </Content>
    )
}

const Content = styled.div`
    display:flex;
    width: 100%;
    flex: 1;

    flex-direction: column;
    justify-content: space-around;
    align-items: center;


    .selectAll{
        display:flex;
        justify-content: end;
        width:100%;
    }



`

const Header = styled.div`
    display:flex;
    flex-direction: column;

    h2{
        font-size:1.6em;
        font-weight: 900;
        color: #585858;
        text-align:center;
    }

    h3{
        font-size:1.3em;
        font-weight: 500;
        color: #585858;
        text-align:center;
    }

`

export default TargetForm