import React from 'react'
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useEffect } from 'react';
import { GLOBALTYPES } from '../../redux/actions/globalTypes';
import { useDispatch } from 'react-redux';
const BottomBarMobile = () => {

    const [active, setActive] = useState(0);
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const handleClick = (url, number) => {
        history.push(url)
    }

    useEffect(() => {
        if (location.pathname === '/' || location.pathname === '/home') {
            setActive(0)

        } else if (location.pathname === '/favouritesDeals') {
            setActive(1)
        } else if (location.pathname === '/settings') {
            setActive(2)
        } else if (location.pathname === '/jobcenter') {
            setActive(3)
        } else if (location.pathname === '/accounts') {
            setActive(4)
        }
    }, [location.pathname]);

    return (


        <BottomBar id="bottombar">
            <Item active={active === 0 ? true : false} onClick={() => handleClick('/home', 0)}>
                <svg width="28" height="28" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path fill={active === 0 ? "#F87778" : "#CCCCCC"}
                        d="M21 20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9.489a1 1 0 0 1 .386-.79l8-6.222a1 1 0 0 1 1.228 0l8 6.222a1 1 0 0 1 .386.79v10.51Zm-2-1V9.978l-7-5.445l-7 5.445V19h14Z" />
                </svg>
            </Item>
            <Item active={active === 1 ? true : false} onClick={() => handleClick('/favouritesDeals', 1)}>
                <svg width="28" height="28" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
                    <path strokeWidth="3.5" fill={active === 1 ? "#F87778" : "#CCCCCC"}
                        d="M178 32c-20.65 0-38.73 8.88-50 23.89C116.73 40.88 98.65 32 78 32a62.07 62.07 0 0 0-62 62c0 70 103.79 126.66 108.21 129a8 8 0 0 0 7.58 0C136.21 220.66 240 164 240 94a62.07 62.07 0 0 0-62-62Zm-50 174.8C109.74 196.16 32 147.69 32 94a46.06 46.06 0 0 1 46-46c19.45 0 35.78 10.36 42.6 27a8 8 0 0 0 14.8 0c6.82-16.67 23.15-27 42.6-27a46.06 46.06 0 0 1 46 46c0 53.61-77.76 102.15-96 112.8Z" />
                </svg>
            </Item>
            <Item active={active === 2 ? true : false} onClick={() => {
                dispatch({ type: GLOBALTYPES.MODAL, payload: { typeModal: "createDealModal", open: true } })
            }}>
                <svg width="28" height="28" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" stroke={active === 2 ? "#F87778" : "#CCCCCC"} strokeLinejoin="round" strokeWidth="3.5">
                        <rect width="36" height="36" x="6" y="6" rx="3" />
                        <path strokeLinecap="round" d="M24 16v16m-8-8h16" />
                    </g>
                </svg>        </Item>

            <Item active={active === 3 ? true : false} onClick={() => handleClick('/myaccount', 3)}>
        

        
<svg width="28" height="28" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
    <path fill={active === 3 ? "#F87778" : "#CCCCCC"} d="M18.1 11c-3.9 0-7 3.1-7 7s3.1 7 7 7s7-3.1 7-7s-3.1-7-7-7m0 12c-2.8 0-5-2.2-5-5s2.2-5 5-5s5 2.2 5 5s-2.2 5-5 5" class="clr-i-outline clr-i-outline-path-1"/>
    <path fill={active === 3 ? "#F87778" : "#CCCCCC"} d="m32.8 14.7l-2.8-.9l-.6-1.5l1.4-2.6c.3-.6.2-1.4-.3-1.9l-2.4-2.4c-.5-.5-1.3-.6-1.9-.3l-2.6 1.4l-1.5-.6l-.9-2.8C21 2.5 20.4 2 19.7 2h-3.4c-.7 0-1.3.5-1.4 1.2L14 6c-.6.1-1.1.3-1.6.6L9.8 5.2c-.6-.3-1.4-.2-1.9.3L5.5 7.9c-.5.5-.6 1.3-.3 1.9l1.3 2.5c-.2.5-.4 1.1-.6 1.6l-2.8.9c-.6.2-1.1.8-1.1 1.5v3.4c0 .7.5 1.3 1.2 1.5l2.8.9l.6 1.5l-1.4 2.6c-.3.6-.2 1.4.3 1.9l2.4 2.4c.5.5 1.3.6 1.9.3l2.6-1.4l1.5.6l.9 2.9c.2.6.8 1.1 1.5 1.1h3.4c.7 0 1.3-.5 1.5-1.1l.9-2.9l1.5-.6l2.6 1.4c.6.3 1.4.2 1.9-.3l2.4-2.4c.5-.5.6-1.3.3-1.9l-1.4-2.6l.6-1.5l2.9-.9c.6-.2 1.1-.8 1.1-1.5v-3.4c0-.7-.5-1.4-1.2-1.6m-.8 4.7l-3.6 1.1l-.1.5l-.9 2.1l-.3.5l1.8 3.3l-2 2l-3.3-1.8l-.5.3c-.7.4-1.4.7-2.1.9l-.5.1l-1.1 3.6h-2.8l-1.1-3.6l-.5-.1l-2.1-.9l-.5-.3l-3.3 1.8l-2-2l1.8-3.3l-.3-.5c-.4-.7-.7-1.4-.9-2.1l-.1-.5L4 19.4v-2.8l3.4-1l.2-.5c.2-.8.5-1.5.9-2.2l.3-.5l-1.7-3.3l2-2l3.2 1.8l.5-.3c.7-.4 1.4-.7 2.2-.9l.5-.2L16.6 4h2.8l1.1 3.5l.5.2c.7.2 1.4.5 2.1.9l.5.3l3.3-1.8l2 2l-1.8 3.3l.3.5c.4.7.7 1.4.9 2.1l.1.5l3.6 1.1z" class="clr-i-outline clr-i-outline-path-2"/>
    <path fill="none" d="M0 0h36v36H0z"/>
</svg>
                
                 </Item>

            <Item active={active === 4 ? true : false} onClick={() => handleClick('/accounts', 4)}>
                <svg width="28" height="28" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" stroke={active === 4 ? "#F87778" : "#CCCCCC"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.5">
                        <path d="M4 12h40v8l-1.398.84a7 7 0 0 1-7.203 0L34 20l-1.398.84a7 7 0 0 1-7.203 0L24 20l-1.398.84a7 7 0 0 1-7.204 0L14 20l-1.399.84a7 7 0 0 1-7.202 0L4 20v-8Z" />
                        <path d="M8 22.489V44h32V22M8 11.822V4h32v8" />
                        <path d="M19 32h10v12H19z" />
                    </g>
                </svg>
            </Item>


        </BottomBar>
    )
}

const BottomBar = styled.div`

    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 10vh;
    display:flex;
    align-items: center;
    justify-content: space-between;
    background: white;
`
const Item = styled.div`

    display:flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .icon{
        font-size:1.8em;
        color: #F87778;
    }

    &:hover{
        cursor: pointer;
        background: #F6F6F6;
    }

`
export default BottomBarMobile