import React from 'react'
import styled from 'styled-components'
 
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { MdDelete } from 'react-icons/md'
import { GLOBALTYPES } from '../../redux/actions/globalTypes'


const AdminCard = ({user}) => {
  const history = useHistory()
  const {pages} = useSelector(state=>state)

  const dispatch = useDispatch()
  return (
    <Card onClick={()=> history.push('/resume/'+ user._id) }>
        <div style={{display:"flex", width:"100%"}}>
          <img src={user.avatar} className="avatar"/>

          <div style={{marginLeft: "5%"}}>
            <h2>{user.fullname}</h2>
            <span> {user.titleJob !== "" ? user.titleJob: user.sector} </span>
          </div>
        </div>
        <div className='btn-delete' onClick={(e)=> { e.stopPropagation(); dispatch({ type: GLOBALTYPES.MODAL,payload: {type:"admin",typeModal:"delete",title:"Etes-vous sûr de vouloir supprimer cette utilisateur?", open:true, userId:user._id, page: pages.activePage}})}}><MdDelete/></div>

   </Card>
  )
}

const Card  = styled.div`
    position: relative;
    display:flex;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 15px;
    padding: 3% 5%;
    align-items:center;
    margin-bottom: 4%;
    justify-content: space-between;
    background: white;
    h2{
      margin: 0px;
      color: var(--color-accent);
      font-size: 1em;
      font-weight: 700;
    }

    span{
      margin: 0px;
      font-size: 0.8em;
      font-weight: 600;
    }

    img{
        width: 50px;
        height: 50px;

       border-radius: 50%;

    }

    &:hover{
        cursor:pointer;
    }

    .btn-delete{
      color: crimson;
      font-size: 2em;
      display:flex;
      align-items: center;
      justify-content: center;
    }
`

export default AdminCard