import React, {useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid';
import { InputStyled } from '../settings/accounts/FormComponents';
import { updateFilterDeal2 } from '../../redux/actions/filterDealActions';
import { GLOBALTYPES } from '../../redux/actions/globalTypes';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import useWindowDimensions from '../../utils/useWindowsDimensions';
import bed from '../../assets/offers/bed.png'
import eat from '../../assets/offers/traiteur.png'
import drink from '../../assets/offers/bar.png'
import spa from '../../assets/offers/spa.png'
import heart from '../../assets/offers/heart.png'
import laugh from '../../assets/offers/laugh.png'
import FilterImageIcon from './FilterImageIcon';
import { Button } from 'antd';
const FilterContent = () => {

    const filterButtons = [
        {id: 1,text:'Séjourner', type: "sleep", svg:bed, width:"25px"},
        {id: 2,text:'Se restaurer', type: "eat", svg:eat, width:"25px"},
        {id: 3,text:'Boire un verre', type: "drink", svg:drink,  width:"25px"},
        {id: 4,text:'S\'amuser', type: "fun", svg:laugh ,  width:"25px"},
        {id: 5,text:'Se détendre', type: "relax", svg:spa,  width:"25px" },
        {id: 6,text:'Se faire plaisir', type: "pleasure", svg:heart,  width:"25px" },
    ]
    const optionsAmountPersons = [
        { value: '1', label: '1 personne' },
        { value: '2', label: '2 personnes' },
        { value: '3', label: '3 personnes' },
        { value: '4', label: '4 personnes' },
        { value: '5', label: '5 personnes' },
        { value: '6', label: '6 personnes' },
        { value: '7', label: '7 personnes' },
        { value: '8', label: '8 personnes' },
        { value: '', label: 'Non précisé' },
    ]

    const animatedComponents = makeAnimated();




    const [selectedDays, setSelectedDays] = useState([])
    const [sector, setSector] = useState("")
    const [localisation, setLocalisation] = useState("")

    const [selectedAmountPersons, setSelectedAmountPersons] = useState([])

    const [filter, setFilter] = useState({})

    const { auth,datas } = useSelector(state => state)

    const optionsDays = datas.days.map(day => ({ value: day.id, label: day.name }));

    const [saveChoice, setSaveChoice]  = useState(null)
  
    const { width } = useWindowDimensions();
    const [sizeButtonToShow, setSizeButtonToShow]  = useState(width>768?6:4)
    const dispatch = useDispatch();



    const handleChangeDays = (selectedOption) => {
        setSelectedDays(selectedOption)
    }

    const handleChangeAmountPersons = (selectedOption) => {
        setSelectedAmountPersons(selectedOption)
    }
    const clickFilterButton = (id) => {
        console.log(id)
        if(saveChoice !== id){
            updateFilter(id);
            setSaveChoice(id) 
        }else{
            updateFilter("");

            setSaveChoice(null) 

        }
       
    }

    const updateFilter = (sector) => {
             setSector(sector) 
             console.log(sector)      
    }
    
    const valid = () => {
        let date = ""
        selectedDays.forEach(day => {
            date += day.value+','
        });
        date = date.slice(0, -1)
        setFilter({tags:sector, localisation:localisation, date: date, amountPersons: selectedAmountPersons.value})
        dispatch(updateFilterDeal2({filter:{tags:sector, localisation:localisation, date: date, amountPersons: selectedAmountPersons.value?selectedAmountPersons.value: ''}}))

        dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}}) 
    }

    return (
        <Content>
            <div>
            <Categories>
                <h3>Type d'établissement</h3>

            </Categories>
            
            <ButtonsContainer>
            {
                    datas.filters.map((filter) => (
                        <ButtonDiv key={uuidv4()}>
                            <ButtonFilter key={uuidv4()} onClick={() => clickFilterButton(filter.id)} active={saveChoice === filter.id ? true : false} background="white" color="#C0C0C0" fontsize="2em">
                                {/*                             <img src={filter.svg}  width={50} height="50" title="Menu" />
 */}                       
                                    <FilterImageIcon type={filter.type} />
                             </ButtonFilter>
                            <span>{filter.name}</span>
                        </ButtonDiv>

                    ))
                }

            </ButtonsContainer>

            <Categories style={{marginBottom: "2%", marginTop:"2%"}}>
                <h3>Localisation</h3>
            </Categories>
            <InputStyled placeholder='Ex: Marseille...' onChange={(e) => {setLocalisation(e.target.value)}} />

            <Categories style={{marginBottom: "2%", marginTop:"2%"}}>
                <h3>Date</h3>
            </Categories>
            <Select  closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
            options={optionsDays} placeholder="Ex: Lundi, Mardi ..."  onChange={handleChangeDays}  />

            <Categories style={{marginBottom: "2%", marginTop:"2%"}}>
            <h3>Personne(s) concernée(s):</h3>
            </Categories>
                    <Select  closeMenuOnSelect={true}
                        components={animatedComponents}
                        options={optionsAmountPersons} placeholder="Ex: 1 personne ..."   onChange={handleChangeAmountPersons} />
            </div>
            <Button style={{marginBottom: "5%", marginTop:"5%", alignSelf:'center'}} size='large' onClick={()=> valid()}>Appliquer</Button>

        </Content>
    )
}


const Content = styled.div`

    display:flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 2% 2%;

`

const Categories = styled.div`
    display:flex;
    justify-content: space-between;
    align-items: end;
    h3{
        margin: 0px;
        padding: 0px;
        font-size: 1em;
        color: #757474;
        font-weight: 600;
    }

    span{
        margin: 0px;
        padding: 0px;
        font-size: 0.8em;
        color: #757474;

    }

    @media screen and (max-width: 768px) {
        h3{
    
            font-size: 0.9em;
       
          }

        span{
    
            font-size: 0.7em;
       
          }
    } 

`

const ButtonFilter  = styled.div`
display:flex;
justify-content: center;
align-items: center;

background: ${({ active }) => active ? '#F87778' : 'white'};
font-weight: ${({ active }) => active ? '700' : '400'};

border: 1px solid #E5E5E5;
width: 60px;
height: 60px;
border-radius: 5px;
font-size: ${props => props.fontsize ? props.fontsize : "1.8em"};

img {
    width: 35px;
    height: 35px;




}


@media only screen and (max-width: 768px) {  
    width: 40px;
    height: 40px;
    img {
        width: 20px;
        height: 20px;
    
    
    
    
    }

 }
    

`

const ButtonDiv = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    justity-content: center;
    span{
        
        color: #C0C0C0;
        font-size: 0.8em;
        max-width: 70px;
        text-align:center;
    }

    margin-right: 2%;

    &:hover{
        cursor: pointer;
    }

    @media only screen and (max-width: 768px) {  
       span{
           font-size: 0.8em;
       }

    }


`

const ButtonsContainer = styled.div`
display: grid;
grid-template-columns: repeat(6, minmax(0, 1fr));

grid-gap: 20px;
margin-top: 2%;
margin-bottom: 3%;

@media only screen and (max-width: 768px) {  

    grid-template-columns: repeat(4, minmax(0, 1fr));

grid-gap: 15px;
}
        

`

const ButtonValid = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    background: #F87778;
    margin: 0px auto;
    padding: 1% 4%;
    border-radius: 5px;
    color: white;
    font-weight: 700;
    margin-top: 5%;

    &:hover{
        cursor: pointer;
    }

    @media only screen and (max-width: 768px) {  
        padding: 3% 8%;
        font-size: 1em;

    }

`
export default FilterContent