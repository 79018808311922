import { DeleteData, EditData } from '../actions/globalTypes';
import { SKILLS_TYPES } from '../actions/skillAction'

const initialState = {
    loading: false,
    skills: [],
    result: 0,
    page: 2
}

const skillReducer = (state = initialState, action) => {
    switch (action.type){
        case SKILLS_TYPES.CREATE_SKILL:
            return {
                ...state,
                skills: [action.payload, ...state.skills]
            };
        case SKILLS_TYPES.LOADING_SKILL:
            return {
                ...state,
                loading: action.payload
            };

        case SKILLS_TYPES.GET_SKILLS:
            return {
                ...state,
                skills: action.payload.skills,
                result: action.payload.result,
                page: action.payload.page
            };
        case SKILLS_TYPES.GET_SKILLS_USER:
                return {
                    ...state,
                    skills: action.payload.skills,
                    result: action.payload.result,
                    page: action.payload.page
                };
        case SKILLS_TYPES.UPDATE_SKILL:
            return {
                ...state,
                skills: EditData(state.skills, action.payload._id, action.payload)
            };
        case SKILLS_TYPES.DELETE_SKILL:
                return {
                    ...state,
                   skills: DeleteData(state.skills, action.payload._id)
               };
        case SKILLS_TYPES.UPDATED_SKILL:
                   return {
                       ...state,
                       skills: EditData(state.skills, action.payload._id, action.payload)
                   };
        default:
            return state;
    }
}

export default skillReducer