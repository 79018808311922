
import { FILTER_DEAL_TYPES } from '../actions/filterDealActions';

const initialState = {
   filter: null
}




const dealReducer = (state = initialState, action) => {
    switch (action.type){
     
        case FILTER_DEAL_TYPES.UPDATE_FILTER:
                return {
                     ...state,
                    filter: action.payload
                };
        default:
            return state;
    }
}


export default dealReducer
