import { Button, DatePicker, Form, Input } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import 'dayjs/locale/fr';
import locale from 'antd/es/date-picker/locale/fr_FR';
import { createCodeTmp } from '../../../../redux/actions/codeTmpActions';

const CreateCode = ({isEdit, formData, setFormData, step, setStep }) => {
    const { auth, theme, modal } = useSelector(state => state)

    const [pages, setPages] = useState([])


    const dispatch = useDispatch();



    const handleChangeInput = e => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })

    }

    const onDateChange = (date, dateString, value) => {
        console.log(value, dateString);
        setFormData({ ...formData, [value]: dateString })

    };



    const onFinish = () => {
        console.log(formData)

        dispatch(createCodeTmp({content:formData, auth}))

/*         if(isEdit){
            dispatch(updateAd({id:formData._id, content:formData, logo, imgBackground, auth}))

        }else{
            dispatch(createAds({content:formData, logo, imgBackground, auth}))

        } */
        
    };
    return (
        <>

            <Form
                name="basic"
                layout='vertical'
                onFinish={onFinish}
                autoComplete="off"
                initialValues={formData ? { ...formData } : null}

            >

                <Form.Item
                    label={"Nom du code"}
                    name="name"
                    onChange={(e) => handleChangeInput(e)}
                    hasFeedback

                    rules={[
                        {
                            required: true,
                            message: 'Entrez le nom!',

                        },
                        {
                            max: 120,
                            message: 'Le nom ne peut pas dépasser 120 caractères!',
                        }
                    ]}
                >
                    <Input name="name" size="large" maxLength={120} />
                </Form.Item>


                <Form.Item
                    label={"Nombre de jours de validité"}
                    name="amountDays"
                    onChange={(e) => handleChangeInput(e)}
                    hasFeedback

                    rules={[
                        {
                            required: true,
                            message: 'Entrez le nombre!',

                        },
                    
                    ]}
                >
                    <Input name="amountDays" size="large" type="number"/>
                </Form.Item>



                <Form.Item
                    style={{ display: 'flex', justifyContent: 'end' }}
                >

                    <Button size="large" type="primary" htmlType="submit" >
                       Créer
                    </Button>

                </Form.Item>

            </Form>

        </>
    )
}

const InputImages = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 45px;

    .iconLibrary {
        transform: translateY(-8px);
        color: #45b061;

       
        &:hover{
            color:#6193c9;
        }
    }

    .iconPhoto {
        color: #5886b8;

        &:hover{
            color:#6193c9;
        }
    }

    

`

const FileUpload = styled.div`
    overflow: hidden;
    margin: 0 10px;
    position: relative;

    #file{
        position: absolute;
        top:0;
        left: 0;
        opacity: 0;



`

export default CreateCode