
import { DeleteData, EditData } from '../actions/globalTypes';
import { PARTNERSHIP_TYPES } from '../actions/partnershipActions';

const initialState = {
    loading: false,
    partnerships: [],
    count: 0,
    page: 0,
    n: 0,
}



const partnershipReducer = (state = initialState, action) => {
    switch (action.type){
        case PARTNERSHIP_TYPES.GET_PARTNERSHIP:
            return {
                ...state,  
                partnerships:  action.payload.data.invitations,
                count: action.payload.data.count,
                page:action.payload.data.page,
                n:action.payload.data.n

            }




        case PARTNERSHIP_TYPES.LOADING:
                return {
                    ...state,
                    loading: action.payload
                };

   

        default:
            return state;
    }
}


export default partnershipReducer
