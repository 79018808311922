import { Button, Form, Input, Progress, Select } from 'antd';
import React, { useEffect, useState } from 'react'

import CreateAds from './CreateAds';

const FormCreateAds = ({ad}) => {
    const [formData, setFormData] = useState(ad?ad:null)

    const [step, setStep] = useState(1);

    useEffect(() => {
   
    
      return () => {
        setFormData(null)
      }
    }, [])
    

    

    return (

        <>
            <
            >

                {step === 1 && 
                    <CreateAds  isEdit={ad?true:false} formData={formData} setFormData={setFormData} step={step} setStep={setStep} />
                }

        
 

        
            </>

        </>
    )
}

export default FormCreateAds