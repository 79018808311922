import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Modal from '../../../utils/Modal/Modal'
import Logo from '../../../../assets/logo-violet.png'
import { Button, Form, Input, Select } from 'antd'

import { useDispatch, useSelector } from 'react-redux'
import { createSubscription } from '../../../../redux/actions/subscriptionAction'
import { getCheckoutLink, getPreviewInvoice } from '../../../../redux/actions/stripeAction'
const ModalCreateSubscription = () => {

    const { auth, pages, stripe } = useSelector(state => state);
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({});
    const [step, setStep] = useState(1)
    const [previewInvoice, setPreviewInvoice] = useState(null);


    useEffect(async () => {
      if(stripe.subscriptions && stripe.subscriptions.data.length > 0){
       let res = await  dispatch(getPreviewInvoice({id:pages.activePage.customerId, content:{price:"price_1ORwJFDYl0FIlCROQU6FZHYo", qty:stripe.subscriptions.data[0].quantity + 1}, auth}))
       setPreviewInvoice(res)
        }else{
            let res = await  dispatch(getPreviewInvoice({id:pages.activePage.customerId, content:{price:"price_1ORwJFDYl0FIlCROQU6FZHYo", qty:1}, auth}))
            setPreviewInvoice(res)
        }
    }, [])
    

    const handleChangeInput = e => {
        const { id, value } = e.target
        setFormData({ ...formData, [id]: value })
    }


    const handleFirstSubmit = () => {
        if(stripe.subscriptions && stripe.subscriptions.data.length > 0){
            handleSubmit()
        }else{
            setStep(2)
        }
    }

    const handleSubmit = async () => {

        if (stripe.subscriptions.data.length > 0) {
            if (pages.activePage.typeCompany === 'inde' || pages.activePage.typeCompany === 'franchise') {
                formData.company = pages.activePage._id;
            }
            formData.subscriptor = pages.activePage._id
            formData.subscriptionStripe = stripe.subscriptions.data[0].id

            dispatch(createSubscription({ content: formData, mailing: true, auth }))
        } else {

            try {
                formData.status = "unpaid";
                if (pages.activePage.typeCompany === 'inde' || pages.activePage.typeCompany === 'franchise') {
                    formData.company = pages.activePage._id;
                }
                formData.subscriptor = pages.activePage._id
                let res = await dispatch(createSubscription({ content: formData, mailing: false, auth }));
                console.log(res)
                const content = { customerId: pages.activePage.customerId, priceId: "price_1ORwJFDYl0FIlCROQU6FZHYo", qty: 1, accountId: res.data.newSubscription._id }
                console.log(content)
                let resLink = await dispatch(getCheckoutLink({ content, auth }));
                console.log(resLink)
                window.location.href = resLink.data.session.url
            } catch (error) {
                // Handle any errors that occurred during subscription creation
                console.error("Error creating subscription:", error);
            }
        }

        /*         if (pages.activePage.typeCompany === 'inde' || pages.activePage.typeCompany === 'franchise') {
                    formData.company = pages.activePage._id;
                }
                formData.subscriptor = pages.activePage._id
                //formData.subscriptionStripe = stripe.subscriptions.data[0].id
        
                dispatch(createSubscription({ content: formData, auth })) */

    }

    return (
        <Modal modalName={"createSubModal"}  >
            {step === 1 && <Container>





                <Form
                    onFinish={() => { handleFirstSubmit() }}
                    layout="vertical"
                    className='formDiv'
                    style={{ width: "100%" }}
                >
                    <img className="logo" src={Logo} alt='logo' />

                    <div  style={{ textAlign: "center" }}>
                        {stripe.subscriptions && stripe.subscriptions.data.length > 0 ? <h2>Ajouter un abonnement</h2> : <h2>Vous n'avez pas encore d'abonnement !</h2>}

                        <span className='span-text' style={{ textAlign: "center" }}>{stripe.subscriptions && stripe.subscriptions.data.length > 0 ?"Pour créer le compte salarié, renseignez l’email du destinataire qui profitera de Behandy !":"Pour créer votre premier compte salarié, renseignez l’email du destinataire qui profitera de Behandy !"}</span>
                    </div>


                    <div style={{ width: "100%" }}>

                        {
                            pages.activePage.typeCompany === "multi" &&
                            <Form.Item
                                className='item'

                                name="company" label="Etablissement" rules={[{ required: true, message: 'Sélectionnez un établisssement!' }]}>
                                <Select onChange={(e) => { setFormData({ ...formData, ['company']: e }) }} size="large" placeholder="Sélectionnez un établissement" dropdownStyle={{ zIndex: "100000" }}>
                                    <Select.Option value={pages.activePage._id}>{pages.activePage.name}</Select.Option>

                                    {
                                        pages.activePage.childrenPages.map((page) => (
                                            <Select.Option value={page._id}>{page.name}</Select.Option>

                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        }


                        <Form.Item
                            label="Email"
                            name="email"
                            className='item'
                            onChange={(e) => handleChangeInput(e)}
                            rules={[
                                {
                                    required: true,
                                    message: 'Entrez l\'email!',
                                },
                                {
                                    max: 140,
                                    message: 'L\'email ne peut pas dépasser 140 caractères!',
                                }
                            ]}
                        >
                            <Input size="large" maxLength={140} />
                        </Form.Item>


                    </div>

                    {previewInvoice && <div className='price'>
                            <span className='price-span'>{previewInvoice.amount_due/100}€</span>
                            <span className='month-span'>/mois</span>

                        </div>}
                        <span className='span-subtext' style={{textAlign:"center"}}>Ceci est le prix mis-à-jour de votre abonnement (seul les comptes actifs sont pris en compte). </span>


                    <Form.Item className="item-next " style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button className="btn-blue" size="large" shape="round" type="primary" htmlType="submit">
                            {stripe.subscriptions && stripe.subscriptions.data.length > 0 ?"Mettre à jour l'abonnement": "Suivant"}


                        </Button>
                    </Form.Item>
                </Form>



            </Container>}

            {step === 2 && <Container>




                <div className='formDiv'  style={{ textAlign: "center" }}>
                     <img className="logo" src={Logo} alt='logo' />

                    <div >
                        {stripe.subscriptions && stripe.subscriptions.data.length > 0 ? <h2>Ajouter un abonnement</h2> : <h2>Finalisons la création de votre premier compte salarié !</h2>}

                        <span className='span-text'>Pour activer votre premier compte salarié, <b>vous devez souscrire un abonnement.</b> </span>
                        </div>


                        {previewInvoice && <div className='price'>
                            <span className='price-span'>{previewInvoice.amount_due/100}€</span>
                            <span className='month-span'>/mois</span>

                        </div>}
                        <span className='span-subtext'>Vous serez redirigé vers un formulaire de paiement Stripe sécurisé. </span>

                    <Button className="btn-blue" size="large" shape="round" type="primary" onClick={()=>handleSubmit()}>
                            Souscrire à un abonnement


                    </Button>


                </div>
          


            </Container>}
        </Modal>
    )
}

const Container = styled.div`
    display:flex;
    position: relative;
    flex-direction: column;
    align-items:center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  
    padding: 5% 15%;

    h2{
        font-size:1.2em;
        font-weight: 900;
        color: #585858;
        text-align:center;
        margin-top: 20px;
        margin-bottom: 30px;

    }

    .logo{
        width: 150px;
    }


    .formDiv{
        width: 100%;
        height: 100%;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;


        .item {
            width: 100%;
        }

        .price{
background: linear-gradient(90deg, rgba(255,160,0,1) 0%, rgba(255,120,181,1) 100%);
            width: fit-content;
            margin: 0px auto;
            padding: 10px;
            border-radius: 10px;

            .price-span{
                color:white;
                font-size: 2em;
                font-weight: 900;

            }
            .month-span{
                color:white;
                font-size: 1em;
                margin-left: 5Px;
                
            }
        }
    }

    .span-text{
        color: #969696;
        
    }

    .span-subtext{
        color: #969696;
        font-size: 0.9em;
        font-style:italic;
    }
`


export default ModalCreateSubscription