import React, { useEffect, useState } from 'react'
import { isInArray } from '../../../utils/function'
import { PanelExplanation } from '../BigScreenCompanyPage'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { getSubscriptions } from '../../../redux/actions/subscriptionAction'
import { NoResults } from '../AdminsManagementComponents'
import { Player } from '@lottiefiles/react-lottie-player'
import EmptyAnimation from '../../../assets/empty.json'
import LoadIcon from '../../../images/loading.gif'
import { ShowMore } from '../../../pages/home'
import { getPages } from '../../../redux/actions/pageAction'
import { Button } from 'antd'
import { IoMdAdd } from 'react-icons/io'
import { GLOBALTYPES } from '../../../redux/actions/globalTypes'
import ModalSendPartnerInvitation from './ModalSendPartnerInvitation'
import { SubItemMenu, SubMenu } from '../avantages/AvantagesComponents'
import MyPartners from './MyPartners'
import MyPartnersDemands from './MyPartnersDemands'
import InvitationModal from './InvitationModal'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
const Partnerships = ({ company }) => {
    const { auth, modal } = useSelector(state => state)
    const [category, setCategory] = useState(1)
    const dispatch = useDispatch();
    const history = useHistory()
    useEffect(() => {
        if(!isInArray(company.admins, auth.user._id)){
            history.push('/')
        }
    }, [])
    

/*     useEffect(() => {
        let filterPages = {order: 'ASC'}
          dispatch(getPages({filterPages,auth}))
        }, []) */
        

    return (
        <Container>

            {modal.typeModal ==='createModalSendInvitation' && <ModalSendPartnerInvitation/>}
            {modal.typeModal ==='invitationModal' && <InvitationModal/>}

            {isInArray(company.admins, auth.user._id) && <PanelExplanation>
                <p>Dans cet espace, vous pouvez ajouter les entreprises avec qui vous avez travaillez et qui pourraient proposer des réductions exclusives à vos salariés (ex: boucherie, primeur, boulangeries...)</p>
            </PanelExplanation>}


           {isInArray(company.admins, auth.user._id) && <div style={{ display: "flex", gap: '5px', alignSelf: 'flex-end'}}>
            <Button type="primary" onClick={() => dispatch({ type: GLOBALTYPES.MODAL,payload: {typeModal:"createModalSendInvitation", open:true}})} style={{background:'var(--color-company)'}} icon={<IoMdAdd />} size={'large'}></Button>
            </div>}


            <SubMenu>
                <SubItemMenu onClick={() => setCategory(1)} active={category === 1 ? true : false}>Mes partenaires </SubItemMenu>
                <SubItemMenu onClick={() => setCategory(2)} active={category === 2 ? true : false}>Mes demandes de partenariat</SubItemMenu>
            </SubMenu>

            {
                category === 1 && <MyPartners/>
            }

            {
                category === 2 && <MyPartnersDemands/>
            }
   {/*          <CompaniesDiv>

                {
                    pages.loading
                        ? <div className="icon-loading"><img src={LoadIcon} alt="loading" /></div>
                        : (pages.count === 0)
                            ? <NoResults>
                                <Player
                                    autoplay
                                    loop
                                    src={EmptyAnimation}
                                    style={{ height: '100%', width: '100%' }}
                                >
                                </Player>
                            </NoResults>
                            : <ItemsContainer>
                                {
                                    pages.pages.map((page) => (
                                      <CompanyItem page={page}/>
                                    ))
                                }

                            </ItemsContainer>
                }
                {
                    (pages.page * pages.n) < pages.count &&
                    <ShowMore>Voir plus</ShowMore>

                } 
            </CompaniesDiv> */}





        </Container>
    )
}

const Container = styled.div`
  display:flex;
  margin-top: 80px;


  flex-direction:column;
  gap: 20px;
h3{
  color: #F87778;
}



  
  @media only screen and (max-width: 768px) {  
    margin-top: 0px;
  }

`
const CompaniesDiv = styled.div`
    display:flex;
    flex-direction:column;
    gap: 15px;

    .icon-loading{
        margin: 50px auto;
    }
`

const ItemsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-gap: 20px;

        @media (max-width: 1200px) {

            grid-template-columns: repeat(2, 1fr);

        }

  

       

`



export default Partnerships