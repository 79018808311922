import React, {  useState } from 'react'
import styled from "styled-components"
import { BiSearch } from "react-icons/bi";
import { GLOBALTYPES } from '../../redux/actions/globalTypes';
import { useDispatch, useSelector } from 'react-redux';
import { updateFilterDeal2 } from '../../redux/actions/filterDealActions';
import { useHistory } from 'react-router-dom';

const Searchbar = () => {
  
  const [search, setSearch] = useState('')
  const {  filterDeal } = useSelector(state => state)
  const dispatch = useDispatch()
  const history = useHistory()

  

  const searchDatas = async () => {

    try {

            //const res = await getDataAPI(`deal?search=${search}`, auth.token)
            //setDatas(res.data.users)
          let filter = {};

          if(filterDeal.filter){
            filter = filterDeal.filter
            filter["search"] = search
          }else{
            filter["search"] = search
          }

          console.log(filter)
          dispatch(updateFilterDeal2({filter}))

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, payload: {error: err.response.data.msg}
        })
    }
  }
    const handleSearch = (e) => {
      console.log("search")

      e.preventDefault()
      searchDatas()
      if(window.location.pathname !== '/'){
        history.push('/')
      }
    }


  return (
    <Container>
      <CenterDiv>
          <BiSearch className='icon'/>
          <form onSubmit={handleSearch}>
          <input type="text" name="search" value={search}  placeholder="Entrez pour rechercher"
            onChange={e => setSearch(e.target.value)} autoComplete="off"/>
          </form>
   
      </CenterDiv>
    </Container>
  )
}


const Container = styled.div`
    display:flex;
    flex: 1;
    background : white;
    justify-content: center;
    padding: 1%;
    border-radius: 10px;

    z-index: 1000;
    @media only screen and (max-width: 768px) {
      padding: 2%;
    }

`
const CenterDiv = styled.div`
    display:flex;
    align-items: center;

    input {
      padding: 0;
      background: none;
      border: none;
      border-radius: 0;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-size: 0.9em;
    }
    
    .icon{
      color: #C0C0C0;
      font-size:  1.1em;
      margin-right: 5px;
    }
`

export default Searchbar