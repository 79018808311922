import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'antd'
import { GLOBALTYPES } from '../../../redux/actions/globalTypes'

const InvitationPreviewCardMobile = ({ invitation, page, status }) => {
    const dispatch = useDispatch();
    const history = useHistory();


    return (
        <>
            {page && <Card >
                <Left>
                    {
                        page.avatar ? <img src={page.avatar} onClick={() => window.location.href = `/company/${page._id}`} />
                            : <img src="https://behandy-front.s3.eu-west-3.amazonaws.com/color-gd67b3d48d_640.png" />
                    }
                </Left>
                <Center>
                        <h2 onClick={() => window.location.href = `/company/${page._id}`} >{page.name}</h2>
                        {
               status && status==='received'&&
            <Button style={{alignSelf:'end'}} type="primary" onClick={() => dispatch({ type: GLOBALTYPES.MODAL,payload: {typeModal:"invitationModal",status:status, invitation: invitation, open:true}})}>Voir proposition</Button>

            }

         
                    {(invitation && invitation.status ==='pending' && status==='sended')  && <span className='answer'>En attente</span>}


                </Center>
                <Right>
  {/*                   <ButtonLike>
                        {
                            deal.company && !isInArray(deal.company.admins, auth.user._id) &&

                            <LikeButton
                                isLike={isLike}
                                handleLike={handleLike}
                                handleUnLike={handleUnLike}
                                className="icon like"
                            />

                        }</ButtonLike>
 */}
                </Right>
            </Card>}
        </>
    )
}


const Card = styled.div`
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;    display:flex;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    background: white;
    padding: 4% 4%;
    position:relative;

`

const Left = styled.div`

    img{
        width: 45px;
        height: 45px;
        object-fit: cover;
        border-radius: 10px;
        
    }

`

const Center = styled.div`
    display:flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    gap:10px;
    margin-left: 5%;
    text-overflow: ellipsis;
    position:relative;
    overflow: hidden; 
    white-space: nowrap;
    z-index: 10;
    height:100%;


    h2{
        margin:0px;
        padding: 0px;
        font-size: 0.9em;
        font-weight: 700;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: calc(80%);
    }

    .answer{
        position: absolute;
        bottom: 5px;
        right: 5px;
        color: #757474;
        font-size: 0.9em;
        font-style: italic;
    }

   



`

const Right = styled.div`
     display:flex;
     align-items: flex-start;
     height: 100%;
     .plus {
         position: absolute;
         left: -5px;
         font-size: 1.5em;
         color:#757474;
         border-radius: 50%;
         padding: 2px;

         &:hover{
             cursor:pointer;
             background : #C0C0C0;

         }
     }
`



export default InvitationPreviewCardMobile