import { Button, Form, Input, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react'
import SearchAddress from '../../../utils/SearchAddress';
import { useDispatch, useSelector } from 'react-redux';
import { createDeal, updateDeal } from '../../../../redux/actions/dealAction';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { MdPhotoLibrary } from 'react-icons/md';
import { GLOBALTYPES } from '../../../../redux/actions/globalTypes';
import { imageShow, videoShow } from '../../../../utils/mediaShow';

const FormCreateDeal = ({deal}) => {

    const [formData, setFormData] = useState(deal?deal:null)
    const dispatch = useDispatch();
    const  {auth, theme, datas} = useSelector(state=>state)
    const  {id} = useParams();
    const tags = [
        { label: 'Séjourner', value: "sleep" },
        { label: 'Se restaurer', value: "eat" },
        { label: 'Boire un verre', value: "drink" },
        { label: 'S\'amuser', value: "fun" },
        { label: 'Se détendre', value: "relax" },
        { label: 'Se faire plaisir', value: "pleasure" },
    ]

    const [localisationText, setLocalisationText] = useState("");
    const [localisationObject, setLocalisationObject] = useState(null);
    const [tagsV, setTags]= useState([]);
    const [images, setImages] = useState([])

    useEffect(() => {
        if(formData && formData.localisation){
            setLocalisationText(formData.localisation.formatted_address)
            setLocalisationObject(formData.localisation)
        }

    }, [])

    const onFinish = () => {
        formData.localisation = localisationObject;
        formData.tags = tagsV
        formData.typeDeal = "partner";
        formData.pages = [id]
        console.log(formData);

        if(deal){
            dispatch(updateDeal({id:deal._id, content: formData, images:images, files: [], auth})) 

        }else {
            dispatch(createDeal({ content: formData, images:images, files: [], auth})) 
        }

    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleAddressChange = (newAddress) => {

        setLocalisationText(newAddress.formatted_address) 
        const { formatted_address,address_components, geometry, geoFormat } = newAddress;
        const newObject = { formatted_address,address_components, geometry, geoFormat };
        setLocalisationObject(newObject)
    
    
    }

    const handleChangeInput = e => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })

    }

    const handleChange = (value) => {
        console.log(`selected ${value}`);
        setTags(value)
      };
      
   

    const handleTagChange = (value) => {
        setTags(value);
      };

      const deleteImages = (index) => {
        const newArr = [...images]
        newArr.splice(index, 1)
        setImages(newArr)
    }


    const handleChangeImages = e => {
        const files = [...e.target.files]
        let err = ""
        let newImages = []

        files.forEach(file => {
            if (!file) return err = "File does not exist."

            if (file.size > 1024 * 1024 * 2) {
                return err = "The image/video largest is 2mb."
            }

            return newImages = [file]
        })
        console.log(files)

        if (err) dispatch({ type: GLOBALTYPES.ALERT, payload: { error: err } })
        setImages(newImages)
    }
    const filters = datas.filters.map(filter => ({ value: filter.id, label: filter.name }));

    return (
        <Form
            name="basic"
            layout='vertical'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            initialValues={deal?{...deal}: null}

        >

            <Form.Item
                label={"Offre"}
                name="offer"
                onChange={(e) => handleChangeInput(e)}
                hasFeedback

                rules={[
                    {
                        required: true,
                        message: 'Entrez votre offre!',

                    },
                    {
                        max: 120,
                        message: 'L\'offre ne peut pas dépasser 120 caractères!',
                    }
                ]}
            >
                <Input name="offer" size="large" maxLength={120} />
            </Form.Item>


            <Form.Item
                    label="Type(s) de promo"
                    name="tags"



                    required={true}
                    rules={[
                        {
                            validator: (_, value) =>
                                tagsV.length > 0 ? Promise.resolve() : Promise.reject(new Error('Veuillez choisir un tag!'))
                        }
                    ]}
                >

                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Choississez au moins un type"
                        onChange={handleChange}
                        options={filters}
                        size="large"
                        dropdownStyle={{ zIndex: "100000" }}
                        />
                </Form.Item>


            <Form.Item
                label="Localisation"
                name="adress"

            >
                <SearchAddress onAddressChange={handleAddressChange} address={localisationText} setAddress={setLocalisationText} />


            </Form.Item>





            <Form.Item
                label="Description"
                name="description"
                rules={[
                    {
                        required: true,
                        message: "Entrez une description!",

                    },
                    {
                        max: 320,
                        message: 'La description ne peut pas dépasser 320 caractères!',
                    }
                ]}
                hasFeedback
                onChange={(e) => handleChangeInput(e)}

            >
                <TextArea name="description" size='large' maxLength={320} showCount />
            </Form.Item>

            <Form.Item
                label={"Site internet"}
                name="website"
                onChange={(e) => handleChangeInput(e)}
                hasFeedback

        
            >
                <Input name="website" size="large" maxLength={120} />
            </Form.Item>


            <Form.Item name="mode" label="Mode"
              rules={[
                {
                  required: true,
                  message: 'Veuillez sélectionner un mode de réservation!', // Custom message for the required rule
                },
              ]}
              
            >
                <Select                     placeholder="Sélectionnez un mode"
 size="large" onChange={(e) => { setFormData({ ...formData, ['mode']: e }) }} >
           
                    <Select.Option value="code">Code promo à utiliser en ligne</Select.Option>

                </Select>
            </Form.Item>

       { (formData && formData.mode && formData.mode ==="code" ) &&  <Form.Item
                label={"Code promo"}
                name="modeInfos"
                onChange={(e) => handleChangeInput(e)}
                hasFeedback

                rules={[
                    {
                        required: true,
                        message: 'Entrez votre code!',

                    },
                    {
                        max: 120,
                        message: 'Le code ne peut pas dépasser 120 caractères!',
                    }
                ]}
            >
                <Input name="modeInfos" size="large" maxLength={120} />
            </Form.Item>
}
<Form.Item
                    label="Photo de présentation de l'offre"
                    name="photo"

                >


                    <ShowImages>
                        {
                            images.map((img, index) => (
                                <div key={index} id="file_img">
                                    {
                                        img.camera ? imageShow(img.camera, theme)
                                            : img.url
                                                ? <>
                                                    {
                                                        img.url.match(/video/i)
                                                            ? videoShow(img.url, theme)
                                                            : imageShow(img.url, theme)
                                                    }
                                                </>
                                                : <>
                                                    {
                                                        img.type.match(/video/i)
                                                            ? videoShow(URL.createObjectURL(img), theme)
                                                            : imageShow(URL.createObjectURL(img), theme)
                                                    }
                                                </>
                                    }
                                    <CrossImage onClick={() => deleteImages(index)}>&times;</CrossImage>
                                </div>
                            ))
                        }
                    </ShowImages>
                    <InputImages>
                        <>

                            <FileUpload>
                                <MdPhotoLibrary className="iconLibrary" />
                                <input type="file" name="file" id="file"
                                    multiple  accept=".jpg, .jpeg, .png"  onChange={handleChangeImages} />
                            </FileUpload>
                        </>


                    </InputImages>
                </Form.Item>




            <Form.Item
                style={{ display: 'flex', justifyContent: 'end' }}
            >
                <Button type="primary" htmlType="submit">
                    {deal?"Modifier":"Créer"}
                </Button>
            </Form.Item>
        </Form>
    )
}


const InputImages = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 45px;

    .iconLibrary {
        transform: translateY(-8px);
        color: #45b061;

       
        &:hover{
            color:#6193c9;
        }
    }

    .iconPhoto {
        color: #5886b8;

        &:hover{
            color:#6193c9;
        }
    }

    

`

const FileUpload = styled.div`
    overflow: hidden;
    margin: 0 10px;
    position: relative;

    #file{
        position: absolute;
        top:0;
        left: 0;
        opacity: 0;



`

export const ShowImages = styled.div`

    max-height: 270px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    place-items: center;
    padding: 10px 0;
    grid-gap: 10px;

    #file_img{
        position: relative;
        width: 100%;
        height: 100%;
    }

    #file_img img, #file_img video{
        display: block;
        object-fit: contain;
        width: 100%;
        height: 100%;
        max-height: 100px;
    }
`

export const CrossImage = styled.div`
    position: absolute;
    top: -1px;
    right: -2px;
    z-index: 4;
    background: white;
    color: crimson;
    padding: 3px 7px;
    border: 1px solid crimson;
    border-radius: 50%;
    font-size: 10px;
    font-weight: bolder;
    cursor: pointer;

`
export default FormCreateDeal