
import { EVENT_TYPES } from '../actions/eventAction';
import { DeleteData, EditData } from '../actions/globalTypes';

const initialState = {
    loading: false,
    events: [],
    count: 0
}



const eventReducer = (state = initialState, action) => {
    switch (action.type){
        case EVENT_TYPES.GET_EVENTS:
            return {
                ...state,  
                events:  action.payload.data.events,
                count: action.payload.data.count,
                page:action.payload.data.page,
                n:action.payload.data.n

            }
    
        
        
        case EVENT_TYPES.GET_MORE_EVENTS:

                return {
                    ...state,  
                    events:  state.events.concat( action.payload.data.events),
                    count: action.payload.data.count,
                    page:action.payload.data.page,
                    n:action.payload.data.n
    
                }

        case EVENT_TYPES.GET_EVENTS_BY_COMPANY:
                return {
                    ...state,  
                    events:  action.payload.data.events,
                    count: action.payload.data.count
        }
        
        case EVENT_TYPES.GET_EVENTS_BY_USER:
            return {
                ...state,  
                events:  action.payload.data.events,
                count: action.payload.data.count
    }
    
        case EVENT_TYPES.GET_EVENT:
                return {
                    ...state,  
                    current_event:  action.payload.data.event,
                }

        case EVENT_TYPES.LOADING:
                return {
                    ...state,
                    loading: action.payload
                };

        case EVENT_TYPES.DELETE_EVENT:
                    return {
                        ...state,
                        events: DeleteData(state.events, action.payload._id)
                    };
        case EVENT_TYPES.UPDATE_EVENT:
                        return {
                            ...state,
                            events: EditData(state.events, action.payload._id, action.payload)
                        };
        default:
            return state;
    }
}


export default eventReducer
