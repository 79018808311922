import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {BsThreeDotsVertical} from 'react-icons/bs'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {MdDelete, MdModeEdit} from 'react-icons/md'
import useWindowDimensions from '../../../utils/useWindowsDimensions'
import { GLOBALTYPES } from '../../../redux/actions/globalTypes'
import { likeEvent, unLikeEvent } from '../../../redux/actions/eventAction'
import { saveEvent, unSaveEvent } from '../../../redux/actions/pageAction'
import LikeButton from '../../utils/LikeButton'
import { isInArray } from '../../../utils/function'
import moment from 'moment'
const EventPreviewCardMobile = ({typePage, event}) => {
    const {auth} = useSelector(state=>state)
    const dispatch = useDispatch();
    const [isLike, setIsLike] = useState(false)
    const [loadLike, setLoadLike] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    const { width } = useWindowDimensions();

    const history = useHistory();

    useEffect(() => {

        return () => {
            setIsLike(false)
        };
    }, [])


    useEffect(() => {
        if( event.likes){
            if ((event.likes.find(like =>  (like._id === auth.user._id))||(event.likes.find(like =>  (like === auth.user._id))))) {
                setIsLike(true)
            } else {
                setIsLike(false)
            }
        }


    }, [event])




    const handleLike = async (e) => {
        e.stopPropagation()
        if (loadLike) return;

        setLoadLike(true)
        await dispatch(likeEvent({ event, auth, typePage }))
        await dispatch(saveEvent({ event, auth }))

        setLoadLike(false)
    }

    const handleUnLike = async (e) => {
        e.stopPropagation()
        if (loadLike) return;
        setLoadLike(true)
        await dispatch(unLikeEvent({ event, auth , typePage}))
        await dispatch(unSaveEvent({ event, auth }))


        setLoadLike(false)
    }

 
      
    

    


    const redirect = () => {
        dispatch({ type: GLOBALTYPES.MODAL,payload: {typeModal:"createDealModal", open:true,  event: event}})

      }

    return (
        <Card onClick={() =>  history.push(`/event/${event._id}`)}>
           {event.company && <Left>
                <img className='avatar' src={event.company.avatar} alt={"logo de " + event.company.name} />
                <h3>{event.company.name}</h3>
                
            </Left>}
            <Center> 
                <div>
                    <h2>Évènement</h2>
                    <h3>{event.title}</h3>
                    <h4>{moment(event.date).format('DD/MM/YY')} {event.time &&  "-"} {event.time}</h4>
                </div>  
                <span>{event.description}</span>


                <ButtonLike>
                   {event.company&&
                     !isInArray(event.company.admins, auth.user._id )?

                  <LikeButton
                    isLike={isLike}
                    handleLike={handleLike}
                    handleUnLike={handleUnLike}
                    className="icon like"
                    />
                : <div style={{display:'flex', flexDirection:'column', fontSize:"1.4em"}}>
                    <BsThreeDotsVertical onClick={(e)=> {e.stopPropagation(e);  console.log("hello");setShowOptions(!showOptions)}}/>

                    {
                        showOptions?<> 
                        <MdModeEdit onClick={(e)=>{e.stopPropagation();redirect(e)}}/>

                        <MdDelete  onClick={(e)=> { e.stopPropagation(); dispatch({ type: GLOBALTYPES.MODAL,payload: {type:"event",typeModal:"delete",title:"Etes-vous sûr de vouloir supprimer cette évènement?", open:true, event:event}})}}/>
                        </>:<></>
                     
                    }



                </div>
            }</ButtonLike>

{event.localisation && <div className='localisation' >
                        <svg width="12" height="12" viewBox="0 0 304 432" xmlns="http://www.w3.org/2000/svg">
                            <path fill="#757474" d="M149 3q62 0 106 43.5T299 152q0 31-15.5 71.5t-37.5 75t-44 65t-37 48.5l-16 17q-6-6-16-18t-35.5-46.5t-45.5-67T16 224T0 152Q0 90 43.5 46.5T149 3zm0 202q22 0 38-15.5t16-37.5t-16-37.5T149 99t-37.5 15.5T96 152t15.5 37.5T149 205z" />
                        </svg>
                        <span>{event.localisation.formatted_address}</span>
                    </div>}

            </Center>
    
        </Card>
    )
}

const ButtonLike = styled.div`
    position:absolute;
    top: 5px;
    right: 5px;
`

const Card = styled.div`
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;    display:flex;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    background: white;
    overflow:hidden;
    margin-bottom: 2%;
    height: 100px;

`

const Left = styled.div`

position: relative;
background: linear-gradient(to right, #FFA000, #FF78B5);
height: 100%;
width: 30%;
text-align :center;
padding: 20px 10px;

display:flex;
align-items:center;
justify-content: center;
flex-direction: column;
gap: 5px;
.avatar{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 2px solid white;
}

h3{
    color:white !important;
    font-weight: 800;
    font-size: 0.8em;
    padding: 0px;
    margin: 0px;
}


.dealicon{
    position:absolute;
    top: 5px;
    left: 5px;
}


`

const Center = styled.div`
    display:flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 2%;
    height: 100%;
    overflow: hidden; 
    z-index: 10;
    position:relative;


    h2{
        margin:0px;
        padding: 0px;
        font-size: 0.9em;
        font-weight: 700;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: calc(90%);
    }

    h3{
        margin:0px;
        padding: 0px;
        font-size: 0.8em;
        font-weight: 400;
        color: #F87778;
        font-style: italic;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: calc(90%);
    }

    h4{
        margin:0px;
        padding: 0px;
        font-size: 0.7em;
        font-weight: 400;
    }
    span{
        margin:0px;
        margin-top: 5px;
        padding: 0px;
        font-size: 0.8em;
        font-weight: 700;
        color: #757474;
        display: block;
        height: 100%;
        overflow: hidden;
    }

    .icon-users{
        color:#757474;
        font-size: 1.2em;
    }

    
    .localisation{
        align-self: end;
        display:flex;
        align-items:center;
        span{
            display:flex;
            margin:0px;
            padding: 0px;
            font-size: 0.7em;
            font-weight: 700;
            color: #757474;
            margin-left: 2px;
    
        }
    }

`






export default EventPreviewCardMobile