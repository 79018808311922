import { Button, Checkbox } from 'antd'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { getDataAPI } from '../../../../../utils/fetchData'
import NoData from '../../../../profile/cv/NoData'
import Loading from '../../../../alert/Loading'
import { ClipLoader } from 'react-spinners'
const PageChoice = ({ step, onStepChange, formData, setFormData, type }) => {


    const {modal,auth} = useSelector(state=>state)

    const [selectedPage, setSelectedPage] = useState('');
    const [pages, setPages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(async() => {
        setIsLoading(true);
        try{
            const res = await getDataAPI(`/pagesByUserId`, auth.token)
            setPages(res.data.pages)
            setIsLoading(false)
        }catch(e){
            setPages([])
            setIsLoading(false)
        }
     

    }, [])

    const handleSelectPage = (pageId) => {
        setSelectedPage(pageId);
    }
    const handleNext = () => {
        if (!selectedPage) return; 

        setFormData({ ...formData, pages: [selectedPage], typeDeal: "company" });
        onStepChange(step + 1);
    }

  return (
    <Content>

        <HeaderModal>
            {
             <h2>{modal.deal?'Modifier':'Créer'} une promotion pour un partenaire  </h2>
            }

            <h3>Pour quelle page?</h3>

        </HeaderModal>

  
        {
            isLoading ? <EmptyPage>
            <ClipLoader
               color={"#F87778"}
               loading={isLoading}
               size={30}
               aria-label="Loading Spinner"
               data-testid="loader"
           />
           
           
           </EmptyPage>: <>
            
            
            { pages && pages.filter(page => page.typePage === 'company').length === 0 && 
        
        <EmptyPage>

        
            <NoData title={"Aucune page entreprise de créée"}/> 
        
       
        </EmptyPage>}


       {pages && pages.filter(page => page.typePage === 'company').length > 0 && <ChoicesDiv>
                {pages.filter(page => page.typePage === 'company').map((page) => (
                    <Choice key={page.id}>
                        <div className='div-left'> 
                            <img src={page.avatar} alt="avatar"/>
                            <span>{page.name}</span>
                        </div>
                        <Checkbox
                            checked={selectedPage === page._id}
                            onChange={() => handleSelectPage(page._id)}
                        />
                    </Choice>
                ))}
        </ChoicesDiv>}
            
            
            </>
        }




        
        <Button disabled={!selectedPage} onClick={ () => {handleNext()}} size="large" shape="round" type="primary" htmlType="submit">
            Suivant
        </Button>
        
    </Content>
  )
}

const Content = styled.div`
    display:flex;
    width: 100%;
    flex: 1;
    gap:20px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;


    .selectAll{
        display:flex;
        justify-content: end;
        width:100%;

        span{
            margin-left: 5px;
            font-size: 0.9em;
        }
    }



`

const EmptyPage = styled.div`

    display:flex;
    flex-direction:column;
    width: 100%;
    height: 100%;
    justify-content:center;
    align-items:center;
`

export const HeaderModal = styled.div`
    display:flex;
    flex-direction: column;

    margin: 20px 0px;

    h2{
        font-size:1.4em;
        font-weight: 900;
        color: #585858;
        text-align:center;
    }

    h3{
        font-size:1.1em;
        font-weight: 500;
        color: #585858;
        text-align:center;
    }

`
const ChoicesDiv = styled.div`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
        width: 100%;
        height: 100%;
        margin-bottom: 20px;

        @media screen and (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        } 

`

const Choice = styled.div`
    
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: flex-start;
    border-radius: 10px;
    width: 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;


    .div-left{
        display:flex;
        align-items: center;
    }
    .div-icon{
        background:red;
        padding: 5px;
        border-radius: 100%;
        .icon{
            color:white;
            font-size:1.4em;
        }

    }

    img{
        width: 35px !important;
        height: 35px !important;
    }
    span{
        font-size: 0.9em;
        margin-left: 5px;
    }
    transition: all 0.2s ease-in;

    &:hover{
        cursor: pointer;
        transform: scale(1);
    }

`
export default PageChoice