
import { USER_TYPES } from '../actions/userAction';

const initialState = {
    users: [],
    totalCount: 0,
    currentPage: 1,
    totalPages: 1,
    current_user: null,
}



const userReducer = (state = initialState, action) => {
    switch (action.type){
        case USER_TYPES.GET_USERS:
            return {
                ...state,  
                users:  action.payload.data.users,
                totalCount: action.payload.data.totalCount,
                currentPage:action.payload.data.currentPage,
                totalPages:action.payload.data.totalPages

            }
        case USER_TYPES.GET_USER:
                return {
                    ...state,  
                    current_user:  action.payload.data.user,
                }

        


        default:
            return state;
    }
}


export default userReducer
