import axios from 'axios'

export const getDataAPI = async (url, token) => {
    const res = await axios.get(`${process.env.REACT_APP_URL}/api/${url}`, {
        headers: { Authorization: token}
    })
    return res;
}

export const getDataAPIWithData = async ( url,content, token) => {
    const res = await axios.get(`${process.env.REACT_APP_URL}/api/${url}`, {
        headers: { Authorization: token}
    }, {params: {
        next_swastik: 'lets add something here'
      },})
    return res;
}

export const postDataAPI = async (url, post, token) => {
    const res = await axios.post(`${process.env.REACT_APP_URL}/api/${url}`, post, {
        headers: { Authorization: token}
    })
    return res;
}

export const postDataAPIWithoutToken = async (url, post) => {
    const res = await axios.post(`${process.env.REACT_APP_URL}/api/${url}`, post)
    return res;
}

export const putDataAPI = async (url, post, token) => {
    const res = await axios.put(`${process.env.REACT_APP_URL}/api/${url}`, post, {
        headers: { Authorization: token}
    })
    return res;
}

export const patchDataAPI = async (url, post, token) => {
    const res = await axios.patch(`${process.env.REACT_APP_URL}/api/${url}`, post, {
        headers: { Authorization: token}
    })
    return res;
}

export const deleteDataAPI = async (url, token) => {
    const res = await axios.delete(`${process.env.REACT_APP_URL}/api/${url}`, {
        headers: { Authorization: token}
    })
    return res;
}