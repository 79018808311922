import { Button} from 'antd'
import React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useEffect } from 'react'
import { createDeal, updateDeal } from '../../../../redux/actions/dealAction'
import DealPreviewCard from '../../../home/DealPreviewCard'
const FinalStep = ({ step, onStepChange, formData, setFormData }) => {

    const { modal, auth } = useSelector(state => state);
    const [images, setImages] = useState([])

    const dispatch = useDispatch();
    useEffect(() => {
      console.log(formData)
      if(modal.deal){

       /*  let deal = modal.deal
        console.log(deal.localisation)
        setLocalisationObject(deal.localisation)
        setFormData(deal)

        let dates = deal.date.replace(' ', '').split(',')
        let datesObj = [];
        dates.forEach(element => {
            datesObj.push({value: element, label:element})
        });
        setDateEdit(datesObj)
        
        setImages(...images, modal.deal.images)
        console.log(modal.deal.images)
        setSelectedDays(datesObj)
        setSelectedAmountPersons({value:deal.amountPersons, label: deal.amountPersons})
        setCurrentCompany(deal.company) */

    }else{
 
        setImages(formData.images)
    }
    
 
    }, [])

    
    
  
    const handleSubmit = (e) => {

        console.log(formData)
  
        e.preventDefault()
    }

    return (
        <Content>

            <Header>

                {
                    formData.target === "targeted" && <h2>{modal.deal?'Modifier':'Créer'}  une promotion ciblée</h2>
                }


                <h3>Finalisation</h3>

            </Header>


     
            <div style={{width: "250px"}}>
            <DealPreviewCard  deal={formData} mode="preview"/>

            </div>

            <Button size="large" shape="round" type="primary"  onClick={(e)=>handleSubmit(e)}>{modal.deal?'Mettre-à-jour': 'Poster'}</Button>




        </Content>
    )
}

const Content = styled.div`
    display:flex;
    width: 100%;
    flex: 1;

    flex-direction: column;
    justify-content: space-around;
    align-items: center;


    .selectAll{
        display:flex;
        justify-content: end;
        width:100%;
    }



`

const Header = styled.div`
    display:flex;
    flex-direction: column;

    h2{
        font-size:1.6em;
        font-weight: 900;
        color: #585858;
        text-align:center;
    }

    h3{
        font-size:1.3em;
        font-weight: 500;
        color: #585858;
        text-align:center;
    }

`

export default FinalStep