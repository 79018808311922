import React, { useState } from 'react';
import { Button, Form, Input, Steps } from 'antd';
import SearchAddress from '../../utils/SearchAddress';
import FormManagementCompanyPart1 from './FormManagementCompanyPart1';
import FormManagementCompanyPart2 from './FormManagementCompanyPart2';
import FormManagementCompanyPart3 from './FormManagementCompanyPart3';

const FormManagementCompany = ({company}) => {
    const [current, setCurrent] = useState(0);
    const [avatar, setAvatar] = useState('');

    const [formData, setFormData] = useState({...company})



    const onChange = (value) => {
        console.log('onChange:', value);
        if(value <  current){
            setCurrent(value)
        }
    };




    // Function to determine the status of each step
    const getStepStatus = (index) => {
        if (index === current) {
            return 'process';
        }  else {
            return 'wait';
        }
    };

    // Define the steps
    const steps = [
        { title: 'Étape 1' },
        { title: 'Étape 2' },
        { title: 'Étape 3' },
    ];

    return (
        <>
            <Steps
                type="navigation"
                current={current}
                onChange={onChange}
                className="site-navigation-steps"
            >
                {steps.map((step, index) => (
                    <Steps.Step
                        key={step.title}
                        title={step.title}
                        status={getStepStatus(index)}
                    />
                ))}
            </Steps>

            {
                current === 0 &&  <FormManagementCompanyPart1 avatar={avatar} setAvatar={setAvatar} setCurrent={setCurrent}  current={current} formData={formData} setFormData={setFormData} company={company} /> 
            }

{
                current === 1 &&  <FormManagementCompanyPart2 setCurrent={setCurrent}  current={current} formData={formData} setFormData={setFormData} company={company} /> 
            }

{
                current === 2 &&  <FormManagementCompanyPart3 avatar={avatar} setCurrent={setCurrent}  current={current}  formData={formData} setFormData={setFormData} company={company} /> 
            }
        </>
    );
}

export default FormManagementCompany;
