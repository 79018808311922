import { Button } from 'antd'
import React from 'react'
import { useState } from 'react';
import styled from 'styled-components'
import job from '../../assets/account/job.png'
import school from '../../assets/account/ecole.png'
import hotel from '../../assets/account/hotel.png'

const ChoiceActivity = ({formData, setFormData, step, setStep}) => {
    
    const [active, setActive] = useState('');


    const handleClick = (choice) => {
        setActive(choice);
        setFormData({ ...formData, target: choice });
    }

    return (
        <ContentModalAccount>
            <div className='titles'>
            <h2>Bonjour {formData.firstname}, ravi de vous rencontrer !</h2>
            <h4>Que faîtes-vous actuellement?</h4>

            </div>

        <ChoicesDivAccount>
                <ChoiceAccount key={Math.random()} onClick={() => handleClick("working")} active={active === 'working' ? true : false}>
                    <img src={hotel} alt='image target' />

                    <span>Je travaille dans l'hébergement-restauration</span>
                </ChoiceAccount>
                <ChoiceAccount key={Math.random()} onClick={() => handleClick("seeking")} active={active === 'seeking' ? true : false}>

                    <img src={job} alt='image target' />

                    <span>Je suis en recherche d'emploi</span>
                </ChoiceAccount>
                <ChoiceAccount key={Math.random()} onClick={() => handleClick("studying")} active={active === 'studying' ? true : false}>
                    <img src={school} alt='image target' />

                    <span>Je suis étudiant</span>
                </ChoiceAccount>
            </ChoicesDivAccount>

            
            <Button onClick={() => { if (active === '') return; setStep(step + 1) }} size="large" shape="round" type="primary" htmlType="submit">
                Suivant
            </Button>
        
        
        </ContentModalAccount>
    )
}

export const ContentModalAccount = styled.div`
    display:flex;
    width: 100%;
    flex: 1;

    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 20px;

    .titles{
        display:flex;
        gap: 0px;
        flex-direction: column;
        h2{
            font-size:1.4em;
            font-weight: 900;
            color: #585858;
            text-align:center;
            margin: 0px;
        }
        h4{
            margin: 0px;
            font-size:1.2em;
            font-weight: 500;
            font-style:italic;
            color: #585858;
            text-align:center;
        }
    }



`

export const ChoicesDivAccount = styled.div`
    
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 15px;
        width: 100%;
        @media screen and (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);

        } 

    


`

export const ChoiceAccount = styled.div`
    
    padding: 5px;
    aspect-ratio: 1 / 1; /* This makes the item a square */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    width: 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background: ${({ active }) => active ? 'var(--color-accent);' : ''};


    img{
        width: 45px !important;
        height: 45px !important;
    }

    span{
        margin: 0px;
        margin-top:10px;
        font-size:0.8em;
        text-align:center;
        color: ${({ active }) => active ? 'white' : '#717171'};

    }
    transition: all 0.2s ease-in;

    &:hover{
        cursor: pointer;
        transform: scale(1.1);
    }

`

export default ChoiceActivity