import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button } from 'antd'
import { postDataAPI } from '../../utils/fetchData'
import { toast } from 'react-toastify'

const EventCard = ({ event, heightCard }) => {
    const [step, setStep] = useState(1);
    const { auth } = useSelector(state => state)

    const [company, setCompany] = useState(null)
    const history = useHistory();
    const [URL, setURL] = useState("");

    useEffect(() => {
        if(event.company[0]){
            setCompany(event.company[0])
        }else{
            setCompany(event.company)

        }
     if (process.env.REACT_APP_URL) {
              setURL(process.env.REACT_APP_URL);
        }
      
    }, [])

    const nextStep = async() => {

        try{
            await postDataAPI(`/visitor`,{source:'event', event:event._id, company:company._id},auth.token)
            setStep(2);

        }catch{
            toast.error('Une erreur est survenue');
        }

    }

    
    return (
        <Card height={heightCard}>
           { company && <><Top >
                <div className='bigscreen bs-top'>
                    <img onClick={() => history.push(`/company/${company._id}`)} src={company.avatar} alt={"logo de " + company.name} />
                    <h3>{company.name}</h3>
                </div>

                <div className='mobile m-top'>
                    <h2 className='title'>Évènement</h2>

                    <h3 className='subtitle'>{event.title}</h3>
                </div>


            </Top>
            <Bottom>
                {
                    step === 1 && <>

                        <div>
                            <h2 className='bigscreen'>Évènement</h2>
                            <h3 className='bigscreen'>{event.title}</h3>
                            <h4>Date:{event.date} ({event.time})</h4>
                            {event.description && <p>Description: {event.description}</p>}

                        </div>
                        <Button onClick={() => nextStep()} type="default" size="large" style={{ margin: "0px auto" }}>Je suis intéressé?</Button>
                    </>
                }

{
                step === 2 && <Content>
                    <p>L’établissement “La Table d’Or” a bien été informé de votre intérêt pour l’évènement et a hâte de vous rencontrer en personne !</p>
                    <Center>
                    <svg xmlns="http://www.w3.org/2000/svg" width="84" height="84" viewBox="0 0 24 24" fill="none" stroke="#F87778" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" class="lucide lucide-calendar-check-2"><path d="M21 14V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h8"/><line x1="16" x2="16" y1="2" y2="6"/><line x1="8" x2="8" y1="2" y2="6"/><line x1="3" x2="21" y1="10" y2="10"/><path d="m16 20 2 2 4-4"/></svg>
                    </Center>
                    <p>Vous retrouverez cette évènement dans votre espace "employabilité"</p>
                    <Button  onClick={() => history.push(`/jobcenter`)} type='primary' style={{ width: "100%", marginTop:'15px' }} size='large'>Y aller</Button>

                </Content>
            }



            </Bottom></>}


        </Card>
    )
}



const Card = styled.div`
    display:flex;
    flex-direction: column;
    height: 100%;
    background : transparent;
    border-radius: 10px;
    overflow: hidden;
    @media screen and (max-width: 768px) {
        min-height: 400px;
    }

    .mobile{
        display:none;
    }

    .bs-top{
        flex-direction:column;
        align-items:center;
    }
    .bigscreen{
        display:flex;
   
    }

        @media screen and (max-width: 768px) {

        .bigscreen{
            display:none;
        }
        .mobile{
            display:flex;
        }


    }
`

const Content = styled.div`
    display:flex;
    width: 100%;
    height: 100%;
    justify-content:space-around;
    align-items:center;
    flex-direction:column;

    text-align: center;
    padding: 4% 8%;



    p{
        margin:0px;
        padding: 0px;
        color: #585858;
        font-size: 1em;
        font-weight: 400;
        font-style: italic;
    }




`

const Center = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    

`
const Top = styled.div`
    position: relative;

    background: linear-gradient(to right, #FFA000, #FF78B5);
    height: ${props => props.height ? props.height : "30vh"};

    display:flex;
    align-items:center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    img{
        width: 85px;
        height: 85px;
        border-radius: 50%;
        border: 2px solid white;
    }

    h3{
        color:white;
        font-weight: 800;
        font-size: 1.2em;
        padding: 0px;
        margin: 0px;
    }

    .m-top{
        flex-direction:column;
        justify-content:center;
        align-items:center;
        .title{
            color:white;
            font-weight: 800;
            font-size: 1.4em;
            padding: 0px;
            margin: 0px;
        }
        .subtitle{
            color:white;
            font-weight: 400;
            font-size: 1em;
            font-style:italic;
            padding: 0px;
            margin: 0px;
        }
    }




    .bg-top{
        flex-direction:column;
        justify-content:center;
        align-items:center;
    }


`

const Bottom = styled.div`
    overflow-wrap: break-word;
    position: relative;
    background : #F9F9F9;
    border: 1px solid #f2f2f2;
    min-height: 15vh;
    flex: 1;
    padding: 2% 4%;
    display:flex;
    justify-content: space-between;
    flex-direction: column;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    .title{
        margin:0px;
        padding: 0px;
        font-size: 1.2em;
        font-weight: 700;

        &:hover{
            cursor: pointer;
        }
    }

    h2{
        margin:0px;
        padding: 0px;
        font-size: 1em;
        font-weight: 800;
        color: #585858;
    }

    
    h3{
        margin:0px;
        padding: 0px;
        font-size: 1.1em;
        font-weight: 900;
        color: #F87778;
    }


    h4{
        margin:0px;
        padding: 0px;
        color: #757474;

        font-size: 0.9em;
        font-weight: 400;
    }

    h5{
        margin:0px;
        padding: 0px;
        margin-top: 5%;
        color: black;
        font-size: 0.9em;
        font-weight: 700;
    }



    p{
        margin:0px;
        margin-top: 5%;
        padding: 0px;
        font-size: 1em;
        font-weight: 400;
        color: #757474;
    }

    .icon-users{
        color:#757474;
        font-size: 1.2em;
    }




        margin-bottom: '50px';


    @media screen and (max-width: 768px) {
        .img-company{
           display:none;
        }

        .title{
            display:none;
        }

        h4{
            font-size: 0.8em;
        }
    } 

`

export default EventCard