import React, {useState} from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from "react-redux";
import {AiOutlineArrowLeft} from 'react-icons/ai'
import { GLOBALTYPES } from '../../redux/actions/globalTypes';
import FilterContent from './FilterContent';
const FilterMobile = () => {
  const {modal} = useSelector(state=>state);
  const dispatch = useDispatch();
  const [saveChoice, setSaveChoice]  = useState(null)

  return (
    
    <>
      
         { modal.typeModal === "filterModalMobile" &&
           <ContainerFilter>
             <Header>
                <AiOutlineArrowLeft  className="icon-left" onClick={()=>dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})}/>
                <h2>Affiner</h2>

             </Header>
              <FilterContent/>
           </ContainerFilter>
         
         }
    
    
    </>
  )
}

const ContainerFilter = styled.div`
    position:fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    background: white;
    z-index: 10000;

    display:flex;
    flex-direction: column;
    padding: 5% 5%;
`
const Header = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 7%;

  h2{
    margin: 0px;
    color:#F87778;
    font-weight: 700;
    font-size: 1em;
  }

  .icon-left{
    position: absolute;
    left: 5%;
    font-size: 1.5em;
    color:#F87778;

  }

`
export default FilterMobile