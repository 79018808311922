import { FORMATIONS_TYPES } from '../actions/formationAction'
import { EditData, DeleteData } from '../actions/globalTypes'

const initialState = {
    loading: false,
    formations: [],
    result: 0,
    page: 2
}

const formationReducer = (state = initialState, action) => {
    switch (action.type){
        case FORMATIONS_TYPES.CREATE_FORMATION:
            return {
                ...state,
                formations: [action.payload, ...state.formations]
            };
        case FORMATIONS_TYPES.LOADING_FORMATION:
            return {
                ...state,
                loading: action.payload
            };

        case FORMATIONS_TYPES.GET_FORMATIONS:
            return {
                ...state,
                formations: action.payload.formations,
                result: action.payload.result,
                page: action.payload.page
            };
        case FORMATIONS_TYPES.GET_FORMATIONS_USER:
                return {
                    ...state,
                    formations: action.payload.formations,
                    result: action.payload.result,
                    page: action.payload.page
                };

        case FORMATIONS_TYPES.DELETE_FORMATION:
                    return {
                        ...state,
                       formations: DeleteData(state.formations, action.payload._id)
                   };
        case FORMATIONS_TYPES.UPDATE_FORMATION:
                       return {
                           ...state,
                           formations: EditData(state.formations, action.payload._id, action.payload)
                       };
        default:
            return state;
    }
}

export default formationReducer