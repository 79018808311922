import React, { useState } from "react";
import styled from "styled-components";
import { H2Title, InputStyled } from "../../../pages/myaccount";
import axios from 'axios'
import { useSelector } from "react-redux";
import { postDataAPI } from "../../../utils/fetchData";
const FormResetPassword = () => {
    const {auth} = useSelector(state=>state)
    const [password, setPassword] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [status, setStatus] = useState(null)

    const updatePassword = async () => {

        if(password !== passwordConfirm){
          setStatus({status: 400, data:"Mot de passe différent!"})
          return;
        }
        try{
            const res = await postDataAPI('/user/resetPassword',{password: password}, auth.token)
            if(res.status === 200){
               
                setStatus(res)
                
            }
        }
        catch(e){
            console.log(e.response)
            console.log(e.response.status)
            if (e.response.status === 400){
                setStatus(e.response)
            }
        }

        setPassword(null)
        setPasswordConfirm(null)
    
    }

  return (
    <ContentPassword>
      <h3>Modification mot de passe</h3>
      <H2Title>Nouveau mot de passe</H2Title>
      <InputStyled
        autoComplete="off"
        type="password"
        name="password"
        id="password"
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Nouveau mot de passe"
        required
      />
      <H2Title>Confirmation mot de passe</H2Title>
      <InputStyled
        autoComplete="off"
        type="password"
        name="confirmPassword"
        id="confirmPassword"
        onChange={(e) => setPasswordConfirm(e.target.value)}        
        placeholder="Mot de passe de confirmation"
        required
      />

      {status && status.status === 200  && <p className='msgResponse valid'>Votre mot de passe a été changé! Vous pouvez quitter cette page!</p>}
      {status && status.status === 400 && <p className='msgResponse error'> {status.data}</p>}
      <BeautifulButton style={{ marginTop: "4%" }} onClick={() => updatePassword()} disabled={status && status.status === 200? true: false}>Confirmer</BeautifulButton>
    </ContentPassword>
  );
};
const ContentPassword = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3% 0%;
  h3 {
    color: #585858;
    font-size: 1.1em;
    font-weight: 600;
    margin: 0px;
    margin-bottom: 1%;
  }

  .msgResponse{
    margin-top: 25px;
    margin-bottom: 25px;


    }

    .error {
        color: red;
    }

    .valid {
        color: green;
    }

    @media only screen and (max-width: 768px) {
      padding: 10% 0%;
      width: 90%;

    }

`;

export const BeautifulButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 3%;
  align-self: flex-end;

  background: var(--color-accent);
  color: white;
  border-radius: 5px;
  border: none;

  &:hover {
    background: var(--color-accent-hover);
  }

  &:focus {
    border: none;
    outline: none;
  }

  &:focus {
    outline: none !important;
  }

  &:disabled {
      background : #c4c4c4;
  }
`;
export default FormResetPassword;
