export const experiencesDatas = [
    {value:"Aucune expérience", label:"Aucune expérience"},
    {value:"Étudiant/Apprenti", label:"Étudiant/Apprenti"},
    {value:"0 - 2 ans", label:"0 - 2 ans"},
    {value:"2 - 5 ans", label:"2 - 5 ans"},
    {value:"10 ans et +", label:"10 ans et +"},

]


export const contractDatas = [
    {value:"CDI", label:"CDI"},
    {value:"CDD", label:"CDD"},
    {value:"Contrat en Alternance", label:"Contrat en Alternance"},
    {value:"Stage", label:"Stage"},
]

export const companyTypeDatas = [
    {value:"Hôtel", label:"Hôtel"},
    {value:"Hôtel-Restaurant", label:"Hôtel-Restaurant"},
    {value:"Restaurant", label:"Restaurant"},
    {value:"Camping", label:"Camping"},
    {value:"Village Vacances", label:"Village Vacances"},
    {value:"Bar-Café", label:"Bar-Café"},
    {value:"Boulangerie", label:"Boulangerie"},
    {value:"Pâtisserie", label:"Pâtisserie"},
    {value:"Boulangerie-Pâtisserie", label:"Boulangerie-Pâtisserie"},
    {value:"Boucheries", label:"Boucheries"},

]







export const profilsDatas = [
    {value:'Cuisine', label:'Cuisine'},
    {value:'Plonge', label:'Plonge'},
    {value:'Boulangerie', label:'Boulangerie'},
    {value:'Pâtisserie', label:'Pâtisserie'},
    {value:'Salle-Service', label:'Salle-Service'},
    {value:'Bar', label:'Bar'},
    {value:'Sommellerie', label:'Sommellerie'},
    {value:'Vente', label:'Vente'},
    {value:'Accueil / Réception', label:'Accueil / Réception'},
    {value:'Loge / Conciergerie', label:'Loge / Conciergerie'},
    {value:'Bagagiste ', label:'Bagagiste '},
    {value:'Maintenance ', label:'Maintenance '},
    {value:'Animation', label:'Animation'},
    {value:'Spa', label:'Spa'},
    {value:'Bassin', label:'Bassin'},
    {value:'Entretien', label:'Entretien'},
    {value:'Personnel d\'étage', label:'Personnel d\'étage'},
    {value:'Siège (Support, RH, marketing,...)', label:'Siège (Support, RH, marketing,...)'},
    {value:'Direction d\'établissement', label:'Direction d\'établissement'},
    {value:'Voiturier', label:'Voiturier'},


]




export const sectorsDatas = [
    {emoji:'🥗' ,value:'Cuisine', label:'Cuisine'},
    {emoji:'🍽️' ,value:'Plonge', label:'Plonge'},
    {emoji:'🥖' ,value:'Boulangerie', label:'Boulangerie'},
    {emoji:'🍰' ,value:'Pâtisserie', label:'Pâtisserie'},
    {emoji:'💁‍♂️' ,value:'Salle-Service', label:'Salle-Service'},
    {emoji:'🍹' ,value:'Bar', label:'Bar'},
    {emoji:'🍷' ,value:'Sommellerie', label:'Sommellerie'},
    {emoji:'👨‍💼' ,value:'Vente', label:'Vente'},
    {emoji:'🎭' ,value:'Animation', label:'Animation'},
    {emoji:'💆‍♀️' ,value:'Spa', label:'Spa'},
    {emoji:'🏊🏽' ,value:'Bassin', label:'Bassin'},
    {emoji:'🏊🏽' ,value:'Entretien', label:'Entretien'},
    {emoji:'🧹' ,value:'Personnel d\'étage', label:'Personnel d\'étage'},
    {emoji:'👩🏾‍💼' ,value:'Siège (Support, RH, marketing,...)', label:'Siège (Support, RH, marketing,...)'},
    {emoji:'👩‍💼' ,value:'Direction d\'établissement', label:'Direction d\'établissement'},
    {emoji:'🚗' ,value:'Voiturier', label:'Voiturier'},
    {emoji:'🤵',value:'Accueil / Réception', label:'Accueil / Réception'},
    {emoji:'👩‍💼',value:'Loge / Conciergerie', label:'Loge / Conciergerie'},
    {emoji:'💼',value:'Bagagiste ', label:'Bagagiste '},
    {emoji:'🔧', value:'Maintenance ', label:'Maintenance '},

]

export const noExpSkillDatas = [
    {emoji:'🥳' , value:"A l'animation, j'ai un sacré déhanché !", label:"A l'animation, j'ai un sacré déhanché !"},
    {emoji:'👨‍🍳' , value:"En cuisine, je suis un vrai cordon bleu !", label:"En cuisine, je suis un vrai cordon bleu !"},
    {emoji:'💁‍♀️' , value:"Au service, je manie les plateaux avec dextérité !", label:"Au service, je manie les plateaux avec dextérité !"},
    {emoji:'😇' , value:"A l'accueil, armé de mon plus beau sourire !", label:"A l'accueil, armé de mon plus beau sourire !"},
    {emoji:'🧼' , value:"A l'entretien, pour que ça brille !", label:"A l'entretien, pour que ça brille !"},
    {emoji:'👷🏽‍♀️' , value:'A la maintenance, j\'ai un côté "Bob le Bricoleur" !', label:'A la maintenance, j\'ai un côté "Bob le Bricoleur" !'},
    {emoji:'🫡' , value:"A l'organisation, pour mon côté militaire", label:"A l'organisation, pour mon côté militaire"},
    {emoji:'💆‍♂️' , value:"Au Spa, en tant que praticien", label:"Au Spa, en tant que praticien"},
    {emoji:'🏊🏾' , value:"A la piscine, pour mon côté secouriste", label:"A la piscine, pour mon côté secouriste"},
    {emoji:'🚗', value: "En tant que Voiturier, je suis un As du volant !", label: 'En tant que Voiturier, je suis un As du volant !'}
]





