import { Button, Form, Input, Select } from 'antd'
import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import TextArea from 'antd/es/input/TextArea';
import { createPage, updatePage, updatePageWithoutImage } from '../../../../redux/actions/pageAction';

const CreatePartner3 = ({page, formData, setFormData, step , setStep }) => {


    const [tags, setTags] = useState([]);
    const dispatch = useDispatch();
    const {auth} = useSelector(state=>state);
    useEffect(() => {
        const updatedCompany = { ...formData, ['specialities']: tags };
        setFormData(updatedCompany);
    }, [tags])
    



  const handleTagChange = (value) => {
    setTags(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      const { value } = e.target;
      if (value) {
        setTags([...tags, value]);
  
        e.target.value = '';
      }
      e.preventDefault();
    }
  };




    const handleChangeInput = e => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })

    }

    const onFinish = () => {
        console.log(formData);
        let data = {...formData};
       data.typePage = "partner";
       data.typeCompany = "inde";

       if(page){
        const id = formData._id

        dispatch(updatePageWithoutImage({ id, pageData: formData, auth }))
       }else{
        dispatch(createPage({content:data,type:"company", auth}))

       }

    };

    return (
        <Form
        name="basic"
        layout='vertical'
        onFinish={onFinish}
        initialValues={formData?{...formData}: null}

        autoComplete="off">

<Form.Item
                                    label="Spécialités"
                                    name="specialities"
                                    >
                                    <Select
                                    mode="tags"
                                    placeholder="Enter tags"
                                    value={tags}
                                    size='large'
                                    onChange={handleTagChange}
                                    onKeyDown={handleKeyDown}
                                    />
                                </Form.Item>



                                                   
                                <Form.Item
                                    label="Description"
                                    name="description"
                                    rules={[
                                    {
                                        required: true,
                                        message: "Entrez une description!",
                                        
                                    },
                                    {
                                      max: 1000,
                                      message: 'La description ne peut pas dépasser 1000 caractères!',
                                    }
                                    ]}
                                    hasFeedback
                                    onChange={(e)=>handleChangeInput(e)}

                                >
                                    <TextArea name="description" size='large' maxLength={1000} showCount />
                                </Form.Item>

                                <Form.Item
                    style={{ display: 'flex', justifyContent: 'end' }}
                >
                 
                 <Button  size="large" onClick={()=>setStep(step - 1)} style={{marginRight:'4px'}} >
                        Précèdent

                        </Button>
                   <Button size="large" type="primary"  htmlType="submit" >
                        {page ? 'Modifier': 'Créer'}
                    </Button>
                     
                </Form.Item>

        </Form>
    )
}

export default CreatePartner3