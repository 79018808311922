import React from 'react'
import styled from 'styled-components'
import {  Input, Form, Button } from 'antd';
import SearchAddress from '../../../utils/SearchAddress';
import { useEffect } from 'react';
import { useState } from 'react';


const FormCompany1 = ({ company, onChange, step, onStepChange }) => {

    const [localisationText, setLocalisationText] = useState("")
    const [localisationObject, setLocalisationObject] = useState(null)
  
    useEffect(() => {
      if(localisationObject){
         setLocalisationText(localisationObject.formatted_address)
         const updatedCompany = { ...company, ['postalAddress']: localisationObject };
         onChange(updatedCompany);
     } 
  }, [localisationObject])

  
  const handleAddressChange = (newAddress) => {

    setLocalisationText(newAddress.formatted_address) 
    const { formatted_address,address_components, geometry, geoFormat } = newAddress;
    const newObject = { formatted_address,address_components, geometry, geoFormat };
    setLocalisationObject(newObject)


  }

  
  const handleChangeInput = e => {
    const { name, value } = e.target
    const updatedCompany = { ...company, [name]: value };
    console.log(updatedCompany)
    onChange(updatedCompany);
  }

  return (
    <ContainerForm>
      <h3>Génial ! Dîtes-moi en plus sur l’entreprise !</h3>


<Form
                           onFinish={ () => {onStepChange(step + 1)}}
                           layout="vertical"
                           style={{width: "100%"}}
                         >
                                <Form.Item
                                    label={company.typeCompany === "inde"?"Dénomination sociale de l’entreprise": "Dénomination sociale de la maison mère*" }
                                    name="registeredName"
                                    onChange={(e)=>handleChangeInput(e)}
                                    hasFeedback

                                    rules={[
                                        {
                                        required: true,
                                        message: 'Entrez votre dénomination sociale!',

                                        },
                                        {
                                          max: 120,
                                          message: 'La dénomination sociale ne peut pas dépasser 120 caractères!',
                                        }
                                    ]}
                                    >
                                    <Input  name="registeredName"  size="large" maxLength={120}/>
                                </Form.Item>

                  


                                
                                <Form.Item
                                    label="N° SIRET"
                                    name="siret"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Entrez votre SIRET!',
                                        },
                                        {
                                        validator: (_, value) =>
                                            value && /^\d{14}$/.test(value) 
                                            ? Promise.resolve() 
                                            : Promise.reject(new Error('SIRET doit contenir exactement 14 chiffres!')),
                                        },
                                    ]}
                                    hasFeedback
                                    onChange={(e)=>handleChangeInput(e)}
                                    >
                                    <Input name="siret" size='large'/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Adresse postale"
                                        name="postalAddress"

                                        >
                                        <SearchAddress onAddressChange={handleAddressChange} address={localisationText} setAddress={setLocalisationText} />
                                    

                                </Form.Item>


                                <Form.Item
                    label="Code Partenariat"
                    name="codePartnership"
       
                    onChange={(e) => handleChangeInput(e)}
                >
                    <Input name="codePartnership" size='large' />
                </Form.Item>
        

                                    <Form.Item className="item-next"  style={{display:'flex', justifyContent:'center' , marginTop:'35px'}}>
                                        <Button size="large" shape="round" type="primary" htmlType="submit">
                                            Suivant
                                        </Button>
                                    </Form.Item>
                                </Form>
    </ContainerForm>
  );
};

export const ContainerForm = styled.div`
  display:flex;
  flex-direction:column;
  justify-content: space-around;
  height: 100%;
  margin-top: 5%;


  Form{
    padding-top: 30px;
  }

  h3{
    font-size: 1.2em;
    font-weight: bold;
    margin: 0px;
    text-align:center;

}



`

export default FormCompany1;

