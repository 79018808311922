import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const HeaderDealCompany = ({ company }) => {
  
  const history = useHistory()
  return (
    <Header>
      <Top>
        <img
          className="header-company"
          src={company.imgBackground}
          alt="header entreprise"
        />

        <AvatarCompany>
          <img src={company.avatar} alt="Logo entreprise"
            onClick={() => { history.push({
                pathname: `/company/${company._id}`,
                state: { company: company }
        });}} />
        </AvatarCompany>
      </Top>

      <TitlesCompany>
        <h2>{company.name}</h2>
      </TitlesCompany>
    </Header>
  );
};

const Header = styled.div`
  width: 100%;
  margin-bottom: 5%;
  height: 200px;

  @media (max-width: 768px) {
    height: auto;

  }
`;

const Top = styled.div`
  position: relative;
  width: 100%;
  height: 120px;


  .header-company {
    width: 100%;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
  }
`;

const AvatarCompany = styled.div`
  position: absolute;
  background: white;
  border-radius: 50%;
  padding: 3px;
  top: 80px;
  left: 50%;

  transform: translate(-50%, -50%);
  img {
    width: 70px;
    height: 70px;
    border-radius: 50%;

  }
`;
const TitlesCompany = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    margin: 0px;
    font-size: 1.1em;
    font-weight: 700;
            text-align:center;

  }

  h3 {
    margin: 0px;
    font-size: 0.9em;
  }
`;
export default HeaderDealCompany;
