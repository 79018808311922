import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import LoadIcon from '../../../../images/loading.gif'
import { getDataAPI } from '../../../../utils/fetchData'
import styled from "styled-components"
import LanguageCard from './LanguageCard'
import { useParams } from 'react-router-dom'
import { LANGUAGES_TYPES } from '../../../../redux/actions/languageAction'
import LoadMoreBtn from '../../LoadMoreBtn'

const Languages = ({id}) => {
    const { languages, auth } = useSelector(state => state)
    const dispatch = useDispatch()
    const [languageList, setLanguage] = useState([])
    const [load, setLoad] = useState(false)

    useEffect(() => {
        setLanguage(languages.languages)
    }, [languages.languages])

    const handleLoadMore = async () => {
        setLoad(true)
        const res = await getDataAPI(`languages/${id}?limit=${languages.page * 3}`, auth.token)

        console.log(res)
        dispatch({
            type: LANGUAGES_TYPES.GET_LANGUAGES_USER, 
            payload: {...res.data, page: languages.page + 1}
        })

        setLoad(false)
    }

    return (
        <ContainerLanguages>
            {
                languageList.map(language => (
                    <LanguageCard key={language._id} language={language} />
                ))
            }

            {
                load && <img src={LoadIcon} alt="loading" className="d-block mx-auto" />
            }


{/* {             <LoadMoreBtn limit={3} result={languages.result} page={languages.page}
            load={load} handleLoadMore={handleLoadMore} />}
 */}
            

        </ContainerLanguages>
    )
}

const ContainerLanguages = styled.div`
    display: flex;
    flex-direction: column;
`

export default Languages
