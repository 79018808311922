
import { DeleteData, EditData } from '../actions/globalTypes';

import { SUBSCRIPTION_TYPES } from '../actions/subscriptionAction';

const initialState = {
    loading: false,
    subscriptions: [],
    count: 0,
    countActive: 0,
    page: 1,
    n: 0,
}



const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUBSCRIPTION_TYPES.CREATE_SUBSCRIPTION:
            return {
                ...state,
                subscriptions: [...state.subscriptions, action.payload],
                loading: false

            }
        case SUBSCRIPTION_TYPES.GET_SUBSCRIPTIONS:
            return {
                subscriptions: action.payload.data.subscriptions,
                countActive: action.payload.data.countActive,
                page: action.payload.data.page,
                n: action.payload.data.n,
                count: action.payload.data.count
            }



        case SUBSCRIPTION_TYPES.LOADING:
            return {
                ...state,
                loading: action.payload
            };


        case SUBSCRIPTION_TYPES.DELETE_SUBSCRIPTION:
            return {
                ...state,
                subscriptions: DeleteData(state.subscriptions, action.payload._id)
        };

        default:
            return state;
    }
}


export default subscriptionReducer
