import { Button, Input } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import styled from 'styled-components'
const Confirm = () => {

    const [status, setStatus] = useState(null)

    const [step, setStep] = useState(1)
    const [token, setToken] = useState("")
    const [url, setURL] = useState(null)
    const [email, setEmail] = useState("")

    const history = useHistory();


    useEffect(() => {
        if (process.env.REACT_APP_URL) {
            setURL(process.env.REACT_APP_URL);
        }
    }, []);

    useEffect(async () => {


        const urlParams = new URLSearchParams(window.location.search);
        setToken(urlParams.get('token'))

        try {
            const res = await axios.post(`${url}/api/confirmToken/${token}`)
            if (res.status === 200) {
                setStatus(res)
                console.log(res)
            }
        }
        catch (e) {

            if (e.response.status === 400) {
                setStatus(e.response)
                console.log(e.response)
            }
        }


        return () => {
        }
    }, [url])

    const handleSend = async () => {
        try{
            const res = await axios.post(`${url}/api/sendConfirmationLink`, {email: email})
            if(res.status === 200){
                setStep(3)
                setStatus(res)
            }
        }
        catch(e){

            setStatus(e.response)

        }

    }


    return (
        <Container>

            {step === 1 && <Content>
                {
                    status && status.status === 200 ?
                        <>
                            <svg width="160" height="160" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                <mask id="ipSSuccess0">
                                    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4">
                                        <path fill="#fff" stroke="#fff" d="m24 4l5.253 3.832l6.503-.012l1.997 6.188l5.268 3.812L41 24l2.021 6.18l-5.268 3.812l-1.997 6.188l-6.503-.012L24 44l-5.253-3.832l-6.503.012l-1.997-6.188l-5.268-3.812L7 24l-2.021-6.18l5.268-3.812l1.997-6.188l6.503.012L24 4Z" />
                                        <path stroke="#000" d="m17 24l5 5l10-10" />
                                    </g>
                                </mask>
                                <path fill="#84cc16" d="M0 0h48v48H0z" mask="url(#ipSSuccess0)" />
                            </svg>
                            <span>{status.data}</span>
                            <Button type={'primary'} size={"large"} onClick={()=> history.push('/login')}>Se connecter</Button>

                        </>

                        :
                        <>
                            <svg width="200" height="200" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path fill="#ef4444" d="M12 17q.425 0 .713-.288T13 16q0-.425-.288-.713T12 15q-.425 0-.713.288T11 16q0 .425.288.713T12 17Zm-1-4h2V7h-2v6Zm1 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z" />
                            </svg>
                            <span>{status && status.data.msg}</span>
                            <Button size='large' type={'primary'} onClick={()=>{setStatus(null); setStep(2)}}>Renvoyer un lien</Button>
                        </>

                }
            </Content>}

            {step === 2 && <Content>
                <h2>Renvoyer lien de confirmation</h2>

                <Input maxLength={140} size="large" placeholder='Entrez votre email' onChange={(e)=>setEmail(e.target.value)}/>
                {status && <span>{status && status.data.msg}</span>}

                <Button type={'primary'} size={'large'} onClick={()=>handleSend()}>Envoyer</Button>
            </Content>}

            {step === 3 && <Content>
                <svg width="160" height="160" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                <mask id="ipSSuccess0">
                                    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4">
                                        <path fill="#fff" stroke="#fff" d="m24 4l5.253 3.832l6.503-.012l1.997 6.188l5.268 3.812L41 24l2.021 6.18l-5.268 3.812l-1.997 6.188l-6.503-.012L24 44l-5.253-3.832l-6.503.012l-1.997-6.188l-5.268-3.812L7 24l-2.021-6.18l5.268-3.812l1.997-6.188l6.503.012L24 4Z" />
                                        <path stroke="#000" d="m17 24l5 5l10-10" />
                                    </g>
                                </mask>
                                <path fill="#84cc16" d="M0 0h48v48H0z" mask="url(#ipSSuccess0)" />
                            </svg>
                            <span>Le mail a bien été envoyé. Vous pouvez fermer cette page.</span>
            </Content>}

            {step === 4 && <Content>
                <svg width="200" height="200" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path fill="#ef4444" d="M12 17q.425 0 .713-.288T13 16q0-.425-.288-.713T12 15q-.425 0-.713.288T11 16q0 .425.288.713T12 17Zm-1-4h2V7h-2v6Zm1 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z" />
                            </svg>
                            <span>Une erreur est survenue. Vous pouvez fermer cette page.</span>
            </Content>}


        </Container>
    )
}

const Container = styled.div`

    display:flex;
    justify-content: center;
    width:100vw;
    height: 100vh;
    align-items: center;
    background :#F0F0F0;
    overflow: hidden;


`

const Content = styled.div`
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    width: 25%;
    background: white;
    padding: 30px 30px;
    border-radius:25px;
    gap: 10px;

    span{
        text-align:center;
    }

    h2{
        font-size: 1.5em;
        margin:0px;
        margin-bottom: 8%;
        font-weight: 700;
        text-align:center;
    }

    p{
        margin: 0px;


    }

    .msgResponse{
        margin-top: 25px;
        margin-bottom: 25px;

    
    }

    .error {
        color: red;
    }

    .valid {
        color: green;
    }
    
    @media (max-width: 768px) {
        width: 80%;
        
      }

`

const InputStyled = styled.input`
    background: #FAFAFA;
    border: none;
    outline: none;
    text-indent: 5px;
    padding: 10px 10px;
    margin-top: 10px;
    width: 100%;

    @media screen and (max-width: 768px) {
        font-size: 0.9em;
    }


`

const ButtonValid = styled.button`
    margin-top: 5%;
    display: flex;
    background: #F87778;
    justify-content: center;
    align-items:center;
    outline: none;
    border: 1px solid #ddd;
    border-radius: 25px;
    padding: 12px 35px;
    margin-bottom: 5%;
    width: 100%;
    
    span {
      font-size: 14px;
      font-weight: 600;
      color: white;
    }

    &:disabled,
    &[disabled]{
    border: none;
    background-color: #cccccc;
    color: #666666;
    }


`
const ReturnButton = styled.span`
    color: #F87778;
    font-weight: 600;
    &:hover{
        cursor: pointer;
        font-weight: 700;

    }

`




export default Confirm