import React , {useEffect} from 'react'
import { AiFillLike, AiOutlineLike } from "react-icons/ai";

const RecommendButton = ({isRecommended, handleRecommend, handleUnrecommend}) => {


    return (
        <>
            {
                isRecommended
                ? <AiFillLike onClick={handleUnrecommend} 
                style={{"color": "#F87778", fontSize: "25px"}} />
                : <AiOutlineLike  onClick={handleRecommend}  style={{"color": "#F87778", fontSize: "25px"}}/>
            }
        </>
    )
}

export default RecommendButton
