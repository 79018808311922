import React from 'react'
import styled from 'styled-components'
import Avatar from '../Avatar'
import {AiFillDelete} from 'react-icons/ai' 


const ItemUser = ({user}) => {
  return (
    <ItemContainer>
        <LeftPart>
            <Avatar src={user.avatar} size="40px;" />

            <InfosUser>
                <h4>{user.fullname}</h4>
                <span>{user.titleJob}</span>

            </InfosUser>
        </LeftPart>

        <RightPart>
            <AiFillDelete  className='icon'/>
        </RightPart>

    </ItemContainer>
  )
}


const ItemContainer = styled.div`
    width: 100%;
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 2% 2%;
    margin-bottom: 3%;

`

const LeftPart = styled.div`
    display:flex;
    align-items: center;
    flex: 1;

`

const InfosUser = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5%;
    h4{
        color: #F87778;
        font-size: 0.9em;
        margin: 0px;

    }

    span{
        font-size: 0.8em;
    }


`

const RightPart = styled.div`
    height: 100%;
    display:flex;
    align-items: start;

    .icon {
        font-size: 1.5em;
        color: #C4C4C4;
    }
`
export default ItemUser