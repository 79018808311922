import { GLOBALTYPES } from './globalTypes'
import { postDataAPI, getDataAPI, patchDataAPI, deleteDataAPI } from '../../utils/fetchData'
import validFormSkill from '../../utils/formValidation/validFormSkill'

export const SKILLS_TYPES = {
    CREATE_SKILL: 'CREATE_SKILL',
    LOADING_SKILL: 'LOADING_SKILL',
    GET_SKILLS: 'GET_SKILLS',
    GET_SKILLS_USER: 'GET_SKILLS_USER',
    UPDATE_SKILL: 'UPDATE_SKILLS',
    DELETE_SKILL: 'DELETE_SKILL',
    UPDATED_SKILL: 'UPDATED_SKILL',

}


export const createSkill= ({data, auth}) => async (dispatch) => {
    const check = validFormSkill(data)
    if(check.errLength > 0)
    return dispatch({type: GLOBALTYPES.ALERT, payload: check.errMsg})
    
    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })

        const res = await postDataAPI('skills', data, auth.token)

        dispatch({ 
            type: SKILLS_TYPES.CREATE_SKILL, 
            payload: {...res.data.newSkill, user: auth.user} 
        })

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: false} })
        dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})
        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} })


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const getSkills = (token) => async (dispatch) => {
    try {
        dispatch({ type: SKILLS_TYPES.LOADING_SKILL, payload: true })
        const res = await getDataAPI('skills', token)
        
        dispatch({
            type: SKILLS_TYPES.GET_SKILLS,
            payload: {...res.data, page: 2}
        })

        dispatch({ type: SKILLS_TYPES.LOADING_SKILL, payload: false })
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const getSkillsUser = (token, id) => async (dispatch) => {
    try {
        dispatch({ type: SKILLS_TYPES.LOADING_SKILL, payload: true })
        const res = await getDataAPI(`user_skills/${id}`, token)
        
        dispatch({
            type: SKILLS_TYPES.GET_SKILLS_USER,
            payload: {...res.data, page: 2}
        })

        dispatch({ type: SKILLS_TYPES.LOADING_SKILL, payload: false })
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}


export const recommendSkill = ({skill, auth, socket}) => async (dispatch) => {
    const newSkill = {...skill, recommendation: [...skill.recommendation, auth.user]}
    dispatch({ type: SKILLS_TYPES.UPDATE_SKILL, payload: newSkill})
    socket.emit('recommend Skill', newSkill)

     try {
        await patchDataAPI(`skills/${skill._id}/recommend`, null, auth.token)
        
        // Notify
/*         const msg = {
            id: auth.user._id,
            text:  'a recommendé une de vos compétence.',
            recipients: [skill.user._id],
        } */


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    } 
}





export const unrecommendSkill = ({skill, auth, socket}) => async (dispatch) => {
    const newSkill = {...skill, recommendation: skill.recommendation.filter(recommend => recommend._id !== auth.user._id)}

    dispatch({ type: SKILLS_TYPES.UPDATE_SKILL, payload: newSkill})

    socket.emit('unrecommend Skill', newSkill)

    try {
        await patchDataAPI(`skills/${skill._id}/unrecommend`, null, auth.token)

        // Notify
/*         const msg = {
            id: auth.user._id,
            text: 'unrecommend your skill.',
            recipients: [skill.user._id],
        }
 */
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}


export const updateSkill = ({id, data, auth}) => async (dispatch) => {
    console.log(data)
    const check = validFormSkill(data)
    if(check.errLength > 0)
    return dispatch({type: GLOBALTYPES.ALERT, payload: check.errMsg})
 
    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })

        const res = await patchDataAPI(`skill/${id}`, data, auth.token)

        dispatch({ type: SKILLS_TYPES.UPDATED_SKILL, payload: res.data.newSkill })
        dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})

        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} })
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const deleteSkill = ({id, skill, auth}) => async (dispatch) => {
    dispatch({ type: SKILLS_TYPES.DELETE_SKILL, payload: skill })

    try {
        const res = await deleteDataAPI(`skill/${id}`, auth.token)
        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} })
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}