import { Button, Checkbox, Radio } from 'antd'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { getDataAPI } from '../../../../utils/fetchData'

import NoData from '../../../profile/cv/NoData'
import { ClipLoader } from 'react-spinners'
const PageChoice = ({ step, onStepChange, formData, setFormData, type }) => {


    const { modal, auth } = useSelector(state => state)
    const [typePage, setTypePage] = useState("company")

    const [pages, setPages] = useState([]);
    const [selectedPages, setSelectedPages] = useState([]);  // Track selected pages
    const [isLoading, setIsLoading] = useState(false);

    // Check if all pages are selected
    const isAllSelected = pages.length === selectedPages.length;

    useEffect(async () => {
        setIsLoading(true);
        try {
            const res = await getDataAPI(`/pagesByUserId`, auth.token)
            setPages(res.data.pages)
            setIsLoading(false);

        } catch (e) {
            setPages([])
            setIsLoading(false);

        }


    }, [])

    useEffect(() => {
      if(pages.length > 0){
        if( pages.filter(obj => obj.typePage === 'company').length === 0 &&  pages.filter(obj => obj.typePage === 'partner').length > 0){
            setTypePage("partner");
        }

      }
    
 
    }, [pages])
    




    // Handle the 'Select All' checkbox
    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedPages(pages.filter(page => page.typePage === typePage).map(page => page._id));
        } else {
            setSelectedPages([]);
        }
    }

    // Handle individual page selection
    const handleSelectPage = (pageId, isChecked) => {
        if (isChecked) {
            setSelectedPages(prev => [...prev, pageId]);
        } else {
            setSelectedPages(prev => prev.filter(id => id !== pageId));
        }
    }

    const handleNext = () => {
        setFormData({ ...formData, pages: selectedPages, typeDeal: typePage });

        console.log(formData)
        onStepChange(step + 1);

    }

    const handleTypeChange = (e) => {

        setTypePage(e.target.value);
        setSelectedPages([]);
        setFormData({ ...formData, typeDeal: e.target.value });
    };
    return (
        <Content>

            <HeaderModal>
                {
                    (formData.target === "global" && type === 'promo') && <h2>{modal.deal ? 'Modifier' : 'Créer'} une promotion pour la communauté</h2>
                }


{/*                 {
                    (formData.target === "global" && type === 'event') && <h2>{modal.event ? 'Modifier' : 'Créer'} un évènement général</h2>
                }
 */}

                <h3>Pour quelle(s) page(s)?</h3>

                <Radio.Group defaultValue={typePage} value={typePage} onChange={handleTypeChange}
                    style={{ margin: "0px auto", marginTop: "10px" }} >
                    <Radio.Button value="company">Mes établissements</Radio.Button>
                    <Radio.Button value="partner">Mes marques</Radio.Button>
                </Radio.Group>

            </HeaderModal>

            {
                isLoading ? <EmptyPage>
                     <ClipLoader
                        color={"#F87778"}
                        loading={isLoading}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                    
                    
                    </EmptyPage> :
                <>
                            { pages && pages.filter(page => page.typePage === typePage).length > 0 && <div className='selectAll'>
                <Checkbox checked={isAllSelected} onChange={handleSelectAll} />
                <span>Tout sélectionner</span>
            </div>}

            { pages && pages.filter(page => page.typePage === typePage).length === 0 && <EmptyPage>


                {
                   typePage==="company" &&  <NoData title={"Aucune page établissement de créée"}/> 
                }
                {
                   typePage==="partner" &&  <NoData title={" Aucune page marque de créée"}/> 
                }
            </EmptyPage>}



          {pages && pages.filter(page => page.typePage === typePage).length>0 &&  <ChoicesDiv>
                {
                    pages && pages.filter(page => page.typePage === typePage).map((page, index) => (
                        <Choice key={index}>
                            <div className='div-left'>
                                <img src={page.avatar} alt="avatar" />
                                <span>{page.name}</span>
                            </div>
                            <Checkbox
                                checked={selectedPages.includes(page._id)}
                                onChange={(e) => handleSelectPage(page._id, e.target.checked)}
                            />
                        </Choice>
                    ))
                }
            </ChoicesDiv>}

                
                
                </>
            }



            <Button disabled={selectedPages.length === 0} onClick={() => { handleNext() }} size="large" shape="round" type="primary" htmlType="submit">
                Suivant
            </Button>

        </Content>
    )
}

const Content = styled.div`
    display:flex;
    width: 100%;
    flex: 1;

    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    gap:20px;
    .selectAll{
        display:flex;
        justify-content: end;
        width:100%;

        span{
            margin-left: 5px;
            font-size: 0.9em;
        }
    }



`

export const HeaderModal = styled.div`
    display:flex;
    flex-direction: column;

    margin: 20px 0px;

    h2{
        font-size:1.4em;
        font-weight: 900;
        color: #585858;
        text-align:center;
    }

    h3{
        font-size:1.1em;
        font-weight: 500;
        color: #585858;
        text-align:center;
    }

`

const EmptyPage = styled.div`

    display:flex;
    flex-direction:column;
    width: 100%;
    height: 100%;
    justify-content:center;
    align-items:center;
`
const ChoicesDiv = styled.div`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
        width: 100%;

        height: 100%;
        margin-bottom: 20px;

        @media screen and (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        } 

`

const Choice = styled.div`
    
    padding: 10px;
    display: flex;
    align-items: center;
    align-self: flex-start;
    justify-content: space-between;
    border-radius: 10px;
    width: 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;


    .div-left{
        display:flex;
        align-items: center;
    }
    .div-icon{
        background:red;
        padding: 5px;
        border-radius: 100%;
        .icon{
            color:white;
            font-size:1.4em;
        }

    }

    img{
        width: 35px !important;
        height: 35px !important;
    }
    span{
        font-size: 0.9em;
        margin-left: 5px;
    }
    transition: all 0.2s ease-in;

    &:hover{
        cursor: pointer;
        transform: scale(1);
    }

`
export default PageChoice