import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from "styled-components"
import DealPreviewCardMobile from '../components/home/DealPreviewCardMobile'
import FilterBar from '../components/home/FilterBar'
import { DEAL_TYPES, getDeals, searchAll, searchDealsFilter } from '../redux/actions/dealAction'
import LoadIcon from '../images/loading.gif'
import DealPreviewCard from '../components/home/DealPreviewCard'
import useWindowDimensions from '../utils/useWindowsDimensions';
import DealCardModal from '../components/home/DealCardModal'
import { GLOBALTYPES } from '../redux/actions/globalTypes'
import { Player } from '@lottiefiles/react-lottie-player';
import EmptyAnimation from '../assets/empty.json'

import FilterModal from '../components/home/FilterModal'
import FilterMobile from '../components/home/FilterMobile'
import { getDataAPI } from '../utils/fetchData'
import FirstConnectionModal from '../components/home/FirstConnectionModal'
import EventPreviewCard from '../components/home/event/EventPreviewCard'

import EventCardModal from '../components/home/event/EventCardModal'
import EventPreviewCardMobile from '../components/home/event/EventPreviewCardMobile'

import { Button, Carousel } from 'antd'
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import DealPartnerPreviewCardNew from '../components/home/card/DealPartnerPreviewCardNew'
import DealCardModalNew from '../components/home/card/DealCardModalNew'
import DealPartnerPreviewCardMobile from '../components/home/card/DealPartnerPreviewCardMobile'
import { getAds } from '../redux/actions/adsActions'
import CarouselAds from '../components/home/ads/CarouselAds'
import AdCardModal from '../components/home/ads/AdCardModal'

const Home = () => {
  const {auth , ads, deals, modal, filterDeal} = useSelector(state=>state)
  const dispatch = useDispatch()
  const {  width } = useWindowDimensions();
  const [load, setLoad] = useState(false)
  const history = useHistory();
  useEffect(() => {
    if(auth.user.firstConnection){
      dispatch({ type: GLOBALTYPES.MODAL,payload: {typeModal:"firstConnectionModal", open:true}})
    }

    let filter = {status:"active"}
          console.log("hello")
        dispatch(getAds({filter,auth}))


    return () => {
      if(width>768){
        dispatch({ type: GLOBALTYPES.MODAL,payload: {open:false}})

      }
    }
  }, [])
  
   useEffect(() => {
    if(filterDeal.filter){


      //dispatch(searchDealsFilter({filterDeal,auth}))
      dispatch(searchAll({ filter: filterDeal.filter, auth }))


    } else{
      let newfilter = { n: 12 };
      dispatch(searchAll({ filter: newfilter, auth }))

    }
   
  }, [filterDeal])

  const getParamsFilter =  (filterData) => {
    console.log(filterData)
    let param = ""

    if(filterData.search && filterData.search !== ""){
        
            if(param === ""){
                param += "search=" + filterData.search
            }
            else {
                param += "&&search=" + filterData.search
            }
        
    }

    if(filterData.sector && filterData.sector !== ""){
        
        if(param === ""){
            param += "sector=" + filterData.sector
        }
        else {
            param += "&&sector=" + filterData.sector
        }
    
    }




    return param
    
}


  const handleLoadMore = async () => {
    let param = ""
    if(filterDeal.filter){
      param = getParamsFilter(filterDeal.filter)
      console.log(param)

    }
    setLoad(true)
    let page = parseInt(deals.page) + 1
    let request = ''
    if(param){
        request = `searchAll?n=12&page=${page}&${param}`
    }else{
        request = `searchAll?n=12&page=${page}`
    }
    const res = await getDataAPI(request, auth.token)

    console.log(res)

    dispatch({
      type: DEAL_TYPES.GET_MORE_DEALS,
      payload: res
    }) 

    setLoad(false)
}



  return (
    <Container>

{ads.ads.length > 0 && <CarouselAds ads={ads.ads} />
    }
      <FilterBar type="home"/>
      
 
      {modal.typeModal=='dealCardModal' && <DealCardModal />}
      {modal.typeModal=='eventCardModal' && <EventCardModal />}
      {modal.typeModal=='dealCardPartnerModal' && <DealCardModalNew/>}
      {modal.typeModal=='adCardModal' && <AdCardModal/>}

      {modal.typeModal=='filterModal' && <FilterModal />}
      {modal.typeModal=='filterModalMobile' && <FilterMobile />}


      <FirstConnectionModal/>

   
 <>
      <DealsContainer>
      {
          deals.loading 
          ? <img src={LoadIcon} alt="loading" className="d-block mx-auto" />
          : (deals.count === 0)
              ? <NoResults >
                    <Player
              autoplay
              loop
              src={EmptyAnimation}
              style={{ height: '100%', width: '100%' }}
            >
            </Player>
            </NoResults>
              : <>
               {
                  width > 768?
                  deals.deals.map((deal) => (
                    <>
                    <>{deal.type ==='promo' && deal.typeDeal ==='company' && <DealPreviewCard key={deal._id} deal={deal}/>}</>
                    <>{deal.type ==='promo' && deal.typeDeal ==='partner' && <DealPartnerPreviewCardNew key={deal._id} deal={deal}/>}</>

{/*                     <>{deal.type ==='event' && <EventPreviewCard typePage={"all"} key={deal._id} event={deal}/>}</>
 */}
                    </>
                  )): 
                  deals.deals.map((deal) => (
                    <>
                    <>{deal.type ==='promo' && deal.typeDeal ==='company' && <DealPreviewCardMobile key={deal._id} deal={deal}/>}</>
                    <>{deal.type ==='promo' && deal.typeDeal ==='partner' && <DealPartnerPreviewCardMobile key={deal._id} deal={deal}/>}</>

{/*                     <>{deal.type ==='event' && <EventPreviewCardMobile typePage={"all"} key={deal._id} event={deal}/>}</>
 */}
                    </>
                  ))
                } 
              
              </>
                    }
      </DealsContainer> 

      {
        (deals.deals.length) < deals.total && 
        <ShowMore onClick={() => handleLoadMore()}>Voir plus</ShowMore>

      }</>


{/*   {
    !auth.user.subscription && <NoAccount>
      <span>Oh Shoot! Vous n'avez pas d'abonnement!</span>
      <Button onClick={()=> dispatch({ type: GLOBALTYPES.MODAL, payload: { typeModal: "activateSubModal", open: true }})}>Activer abonnement</Button>
    
    </NoAccount>
  }
 */}

    </Container>
  )
}

const Container = styled.div`
  width: 80%;
  display:flex;
  flex-direction: column;

  
  @media only screen and (max-width: 768px) {
    width: 90%;

  }

`

const AdsContent = styled.div`
  display:flex;
  margin: 20px 0px,
  borderRadius: 10px,
  height: 160px,
  color: #fff,
  lineHeight:160px,
  textAlign: center,
  background: #364d79,

`

const DealsContainer = styled.div`
  margin: 0px auto;
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-auto-rows: 280px;

  grid-gap: 20px;
  margin-top: 5%;
  @media only screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  @media only screen and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr );
    grid-template-rows: repeat(2, auto);
  }

  @media only screen and (max-width: 1080px) {
    grid-template-columns: repeat( auto-fit, minmax(25%, 1fr) );
    grid-template-rows: repeat(3, auto);
  }

  width: 100%;
  height: 100%;

`

const NoInfos = styled.div`
  display:flex;
  justify-content:center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
`
const NoResults = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  display:flex;
  flex-direction: column;
  jutify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0px auto;

`

export const ShowMore = styled.div`
  margin: 0px auto;
  margin-top: 1%;
  margin-bottom: 1%;
  font-weight: 600;
  color: #F87778;
  &:hover{
    cursor: pointer;
    font-weight: 700;
  }


  @media only screen and (max-width: 768px) {
    margin-bottom: 4%;


  }

`
export default Home