import { Button, Form, Input, Select } from 'antd'
import React from 'react'
import SearchAddress from '../../utils/SearchAddress'
import { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

const FormManagementCompanyPart2 = ({setCurrent, current, formData, setFormData}) => {
    const [localisationText, setLocalisationText] = useState("")
    const [localisationObject, setLocalisationObject] = useState(null)
    const  {datas} = useSelector(state=>state);

    
    useEffect(() => {
        if(formData.address){
            setLocalisationText(formData.address.formatted_address)
            setLocalisationObject(formData.address)
        }

    }, [])
    

    const handleChangeInput = e => {
        const { name, value } = e.target
        const updatedCompany = { ...formData, [name]: value };
        setFormData(updatedCompany);

    }


    const handleAddressChange = (newAddress) => {

        setLocalisationText(newAddress.formatted_address)
        const { formatted_address, address_components, geometry } = newAddress;
        const newObject = { formatted_address, address_components, geometry };
        setLocalisationObject(newObject)


    }
  return (
    <Form
    onFinish={ () => {setCurrent(current + 1)}}

    layout="vertical"
    style={{ width: "100%" }}
    initialValues={{name: formData.name, sector: formData.sector, address: formData.address, email: formData.email, phone: formData.phone, website: formData.website}}
>
    <Form.Item
        label="Nom d'établissement"
        name="name"
        onChange={(e) => handleChangeInput(e)}
        hasFeedback

        rules={[
            {
                required: true,
                message: "Entrez le nom de l'entreprise!",

            },
            {
                max: 120,
                message: 'Le nom de l\'entreprise ne peut pas dépasser 120 caractères!',
              }
        ]}
    >
        <Input name="name" size="large" maxLength={120} />
    </Form.Item>


    <Form.Item name="sector" label="Type d'établissement" rules={[{ required: true, message: 'Sélectionnez le type!', }]}>
        <Select onChange={(e) => { setFormData({ ...formData, ['sector']: e }) }}  size="large" placeholder="Sélectionnez type" dropdownStyle={{ zIndex: "100000" }}>

            {
                datas.companyTypes.map((sector) => (
                    <Select.Option value={sector.id}>{sector.name} {sector.value}</Select.Option>

                ))
            }
        </Select>
    </Form.Item>



    <Form.Item
        label="Adresse de l'établissement"
        name="address"

    >
        <SearchAddress onAddressChange={handleAddressChange} address={localisationText} setAddress={setLocalisationText} />


    </Form.Item>


    <Form.Item
        label="Email"
        name="email"
        hasFeedback
        rules={[
            {
                required: true,
                message: "Entrez l'adresse email",
            },
            {
                type: 'email',
                message: "Entrez une adresse email valide",
            },
            {
                max: 120,
                message: 'L`\'email ne peut pas dépasser 120 caractères!',
              }
        ]}
        onChange={(e) => handleChangeInput(e)}
    >
        <Input name="email" size="large" maxLength={120}/>
    </Form.Item>


    <Form.Item
        label="Téléphone"
        name="phone"
        rules={[
   
            {
                pattern: /^0\d{9}$/,
                message: 'Le numéro de téléphone doit comporter 10 chiffres et commencer par 0',
            }
        ]}
        hasFeedback
        onChange={(e) => handleChangeInput(e)}
    >
        <Input name="phone" size="large" />
    </Form.Item>


    <Form.Item
        label="Site web"
        name="website"


        rules={[
            {
                message: "Entrez le site web",
            },
            {
                max: 120,
                message: 'L\' adresse du site ne peut pas dépasser 120 caractères!',
              }
        ]}

        onChange={(e) => handleChangeInput(e)}

    >
        <Input name="website" size="large" maxLength={120}/>
    </Form.Item>


    <Form.Item className="item-next"  style={{display:'flex', justifyContent:'center' , marginTop:'35px'}}>
                 <Button size="large" shape="round" type="primary" htmlType="submit">
                     Suivant
                 </Button>
             </Form.Item>


</Form>
  )
}

export default FormManagementCompanyPart2