import React, { useEffect, useState } from 'react'
import {useSelector } from 'react-redux'
import styled from 'styled-components'

import { SubItemMenu, SubMenu } from './avantages/AvantagesComponents'
import AccountManagement from './management/AccountManagement'
import SubscriptionManagement from './management/subscription/SubscriptionManagement'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'
const ManagementCompanyPage = (company) => {

    const { pages } = useSelector(state => state)
    const [choice, setChoice] = useState(1)
    const history = useHistory()
    const location = useLocation();

    useEffect(() => {

        const queryParams = new URLSearchParams(location.search);
        const choice = queryParams.get('choice');
        if (choice) {
            setChoice(Number(choice))

        }

        // Add more logic here as needed
    }, [location]);
    const addToUrlAndNavigate = (number) => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('choice', number); // Set 'mode' to the new value
        let newPath = location.pathname + '?' + queryParams.toString();
        setChoice(number)
  
        history.push(newPath);
      };



    return (
        <Content>

            <SubMenu>
                <SubItemMenu onClick={() => addToUrlAndNavigate(1)} active={choice === 1 ? true : false}>Compte {company.company.typeCompany === 'multi' ?"principale": ""}</SubItemMenu>
                {company.company.typeCompany === 'multi' &&  <SubItemMenu onClick={() => addToUrlAndNavigate(2)} active={choice === 2 ? true : false}>Pages</SubItemMenu>}
{  company.company.typePage !== 'partner' &&              <SubItemMenu onClick={() => addToUrlAndNavigate(3)} active={choice === 3 ? true : false}>Abonnement</SubItemMenu>
}
            </SubMenu>


            {choice === 1 && <AccountManagement/> }
            {choice === 3 && <SubscriptionManagement/> }


        </Content>
    )
}
const Content = styled.div`
    display:flex;
  margin-top: 10%;


  flex-direction:column;
  gap: 20px;

  h3{
    font-size: 1em;
    color: #585858;
    font-weight: 700;
    margin: 0px;
  }


  
  @media only screen and (max-width: 768px) {  

  }
    
`

export default ManagementCompanyPage