import React from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { GLOBALTYPES } from "../../../redux/actions/globalTypes";
import { deactivateFirstConnection } from "../../../redux/actions/profileAction";


const modalVariant = {
  initial: { opacity: 0 },
  isOpen: { opacity: 1 },
  exit: { opacity: 0 }
};
const containerVariant = {
  initial: { top: "-50%", transition: { type: "spring" } },
  isOpen: { top: "50%" },
  exit: { top: "-50%" }
};
const Modal = ({modalName, handleClose, children, isOpen, heightModal, widthModal, maxHeightModal, showOnMobile }) => {
  const dispatch = useDispatch()
  const {modal, auth} = useSelector(state=>state);



  handleClose = (e) => {
    e.stopPropagation();
    dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})

    if(modalName === 'firstConnectionModal'){
      dispatch(deactivateFirstConnection(auth))
    }
  }
  return (
      <AnimatePresence>

      {(modal.typeModal ===  modalName && modal.open ) && (
        
        <Overlay
          initial={"initial"}
          animate={"isOpen"}
          exit={"exit"}
          variants={modalVariant}
        >
          <ModalContainer  key={modalName} variants={containerVariant} width={widthModal} height = {heightModal} maxHeight={maxHeightModal}>
              
            <CloseButton
              onClick={(e)=>{handleClose(e)}}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20.39 20.39"
            >
              <title>close</title>
              <line
                x1="19.39"
                y1="19.39"
                x2="1"
                y2="1"
                fill="none"
                stroke="white"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="3"
              />
              <line
                x1="1"
                y1="19.39"
                x2="19.39"
                y2="1"
                fill="none"
                stroke="white"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="3"
              />
            </CloseButton>
            <Content>{children}</Content>
            
          </ModalContainer>
        </Overlay>
      )}</AnimatePresence>
  );
};

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  overflow-y: hidden;
  display:flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 5000;
`;
const ModalContainer = styled(motion.div)`
  width: ${props => props.width ? props.width : "40%"};
  height: ${props => props.height ? props.height : "75%"};
  max-height: ${props => props.maxHeight ? props.maxHeight : "auto"};

  min-width: 400px;
  background-color: white;
  overflow:hidden;
  position: absolute;
  top: 0%;
  transform: translate(0, -50%);
  border-radius: 12px;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    min-width: auto;
    max-height: 100%;


  }
  

`;
const CloseButton = styled.svg`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  z-index: 50;
  padding: 2px;
  border-radius: 5px;
  background: #F87778; 
`;

const Content = styled.div`
  display:flex;
  position: relative;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items:center;
  overflow-y: scroll;

  overflow: auto;

  /* width */
  ::-webkit-scrollbar {
  width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
  background: #F87778; 
  border-radius: 10px;
  
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
  }


`
export default Modal;
