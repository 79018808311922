import { Button, Carousel } from 'antd'
import React from 'react'
import { GLOBALTYPES } from '../../../redux/actions/globalTypes'
import { useDispatch } from 'react-redux'
import useWindowDimensions from '../../../utils/useWindowsDimensions'

const CarouselAds = ({ads}) => {
    const dispatch = useDispatch()
    const {width} = useWindowDimensions();
  return (
    <>
    {width >= 768 && <Carousel autoplay  autoplaySpeed={5000} speed={2000}>
      {
        ads.map((ad, index) => (
          <div key={index}>
            <div style={{
              backgroundImage: `url(${ad.imgBackground})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              borderRadius: "10px",
              display: 'flex',
              overflow: 'hidden',
              margin: "30px 0px",
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: "20px",
              height: '160px' // Set a height for your carousel item
            }}>
              <div style={{display: "flex", flexDirection: "column", gap: "5px", alignItems: "flex-start"}}>
                <img src={ad.logo} alt="logo" style={{height: "40px", objectFit: "contain"}}/>
                <h3 style={{fontSize: "2em", fontWeight: "900", margin: "0px", color: '#fff'}}>{ad.headerTitle}</h3>
                <h2 style={{fontSize: "1.2em", fontWeight: "400", margin: "0px", lineHeight: "1", color: '#fff'}}>{ad.headerSubtitle}</h2>
              </div>
              
              <Button onClick={()=> dispatch({ type: GLOBALTYPES.MODAL, payload: { typeModal: "adCardModal", open: true, ad: ad } })}  style={{marginTop: '10px'}}>Découvrir</Button>
            </div>
          </div>
        ))
      }
    </Carousel>}
    {width < 768 && <Carousel autoplay  autoplaySpeed={5000} speed={2000}>
      {
        ads.map((ad, index) => (
          <div key={index}>
            <div style={{
              backgroundImage: `url(${ad.imgBackground})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              borderRadius: "10px",
              display: 'flex',
              overflow: 'hidden',
              margin: "15px 0px",
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: "20px",
              height: '140px' // Set a height for your carousel item
            }}>
              <div style={{display: "flex", flexDirection: "column", gap: "5px", alignItems: "flex-start"}}>
                <img src={ad.logo} alt="logo" style={{height: "35px", objectFit: "contain"}}/>
                <h3 style={{fontSize: "1.3em", fontWeight: "900", margin: "0px", color: '#fff'}}>{ad.headerTitle}</h3>
                <h2 style={{fontSize: "1em", fontWeight: "400", margin: "0px", lineHeight: "1", color: '#fff'}}>{ad.headerSubtitle}</h2>
              </div>
              
              <Button onClick={()=> dispatch({ type: GLOBALTYPES.MODAL, payload: { typeModal: "adCardModal", open: true, ad: ad } })}  style={{marginTop: '10px'}}>Découvrir</Button>
            </div>
          </div>
        ))
      }
    </Carousel>}
    </>
  )
}

export default CarouselAds