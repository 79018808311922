import { GLOBALTYPES } from './globalTypes'
import { postDataAPI, getDataAPI, patchDataAPI, deleteDataAPI, putDataAPI } from '../../utils/fetchData'
import { getParamsFilter } from './userAction'


export const SUBSCRIPTION_TYPES = {
    CREATE_SUBSCRIPTION: 'CREATE_SUBSCRIPTION',
    GET_SUBSCRIPTIONS: 'GET_SUBSCRIPTIONS',
    LOADING: 'LOADING',
    DELETE_SUBSCRIPTION: 'DELETE_SUBSCRIPTION',
    ACTIVATE_SUBSCRIPTION: 'ACTIVATE_SUBSCRIPTION'
}


export const createSubscription = ({content,mailing, auth}) => async (dispatch) => {
    try {

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })
        

        const res = await postDataAPI('subscription?mailing='+mailing, content, auth.token)

        console.log(res)
        dispatch({ 
            type: SUBSCRIPTION_TYPES.CREATE_SUBSCRIPTION, 
            payload: {...res.data.newSubscription} 
        })
        dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: false} })

        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} });
        return res;

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
        return false;
    }
}


export const activateSubscription = ({content, auth}) => async (dispatch) => {
    try {

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })
        

        const res = await postDataAPI('activateSubscription', content, auth.token)

        console.log(res)
        dispatch({ 
            type: SUBSCRIPTION_TYPES.ACTIVATE_SUBSCRIPTION, 
            payload: {...res.data.newSubscription} 
        })
        dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: false} })

        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} });
        return true;

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
        return false;
    }
}

export const reactivateSubscription = ({id,content, auth}) => async (dispatch) => {
    try {

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })
        

        const res = await putDataAPI('reactivateSubscription/'+id, content, auth.token)

        console.log(res)
        dispatch({ 
            type: SUBSCRIPTION_TYPES.ACTIVATE_SUBSCRIPTION, 
            payload: {...res.data.newSubscription} 
        })
        dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: false} })

        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} });
        return true;

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
        return false;
    }
}


export const getSubscriptions= ({filter, auth}) => async (dispatch) => {
    try {
        let param = getParamsFilter(filter)

        dispatch({type: SUBSCRIPTION_TYPES.LOADING, payload: true})
        let url = `/subscription?${param}`

        const res = await getDataAPI(url, auth.token)

        dispatch({
            type: SUBSCRIPTION_TYPES.GET_SUBSCRIPTIONS,
            payload: res
        }) 

 
        dispatch({type: SUBSCRIPTION_TYPES.LOADING, payload: false}) 
        return true;

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
        return false;
    }
    
}



export const deactivateSubscription= ({subscription, auth}) => async (dispatch) => {

    try {
        const res = await deleteDataAPI(`/deactivateSubscription/${subscription._id}`, auth.token)
        dispatch({ type: SUBSCRIPTION_TYPES.DELETE_SUBSCRIPTION, payload: subscription })

        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} });

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}