import {  GLOBALTYPES } from './globalTypes'
import { postDataAPI, getDataAPI, patchDataAPI } from '../../utils/fetchData'
import { imageUpload } from '../../utils/imageUpload'

export const FILTER_DEAL_TYPES = {
    UPDATE_FILTER: 'UPDATE_FILTER',
}



export const DEAL_TYPES = {
    CREATE_DEAL: 'CREATE_DEAL',
    LOADING: 'LOADING',
    GET_DEALS:  'GET_DEALS',
    GET_DEALS_BY_COMPANY: 'GET_DEALS_BY_COMPANY',
    GET_DEAL:  'GET_DEAL',
    UPDATE_DEAL: 'UPDATE_DEAL'
}


export const updateFilterDeal = ({sector}) => async (dispatch) => {
    try {

        dispatch({ type: FILTER_DEAL_TYPES.UPDATE_FILTER, payload: {sector: sector} })


    } catch (err) {
        dispatch({ 
            type: GLOBALTYPES.ALERT, 
            payload: {
                error: err.response.data.msg
            } 
        })
    }
}

export const updateFilterDeal2 = ({filter}) => async (dispatch) => {
    try {

        console.log(filter)
        dispatch({ type: FILTER_DEAL_TYPES.UPDATE_FILTER, payload: filter })


    } catch (err) {
        dispatch({ 
            type: GLOBALTYPES.ALERT, 
            payload: {
                error: err.response.data.msg
            } 
        })
    }
}


export const createDeal = ({content,images, auth}) => async (dispatch) => {
    let media = [];
    try {
       /*  const check = validCreatePageCompany(content)
        if(check.errLength > 0)
        return dispatch({type: GLOBALTYPES.ALERT, payload: check.errMsg}) */

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })
        
         if(images.length > 0){
            media = await imageUpload(images)
        } 
        console.log(content) 
        content.images = media;

        const res = await postDataAPI('deal', content, auth.token)

        dispatch({ 
            type: DEAL_TYPES.CREATE_DEAL, 
            payload: {...res.data.newDeal, user: auth.user} 
        })

        dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: false} })

        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} })
;

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}


export const getDeals = ({auth}) => async (dispatch) => {

    try {
        dispatch({type: DEAL_TYPES.LOADING, payload: true})
        const res = await getDataAPI(`/deal`, auth.token)


        dispatch({
            type: DEAL_TYPES.GET_DEALS,
            payload: res
        }) 
 
        dispatch({type: DEAL_TYPES.LOADING, payload: false})


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
    }
    
}

const getParamsFilter =  (filterData) => {
    let param = ""

    if(filterData.search && filterData.search !== ""){
        
            if(param === ""){
                param += "search=" + filterData.search
            }
            else {
                param += "&&search=" + filterData.search
            }
        
    }

    if(filterData.sector && filterData.sector !== ""){
        
        if(param === ""){
            param += "sector=" + filterData.sector
        }
        else {
            param += "&&sector=" + filterData.sector
        }
    
    }



    return param
    
}


export const searchDeals = ({filter,auth}) => async (dispatch) => {

    try {
        console.log(filter)
        dispatch({type: DEAL_TYPES.LOADING, payload: true})

        let param = getParamsFilter(filter)
      
        let url = `/deal?`+ param
        console.log(url)
            const res = await getDataAPI(`/deal?`+ param, auth.token)

        


         dispatch({
            type: DEAL_TYPES.GET_DEALS,
            payload: res
        }) 
 
        dispatch({type: DEAL_TYPES.LOADING, payload: false}) 


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err}
        })
    }
    
}
export const getDealsByCompany = ({auth, id}) => async (dispatch) => {

    try {
        dispatch({type: DEAL_TYPES.LOADING, payload: true})
        const res = await getDataAPI(`/dealsByCompany/${id}`, auth.token)


        dispatch({
            type: DEAL_TYPES.GET_DEALS_BY_COMPANY,
            payload: res
        }) 
 
        dispatch({type: DEAL_TYPES.LOADING, payload: false})


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
    }
    
}

export const getDealsByUser = ({auth}) => async (dispatch) => {

    try {
        dispatch({type: DEAL_TYPES.LOADING, payload: true})
        const res = await getDataAPI(`/dealsByUser`, auth.token)


        dispatch({
            type: DEAL_TYPES.GET_DEALS_BY_COMPANY,
            payload: res
        }) 
 
        dispatch({type: DEAL_TYPES.LOADING, payload: false})


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
    }
    
}



export const getDeal = ({auth, id}) => async (dispatch) => {

    try {
        dispatch({type: DEAL_TYPES.LOADING, payload: true})
        const res = await getDataAPI(`/deal/${id}`, auth.token)

        dispatch({
            type: DEAL_TYPES.GET_DEAL,
            payload: res
        }) 
 
        dispatch({type: DEAL_TYPES.LOADING, payload: false})


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
    }
    
}

export const likeDeal = ({deal, auth}) => async (dispatch) => {
    const newDeal = {...deal, likes: [...deal.likes, auth.user]}
    dispatch({ type: DEAL_TYPES.UPDATE_DEAL, payload: newDeal})
    console.log(newDeal)

    try {
        await patchDataAPI(`deal/${deal._id}/like`, null, auth.token)
        
    
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const unLikeDeal = ({deal, auth}) => async (dispatch) => {
    const newDeal = {...deal, likes: deal.likes.filter(like => like._id !== auth.user._id)}
    dispatch({ type: DEAL_TYPES.UPDATE_DEAL, payload: newDeal})


    try {
        await patchDataAPI(`deal/${deal._id}/unlike`, null, auth.token)

   

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}


