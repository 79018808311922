
const initialState = {
    isOpen: false,
}

export const SIDEBAR_TYPES = {
    OPEN_SIDEBAR: 'OPEN_SIDEBAR',
    CLOSE_SIDEBAR: 'CLOSE_SIDEBAR',

}


const sidebarReducer = (state = initialState, action) => {
    switch (action.type){
        case SIDEBAR_TYPES.OPEN_SIDEBAR:
            return {
                ...state,
                isOpen: true,
                data: action.payload,
            };
        case SIDEBAR_TYPES.CLOSE_SIDEBAR:
                return {
                    ...state,
                    isOpen: false
                };
       
        default:
            return state;
    }
}


export default sidebarReducer
