import React, { useState } from 'react'
import { Button, Modal, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BiEdit } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import { BsEye } from 'react-icons/bs';
import { deleteAds } from '../../../redux/actions/adsActions';
import FormCreateAds from './form/FormCreateAds';



const data = [];
for (let i = 0; i < 100; i++) {
  data.push({
    key: i,
    headerTitle: `Offre`,
    headerSubtitle: 'Sous titite',
    company: `Boulangerie`,
    status: `En cours`,

  });
}
const AdsTab = () => {

  const {ads, auth} = useSelector(state=>state);
  const history = useHistory();
  const dispatch = useDispatch();
  const [openDelete, setOpenDelete] = useState(false);
  const [openPage, setOpenPage] = useState(false);
  const [currentAd, setCurrentAd] = useState(null)
  const columns = [
    {
      title: 'Titre',
      width: 100,
      dataIndex: 'headerTitle',
      key: 'headerTitle',
    },
    {
      title: 'Sous-Titre',
      width: 100,
      dataIndex: 'headerSubtitle',
      key: 'headerSubtitle',
    },
    {
      title: 'Entreprise',
      dataIndex: 'page',
      key: 'page',
      render: (page) => page?.name, 
      width: 150,
    },
    {
      title: 'Statut',
      dataIndex: 'status',
      key: 'status',
      width: 150,
    },

    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: 80,
      render: (text, record) => <div style={{display:"flex", gap: "5px"}}>
         <Button onClick={(e)=>{setCurrentAd(record); showModalPage()}} size="large" icon={<BiEdit />}  />

         <Button  onClick={(e)=>{setCurrentAd(record); showModalDelete()}}  type="primary" danger size="large" icon={<MdDelete />}  />

      </div>,
    },
  ];
  const showModalDelete = () => {
    if(currentAd){
      setOpenDelete(true);
    }
  };
  const handleDeleteOk = () => {

    setOpenDelete(false);
    dispatch(deleteAds({ad:currentAd, auth}))

  };

  const handleDeleteCancel = () => {
    setOpenDelete(false);
  };

  const showModalPage = () => {
   if(currentAd){
    setOpenPage(true)
   }
  };


  const handlePageCancel = () => {
    setOpenPage(false);
  };

  return (

    <>
    <Table
    columns={columns}
    dataSource={ads.ads}
    scroll={{
      x: 1500,
     
    }}
    onRow={(record, rowIndex) => {
      return {
        onClick: (event) => {setCurrentAd(record);}, // click row
    
      };
    }}
  />

<Modal
        open={openDelete}
        title="Supprimer page"
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >

      Êtes-vous sûr de vouloir supprimer cette pub?
    </Modal>

    <Modal title="Modifier Page" open={openPage} onCancel={handlePageCancel} footer={null} >
      <FormCreateAds ad={currentAd}  />
{/*                 <FormCreatePartner page={currentPage}/>
 */}     </Modal>
  </>
  )
}

export default AdsTab