import { GLOBALTYPES } from './globalTypes'
import { postDataAPI, getDataAPI, patchDataAPI, deleteDataAPI, putDataAPI } from '../../utils/fetchData'

import { imageUpload } from '../../utils/imageUpload'
import { getParamsFilter } from './userAction'

export const ADS_TYPES = {
    CREATE_ADS: 'CREATE_ADS',
    UPDATE_ADS: 'UPDATE_ADS',
    GET_ADS: 'GET_ADS',
    DELETE_AD: 'DELETE_AD',

    LOADING: 'LOADING',
}


export const createAds = ({content,logo,imgBackground, auth}) => async (dispatch) => {
    let logoImg = [];
    let imgBackgroundImg = [];
    try {


        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })
        
        if(logo.length > 0){
            logoImg = await imageUpload(logo)
        } 
    
        content.logo = logoImg[0].url;

        if(imgBackground.length > 0){
            imgBackgroundImg = await imageUpload(imgBackground)
        } 
        content.imgBackground = imgBackgroundImg[0].url;

  
        const res = await postDataAPI('mainAdvertisement', content, auth.token)

        dispatch({ 
            type: ADS_TYPES.CREATE_ADS, 
            payload: {...res.data.newAds, user: auth.user} 
        })

        dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: false} })

        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} });


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const updateAd = ({id,content,logo,imgBackground, auth}) => async (dispatch) => {
    let logoImg = [];
    let imgBackgroundImg = [];
    try {
        console.log(id)
        console.log(content)


        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })
        
        if(logo.length > 0){
            logoImg = await imageUpload(logo)
            content.logo = logoImg[0].url;

        } 
    

        if(imgBackground.length > 0){
            imgBackgroundImg = await imageUpload(imgBackground)
            content.imgBackground = imgBackgroundImg[0].url;

        } 

  
        const res = await putDataAPI('mainAdvertisement/'+id, content, auth.token)

        dispatch({ 
            type: ADS_TYPES.UPDATE_ADS, 
            payload: {...res.data.newAds, user: auth.user} 
        })

        dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: false} })

        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} });


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const getAds = ({filter,auth}) => async (dispatch) => {
    try {
        dispatch({type: ADS_TYPES.LOADING, payload: true})

        let param = getParamsFilter(filter)
      
    
        const res = await getDataAPI(`/mainAdvertisements?`+ param, auth.token)


        dispatch({
            type: ADS_TYPES.GET_ADS,
            payload: res
        }) 
 
        dispatch({type: ADS_TYPES.LOADING, payload: false})


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
    }
    
}


export const deleteAds= ({ad, auth}) => async (dispatch) => {

    try {
        const res = await deleteDataAPI(`mainAdvertisement/${ad._id}`, auth.token)
        dispatch({ type: ADS_TYPES.DELETE_AD, payload: ad })

        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} });

        
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}




