import React, { useEffect } from 'react'
import { useHistory } from "react-router-dom";

import styled from 'styled-components'
import { useSelector } from 'react-redux';
import { IoMdAdd, IoMdAddCircle } from 'react-icons/io';
import { Link } from 'react-router-dom';
import CreatePageModal from '../components/settings/accounts/CreatePageModal';
import { useDispatch } from 'react-redux'
import { getPagesByUserID } from '../redux/actions/pageAction';
import Avatar from '../components/Avatar';
import { GLOBALTYPES } from '../redux/actions/globalTypes';
import { IoMdTrash } from 'react-icons/io'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { v4 as uuidv4 } from 'uuid';
import GroupeModal from '../components/settings/accounts/GroupeModal';

const Accounts = () => {

    const { auth, pages, modal} = useSelector(state=>state)
    const dispatch = useDispatch()
    const history = useHistory();

    useEffect(() => {
        if(auth.token) {
          dispatch({ type: GLOBALTYPES.THEME, payload: {theme: 'company'} })

          dispatch(getPagesByUserID({auth}))
        }
      }, [dispatch, auth])

    useEffect(() => {
      
    
      return () => {
        dispatch({ type: GLOBALTYPES.THEME, payload: {theme: 'normal'} })
      }
    }, [])
    

    const redirect = (page) => {
        if(page.typeCompany === 'multi'){
            dispatch({ type: GLOBALTYPES.MODAL,payload: {typeModal:"groupeModal", open:true,page:page}})
            return;
        }
        else if(page.typePage === "company" || page.typePage === "partner"){
            history.push(`/company/${page._id}`)
        }
        
    }
    const isInArray = (array, id) => {
        let res = false;
       array.forEach(element => {
           if(element._id === id){
               res = true;
           }
     });
     return res;
     }
  
    
    return (
        <>
        
        {true &&
         <Container>
         <Main>
             
             <Content>
                  <CreatePageModal />

                  {
                    modal.typeModal === 'groupeModal' && <GroupeModal/>
                  }
                   
                { <PanelInfo>
                     <h1>ESPACE ENTREPRISE</h1>
                     <p>Bonjour {auth.user.fullname}! Vous retrouverez votre espace pour créer et gérer vos pages “entreprise”. </p>
                 </PanelInfo>}
 
                 <h3>Mes pages </h3>
 
                 <ListActions>
 
                          {
                             pages.pages.map(page => (
                               <> {

                                    page.typeCompany !== 'franchise' && 
                                 <Page onClick={(e) => redirect(page)} key={uuidv4()}>
                                      <Avatar src={page.avatar} size="50%" />
                                      <p>{page.name}</p>
                                      { (page.typePage ==="company" && page.typeCompany !== 'multi') && <h4>(Entreprise)</h4>}
                                      { page.typeCompany ==="multi" && <h4>(Groupe)</h4>}
                                      { (page.typePage ==="partner") && <h4>(Partenaire)</h4>}

                                 </Page> } 
                                 </>
                             ))
                         }
                         <Action onClick={() => {dispatch({ type: GLOBALTYPES.MODAL,payload: {typeModal:"createPageModal", open:true}})}}>
                         <IoMdAdd className="icon"/> <span> Créer une page  </span></Action>
 
                 </ListActions>
            
                
 
             </Content>
             
         </Main>
         
 
  
     </Container> }

     {
         false && 

         <ContainerMobile>
             {(modal.typeModal ===  "createPageModal" && modal.open) && <CreatePageModal />}

             <HeaderTopMobile>
                 <div onClick={() => history.push('/settings')}><AiOutlineArrowLeft className="icon-arrow" style={{fontSize: "1.5em"}} /></div>
                <h2>Mes pages</h2>
                <div onClick={() => history.push('/pageCreation')}><IoMdAddCircle className="icon-add" style={{fontSize: "2em"}}/></div>
             </HeaderTopMobile>

             <h3>Vous pouvez créer et gérer vos pages ici</h3>

             {
                pages.pages.map(page => (
                    <PageMobile onClick={(e) => redirect(page)} key={uuidv4()}>
                        {
                        isInArray(page.admins, auth.user._id) && 
                        <ButtonDeleteMobile  onClick={(e)=> { e.stopPropagation(); dispatch({ type: GLOBALTYPES.MODAL,payload: {type:"page",typeModal:"delete",title:"Etes-vous sûr de vouloir supprimer cette page?", page:page, open:true}})}}><IoMdTrash/></ButtonDeleteMobile>
                        }
                           
                            <Avatar src={page.avatar} size="100px" />
                        <p>{page.name}</p>
                        { page.typePage ==="company" && <h4>(Entreprise)</h4>}
{/*                         <ManageButton onClick={(event) => {event.stopPropagation(); history.push('/management-users/'+ page._id)}} >Gérer</ManageButton>
 */}                    </PageMobile>
                             ))
            }


         </ContainerMobile>
     }
       
        </>
    

    )
}

const Container = styled.nav`
    width: 100%;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 768px) {
      width: 100%;
      margin-left: 0%;
      margin-right: 0%;
    }  
`;


 const Main = styled.div`
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 0px 10px;
    }
    .topbar{
        display:none;
            
    }
`;

 const Content = styled.div`
    width: 80%;
    display:flex;
    flex-direction: column;

    h3 {
        font-size: 20px;
        font-weight: 700;
        margin-top: 25px;
        color: var(--color-dark-grey);
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      margin-top: 0px;

      h3{

          margin-top: 0px;
          font-size: 1.2em;
          margin-left: 10px;


      }
    }
`;



const PanelInfo = styled.div `
    
    background: var(--color-accent);
    border-radius: 15px;
    color: white;

    padding: 10px;

    h1{
        font-size: 30px;
        text-align: center;
        font-weight: 900;

    }

    @media screen and (max-width: 768px) {
        margin: 30px 10px;
        margin-bottom: 15px;
        padding: 5px 10px;
        border-radius: 10px;

        h1{
            font-size: 1.2em;
            
        }

        span{
            font-size:0.9em;
        }
    }

`
const ListActions = styled.div `
    display: grid;
    grid-template-columns:repeat(4, 1fr);
    grid-template-rows:repeat(4, auto);
    grid-gap: 20px;
    
    @media screen and (max-width: 768px) {
        grid-template-columns:repeat(2, 1fr);
        grid-template-rows:repeat(2, auto);
        grid-gap: 0px;
        margin-bottom: 20px;
    }
    `
const Page = styled.div `
    position: relative;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    width: 200px;
    height: 200px;
    border-radius: 15px;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 25px;


    p{
        width: 80%;
        text-align: center;
        padding: 0px;
        margin: 0px;
        font-weight: 600;
        font-size:16px;
    }

    h4{
        padding: 0px;
        margin: 0px;
        font-size: 14px;
        font-weight: 400;
    }
    .icon {
        font-size: 90px;
        color: var(--color-accent);
    }

    &:hover {
        background: var(--color-accent);
        color: white;

        .icon {
            color: white;
        }
    }

    @media screen and (max-width: 768px) {
        width: 130px;
        height: 130px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        
    
        p{
            font-size: 0.7em;
        }
        
        h4{
            display:none;
            
        }
    }
`
const Action = styled.div `

    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    width: 200px;
    height: 200px;
    border-radius: 15px;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 25px;

    span{
        width: 80%;
        text-align: center;

    }
    .icon {
        font-size: 90px;
        color: var(--color-accent);
    }

    &:hover {
        background: var(--color-accent);
        color: white;

        .icon {
            color: white;
        }
    }

    @media screen and (max-width: 768px) {
        width: 130px;
        height: 130px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

        span{
            display:none;
        }
    }
`

export const ActionLink = styled(Link)`
    
    color: black;
    text-decoration: none;
    z-index: 100;
    &:hover {
        text-decoration: none;
    }

`
const ButtonDelete = styled.div`
    position: absolute;
    display:flex;
    align-items: center;
    justify-content: center;
    font-size: 1.7em;
    border-radius: 50%;
    top: 5px;
    right: 5px;
    &:hover{
        cursor: pointer;
        background: white;
        color: crimson;
    }



`
const ContainerMobile = styled.div`
    display:flex;
    flex-direction: column;
    width: 100%;

    align-items: center;

    h3{
        font-size: 0.8em;
        font-weight: 500;
        color: #757474;
        margin:0px;
        margin-top: 8%;
        margin-bottom: 8%;
    }
`

const HeaderTopMobile = styled.div`
    display:flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    color: var(--color-accent);

    h2 {
        font-size: 1em;
        margin: 0px;
        color: var(--color-dark-grey);
    }

    padding: 0% 4%;
    
`

const ButtonDeleteMobile = styled.div`
    position: absolute;
    display:flex;
    align-items: center;
    justify-content: center;
    font-size: 1.7em;
    border-radius: 50%;
    top: 5px;
    right: 5px;
    color: #C4C4C4;

    &:hover{
        cursor: pointer;
        background: white;
        color: crimson;
    }



`
const PageMobile = styled.div `
    position: relative;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    width: 90%;
    height: 200px;
    border-top: 2px solid #eee;


    p{
        width: 80%;
        text-align: center;
        padding: 0px;
        margin: 0px;
        font-weight: 600;
        font-size:16px;
    }

    h4{
        padding: 0px;
        margin: 0px;
        font-size: 14px;
        font-weight: 400;
    }
    .icon {
        font-size: 90px;
        color: var(--color-accent);
    }


 
`

const ManageButton = styled.div`

    display:flex;
    justify-content:center;
    align-items: center;
    border: 1px solid var(--color-accent);
    color: var(--color-accent);
    border-radius: 5px;
    padding: 1% 5%;
    margin-top: 5%;
    font-size: 0.9em;


`


export default Accounts
