import { GLOBALTYPES } from './globalTypes'
import { postDataAPI, getDataAPI, patchDataAPI, deleteDataAPI } from '../../utils/fetchData'

export const DATA_TYPES = {

    LOADING: 'LOADING',
    GET_DATAS:  'GET_DATAS',

}




export const getCompanyTypes = () => async (dispatch) => {
    console.log("get compnay")

    try {
        dispatch({type: DATA_TYPES.LOADING, payload: true})
        const res = await getDataAPI(`/datas/fr`)
        console.log(res)

        dispatch({
            type: DATA_TYPES.GET_DATAS,
            payload: res
        }) 
 
        dispatch({type: DATA_TYPES.LOADING, payload: false})


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
    }
    
}






