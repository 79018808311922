import React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import PageChoice from './PageChoice'
import OfferPresentation from '../OfferPresentation'
import OfferModality from '../OfferModality'
import FinalStep from '../FinalStep'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PartnersChoice from './PartnersChoice'

const PartnersPromo = ({ formData, setFormData }) => {
    const [step, setStep] = useState(1)
    const {modal} = useSelector(state=>state)

    useEffect(() => {
      if(modal.deal){
        setStep(2);
      }
    
    }, [])
    
    const handleStepChange = (step) => {
        setStep(step);
      };

    return (
    <Content>
        
        {step === 1 && <PageChoice type='promo' step={step} onStepChange={handleStepChange} formData={formData} setFormData={setFormData}/>}
        {step === 2 && <PartnersChoice type='promo' step={step} onStepChange={handleStepChange} formData={formData} setFormData={setFormData}/>}

      
        {step === 3 && <OfferPresentation step={step} onStepChange={handleStepChange} formData={formData} setFormData={setFormData}/>}
        {step === 4 && <OfferModality step={step} onStepChange={handleStepChange} formData={formData} setFormData={setFormData}/>}
        {step === 5 && <FinalStep step={step} onStepChange={handleStepChange} formData={formData} setFormData={setFormData}/>}

    </Content>
  )
}

const Content = styled.div`
    display:flex;
    width: 100%;
    flex: 1;

    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    h2{
        font-size:1.6em;
        font-weight: 900;
        color: #585858;
        text-align:center;
    }

`

export default PartnersPromo