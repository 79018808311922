import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Logo from '../../../../assets/logo-violet.png'
import PromoCreation from '../../../deal/DealCreation/promo/PromoCreation'
import Modal from '../../../utils/Modal/Modal'
import OfferPresentation from '../../../deal/DealCreation/promo/OfferPresentation'
import OfferModality from '../../../deal/DealCreation/promo/OfferModality'
import FinalStep from '../../../deal/DealCreation/promo/FinalStep'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'


const CreateDealModal = () => {
    const { pages, modal } = useSelector(state => state)
    const [typeOffer, setTypeOffer] = useState('promo')
    const [step, setStep] = useState(1)
    const  {id} = useParams();


    const initialState = {
     pages:[id], typeDeal:"partner",  offer: '', date: [], amountPersons: '', address: '', description: '', phone: '', email: '', website: '', localisation: '', sector: ''
    }

    const [formData, setFormData] = useState(modal.deal?modal.deal:initialState)


    useEffect(() => {
        return () => {
            setStep(1);
            setTypeOffer('')
            setFormData(initialState)
        }
    }, [])

    useEffect(() => {
        if (modal.deal) {
            setTypeOffer(modal.deal.type)
            setStep(1);

        }
    }, [modal])

    const handleStepChange = (step) => {
        setStep(step);
    };

    return (
        <Modal modalName={"createDealPartnerModal"} widthModal={"45vw" }heightModal={"90vh"}>


            <Container>
                <img className="logo" src={Logo} alt='logo' />
                <ContentForm>
        {step === 1 && <OfferPresentation step={step} onStepChange={handleStepChange} formData={formData} setFormData={setFormData}/>}
        {step === 2 && <OfferModality step={step} onStepChange={handleStepChange} formData={formData} setFormData={setFormData}/>}


                </ContentForm>
               

            </Container>


        </Modal>
    )
}


const ContentForm = styled.div`
    display:flex;
    flex-direction:column; 
    width: 100%;
    flex: 1;

    @media screen and (max-width: 768px) {
        padding: 3% 0%;
       

    } 
`
const Container = styled.div`
    display:flex;
    position: relative;
    flex-direction: column;
    align-items:center;
    height: 100%;
    width: 100%;
    h1{
        font-size: 1.5em;
        font-weight: bold;
        margin: 0px;
    }
    padding: 2% 5%;

    .logo{
        width: 150px;
    }
`



export const ShowImages = styled.div`

    max-height: 270px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    place-items: center;
    padding: 10px 0;
    grid-gap: 10px;

    #file_img{
        position: relative;
        width: 100%;
        height: 100%;
    }

    #file_img img, #file_img video{
        display: block;
        object-fit: contain;
        width: 100%;
        height: 100%;
        max-height: 100px;
    }
`

export const CrossImage = styled.div`
    position: absolute;
    top: -1px;
    right: -2px;
    z-index: 4;
    background: white;
    color: crimson;
    padding: 3px 7px;
    border: 1px solid crimson;
    border-radius: 50%;
    font-size: 10px;
    font-weight: bolder;
    cursor: pointer;

`
export const Stream = styled.div`
    position: relative;
`

export const CrossStream = styled.div`
    position: absolute;
    top: -10px;
    right: 5px;
    color: crimson;
    font-size: 2rem;
    font-weight: 900;
    cursor: pointer;
`

export default CreateDealModal