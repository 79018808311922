import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import styled from "styled-components"
import { createLanguage, updateLanguage } from '../../../../redux/actions/languageAction'
import StarRating from './StarRating'
import logo from '../../../../assets/logo.png'
import { GLOBALTYPES } from '../../../../redux/actions/globalTypes'
import { ButtonValidate, Container, ContentCV, CrossCV, FooterCV, FormCV, HeaderCV, InputStyledCV } from '../experience/AddExperience'
import { isMobile } from 'react-device-detect'
import FormAlertMessage from '../../../FormAlertMessage'
import Modal from '../../../utils/Modal/Modal'


const AddLanguage = () => {
    
    const { auth, modal,alert } = useSelector(state => state)
    const dispatch = useDispatch()

    const [ratingLanguage, setRatingLanguage] = useState(0);

    const getRate = (childdata) => {
        setRatingLanguage(childdata)
    }



    const initialState = { 
        language:'', rating: 0
    }

    const [languageData, setLanguageData] = useState(initialState)
    const { language} = languageData



    useEffect(() => {
        dispatch({ type: GLOBALTYPES.ALERT, payload: {}})

    
        return () => {
    
            dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})
        }
    }, [])

    useEffect(() => {
        if(modal.language){
            let language = {
                language: modal.language.language,
                rating: modal.language.rating
            }
            console.log(language)
            setLanguageData(language)
        }else{
            setLanguageData(initialState)
        }
    }, [modal])
    

    const handleChangeInput = e => {
        const { name, value } = e.target
        setLanguageData({...languageData, [name]:value})

    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const data= {
            ...languageData, rating: ratingLanguage
        }
        console.log(data)
        if(modal.onEdit){
            dispatch(updateLanguage({id: modal.language._id, data, auth}))
        }else{
            dispatch(createLanguage({data, auth}))

        }
    }
  
   

    return (
        <Modal modalName={"addLanguage"} >          
     

              <Container>
                <h2>{modal.onEdit? "Modifier une langue": "Ajouter une langue"} </h2>
                <ContentCV>
     

                <h2 style={{marginTop: isMobile?"5%": "0%"}}>Langue*</h2>
                <InputStyledCV name="language" id="language" value={language} onChange={handleChangeInput} placeholder="Ex: Français, Anglais..." autoComplete={"off"} required/>
                <FormAlertMessage message= {alert.language ? alert.language : ''}/>

                <h2>Niveau*</h2>
                <div style={{display:"flex", justifyContent:"center"}}>
                            
                    <StarRating size="60px" getRate={getRate} rate={modal.language?modal.language.rating:0}/>

                </div>
                <FormAlertMessage message= {alert.rating ? alert.rating : ''}/>


                </ContentCV>


                <FooterCV>
                    <ButtonValidate onClick={handleSubmit}>
                    {
                        modal.onEdit?<span>Modifier</span>:<span>Ajouter</span>
                    }
                    </ButtonValidate>
                </FooterCV>
            </Container>
        </Modal>
    )
}



export default AddLanguage
