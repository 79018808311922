import React, { useState, useEffect } from "react";
import styled from "styled-components"

const StarRating = ({size, getRate, rate=0}) => {
    const [rating, setRating] = useState(rate);
    const [hover, setHover] = useState(0);

 
    return (
      <Stars >
        {[...Array(5)].map((star, index) => {

          index += 1;
          return (
            <button
              type="button"
              key={index}
              className={index <= (hover || rating) ? "on" : "off"}
              onClick={() => {setRating(index); getRate(index)}}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(rating)}
            >
              <Star size={size} className="star">&#9733;</Star>
            </button>
          );
        })}
      </Stars>
    );
  };

const Stars = styled.div `

  button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;


  }
  .on {
    color: #F87778;
  }
  .off {
    color: #ccc;
  }

`

const Star = styled.div `
    font-size:${props => props.size ? props.size : "50px"}; 
`

export default StarRating;