import { Button, Form, Input, Select } from 'antd'
import React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import bed from '../../../../assets/offers/bed.png'
import heart from '../../../../assets/offers/heart.png'
import laugh from '../../../../assets/offers/laugh.png'
import spa from '../../../../assets/offers/spa.png'
import traiteur from '../../../../assets/offers/traiteur.png'
import bar from '../../../../assets/offers/bar.png'

import styled from 'styled-components'
import { useEffect } from 'react'
import { MdPhotoLibrary } from 'react-icons/md'
import { GLOBALTYPES } from '../../../../redux/actions/globalTypes'
import { imageShow, videoShow } from '../../../../utils/mediaShow'
import { HeaderModal } from './PageChoice'
import SearchAddress from '../../../utils/SearchAddress'

const OfferPresentation = ({ step, onStepChange, formData, setFormData }) => {

    const { modal, theme , datas} = useSelector(state => state)
    const dispatch = useDispatch();
    const [selectedTags, setSelectedTags] = useState([])
    const [selectedTagsDescription, setSelectedTagsDescription] = useState([])

    const [isLoading, setIsLoading] = useState(true);
    const [images, setImages] = useState([])
    const [localisationText, setLocalisationText] = useState("");
    const [localisationObject, setLocalisationObject] = useState(null);
    useEffect(() => {
     
        //If edit
        if(modal.deal){
            setSelectedTags(modal.deal.tags)
            setSelectedTagsDescription(modal.deal.tagsDescription)
            setImages(modal.deal.images)
            setFormData({ ...formData, ['images']: modal.deal.images })
            if(modal.deal.localisation){
                setLocalisationText(modal.deal.localisation.formatted_address)
                setLocalisationObject(modal.deal.localisation)
                setFormData({ ...formData, localisation: modal.deal.localisation })
            }


        }
    }, [])
    

    useEffect(() => {
        if(formData && formData.localisation){
            setLocalisationText(formData.localisation.formatted_address)
            setLocalisationObject(formData.localisation)
        }

    }, [])

    useEffect(() => {
        setFormData({ ...formData, ['tags']: selectedTags })
        setIsLoading(false)

    }, [selectedTags])
    
    useEffect(() => {
        setFormData({ ...formData, ['tagsDescription']: selectedTagsDescription })
        setIsLoading(false)

    }, [selectedTagsDescription])

    useEffect(() => {
        setFormData({ ...formData, ['images']: images })

    }, [images])



    const handleAddressChange = (newAddress) => {

        setLocalisationText(newAddress.formatted_address) 
        const { formatted_address,address_components, geometry, geoFormat } = newAddress;
        const newObject = { formatted_address,address_components, geometry, geoFormat };
        setLocalisationObject(newObject)
        console.log(newObject)


    
    }

    const handleChangeInput = e => {
        const { id, value } = e.target
        setFormData({ ...formData, [id]: value })
    }

    const deleteImages = (index) => {
        const newArr = [...images]
        newArr.splice(index, 1)
        setImages(newArr)
    }


    const handleChangeImages = e => {
        const files = [...e.target.files]
        let err = ""
        let newImages = []

        files.forEach(file => {
            if (!file) return err = "File does not exist."

            if (file.size > 1024 * 1024 * 2) {
                return err = "The image/video largest is 2mb."
            }

            return newImages = [file]
        })
        console.log(files)

        if (err) dispatch({ type: GLOBALTYPES.ALERT, payload: { error: err } })
        setImages(newImages)
    }

    const validateMessages = {
        required: '${label} requis!',
        types: {
            email: "${label} invalide!",
            number: '${label} is not a valid number!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    };

    const handleNext = () => {
        setFormData({ ...formData, localisation: localisationObject })

        console.log(formData)
        dispatch({ type: GLOBALTYPES.TEMP, payload: { next: true } });
        onStepChange(step + 1)
    }

    const handleChange = (value, type) => {
        console.log(`selected ${value}`);
        if(type === 'tags'){
            setSelectedTags(value)
        }else{
            setSelectedTagsDescription(value)
        }            

      };
      
   
    const filters = datas.filters.map(filter => ({ value: filter.id, label: filter.name }));

    return (
        <Content>

            <HeaderModal>
                {
                    formData.target === "global" && <h2>{modal.deal?'Modifier':'Créer'} une promotion pour la communauté</h2>
                }
                {
                    formData.target === "targeted" && <h2>{modal.deal?'Modifier':'Créer'} une promotion ciblée</h2>
                }
                {
                    formData.target === "custom" && <h2>{modal.deal?'Modifier':'Créer'} une promotion sur-mesure</h2>
                }

                <h3>Présentation de l'offre</h3>

            </HeaderModal>


          { !isLoading && <Form
                onFinish={() => { handleNext() }}
                layout="vertical"
                style={{ width: "100%" }}
                initialValues={{ offer: formData.offer, description: formData.description, tags: selectedTags, tagsDescription:selectedTagsDescription }}
                validateMessages={validateMessages}
            >

                    
                <Form.Item
                    label="Type(s) de promo"
                    name="tags"



                    required={true}
                    rules={[
                        {
                            validator: (_, value) =>
                                selectedTags.length > 0 ? Promise.resolve() : Promise.reject(new Error('Veuillez choisir un type!'))
                        }
                    ]}
                >

                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Choississez au moins un type"
                        onChange={(e) => handleChange(e,'tags')}
                        options={filters}
                        size="large"
                        dropdownStyle={{ zIndex: "100000" }}
                        />
                </Form.Item>

                

                <Form.Item
                    label="Titre de l'offre"
                    name="offer"
                    onChange={(e) => handleChangeInput(e)}
                    rules={[
                        {
                            required: true,
                            message: 'Entrez votre titre!',
                        },
                        {
                            max: 140,
                            message: 'Le titre ne peut pas dépasser 140 caractères!',
                          }
                    ]}
                >
                    <Input size="large" maxLength={140} />
                </Form.Item>




                <Form.Item
                label="Localisation"
                name="adress"

            >
                <SearchAddress onAddressChange={handleAddressChange} address={localisationText} setAddress={setLocalisationText} />


            </Form.Item>



                <Form.Item
                    label="Description"
                    name="description"
                    onChange={(e) => handleChangeInput(e)}
                    hasFeedback
                    rules={[
                        {
                            max: 1000,
                            message: 'La description ne peut pas dépasser 1000 caractères!',
                          }
                    ]}

                >
                    <Input.TextArea size='large' maxLength={1000} showCount />
                </Form.Item>

                <Form.Item
                    label="Tag(s) de promo"
                    name="tagsDescription"



                    required={true}
                    rules={[
                        {
                            validator: (_, value) =>
                                selectedTagsDescription.length > 0 ? Promise.resolve() : Promise.reject(new Error('Veuillez choisir un tag!'))
                        }
                    ]}
                >

                    <Select
                        mode="tags"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Choississez au moins un tag"
                        onChange={(e) => handleChange(e,'tagsDescription')}
                        size="large"
                        dropdownStyle={{ zIndex: "100000" }}
                        />
                </Form.Item>




                <Form.Item
                    label="Photo de présentation de l'offre"
                    name="photo"
                    rules={[
                        {
                          validator: (_, value) => {
                            console.log(images)
                            if (images.length === 0 || images[0].url) {
                                return Promise.resolve();
                            }
                            const file = images[0];
                            const isLessThan2MB = file.size / 1024 / 1024 < 2;
                            if (!isLessThan2MB) {
                              return Promise.reject(new Error('Le fichier ne doit pas dépasser 2MB.'));
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}

                >

                    <ShowImages>
                        {
                            images.map((img, index) => (
                                <div key={index} id="file_img">
                                    {
                                        img.camera ? imageShow(img.camera, theme)
                                            : img.url
                                                ? <>
                                                    {
                                                        img.url.match(/video/i)
                                                            ? videoShow(img.url, theme)
                                                            : imageShow(img.url, theme)
                                                    }
                                                </>
                                                : <>
                                                    {
                                                        img.type.match(/video/i)
                                                            ? videoShow(URL.createObjectURL(img), theme)
                                                            : imageShow(URL.createObjectURL(img), theme)
                                                    }
                                                </>
                                    }
                                    <CrossImage onClick={() => deleteImages(index)}>&times;</CrossImage>
                                </div>
                            ))
                        }
                    </ShowImages>
                    <InputImages>
                        <>

                            <FileUpload>
                                <MdPhotoLibrary className="iconLibrary" />
                                <input type="file" name="file" id="file"
                                    multiple  accept=".jpg, .jpeg, .png"  onChange={handleChangeImages} />
                            </FileUpload>
                        </>


                    </InputImages>
                </Form.Item>


                <Form.Item className="item-next" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button size="large" shape="round" type="primary" htmlType="submit">
                        Suivant
                    </Button>
                </Form.Item>
            </Form>
}







        </Content>
    )
}

const Content = styled.div`
    display:flex;
    width: 100%;
    flex: 1;

    flex-direction: column;
    justify-content: space-around;
    align-items: center;


    .selectAll{
        display:flex;
        justify-content: end;
        width:100%;
    }



`


const TagsChoices = styled.div`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 15px;
        width: 100%;


        @media screen and (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        } 

`

const Tag = styled.div`
    
    padding: 5px 15px;
    line-height: 1.2; 
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background-color: ${props => props.selected ? 'var(--color-accent)' : 'transparent'};
    text-align: center;
    color: ${props => props.selected ? 'white' : ''};

    img{
        width: 20px !important; 
        height: 20px !important;
        margin-right: 5px;
    }



    &:hover{
        cursor: pointer;
        transform: scale(1);
        background: #fafafa;
        color:black;
    }

`

const InputImages = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 45px;

    .iconLibrary {
        transform: translateY(-8px);
        color: #45b061;

       
        &:hover{
            color:#6193c9;
        }
    }

    .iconPhoto {
        color: #5886b8;

        &:hover{
            color:#6193c9;
        }
    }

    

`

const FileUpload = styled.div`
    overflow: hidden;
    margin: 0 10px;
    position: relative;

    #file{
        position: absolute;
        top:0;
        left: 0;
        opacity: 0;



`

export const ShowImages = styled.div`

    max-height: 270px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    place-items: center;
    padding: 10px 0;
    grid-gap: 10px;

    #file_img{
        position: relative;
        width: 100%;
        height: 100%;
    }

    #file_img img, #file_img video{
        display: block;
        object-fit: contain;
        width: 100%;
        height: 100%;
        max-height: 100px;
    }
`

export const CrossImage = styled.div`
    position: absolute;
    top: -1px;
    right: -2px;
    z-index: 4;
    background: white;
    color: crimson;
    padding: 3px 7px;
    border: 1px solid crimson;
    border-radius: 50%;
    font-size: 10px;
    font-weight: bolder;
    cursor: pointer;

`
export default OfferPresentation