import React, { useEffect, useState } from 'react'

import CreateCode from './CreateCode';

const FormCreateCode = ({ad}) => {
    const [formData, setFormData] = useState(ad?ad:null)

    const [step, setStep] = useState(1);

    useEffect(() => {
   
    
      return () => {
        setFormData(null)
      }
    }, [])
    

    

    return (

        <>
            <
            >

                {step === 1 && 
                    <CreateCode  isEdit={ad?true:false} formData={formData} setFormData={setFormData} step={step} setStep={setStep} />
                }

        
 

        
            </>

        </>
    )
}

export default FormCreateCode