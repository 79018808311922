import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { createFormation, updateFormation } from '../../../../redux/actions/formationAction'
import { GLOBALTYPES } from '../../../../redux/actions/globalTypes'
import { Container } from '../experience/AddExperience'
import Modal from '../../../utils/Modal/Modal'

import { Button, Checkbox, DatePicker, Form, Input } from 'antd'
import SearchAddress from '../../../utils/SearchAddress'
import dayjs from 'dayjs'
import 'dayjs/locale/fr';
import locale from 'antd/es/date-picker/locale/fr_FR';


const AddFormation = ({ }) => {

    const { auth, modal } = useSelector(state => state)
    const dispatch = useDispatch()

    const [form] = Form.useForm(); // Using Form hook to create an instance
    const [formData, setFormData] = useState(null)
    const [localisationText, setLocalisationText] = useState("")
    const [localisationObject, setLocalisationObject] = useState(null)

    useEffect(() => {

        if (modal.formation) {
            setFormData(modal.formation)
            setLocalisationObject(modal.formation.localisation)
        }else{
            setFormData({currentformation:false})

        }
        dispatch({ type: GLOBALTYPES.ALERT, payload: {} })

        return () => {

            dispatch({ type: GLOBALTYPES.MODAL, payload: { open: false } })
        }
    }, [])

    useEffect(() => {
        if (localisationObject) {
            setLocalisationText(`${localisationObject.formatted_address}   `)
        }
    }, [localisationObject])


    const submit = (e) => {


        if (formData.currentformation)
            formData.end = null

        formData.localisation = localisationObject

        if (modal.onEdit) {

            setLocalisationObject(modal.formation.localisation)
            const data = formData

            dispatch(updateFormation({ id: modal.formation._id, data, auth }))
        } else {
            const data = formData

            dispatch(createFormation({ data, auth }))

        }
    }


    const onDateChange = (date, dateString, value) => {
        setFormData({ ...formData, [value]: dateString })

    };


    const handleChangeInput = e => {
        const { id, value } = e.target
        setFormData({ ...formData, [id]: value })
    }

    const handleAddressChange = (newAddress) => {

        setLocalisationText(newAddress.formatted_address)
        const { formatted_address, address_components, geometry } = newAddress;
        const newObject = { formatted_address, address_components, geometry };
        setLocalisationObject(newObject)

    }

    const handleCheckboxChange = (e) => {
        const { id, checked } = e.target;
        setFormData({ ...formData, [id]: checked })
    };




    return (
        <Modal modalName={"addFormation"}>
            <Container>
                <h2>{modal.onEdit ? "Modifier une formation" : "Ajouter une formation"}</h2>


                <Form
                    onFinish={() => { submit() }}
                    layout="vertical"
                    style={{ width: "100%" }}
                    initialValues={{ // Setting initial values here
                        start: modal.formation && dayjs(modal.formation.start),
                        end: modal.formation && dayjs(modal.formation.end),
                        formationname: modal.formation && (modal.formation.formationname),
                        schoolname: modal.formation && (modal.formation.schoolname),
                        description: modal.formation && modal.formation.description,

                    }}
                    form={form}

                >

                    <Form.Item name="checkbox-group" valuePropName="checked">
                        <Checkbox
                            id="currentformation"
                            name="currentformation"

                            onChange={handleCheckboxChange}
                            checked={modal.formation ? modal.formation.currentformation : false}

                        >
                            Formation Actuelle?
                        </Checkbox>
                    </Form.Item>

                    <Form.Item
                        label="Intitulé de la formation"
                        name="formationname"
                        onChange={(e) => handleChangeInput(e)}
                        rules={[
                            {
                                required: true,
                                message: 'Entrez l\'intitulé!',
                            },
                            {
                                max: 140,
                                message: 'L\'intitulé ne peut pas dépasser 140 caractères!',
                            }
                        ]}
                    >
                        <Input size="large" maxLength={140} />
                    </Form.Item>


                    <Form.Item
                        label="Nom de l'école"
                        name="schoolname"
                        onChange={(e) => handleChangeInput(e)}
                        rules={[
                            {
                                required: true,
                                message: 'Entrez le nom de l\'école!',
                            },
                            {
                                max: 140,
                                message: 'Le nom ne peut pas dépasser 140 caractères!',
                            }
                        ]}
                    >
                        <Input size="large" maxLength={140} />



                    </Form.Item>

                    <Form.Item
                        label="Localisation"
                        name="localisation"
                        required={true}
                        rules={[
                            {
                                validator: (_, value) =>
                                    localisationText ? Promise.resolve() : Promise.reject(new Error('Veuillez entrer une localisation!'))
                            }
                        ]}

                    >
                        <SearchAddress onAddressChange={handleAddressChange} address={localisationText} setAddress={setLocalisationText} />

                    </Form.Item>


                    <Form.Item label="Période" style={{ marginBottom: 0 }} rules={[
                        {
                            required: true,
                            message: 'Entrez une période!',
                        },
                    ]}>
                        <Form.Item
                        
                            name="start"
                            style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                        >
                            <DatePicker onChange={(date, dateString) => onDateChange(date, dateString, 'start')}
                                placeholder='Début' style={{ width: "100%" }} size='large' locale={locale} />

                        </Form.Item>

                        {
                            ( formData && (formData.currentformation == false || formData.currentformation == undefined))  &&
                            <Form.Item
                            onChange={(e) => handleChangeInput(e)}
                            name="end"
                            style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                        >
                            <DatePicker onChange={(date, dateString) => onDateChange(date, dateString, 'end')} placeholder='Fin' size='large' style={{ width: "100%" }} locale={locale} />

                        </Form.Item>
                        }

                    </Form.Item>





                    <Form.Item
                        label="Description"
                        name="description"
                        onChange={(e) => handleChangeInput(e)}
                        hasFeedback
                        rules={[
                            {
                                max: 500,
                                message: 'La description ne peut pas dépasser 500 caractères!',
                            }
                        ]}

                    >
                        <Input.TextArea size='large' maxLength={500} showCount />
                    </Form.Item>




                    <Form.Item className="item-next" style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button size="large" shape="round" type="primary" htmlType="submit">
                            Ajouter
                        </Button>
                    </Form.Item>
                </Form>
            </Container>

        </Modal>
    )
}


export default AddFormation
