import { Button } from 'antd'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { Choice, ChoicesDiv } from '../promo/Target'
import { useSelector } from 'react-redux'
import target from '../../../../assets/offers-icons/target.png'
import global from '../../../../assets/offers-icons/general.png'

const Target = ({ step, onStepChange, formData, setFormData }) => {

    const [active, setActive] = useState('');
    const {modal} = useSelector(state=>state)
    const handleClick = (choice) => {
        setActive(choice);
        setFormData({ ...formData, target: choice });
    }

    useEffect(() => {
      console.log(formData)
    }, [formData])
    
    
  return (
    <Content>
        
        <h2>{modal.event?'Modifier':'Créer'} un évènement <br/> Quel type d'offre?</h2>

        <ChoicesDiv>
                <Choice key={Math.random()} onClick={() => handleClick("global")} active={active === 'global' ? true : false}>
                    <img src={global} alt=' target' />

                    <span>Générale</span>
                </Choice>
                <Choice key={Math.random()} onClick={() => handleClick("targeted")} active={active === 'targeted' ? true : false}>

                    <img src={target} alt=' target' />

                    <span>Ciblée</span>
                </Choice>
{/*                 <Choice key={Math.random()} onClick={() => handleClick("custom")} active={active === 'custom' ? true : false}>
                    <img src='./offers/custom.png' alt='image target' />

                    <span>Sur-mesure</span>
                </Choice> */}
            </ChoicesDiv>
        
        <Button onClick={ () => {if(active === '')return;onStepChange(step + 1)}} size="large" shape="round" type="primary" htmlType="submit">
                                            Suivant
                                        </Button>
        
    </Content>
  )
}

const Content = styled.div`
    display:flex;
    width: 100%;
    flex: 1;

    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    h2{
        font-size:1.4em;
        font-weight: 900;
        color: #585858;
        text-align:center;
    }

`


export default Target