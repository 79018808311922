import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AiFillShop, AiFillMail } from 'react-icons/ai'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { BiWorld } from 'react-icons/bi'
import { BsFillTelephoneFill } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import ManagementCompanyPage from './ManagementCompanyPage'
import { checkImage, imageUpload } from '../../utils/imageUpload'
import { GLOBALTYPES } from '../../redux/actions/globalTypes'
import { updatePageBackgroundImage } from '../../redux/actions/pageAction'
import { useParams } from 'react-router-dom'

import AvantagesComponents from './avantages/AvantagesComponents'
import { convertIdToLabel, extractLocalityAndCountry, isInArray } from '../../utils/function'
import HeaderDealCompany from '../deal/HeaderDealCompany'
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import MyEmployees from './employees/MyEmployees'
import PhoneBook from './phonebook/PhoneBook'
import Partnerships from './partnerships/Partnerships'
import useWindowDimensions from '../../utils/useWindowsDimensions'
import { Modal } from 'antd'

const BigScreenCompanyPage = ({ company }) => {

    const { auth, datas } = useSelector(state => state)
    const [choice, setChoice] = useState(0)
    const [modalDeleteNotPossible, setModalDeleteNotPossible] = useState(false)
    const [background, setBackground] = useState('')
    const { id } = useParams()
    const {  width } = useWindowDimensions();


    const dispatch = useDispatch();
    const location = useLocation();

    const history = useHistory();

    useEffect(() => {

        const queryParams = new URLSearchParams(location.search);
        const mode = queryParams.get('mode');
        if (mode) {
            setChoice(Number(mode))

        }
    }, [location]);

    const addToUrlAndNavigate = (number) => {
        const newPath = `/company/${company._id}?mode=${number}`;
        setChoice(number);
        history.push(newPath);
    };

    const handleDelete = (e) => {
        e.stopPropagation(); 
        if(company.subscription){
            setModalDeleteNotPossible(true)
        }else{
            dispatch({ type: GLOBALTYPES.MODAL, payload: { type: "page", typeModal: "delete", title: "Etes-vous sûr de vouloir supprimer cette page?", page: company, open: true } }) 

        }

    }



    const changeBackground = async (e) => {
        const file = e.target.files[0]

        const err = checkImage(file)
        if (err) return dispatch({
            type: GLOBALTYPES.ALERT, payload: { error: err }
        })

        setBackground(file)

        const media = await imageUpload([file]);
        const imgBackground = media[0].url;
        dispatch(updatePageBackgroundImage({ imgBackground, id, auth }))
    }

    return (
        <Container>
            <CenterDiv>
                <CenterHeaderCompany>
                    <ImgHeader src={company.imgBackground} alt="header entreprise">

                        {
                            isInArray(company.admins, auth.user._id)
                                ?
                                <ChangeImgHeader className="change">
                                    <label>
                                        Modifier Image <input type="file" style={{ display: "none" }} accept=".jpg, .jpeg, .png" onChange={changeBackground} />
                                    </label>
                                </ChangeImgHeader>
                                : null
                        }

                    </ImgHeader>


                    {company.typePage === "company" && <CenterHeaderMenu>
                        <CenterHeaderMenuItem onClick={() => addToUrlAndNavigate(0)} active={choice === 0 ? true : false}>
                            {isInArray(company.admins, auth.user._id, 'admin') ? "Mes invitations" : "Nos invitations"}
                        </CenterHeaderMenuItem>
                        {
                            (isInArray(company.admins, auth.user._id) && company.typePage === "company" ) &&
                            <CenterHeaderMenuItem onClick={() => addToUrlAndNavigate(2)} active={choice === 2 ? true : false}>
                                Mes salariés
                            </CenterHeaderMenuItem>
                        }

                        {
                            (isInArray(company.admins, auth.user._id) && company.typePage === "company" ) &&
                            <CenterHeaderMenuItem onClick={() => addToUrlAndNavigate(4)} active={choice === 4 ? true : false}>
                                Mes partenaires
                            </CenterHeaderMenuItem>
                        } 

                        {
                            isInArray(company.admins, auth.user._id) &&
                            <CenterHeaderMenuItem onClick={() => addToUrlAndNavigate(3)} active={choice === 3 ? true : false}>
                                Annuaire
                            </CenterHeaderMenuItem>
                        } 

                        {
                            isInArray(company.admins, auth.user._id, 'admin') &&
                            <CenterHeaderMenuItem onClick={() => addToUrlAndNavigate(1)} active={choice === 1 ? true : false}>
                                {company.typeCompany === 'multi' ? "Gestion compte" : "Gestion page"}
                            </CenterHeaderMenuItem>
                        }



                    </CenterHeaderMenu>}


                    {company.typePage === "partner" && <CenterHeaderMenu>
                        <CenterHeaderMenuItem onClick={() => addToUrlAndNavigate(0)} active={choice === 0 ? true : false}>
                            {isInArray(company.admins, auth.user._id, 'admin') ? "Mes invitations" : "Nos invitations"}
                        </CenterHeaderMenuItem>
  



                        {
                            isInArray(company.admins, auth.user._id) &&
                            <CenterHeaderMenuItem onClick={() => addToUrlAndNavigate(3)} active={choice === 3 ? true : false}>
                                Annuaire
                            </CenterHeaderMenuItem>
                        } 
                        {
                            isInArray(company.admins, auth.user._id, 'admin') &&
                            <CenterHeaderMenuItem onClick={() => addToUrlAndNavigate(1)} active={choice === 1 ? true : false}>
                                { "Gestion page"}
                            </CenterHeaderMenuItem>
                        }


                    </CenterHeaderMenu>}
                </CenterHeaderCompany>



                <div className='header-mobile'>
                    <HeaderDealCompany company={company} />
                </div>

                <MenuCompanyMobile>

                    <MenuItemCompanyMobile active={choice === 0 ? true : false} onClick={() => { addToUrlAndNavigate(0) }}>
                        {isInArray(company.admins, auth.user._id, 'admin') ? "Mes invitations" : "Nos invitations"}
                    </MenuItemCompanyMobile>

                    {
                        (isInArray(company.admins, auth.user._id) && company.typePage === "company" )&&
                        <MenuItemCompanyMobile active={choice === 2 ? true : false} onClick={() => { addToUrlAndNavigate(2) }}>Mes salariés</MenuItemCompanyMobile>

                    }

                    {
                        (isInArray(company.admins, auth.user._id) && company.typePage === "company" )&&
                        <MenuItemCompanyMobile active={choice === 4 ? true : false} onClick={() => { addToUrlAndNavigate(4) }}>Mes partenaires</MenuItemCompanyMobile>
                    }

{
                        isInArray(company.admins, auth.user._id) &&
                        <MenuItemCompanyMobile active={choice === 3 ? true : false} onClick={() => { addToUrlAndNavigate(3) }}>Annuaire</MenuItemCompanyMobile>

                    } 
                    {
                        isInArray(company.admins, auth.user._id) &&
                        <MenuItemCompanyMobile active={choice === 1 ? true : false} onClick={() => { addToUrlAndNavigate(1) }}>{company.typeCompany === 'multi' ? "Gestion compte" : "Gestion page"}</MenuItemCompanyMobile>

                    }



                    <MenuItemCompanyMobile active={choice === 7 ? true : false} onClick={() => { addToUrlAndNavigate(7) }}>A propos</MenuItemCompanyMobile>

                </MenuCompanyMobile>




                {choice === 0 && <AvantagesComponents company={company} />}

                {
                    choice === 1 && <ManagementCompanyPage company={company} />
                }

                {
                    (choice === 2 &&  company.typePage === "company") && <MyEmployees company={company} />
                }

                {
                    choice === 3 && <PhoneBook company={company} />
                }

                {
                    choice === 4 && <Partnerships company={company} />
                }
                {
                    choice === 7 &&
                    <PanelCompanyDescription>

                        <h4>Bio:</h4>
                        <p style={{ marginBottom: "5%", marginTop: "2%",    wordBreak: "break-word" }}>{company.description} </p>
                        <h4>Informations:</h4>

                        {company.sector && <InformationItem style={{ marginTop: "2%" }}><AiFillShop className="icon" /> <span>{convertIdToLabel(datas.companyTypes ,company.sector)}</span></InformationItem>}
                        {company.address && <InformationItem><FaMapMarkerAlt className="icon" /> <span>{extractLocalityAndCountry(company.address)} </span></InformationItem>}
                        {company.website && <InformationItem><BiWorld className="icon" /> <span>{company.website}</span></InformationItem>}
                        {company.phone && <InformationItem><BsFillTelephoneFill className="icon" /> <span>{company.phone}</span></InformationItem>}
                        {company.email && <InformationItem><AiFillMail className="icon" /> <span>{company.email}</span></InformationItem>}

                    </PanelCompanyDescription>
                }


            </CenterDiv>
            <RightDiv>
                <PanelCompany>
                <PanelCompanyHeader style={company.typePage === "partner" ? { background: 'linear-gradient(to right, #FF4B2D, #FF4268)' } : {}} >
                        <img src={company.avatar} alt="Logo entreprise" />

                        <h2>{company.name}</h2>
                    </PanelCompanyHeader>

                    <PanelCompanyDescription>

                        <h4>Bio:</h4>
                        <p style={{ marginBottom: "5%", marginTop: "2%" }}>{company.description} </p>
                        <h4>Informations:</h4>

                         {company.sector && <InformationItem style={{ marginTop: "2%" }}><AiFillShop className="icon" /> <span>{convertIdToLabel(datas.companyTypes ,company.sector)}</span></InformationItem>}
                         {company.address && <InformationItem><FaMapMarkerAlt className="icon" /> <span>{extractLocalityAndCountry(company.address)}</span></InformationItem>}
                        {company.website && <InformationItem><BiWorld className="icon" /> <span>{company.website}</span></InformationItem>}
                        {company.phone && <InformationItem><BsFillTelephoneFill className="icon" /> <span>{company.phone}</span></InformationItem>}
                        {company.email && <InformationItem><AiFillMail className="icon" /> <span>{company.email}</span></InformationItem>}

                    </PanelCompanyDescription>
                </PanelCompany>

                {
                    isInArray(company.admins, auth.user._id, 'admin') &&
                    <Button onClick={(e) => handleDelete(e)}>Supprimer page</Button>
                }

            </RightDiv>

            <Modal
        open={modalDeleteNotPossible}
        title="Supprimer page"
        onOk={()=>setModalDeleteNotPossible(false)}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <OkBtn />
          </>
        )}
      >

      Vous avez un abonnement en cours, veuillez l'annuler avant de supprimer cette page!
    </Modal>

        </Container>
    )
}

const Container = styled.div`
    display:flex;
    width: 90%;
    margin: 0px auto;
    margin-bottom: 5%;

    @media screen and (max-width: 768px) {
        width: 100%;
        overflow-x: hidden;
    }
`

const MenuCompanyMobile = styled.div`
    display:flex;
    justify-content: flex-start;
    margin-top: 5%;
    margin-bottom: 5%;
    position: relative;
    overflow-x: auto;

    @media (min-width: 768px){
        display:none;
    }
`

const MenuItemCompanyMobile = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #C4C4C4;

    background: ${({ active }) => active ? 'var(--color-accent)' : 'transparent'};
    color: ${({ active }) => active ? 'white' : '#C4C4C4'};
    font-size: 0.9em;
    padding: 5px 8px;
    border-radius: 5px;
    margin-right: 2%;
    font-weight: 600;
    flex-grow: 1;
    white-space: nowrap;



`

const CenterDiv = styled.div`
    width: 72%;

    .header-mobile{
        display:none;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
        .header-mobile{
            display:flex;
        }
    }
`

const ImgHeader = styled.div`
    background-image:url(${(props) => props.src});
    background-size: cover;
    position: relative;
    width: 100%;
    height: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-position: center;

    &:hover{
        opacity: 0.5;
    }

    &:hover > .change{
        display:flex;
    }

    @media (max-width: 768px) {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

    }

    

`

const ChangeImgHeader = styled.div`
    position: absolute;

    label {
        cursor: pointer;
        padding: 20px;
        border: 4px solid white;
    }

    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    font-size: 35px;
    font-weight: 700;
    color: white;
    
    display: none;
`

const CenterHeaderCompany = styled.div`
    background :#F7F6F6;
    height: 25vh;
    border-radius: 15px;
    
    img{
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        width: 100%;
        height: 75% ;
        object-fit: cover;
    }

    @media screen and (max-width: 768px) {
        border-radius: 10px !important;
        overflow:hidden;
        display:none;

     }




`

const CenterHeaderMenu = styled.div`
    display:flex;
    min-height: 25%;
    align-items: center;
    padding: 0% 2%;
    background: #F7F6F6;

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    overflow:hidden;
    border-bottom-left-radius:15px;
    border-bottom-right-radius:15px;

    @media screen and (max-width: 768px) {
       display:none;
    }

`

const CenterHeaderMenuItem = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: ${({ active }) => active ? 'var(--color-accent)' : '#969696'};
    height: 100%;
    font-size: 0.8em;
    padding: 10px 2%;
    text-align:center;
    border-bottom: ${({ active }) => active ? '4px solid  var(--color-accent)' : '4px solid  transparent'};
    &:hover{
        cursor: pointer;
    }


`

export const PanelExplanation = styled.div`
    display:flex;
    padding: 8px 15px;
    background:rgba(96, 118, 231, 0.21);
    justify-content:center;
    align-items:center;
    border-radius: 10px;
    p{
        margin: 0px;
        color:#6076E7;
        font-size: 0.8em;
    }

    @media screen and (max-width: 768px) {
        margin-top: 0px;
    }

`
const DealsContainer = styled.div`
  margin: 0px auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, auto);
  grid-gap: 20px;
  margin-top: 10%;
  @media only screen and (max-width: 768px) {
    width: 90%;
    display: flex;
    flex-direction: column;
  }


`


const RightDiv = styled.div`

    width: 28%;
    min-height: 85vh;
    padding: 0% 2%;

    @media screen and (max-width: 768px) {
        display:none;
    }
    

`

const PanelCompany = styled.div`
    display:flex;
    flex-direction: column;
    background: #F6F6F6;
    height: auto;
    min-height: 80vh;
    max-height: 150vh;
    border-radius: 15px;

`

const PanelCompanyHeader = styled.div`
    width: 100%;
    height: 40vh;

    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--color-accent);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 20px 10px;
    img{
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }

    h2{
        margin: 0px;
        font-size: 1.3em;
        font-weight: 700;
        color: white;
        margin-top: 2%;
        text-align:center;
    }
`

const PanelCompanyDescription = styled.div`
    display:flex;
    flex-direction: column;
    padding: 5% 5%;    word-break: break-word;

    h4{
        margin: 0px;
        font-size: 1em;
        font-weight: 700;
        color: var(--color-accent);
    }

    p{
        margin: 0px;
        font-size: 0.9em;
        color: #757474;
    }

`
const InformationItem = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 2%;
    .icon{
        font-size: 1em;
        color: var(--color-accent);
        margin-right: 10px;
    }

    span{
        margin: 0px;
        font-size: 0.9em;
        color: #757474;
        
    }
`


const Button = styled.div`
    display:flex;
    align-items:center;
    justify-content: center;
    padding: 4% 2%;
    margin-top: 2%;
    border-radius: 15px;
    border: 1px solid #ddd;
    background: white;

    &:hover{
      cursor: pointer;
      background: #ddd;
    }

`
export default BigScreenCompanyPage