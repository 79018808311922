import React, { useEffect } from 'react'
import styled from 'styled-components'
import { getDataAPI } from '../../../utils/fetchData'
import { useDispatch, useSelector } from 'react-redux'
import { getPartnerships } from '../../../redux/actions/partnershipActions'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import InvitationPreviewCard from './InvitationPreviewCard'
import LoadIcon from '../../../images/loading.gif'
import InvitationPreviewCardMobile from './InvitationPreviewCardMobile'
import useWindowDimensions from '../../../utils/useWindowsDimensions'

const MyPartners = () => {
  const { partnerships, auth } = useSelector(state => state);
  const { id } = useParams();
  const {width} = useWindowDimensions();
  const dispatch = useDispatch();
  useEffect(async () => {
    let newfilter = { currentId: id, status: "accepted" };

    dispatch(getPartnerships({ filter: newfilter, auth }))
  }, [])
  return (

    <>
      {
        partnerships.loading
          ? <img style={{ margin: '10% 0%' }} src={LoadIcon} alt="loading" className="d-block mx-auto" />
          : (partnerships.count === 0)
            ? <div style={{ margin: '10% auto' }}> Aucune demande disponible</div>
            : <Container>

            
              {
                partnerships.partnerships.map((invitation) => (
                 <>{
                    width > 768?
                  <InvitationPreviewCard invitation={invitation} page={invitation.sender._id === id ? invitation.receiver : invitation.sender} />:
                  <InvitationPreviewCardMobile invitation={invitation} page={invitation.sender._id === id ? invitation.receiver : invitation.sender} />
                 }
                  </> 
                  ))
              }

            </Container>
      }

    </>
  )
}

const Container = styled.div`
margin: 0px auto;
position: relative;
display: grid;
grid-template-columns: repeat(4, minmax(0, 1fr));

grid-gap: 20px;
@media only screen and (max-width: 768px) {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 900px) {
  grid-template-columns: repeat(2, 1fr );
  grid-template-rows: repeat(2, auto);
}

@media (min-width: 769px) and (max-width: 1280px) {
  /* Add your styles here */
  grid-template-columns: repeat(3, 1fr); /* Example: 3 columns */
}




width: 100%;
height: 100%;


`

export default MyPartners