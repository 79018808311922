import React from 'react'
import styled from 'styled-components'
import LogoRedImg from '../../assets/logo-pink.png'
import LogoBlueImg from '../../assets/logo-violet.png'
import { BsPlusCircleFill } from "react-icons/bs";
import {GiHamburgerMenu} from "react-icons/gi"
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Searchbar from './Searchbar';
import { GLOBALTYPES } from '../../redux/actions/globalTypes';
import useWindowDimensions from '../../utils/useWindowsDimensions';
import { SIDEBAR_TYPES } from '../../redux/reducers/sidebarReducer';

const NavbarHome = () => {

  const {width, } = useWindowDimensions();
  const dispatch = useDispatch();
  const history = useHistory();

  const {sidebar, theme, auth,pages} = useSelector(state => state)
  const redirect = () => {
    if(width > 768 ){
      dispatch({ type: GLOBALTYPES.MODAL,payload: {typeModal:"createDealModal", open:true}})
    }else{
      dispatch({ type: GLOBALTYPES.MODAL,payload: {typeModal:"createDealModal", open:true}})


    }
  }

  const handleOpenMenu = () => {
    if(sidebar.isOpen){
      dispatch({type: SIDEBAR_TYPES.CLOSE_SIDEBAR})
    }else{
      dispatch({type: SIDEBAR_TYPES.OPEN_SIDEBAR})
    }
  }

  return (
    <Navbar id="navbar">
      <NavbarFull>
      {<GiHamburgerMenu  onClick={()=> handleOpenMenu()} className='icon-menu'/>}
      <NavbarContent>
      <LeftContainer>
             <Logo  className="logo" src={(theme && theme.theme==='company')?LogoBlueImg:LogoRedImg} onClick={() => history.push('/')} ></Logo>

          </LeftContainer>

          <CenterContainer >
            <div className="searchbar" >
            <Searchbar />

            </div>
          </CenterContainer>

          <RightContainer>

            {(auth.user && auth.user.avatar) && <img onClick={() => history.push('/settings')} className='avatar' src={auth.user.avatar} alt={'img user'}/>}

            {
              (pages.pages && pages.pages.length > 0) && 
              <ButtonCreateOffer onClick={() => redirect()}>
                <BsPlusCircleFill className='icon-btn'/>
                <span>Poster une offre</span>
            </ButtonCreateOffer>
            }
           
           {/*  <ButtonLogout onClick={() => dispatch(logout())}>
              <HiOutlineLogout className='icon-btn'/>
              <span>Déconnexion</span>
            </ButtonLogout>
            <BsGearFill className='icon-param' onClick={() => history.push('/settings')} /> 
 */}
          </RightContainer>
      </NavbarContent>
      </NavbarFull>
        
    </Navbar>
  )
}

const Navbar = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    display:flex;
    justify-content: center;
    width: 100vw;
    background: var(--color-background);
    height: 10vh;


    

  
`
const NavbarFull = styled.div`
    position : relative;
    display:flex;
    justify-content: center;
    width: 100%;

    .icon-menu{
      position: absolute;
      left: 2%;
      top: 50%;
      font-size: 1.5em;
      color: #757474;
      transform: translate(-50%, -50%);
      &:hover{
        cursor: pointer;
      }

      
    @media only screen and (max-width: 768px) {
      display: none;
    }
 
    }


`


const NavbarContent = styled.div`
    display:flex;
    justify-content: space-between;
    width: 80%;
    @media only screen and (max-width: 768px) {
      width: 90%;
    }
`
const Logo = styled.img`
    margin: 5px;
    max-width: 200px;
    height: auto;

    @media only screen and (max-width: 768px) {
      max-width: 150px;

    }

    &:hover{
      cursor: pointer;
    }
 
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  width:30%;

  .icon-btn{
    display:none;
  }


  @media only screen and (max-width: 768px) {
  
   width: auto;

   .icon-btn{
      display:flex;
      color: var(--color-accent);
      font-size: 1.9em;
      margin-right: 5px;
    }

  }


`;

const CenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  .searchbar{
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    .searchbar{
      display:none !important;
    }
  }

`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width:30%;
  @media only screen and (max-width: 768px) {
    width: auto;
    justify-content: flex-end;
    padding: 0px;
    background
  }

  .avatar{
    display:none;

    @media only screen and (max-width: 768px) {
      display: flex;
      border-radius: 50%;
      width: 35px;
      height: 35px;
    }
  }
  
  .icon-param{
    font-size: 2.5em;
    color: var(--color-accent);
    padding: 5px;
    &:hover{
      cursor: pointer;
      background: #eeeeee;
      border-radius: 100%;
    }

    @media only screen and (max-width: 768px) {
      display: none;

    }
  }


`;


const ButtonLogout = styled.div`
  margin-right: 2%;
  display:flex;
  justify-content: center;
  align-items: center;
  background: #eeeeee;
  border-radius: 25px;
  padding: 5px 10px;

  .icon-btn{
    color: var(--color-accent);
    font-size: 1.6em;
    margin-right: 5px;
  }
  span{
    margin: 0px;
    font-size: 0.9em;
    font-weight: 700;
  }

  &:hover{
    cursor: pointer;
    background : #d4d4d4;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }

`
//background: linear-gradient(7deg, rgba(255,74,49,1) 0%, rgba(255,66,101,1) 99%);

const ButtonCreateOffer = styled.div`
  margin-right: 2%;
  display:flex;
  justify-content: center;
  align-items: center;
  background: var(--color-accent);
  
  border-radius: 25px;
  padding: 5px 10px;
  
  .icon-btn{
    color: white;
    font-size: 1.6em;
    margin-right: 5px;
  }
  span{
    margin: 0px;
    font-size: 0.9em;
    font-weight: 700;
    color: white;
  }

  &:hover{
    cursor: pointer;
    background : var(--color-accent-hover);
  }

  @media (max-width: 768px) {

    display:none;
    

  }

`

export default NavbarHome