import { Button } from 'antd';
import React, { useState } from 'react'
import styled from 'styled-components'
import { FaExchangeAlt } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux';
import { AiFillCheckCircle, AiOutlineFieldNumber, AiOutlineLink, AiOutlinePhone, AiOutlineStop } from 'react-icons/ai';
import { MdAlternateEmail } from 'react-icons/md';
import { GLOBALTYPES } from '../../redux/actions/globalTypes';
import { postDataAPI } from '../../utils/fetchData';
import { toast } from 'react-toastify';
const ModalityContent = ({ deal }) => {
    const { auth } = useSelector(state => state);
    const dispatch = useDispatch();
    const [step, setStep] = useState(1);

    const nextStep = async() => {

        try{
            await postDataAPI(`/visitor`,{source:'deal', deal:deal._id, company:deal.company._id},auth.token)
            setStep(2);

        }catch{
            toast.error('Une erreur est survenue');
        }

    }

    const handleRedirect = () => {
        // Add any logic you want to execute before redirecting here
        
        window.location.href = deal.filePdf[0].url;
      };
      
    return (
        <>
   

            {
                step === 1 && <Content>
                    <div className='top'>
                        <h2>Pour profiter de cette promo : </h2>

                        <Item>
                            { deal.filePdf && deal.filePdf.length > 0 && <PdfButton onClick={handleRedirect}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
                                        <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 7.91V16a6 6 0 0 0 6 6v0a6 6 0 0 0 6-6V6a4 4 0 0 0-4-4v0a4 4 0 0 0-4 4v9.182a2 2 0 0 0 2 2v0a2 2 0 0 0 2-2V8"/>
                                    </svg>
                                </PdfButton>}
                            {
                                deal.mode === 'noreservation' && <>
                                    <AiOutlineStop className='icon'/>
                                    <span>Pas de réservation</span>
                                </>
                            }
                                                {
                                deal.mode === 'phone' && <>
                                    <AiOutlinePhone className='icon'/>
                                    <span>Réservation par téléphone</span>
                                    {deal.modeInfos && <span>{deal.modeInfos}</span>}
                                </>
                            }
                            {
                                deal.mode === 'code' && <>
                                    <AiOutlineFieldNumber className='icon'/>
                                    <span>Réservation avec code</span>
                                    {deal.modeInfos && <span>{deal.modeInfos}</span>}

                                </>
                            }

                            {
                                deal.mode === 'link' && <>
                                    <AiOutlineLink className='icon'/>
                                    <span>Réservation avec lien</span>
                                    {deal.modeInfos && <a href={deal.modeInfos}>Cliquez-ici</a>}

                                </>
                            }

                            {
                                deal.mode === 'email' && <>
                                    <MdAlternateEmail className='icon'/>
                                    <span>Réservation par email</span>
                                    {deal.modeInfos && <span>{deal.modeInfos}</span>}

                                </>
                            }
                        
                        </Item>
                        <p>En cas de demande, veuillez présenter le pass Behandy en cliquant sur le bouton ci-dessous</p>
                    </div>
                    <div className='bottom'>
                    <Button  onClick={()=>setStep(3)} type='primary' style={{ margin:"0px auto" }} size='large'>Pass Behandy</Button>

                    </div>

                </Content>
            }

{
                step === 3 && <ContentLast>
                    <h3>Veuillez montrer cette page à l’établissement pour profiter de l’offre :</h3>

                    <div className='center-div'>
                        <div className='top'>
                            <h4>Compte vérifié:</h4>
                            <span>{auth.user.fullname} peut bénéficier votre offre! </span> 
                        </div>

                        <div className='center'>
                            <div className='img-div'>
                            <img src={auth.user.avatar} alt='img user'/>
                            <AiFillCheckCircle className='icon'/>
                            </div>
                            <span>{auth.user.fullname}</span> 
                            <p>{auth.user.titleJob}</p>
                        </div>

                        
                       

                    </div>
         
                    <Button  onClick={()=>    dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})
} type='primary' style={{ width: "100%" }} size='large'>Quitter</Button>

                </ContentLast>
            }

        </>
    )
}

const Content = styled.div`
    display:flex;
    width: 100%;
    height: 100%;
    justify-content:space-between;
    align-items:center;
    flex-direction:column;
    text-align: center;
    padding: 4% 8%;

    h2{
        margin:0px;
        padding: 0px;
        color: #585858;
        font-size: 1.2em;
        font-weight: 900;
        text-align:center;
    }

    p{
        margin:0px;
        padding: 0px;
        color: #585858;
        font-size: 1em;
        font-weight: 400;
        font-style: italic;
    }

    .top{
        display:flex;
        align-items: center;
        justify-content: space-around;
        flex-direction:column;
        height: 80%;

    }

    .bottom{
        display:flex;
        height: 20%;
        align-items: center;
    }

    @media screen and (max-width: 768px) {
        padding-bottom: 7vh;
     }

`

const PdfButton = styled.div`
     position: absolute;
     top: 5px;
     right: 5px;

     &:hover{
        cursor: pointer;
     }
`
const ContentLast = styled.div`
    display:flex;
    width: 100%;
    height: 100%;
    justify-content:space-around;
    flex-direction:column;

    padding: 4% 8%;

    h3{
        margin:0px;
        padding: 0px;
        color: #585858;
        font-size: 1em;
        font-weight: 900;
        text-align:center;
    }

    p{
        margin:0px;
        padding: 0px;
        color: #585858;
        font-size: 1em;
        font-weight: 400;
        font-style: italic;
    }

    .center-div{
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        gap: 15px;

        .top{
            display:flex;
            align-items:center;
            flex-direction:column;
            gap:0px;

            h4{
                margin:0px;
                padding: 0px;
                color: black;
                font-size: 1.2em;
                font-weight:900;
            }

            span{
                margin:0px;
                padding: 0px;
                color: #585858;
                font-size: 0.9em;
                font-weight: 400;
            }
        }

        .center{
            display:flex;
            flex-direction:column;
            align-items:center;

            .img-div{
                position:relative;
                img{
                    width: 50px;
                    height:50px;
                }
    
                .icon{
                    font-size: 1.4em;
                    color:#67C7DC;
                    position: absolute;
                    bottom : 0px;
                    right: -10px;
                }
    
            }

            span{
                color: var(--color-accent);
                font-size: 0.9em;
                font-weight: 900;
            }
        }

        .bottom{
            display:flex;
            text-align:center;
            flex-direction:column;
            align-items:center;

            span{
                color: #585858;
                font-size: 0.9em;
                font-weight: 900;
            }
            p{
                color: #757474;
                font-size: 0.9em;
                font-weight: 400;
                font-style: italic;
            }
        }
    }

`


const Item = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
    position:relative;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 15px;

    aspect-ratio: 1/1;
    padding: 10px;
    width: 50%;

    .icon{
        font-size: 2.4em;
        color: var(--color-accent);
    }
    span{
        font-size: 0.8em;
        text-align:center;
    }

`
const Center = styled.div`
    display:flex;
    flex-direction: column;
    gap: 10px;

    .center-div{
        display:flex;
        gap: 20px;
        align-items:center;

        .icon{
            font-size: 2em;
        }
        img{
            width: 50px;
            height:50px;
        }
    }

`
export default ModalityContent