export const validateEmail = (mail) => {
   
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
    return (true)
    }
    return (false)
 
}

export const convertIdToLabel = (array, id) => {
  
  // Trouver l'objet dans le tableau qui a la valeur correspondante
  const found = array.find(item => item.id == id);

  // Retourner le label de cet objet, ou undefined si non trouvé
  return found ? found.name : undefined;
};


export const formatDate = (arrayDays, arrayDatas) => {
  let formattedDates = "";
  arrayDays.forEach(element => {
    formattedDates += convertIdToLabel(arrayDatas, element) + ', '
  });
  if (formattedDates.length > 0) {
    formattedDates = formattedDates.slice(0, -2);
  }
  return formattedDates;
}


export const isInArray = (array, id, role) => {
  if(!array) return;
  return array.some(element => 
    (element.user._id?element.user._id.toString():element.user) === id.toString() && 
    (role === undefined || element.role === role)
  );
};


 export const extractLocalityAndCountry = (addressComponents) => {
    if(!addressComponents) return "Non définie"
    let locality = '';
    let country = '';
  
    addressComponents.address_components.forEach(component => {
      if (component.types.includes('locality')) {
        locality = component.long_name;
      }
      if (component.types.includes('country')) {
        country = component.long_name;
      }
    });
  
    if(!locality && country){
      return country
    }
    if(locality && !country){
      return locality
    }

    return `${locality}, ${country}`; 
  }
  

  export const  getComponentByType = (place, type)  => {
    let result = '';
  
    if (place && place.address_components) {
      for (let component of place.address_components) {
        if (component.types.includes(type)) {
          result = component.long_name;
          break;
        }
      }
    }
  
    return result;
  }
  

  export const validateURL = (_, value) => {
    const urlPattern = new RegExp('^(https?:\\/\\/)' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?' + // port
      '(\\/[-a-z\\d%_.~+]*)*' + // path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d%_.~+=]*)?$', 'i'); // fragment locator
    if (!value || urlPattern.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Entrez une URL valide!'));
  };
  
  
  export const extractFileNameFromUrl = (url) => {
    // Use the lastIndexOf method to find the last occurrence of '/'
    console.log(url)
    const lastSlashIndex = url.lastIndexOf('/');
    
    // Extract the substring from the character after the last '/' to the end of the string
    // This will be the file name
    let  fileName = url.substring(lastSlashIndex + 1);


    
    return fileName;
  };