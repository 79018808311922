import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { getDealsByCompany2, searchAll } from '../../../redux/actions/dealAction'
import DealPreviewCard from '../../home/DealPreviewCard'
import EventPreviewCard from '../../home/event/EventPreviewCard'

import LoadIcon from '../../../images/loading.gif'
import { v4 as uuidv4 } from 'uuid';
import { getEventsNew } from '../../../redux/actions/eventAction'
import { PanelExplanation } from '../BigScreenCompanyPage'
import useWindowDimensions from '../../../utils/useWindowsDimensions'
import DealPreviewCardMobile from '../../home/DealPreviewCardMobile'
import EventPreviewCardMobile from '../../home/event/EventPreviewCardMobile'
import { isInArray } from '../../../utils/function'
import { ShowMore } from '../../../pages/home'
import DealPartnerPreviewCardNew from '../../home/card/DealPartnerPreviewCardNew'
import DealCardModalNew from '../../home/card/DealCardModalNew'
import DealPartnerPreviewCardMobile from '../../home/card/DealPartnerPreviewCardMobile'
const AvantagesComponents = ({ company }) => {

  const { auth, deals, events, modal } = useSelector(state => state)
  const [category, setCategory] = useState(1)
  const dispatch = useDispatch();
  const [choice, setChoice] = useState('global')
  const { height, width } = useWindowDimensions();

  const n = 12;
  useEffect(() => {
    if (category === 1) {
      if(company.typePage === "company"){
        if (isInArray(company.admins, auth.user._id)) {
          if (choice !== 'expired') {
            let newfilter = {typeDeal: 'company', target: choice, isAdmin: true , n:20, };
            dispatch(getDealsByCompany2({ auth, id: company._id, filter: newfilter }))
          } else {
            let newfilter = { typeDeal: 'company', showPast: true, isAdmin: true, n: 20 };
            dispatch(getDealsByCompany2({ auth, id: company._id, filter: newfilter }))
          }
        } else {
          if (company) {
            const idArray = company.childrenPages.map(obj => obj._id);
            let childrenPagesStr = idArray.join(',');          
            let companiesID = company._id + (childrenPagesStr ? ',' + childrenPagesStr : '');
            let newfilter = { company: companiesID, type: 'promo', n: n };
            dispatch(searchAll({ filter: newfilter, auth }))

          }
        }
      }else{
        let newfilter = { typeDeal: 'partner', isAdmin: true, n: 50};

        dispatch(getDealsByCompany2({ auth, id: company._id, filter: newfilter }))

      }

    }
/*     if (category === 2) {
      if (company) {
        const idArray = company.childrenPages.map(obj => obj._id);
        let childrenPagesStr = idArray.join(',');
        let companiesID = company._id + (childrenPagesStr ? ',' + childrenPagesStr : '');

        if (isInArray(company.admins, auth.user._id)) {
          if (choice !== 'expired') {
            let newfilter = { company: companiesID, target: choice, isAdmin: true, n: n };
            dispatch(getEventsNew({ filter: newfilter, auth }))
          } else {
            let newfilter = { company: companiesID, isAdmin: true, showPast: true, n: n };
            dispatch(getEventsNew({ filter: newfilter, auth }))
          }


        } else {
          let newfilter = { company: companiesID, type: 'event', n: n };
          dispatch(searchAll({ filter: newfilter, auth }))
        }

      }

    } */
  }, [category, choice])



  return (
    <Container>
      {modal.typeModal=='dealCardPartnerModal' && <DealCardModalNew/>}

      {isInArray(company.admins, auth.user._id) && <PanelExplanation>
        <p>Dans cet espace, vous pourrez retrouver et gérer vos avantages : ce sont les moments d’exception que vous offrez aux membres de la communauté dans votre établissement (offres de réduction, invitations à un évènement...).</p>
      </PanelExplanation>}

   {  company.typePage === "company" &&   <SubMenu>
        <SubItemMenu onClick={() => setCategory(1)} active={category === 1 ? true : false}>Promos </SubItemMenu>
{/*         <SubItemMenu onClick={() => setCategory(2)} active={category === 2 ? true : false}>Évènements</SubItemMenu>
 */}      </SubMenu>}

      {(isInArray(company.admins, auth.user._id) && category === 1 &&  company.typePage === "company") && <Categories>
        <Button shape="round" onClick={() => setChoice('global')} type={choice === 'global' ? 'primary' : 'default'}>En cours</Button>
        <Button shape="round" onClick={() => setChoice('expired')} type={choice === 'expired' ? 'primary' : 'default'}>Expirées</Button>
      </Categories>}

      {(isInArray(company.admins, auth.user._id) && category === 2 &&  company.typePage === "company") && <Categories>
        <Button shape="round" onClick={() => setChoice('global')} type={choice === 'global' ? 'primary' : 'default'}>Ouvert à tous</Button>
        <Button shape="round" onClick={() => setChoice('expired')} type={choice === 'expired' ? 'primary' : 'default'}>Terminés</Button>
      </Categories>}



      {category === 1 && <div style={{ display: 'flex', justifyContent: "center" }}>
        {
          deals.loading
            ? <img style={{ margin: '10% 0%' }} src={LoadIcon} alt="loading" className="d-block mx-auto" />
            : (deals.count === 0)
              ? <div style={{ margin: '10% 0%' }}> Aucune offre disponible</div>
              : <DealsContainer>

                {deals.deals.map((deal, index) => (
                  <>
                    {width > 768 ?<> {deal.typeDeal==="company"?<DealPreviewCard key={index} deal={deal} /> : <DealPartnerPreviewCardNew key={index} deal={deal} />  }</> : <>{deal.typeDeal==='company'? <DealPreviewCardMobile key={index} deal={deal} />: <DealPartnerPreviewCardMobile key={index} deal={deal} />}</>}
                  </>

                ))}

              </DealsContainer>
        }
      </div>
      }

      {category === 2 && isInArray(company.admins, auth.user._id) && <div style={{ display: 'flex', justifyContent: "center" }}>
        {
          events.loading
            ? <img style={{ margin: '10% 0%' }} src={LoadIcon} alt="loading" className="d-block mx-auto" />
            : (events.count === 0)
              ? <div style={{ margin: '10% 0%' }}> Aucune offre disponible</div>
              : <DealsContainer>

                {events.events.map((event) => (

                  <>
                    {width > 768 ? <EventPreviewCard typePage={"events"} key={uuidv4()} event={event} /> : <EventPreviewCardMobile typePage={"events"} key={uuidv4()} event={event} />}
                  </>

                ))}

              </DealsContainer>
        }
      </div>
      }
      {category === 2 && !isInArray(company.admins, auth.user._id) && <div style={{ display: 'flex', justifyContent: "center" }}>
        {
          deals.loading
            ? <img style={{ margin: '10% 0%' }} src={LoadIcon} alt="loading" className="d-block mx-auto" />
            : (deals.count === 0)
              ? <div style={{ margin: '10% 0%' }}> Aucune offre disponible</div>
              : <DealsContainer>

                {deals.deals.map((event) => (

                  <>
                    {width > 768 ? <EventPreviewCard typePage={"events"} key={uuidv4()} event={event} /> : <EventPreviewCardMobile typePage={"events"} key={uuidv4()} event={event} />}
                  </>

                ))}

              </DealsContainer>
        }
      </div>
      }


{/* {
        (deals.deals.length) < deals.count && 
        <ShowMore onClick={() => handleLoadMore()}>Voir plus</ShowMore>

      } */}
{/*       {
        (category === 2 && (events.page * events.n) < events.count) &&
        <ShowMore onClick={() => handleLoadMore()}>Voir plus</ShowMore>

      } */}

    </Container>
  )
}

const DealsContainer = styled.div`
margin: 0px auto;
position: relative;
display: grid;
grid-template-columns: repeat(3, minmax(0, 1fr));
grid-auto-rows: 280px;

grid-gap: 20px;
@media only screen and (max-width: 768px) {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 900px) {
  grid-template-columns: repeat(2, 1fr );
  grid-template-rows: repeat(2, auto);
}

@media only screen and (max-width: 1080px) {
  grid-template-columns: repeat( auto-fit, minmax(25%, 1fr) );
  grid-template-rows: repeat(3, auto);
}

width: 100%;
height: 100%;


`

const Container = styled.div`
  display:flex;
  margin-top: 80px;


  flex-direction:column;
  gap: 20px;
h3{
  color: #F87778;
}



  
  @media only screen and (max-width: 768px) {  
    margin-top: 0px;
  }

`

export const Categories = styled.div`
  display:flex;
  gap: 10px;
  z-index: 2;


    @media screen and (max-width: 768px) {
      gap: 6px;

    }
`


export const SubMenu = styled.div`
  display:flex;
  z-index: 2;
`

export const SubItemMenu = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: ${({ active }) => active ? 'var(--color-accent)' : '#757474'};;
    height: 100%;
    font-size: 1em;
    padding: 0% 2%;
    text-align:center;
    &:hover{
        cursor: pointer;
    }

`


export default AvantagesComponents