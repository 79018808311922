import React from 'react';
import { Table } from 'antd';

const InvoiceTable = ({ invoices }) => {
  const columns = [
    {
      title: 'Facture',
      dataIndex: 'number',
      key: 'invoice',
    },
    {
      title: 'Date',
      dataIndex: 'effective_at',
      key: 'date',
      render: (date) => {
        const dates = new Date(date * 1000); // Multiply by 1000 to convert from seconds to milliseconds

        const options = {
            day: 'numeric', // Two-digit day (e.g., 28)
            month: 'short', // Short month name (e.g., "janv." for January)
            year: 'numeric', // Four-digit year (e.g., 2024)
            timeZone: 'UTC', // Set the time zone to UTC to match the Unix timestamp
          };
          
          // Format the date
          const formattedDate = dates.toLocaleDateString('fr-FR', options);
          return formattedDate;

      }
    },
    {
      title: 'Montant',
      dataIndex: 'amount_paid',
      key: 'price',
      className: 'hide-on-mobile', // Apply the responsive class here

      render: (text) => `${text/100}€`,
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <a href={record.invoice_pdf} target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
        <path fill="currentColor" d="m9 9.114l1.85-1.943a.52.52 0 0 1 .77 0c.214.228.214.6 0 .829l-1.95 2.05a1.552 1.552 0 0 1-2.31 0L5.41 8a.617.617 0 0 1 0-.829a.52.52 0 0 1 .77 0L8 9.082V.556C8 .249 8.224 0 8.5 0s.5.249.5.556z"/>
        <path fill="currentColor" d="M16 13.006V10h-1v3.006a.995.995 0 0 1-.994.994H3.01a.995.995 0 0 1-.994-.994V10h-1v3.006c0 1.1.892 1.994 1.994 1.994h10.996c1.1 0 1.994-.893 1.994-1.994"/>
    </svg></a>
      ),
    },
  ];

  return <Table columns={columns} dataSource={invoices} rowKey="invoice" pagination={false} />;
};

export default InvoiceTable;
