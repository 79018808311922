import React from 'react'
import Modal from '../../../utils/Modal/Modal'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
const ModalRedeemCode = () => {
    const { modal } = useSelector(state => state)
    return (
        <Modal modalName={"redeemCodeModal"} heightModal={"60vh"} widthModal={"25vw"}>
            <Container>
                <Top> 
                
                <svg width="60" height="60" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <g fill="#F87778">
                        <path d="M5.5 5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
                        <path d="M8 2a2 2 0 0 0 2-2h2.5A1.5 1.5 0 0 1 14 1.5v13a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-13A1.5 1.5 0 0 1 3.5 0H6a2 2 0 0 0 2 2m0 1a3.001 3.001 0 0 1-2.83-2H3.5a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-13a.5.5 0 0 0-.5-.5h-1.67A3.001 3.001 0 0 1 8 3" />
                    </g>
                </svg>  
                
                <span className='title'>CODE ABONNEMENT</span>
                <span className='subtitle'>Vous pouvez partager ce code avec votre employé</span>

                </Top>

                <Bottom>
                    <span className='title'>CODE</span>
                    <span className='code'>{modal.code}</span>
                </Bottom>

            </Container>
        </Modal>
    )
}

const Container = styled.div`
    display:flex;
    position: relative;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    height: 100%;
    width: 100%;
  
    padding: 2% 5%;

    .logo{
        width: 150px;
    }
`

const Top = styled.div`
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;

    padding: 25px 0px;
    border-bottom: dashed #969696;
    .title{
        font-size: 1.2em;
        font-weight: 900;
        margin: 10px 0px;
    }

    .subtitle{
        margin: 0px;
        padding: 0px;
    }
`

const Bottom = styled.div`
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    padding: 25px 0px;


    .title{
        color:#969696;
    }

    .code{
        font-weight: 900;
        font-size: 1.2em;
    }
`


export default ModalRedeemCode