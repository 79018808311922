import React, { useEffect, useState } from 'react'
import LayoutAdmin from '../components/admin/LayoutAdmin'
import { Button, Modal, theme } from 'antd';
import { Content } from 'antd/es/layout/layout';
import PartnersTab from '../components/admin/partners/PartnersTab';
import styled from 'styled-components';
import FormCreatePartner from '../components/admin/partners/FormCreatePartner';
import { useDispatch, useSelector } from 'react-redux';
import { GLOBALTYPES } from '../redux/actions/globalTypes';
import CreatePageModal from '../components/settings/accounts/CreatePageModal';
import { getPages } from '../redux/actions/pageAction';
import AdsTab from '../components/admin/ads/AdsTab';
import FormCreateAds from '../components/admin/ads/form/FormCreateAds';
import { getAds } from '../redux/actions/adsActions';

const AdminAds = () => {
    const dispatch = useDispatch();
    const {modal, auth} = useSelector(state=>state)
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const [isModalOpen, setIsModalOpen] = useState(false);



    
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    useEffect(() => {
    let filter = {}
        console.log("hello")
      dispatch(getAds({filter,auth}))

    }, [])
    


    return (
        <LayoutAdmin title={"Publicités"}>
            <Content
                style={{
                    margin: '24px 16px 0',
                }}
            >
                <div
                    style={{
                        padding: 24,
                        minHeight: 360,
                        background: colorBgContainer,
                        borderRadius: borderRadiusLG,
                    }}
                >
                    <Container>
                        <Button  style={{alignSelf:"end"}} type="primary" onClick={() =>{ showModal()  /*  dispatch({ type: GLOBALTYPES.MODAL,payload: {typeModal:"createPageModal",type:"partner", open:true}}) */}}>
                            Créer une publicité
                        </Button>
                        <AdsTab />
                    </Container>
                </div>
            </Content>

            {(modal.typeModal ===  "createPageModal" && modal.open) && <CreatePageModal />}

   
   
            <Modal title="Créer  Publicité" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null} >
                <FormCreateAds/>
            </Modal>

        </LayoutAdmin>
    )
}

const Container = styled.div`
    display:flex;
    flex-direction: column;
    gap: 15px;
`
export default AdminAds