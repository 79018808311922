import { PROFILE_TYPES } from '../actions/profileAction'


const initialState = {
    loading: false,
    ids: [],
    users: [],
    posts: [],
    portfolioPosts: [],
}



const profileReducer = (state = initialState, action) => {
    switch (action.type){
        case PROFILE_TYPES.LOADING:
            return {
                ...state,
                loading: action.payload
            };
     
        
        default:
            return state;
    }
}

export default profileReducer