import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import FormManagementCompany from './FormManagementCompany'
import AdminsManagementComponents from '../AdminsManagementComponents'
import { Categories } from '../avantages/AvantagesComponents'
import { Button } from 'antd'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom'

const AccountManagement = () => {
    const { pages } = useSelector(state => state)
    const [choice, setChoice] = useState(1)

    const history = useHistory()
    const location = useLocation();

    useEffect(() => {

        const queryParams = new URLSearchParams(location.search);
        const choice = queryParams.get('choiceAccount');
        if (choice) {
            setChoice(Number(choice))

        }


        // Add more logic here as needed
    }, [location]);

    useEffect(() => {
      
    
      return () => {
        console.log("end")
        setChoice(1)
      }
    }, [])
    

    const addToUrlAndNavigate = (number) => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.delete('choiceSub')
       

        queryParams.set('choiceAccount', number); // Set 'mode' to the new value
        let newPath = location.pathname + '?' + queryParams.toString();
        setChoice(number)
  
        history.push(newPath);
      };

    return (
        <>
            <Categories>
                <Button shape="round" onClick={() => addToUrlAndNavigate(1)} type={choice === 1 ? 'primary' : 'default'}>Informations compte</Button>
                <Button shape="round" onClick={() => addToUrlAndNavigate(2)} type={choice === 2 ? 'primary' : 'default'}>Propriétaires</Button>
            </Categories>
            {choice === 1 && <FormManagementCompany company={pages.activePage} />}

            {
                choice === 2 && <AdminsManagementComponents company={pages.activePage} />
            }
        </>
    )
}

export default AccountManagement