const validFormSkill = ({name}) => {
    const err = {}

    if(!name) {
        err.name = "S'il vous plaît, ajoutez une compétence."
    }else if(name.length > 50){
        err.name = "Le nom est trop grand (<50)!"
    }


    return {
        errMsg: err,
        errLength: Object.keys(err).length
    }
}


  
export default validFormSkill