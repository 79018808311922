import React, { useState, useEffect } from "react";
import styled from "styled-components"

const StarRate = ({size, rate}) => {
    const [rating, setRating] = useState(rate);

 
    return (
      <Stars >
        {[...Array(5)].map((star, index) => {
          index += 1;
          return (
            <span
              key={index}
              className={index <= (rating) ? "on" : "off"}

            >
              <Star size={size} className="star">&#9733;</Star>
            </span>
          );
        })}
      </Stars>
    );
  };

const Stars = styled.div `

  display: flex;
  span {
    background-color: transparent;
    border: none;
    outline: none;


  }
  .on {
    color: #F87778;
  }
  .off {
    color: #ccc;
  }

`

const Star = styled.div `
    font-size:${props => props.size ? props.size : "50px"}; 

    
    @media (max-width: 768px) {
      
      font-size: 30px;


  }
`

export default StarRate;