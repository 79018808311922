import React from 'react'
import styled from "styled-components"
const LoadMoreBtn = ({limit, result, page, load, handleLoadMore}) => {
    return (
        <>
            {
                result < limit * (page - 1) ? '' : 

                !load && <Button 
                onClick={handleLoadMore}>
                    Voir plus
                </Button>
            }
            
        </>
    )
}

const Button = styled.button `
    margin: 0px auto;
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    padding: 5px;
    border-radius: 5px;
    color: #F87778;
    &:hover {
        background: #e9f5f5;
    }

`

export default LoadMoreBtn