import React from 'react'

import { UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import logo from '../../assets/logo-pink.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import Title from 'antd/es/typography/Title';
const { Header, Content, Footer, Sider } = Layout;


const menus = [
    {
        key: "0",
        icon: React.createElement(UserOutlined),
        label: 'Dashboard',
        path: '/admin'
    },
    {
        key: "1",
        icon: React.createElement(UserOutlined),
        label: 'Utilisateurs',
        path: '/admin/users'
    },
    {
        key: "2",
        icon: React.createElement(UserOutlined),
        label: 'Partenaires',
        path: '/adminPartners'

    },
    {
        key: "3",
        icon: React.createElement(UserOutlined),
        label: 'Entreprises',
        path: '/admin/companies'

    },
    {
        key: "4",
        icon: React.createElement(UserOutlined),
        label: 'Publicités',
        path: '/adminAds'

    },
    {
        key: "5",
        icon: React.createElement(UserOutlined),
        label: 'Codes temporaires',
        path: '/adminCodeTmp'

    },
]


const LayoutAdmin = ({ children ,title }) => {
    const history = useHistory();

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const onMenuClick = (item) => {
        // Redirect to the path specified in the menu item
        history.push(item.path);
    };

    return (
        <Layout>
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                onBreakpoint={(broken) => {
                    console.log(broken);
                }}
                onCollapse={(collapsed, type) => {
                    console.log(collapsed, type);
                }}
            >
                <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", padding: "20px" }}> 
                    <img onClick={()=> history.push('/')} src={logo} alt={"logo"} style={{ width: "150px" }} /> 
                </div>
                <Menu theme="dark" mode="inline" defaultSelectedKeys={['4']} items={menus.map(menu => ({
                    ...menu,
                    onClick: () => onMenuClick(menu)
                }))} />
            </Sider>
            <Layout>
                <Header
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems:'center',
                        padding: 0,
                        background: colorBgContainer,
                    }}
                > <h2>{title} </h2></Header>
                {children}
                <Footer
                    style={{
                        textAlign: 'center',
                    }}
                >
                    Behandy ©{new Date().getFullYear()} Created by Behandy
                </Footer>
            </Layout>
        </Layout>
    )
}

export default LayoutAdmin