import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FaUserFriends } from 'react-icons/fa'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { MdDelete, MdModeEdit } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { GLOBALTYPES } from '../../redux/actions/globalTypes'
import LikeButton from '../utils/LikeButton'
import { likeDeal, unLikeDeal } from '../../redux/actions/dealAction'
import { saveDeal, unSaveDeal } from '../../redux/actions/pageAction'
import useWindowDimensions from '../../utils/useWindowsDimensions'
import { convertIdToLabel, extractLocalityAndCountry, formatDate, isInArray } from '../../utils/function'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const DealPreviewCard = ({ typePage, deal, mode = "normal" }) => {
    const { auth ,datas} = useSelector(state => state)
    const history = useHistory();
    const dispatch = useDispatch();
    const [isLike, setIsLike] = useState(false)
    const [loadLike, setLoadLike] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    const { width } = useWindowDimensions();
    const [formattedDate, setFormattedDate] = useState('')
    useEffect(() => {

        if(deal.date){
            setFormattedDate(formatDate(deal.date, datas.days ))
        }
        return () => {
            setIsLike(false)
        };
    }, [])


    useEffect(() => {
        if (mode !== "preview" && deal.likes) {
            if ((deal.likes.find(like => (like._id === auth.user._id)) || (deal.likes.find(like => (like === auth.user._id))))) {

                setIsLike(true)
            } else {
                setIsLike(false)
            }
        }


    }, [deal])


    const handleLike = async (e) => {
        e.stopPropagation()
        if (loadLike) return;

        setLoadLike(true)
        await dispatch(likeDeal({ deal, auth, typePage }))
        await dispatch(saveDeal({ deal, auth }))

        setLoadLike(false)
    }

    const handleUnLike = async (e) => {
        e.stopPropagation()
        if (loadLike) return;
        setLoadLike(true)
        await dispatch(unLikeDeal({ deal, auth, typePage }))
        await dispatch(unSaveDeal({ deal, auth }))


        setLoadLike(false)
    }


    const redirect = (e) => {
        e.stopPropagation();
        if(auth.user.subscription?.status === 'active' || isInArray(deal.company.admins, auth.user._id)){
            dispatch({ type: GLOBALTYPES.MODAL, payload: { typeModal: "dealCardModal", open: true, deal: deal } })
        }else{
            dispatch({ type: GLOBALTYPES.MODAL, payload: {  typeModal: "activateSubModal", open: true } })
        }
    }


    const edit = (e) => {
        e.stopPropagation();
        dispatch({ type: GLOBALTYPES.MODAL, payload: { typeModal: "createDealModal", open: true, deal: deal } })
    }

    return (
        <Card onClick={(e) =>redirect(e)}>
            <Top>
                {
                    (deal.images && deal.images[0] && auth.user.subscription?.status === 'active') ? <img className='imgback' effect="blur" src={deal.images[0].url}  alt="img"/>
                        : <img className='imgback' effect="blur" src="https://behandy-front.s3.eu-west-3.amazonaws.com/color-gd67b3d48d_640.png" alt="img" />

                }
            

                {
                    <TypeTag>{convertIdToLabel(datas.filters, deal.tags[0])}</TypeTag>
                }

                
               {deal.target==="partner"&& <PartnerBanner>
                    PARTENAIRE
                </PartnerBanner>}



                {mode !== "preview" &&
                    <ButtonLike>
                        {
                            deal.company && !isInArray(deal.company.admins, auth.user._id)
                                ?
                                <LikeButton
                                    isLike={isLike}
                                    handleLike={handleLike}
                                    handleUnLike={handleUnLike}
                                    className="icon like"
                                />
                                : <ChoiceDiv>

                                    {
                                        showOptions ? <>
                                            <MdDelete onClick={(e) => { e.stopPropagation(); dispatch({ type: GLOBALTYPES.MODAL, payload: { type: "deal", typeModal: "delete", title: "Etes-vous sûr de vouloir supprimer cette offre?", open: true, deal: deal } }) }} />
                                            <MdModeEdit onClick={(e) => edit(e)} />
                                        </> : <></>

                                    }
                                    <BsThreeDotsVertical onClick={(e) => { e.stopPropagation(); setShowOptions(!showOptions) }} />


                                </ChoiceDiv>
                        }</ButtonLike>}
            </Top>
            <Bottom>
                <div>
                    <h2 className={(auth.user.subscription?.status !== 'active' && !isInArray(deal.company.admins, auth.user._id)) ? 'blured' : ''} >{(deal.company && (auth.user.subscription?.status === 'active' || isInArray(deal.company.admins, auth.user._id)) ) ? deal.company.name : "Nom de l'entreprise"}</h2>
                    <h3>{deal.offer}</h3>
                    <h4>{(deal.date && deal.date.length === 7) ? 'Tous les jours' : formattedDate}</h4>

                </div>

                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                    
                    <div style={{ display: "flex" }}>
                        {deal.amountPersons > 0 && <><FaUserFriends className='icon-users' />
                        <span>{deal.amountPersons}</span></>}
                    </div>


                    {deal.company && <div className='localisation' >
                        <svg width="15" height="15" viewBox="0 0 304 432" xmlns="http://www.w3.org/2000/svg">
                            <path fill="#757474" d="M149 3q62 0 106 43.5T299 152q0 31-15.5 71.5t-37.5 75t-44 65t-37 48.5l-16 17q-6-6-16-18t-35.5-46.5t-45.5-67T16 224T0 152Q0 90 43.5 46.5T149 3zm0 202q22 0 38-15.5t16-37.5t-16-37.5T149 99t-37.5 15.5T96 152t15.5 37.5T149 205z" />
                        </svg>
{  deal.company.address &&    <span>{extractLocalityAndCountry(deal.company.address)}</span>
}                    </div>}

                    {/*                     <span style={{ fontSize: "0.7em", fontWeight: 'lighter', whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "calc(50%)", display: "flex", justifyContent: "flex-end" }}>Expire le {moment(deal.enddate).format('DD/MM/YY')}</span>
 */}
                    {/*                  <span  style={{fontSize:"0.9em", whiteSpace: "nowrap",textOverflow: "ellipsis",overflow: "hidden",width: "calc(50%)", display:"flex", justifyContent:"flex-end"}}>{deal.localisation.formatted_address}</span>
 */}
                </div>


                {
                    deal.company && mode !== 'preview' && <img onClick={() =>{if((auth.user.subscription?.status === 'active' || isInArray(deal.company.admins, auth.user._id)))history.push(`/company/${deal.company._id}`)}} src={deal.company.avatar} alt={"logo de " + deal.company.name} />

                }


            </Bottom>
        </Card>
    )
}

export const PartnerBanner = styled.div`

position: absolute;
bottom: 20px;
right: -30px;
box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
transform: rotate(-40deg);
padding: 3px 30px;
background: #518efc;
font-size: 0.8em;
color: white;
font-style: oblique;
font-weight: 400;

`

export const TypeTag = styled.div`
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 0.8em;
    color: white;
    padding: 0px 15px;
    border-radius: 50px;
`

const Card = styled.div`
    position: relative;
    overflow:hidden;
    display:flex;
    flex-direction: column;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 15px;
    .imgback{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;

    }

    &:hover{
        cursor:pointer;
    }

    min-height: 250px;
`
const Top = styled.div`
    position: relative;
    height: 60%;

    .dealicon{
        position:absolute;
        top: 5px;
        left: 5px;
    }


`

const Bottom = styled.div`
    position: relative;
    background : white;

    height: 40%;
    padding: 2% 4%;
    display:flex;
    flex-direction: column;
    justify-content space-between;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    
    .blured{
        filter: blur(3px);
    }
    h2{
        margin:0px !important;
        padding: 0px !important;
        font-size: 0.9em !important;
        font-weight: 700 !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
        overflow: hidden;
        width: calc(80%) !important;
        text-align: left;
        color: black;
    }

    h3{
        margin:0px;
        padding: 0px;
        font-size: 0.8em;
        font-weight: 400;
        color: var(--color-accent);
        font-style: italic;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: calc(80%);

    }

    h4{
        margin:0px;
        padding: 0px;
        font-size: 0.7em;
        font-weight: 400;
    }

    span{
        display:flex;
        margin:0px;
        padding: 0px;
        font-size: 0.8em;
        font-weight: 700;
        color: #757474;
        margin-left: 5px;

    }

    .icon-users{
        color:#757474;
        font-size: 1.2em;
    }

    img{
        position: absolute;
        width: 2.2em;
        height: 2.2em;
        top: 7px;
        right: 7px;
        border-radius: 50%;
    }

    .localisation{
        align-self: end;
        display:flex;
        align-items:center;
        span{
            display:flex;
            margin:0px;
            padding: 0px;
            font-size: 0.8em;
            font-weight: 700;
            color: #757474;
            margin-left: 2px;
    
        }
    }

`
const ButtonLike = styled.div`
        position: absolute;
        top: 5px;
        right: 5px;
     
`
const ChoiceDiv = styled.div`
    display:flex;
    justify-content: center;
    align-items:center;
    background : white;

    font-size: 1.7em;
    padding: 3px;
    border-radius: 50px;

   

`

export default DealPreviewCard