import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Modal from '../../utils/Modal/Modal';
import InvitationCard from './InvitationCard';
const InvitationModal = () => {
 
  const {modal} = useSelector(state => state)



  return (
    <Modal  modalName={"invitationModal"}  heightModal={'70vh'} widthModal={'25vw'} >


            <Content>

                <InvitationCard invitation={modal.invitation} page={modal.status === 'received'?modal.invitation.sender:modal.invitation.receiver} heightCard={"30vh"} />

            </Content>



    </Modal>

  )
}



const Content = styled.div`

    position: relative;
    width: 100%;
    height: 100%;
`




export default InvitationModal