import React from 'react'
import restaurant from '../../assets/filters/resto.png'
import hotel from '../../assets/filters/hotel.png'
import bakery from '../../assets/filters/boulangerie.png'
import pastry from '../../assets/filters/patisserie.png'
import bar from '../../assets/filters/bar.png'
import cafe from '../../assets/filters/coffee.png'
import butcherie from '../../assets/filters/charcut.png'
import fish from '../../assets/filters/poisson.png'
import food from '../../assets/filters/primeur.png'
import drink from '../../assets/filters/vin.png'
import health from '../../assets/filters/spa.png'
import hobby from '../../assets/filters/heart.png'
import other from '../../assets/filters/more.png'


const FilterImageIcon = ({type}) => {
  return (
    <>
        {type ==="restaurant" &&  <img src={restaurant}  width={50} height="50" title="Menu" />}
        {type ==="hotel" &&  <img src={hotel}  width={50} height="50" title="Menu" />}
        {type ==="bakery" &&  <img src={bakery}  width={50} height="50" title="Menu" />}
        {type ==="pastry-shop" &&  <img src={pastry}  width={50} height="50" title="Menu" />}
        {type ==="bar" &&  <img src={bar}  width={50} height="50" title="Menu" />}
        {type ==="cafe" &&  <img src={cafe}  width={50} height="50" title="Menu" />}
        {type ==="butcherie" &&  <img src={butcherie}  width={50} height="50" title="Menu" />}
        {type ==="fish-shop" &&  <img src={fish}  width={50} height="50" title="Menu" />}
        {type ==="food" &&  <img src={food}  width={50} height="50" title="Menu" />}
        {type ==="drink" &&  <img src={drink}  width={50} height="50" title="Menu" />}
        {type ==="health" &&  <img src={health}  width={50} height="50" title="Menu" />}
        {type ==="hobby" &&  <img src={hobby}  width={50} height="50" title="Menu" />}
        {type ==="other" &&  <img src={other}  width={50} height="50" title="Menu" />}

    </>
  )
}

export default FilterImageIcon