import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from "styled-components"
import { useLocation } from "react-router-dom";
import { getPageById } from '../../redux/actions/pageAction'

import { getDealsByCompany2 } from '../../redux/actions/dealAction'
import { GLOBALTYPES } from '../../redux/actions/globalTypes'
import {IoMdAddCircle } from 'react-icons/io';
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { useHistory } from 'react-router-dom'
import ItemUser from '../../components/management-users/ItemUser'

const ManagementUsers = () => {
  const { auth, pages } = useSelector(state => state)
  const location = useLocation();
  const dispatch = useDispatch()
  const history = useHistory()
  const [company, setCompany] = useState(null);
  const { id } = useParams()

   useEffect(() => {
    if(pages.activePage){
        setCompany(pages.activePage)
    }
 
  }, [pages.activePage]);

  useEffect(() => {
   if(location.state && location.state.company){ 
        setCompany(location.state.company)
   }else{
     dispatch(getPageById({auth, id}))
   }  

   dispatch(getDealsByCompany2({auth, id, filter: {}}))
 },[])


  return (
    <Container>
{            
    pages.activePage &&        <>
                <HeaderTopMobile>
                    <div onClick={() => history.push('/accounts')}><AiOutlineArrowLeft className="icon-arrow" style={{fontSize: "1.4em"}} /></div>
                    <h2 >Gérer</h2>
                    <div onClick={() => dispatch({ type: GLOBALTYPES.MODAL,payload: {typeModal:"createPageModal", open:true}})}><IoMdAddCircle className="icon-add" style={{fontSize: "2em"}}/></div>
                </HeaderTopMobile>

                <div style={{padding:"0% 4%"}}>
                <h3 style={{width: "90%", textAlign:"center"}}>Vous pouvez gérer les droits des utilsateurs de la page “{pages.activePage.name}” </h3>

                <h4>Modérateurs</h4>

                <span>Les modérateurs peuvent publier du contenu sur la page </span>


                    {
                        pages.activePage.admins.length > 0 ? <div style={{marginTop: "5%"}}>
                        {
                            pages.activePage.admins.map( (user) => (
                                <ItemUser user={user.user} />
                            ))
                        }
                        </div>
                        : <p>Aucun administrateur</p>
                    }


                    <ButtonAdd>Ajouter</ButtonAdd>
                </div>


            </>
}


 
    </Container>
  )
}

const Container = styled.div`
    display:flex;
    flex-direction: column;
    width: 100%;

    align-items: center;

    h3{
        font-size: 0.8em;
        font-weight: 500;
        color: #757474;
        margin:0px;
        margin-top: 8%;
        margin-bottom: 6%;
        border-bottom: 2px solid #eee;
        padding-bottom: 4%;

    }

    h4{
        width: 100%;
        font-size: 0.8em;
        font-weight: 700;
        color: black;
        margin:0px;
        margin-bottom: 4%;

    }

    span{
        margin: 0px;
        font-size: 0.7em;
        font-weight: 400;
        color: #757474;
        width: 100%;

    }



`

const HeaderTopMobile = styled.div`
    display:flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    color: #F87778;

    h2 {
        font-size: 1em;
        margin: 0px;
    }

    
`

const ButtonAdd = styled.button`
    margin: 0 auto;
    margin-top: 15px;

    display:flex;

    background: #F87778;
    color: white;
    border: none;
    padding: 1% 4%;
    border-radius: 5px;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    

`


export default ManagementUsers; 