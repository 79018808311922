import { Player } from '@lottiefiles/react-lottie-player'
import React from 'react'
import styled from 'styled-components'
import EmptyAnimation from '../../assets/empty.json'
import { v4 as uuidv4 } from 'uuid';
import AdminCard from './AdminCard'
import { BsPlusCircleFill } from 'react-icons/bs'
import { GLOBALTYPES } from '../../redux/actions/globalTypes'
import { useDispatch, useSelector } from 'react-redux'
import AdminSearchModal from './AdminSearchModal'

const AdminsManagementComponents = ({ company }) => {
  const { modal, pages } = useSelector(state => state)
  const dispatch = useDispatch()

  return (
    <>
      {(modal.typeModal === "adminModal" && modal.open) && <AdminSearchModal page={company} />}

      <Header>
        <AddButton onClick={() => dispatch({ type: GLOBALTYPES.MODAL, payload: { typeModal: "adminModal", open: true, company: company } })}><BsPlusCircleFill className='icon-btn' />
          <span>Ajouter </span></AddButton>
      </Header>

      {pages.activePage.admins.filter(user => user.user.role !== 'admin').length === 0 &&
        <NoResults>
          <Player
            autoplay
            loop
            src={EmptyAnimation}
            style={{ height: '100%', width: '100%' }}
          >
          </Player>
          <p style={{ transform: 'translateY(-30px)' }}>Ajouter un administrateur autre que le super admin</p>
        </NoResults>
      }

      {
        (company.admins.length === 0)
          ? <NoResults>
            <Player
              autoplay
              loop
              src={EmptyAnimation}
              style={{ height: '100%', width: '100%' }}
            >
            </Player>
            <p style={{ transform: 'translateY(-30px)' }}>Aucune admin</p>
          </NoResults>
          : <ListAdmins>
            {
              pages.activePage.admins.map((user) => (
                user.user.role !== 'admin' ? <AdminCard page={company} key={uuidv4()} user={user.user} /> : null
              ))
            }

          </ListAdmins>
      }
    </>
  )
}




const Header = styled.div`
    display:flex;
    justify-content: flex-end;
    align-items:center;
    h2{
        font-size: 1.3em;
        font-weight: 600;
        color: var(--color-dark-grey)
    }

    @media screen and (max-width: 768px) {
       h2{
        font-size: 1em;
       }
    } 
`

export const NoResults = styled.div`

  display:flex;
  flex-direction: column;
  jutify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0px auto;

`

const AddButton = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    background: var(--color-accent);

    border-radius: 25px;
    padding: 5px 15px;

    span{
        color: white;
        font-weight: 600;
    }

    .icon-btn{
        font-size: 1.5em;
        margin-right: 5px;
        color: white;
    }
  

    &:hover{
        cursor : pointer;
        background: var(--color-accent-hover);
    }

    @media only screen and (max-width: 768px) {  
        span{
            display:none;
        }
    
        padding: 0px;
        background: white;
        .icon-btn{
            font-size: 2em;
            margin-right: 0px;
            color: var(--color-accent);
        }

        &:hover{
            .icon-btn{
                background: white;
                color: #f88485;
            }
        }
      


      
    }

`

const ListAdmins = styled.div`
display:grid;
grid-template-columns: repeat(2, 1fr);

grid-gap: 10px;

margin-top: 4%;

.icon-loading{
  display:none;

                 
}


@media only screen and (max-width: 768px) {  
  display:flex;
  flex-direction: column;

  margin-top: 5%;

`
export default AdminsManagementComponents