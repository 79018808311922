import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FaUserFriends } from 'react-icons/fa'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { useHistory } from 'react-router-dom'
import { likeDeal, unLikeDeal } from '../../../redux/actions/dealAction'
import { useDispatch, useSelector } from 'react-redux'
import LikeButton from '../../utils/LikeButton'
import { GLOBALTYPES } from '../../../redux/actions/globalTypes'
import { MdDelete, MdModeEdit } from 'react-icons/md'
import { saveDeal, unSaveDeal } from '../../../redux/actions/pageAction'
import { convertIdToLabel, extractLocalityAndCountry, isInArray } from '../../../utils/function'
import { Tag } from 'antd'

const DealPartnerPreviewCardMobile = ({ typePage, deal }) => {
    const { auth , datas} = useSelector(state => state)
    const dispatch = useDispatch();
    const [isLike, setIsLike] = useState(false)
    const [loadLike, setLoadLike] = useState(false)
    const [showOptions, setShowOptions] = useState(false)

    const history = useHistory();



    useEffect(() => {
        if (deal.likes && (deal.likes.find(like => (like._id === auth.user._id)) || (deal.likes.find(like => (like === auth.user._id))))) {
            setIsLike(true)
        } else {
            setIsLike(false)
        }
    }, [deal])

    const handleLike = async (e) => {
        e.stopPropagation()
        if (loadLike) return;
        setLoadLike(true)
        await dispatch(likeDeal({ deal, auth, typePage }))
        await dispatch(saveDeal({ deal, auth }))
        setLoadLike(false)
    }

    const handleUnLike = async (e) => {
        e.stopPropagation()
        if (loadLike) return;
        setLoadLike(true)
        await dispatch(unLikeDeal({ deal, auth, typePage }))
        await dispatch(unSaveDeal({ deal, auth }))


        setLoadLike(false)
    }


    const redirect = (e) => {
        e.stopPropagation();
        if(auth.user.subscription?.status === 'active' || isInArray(deal.company.admins, auth.user._id)){
            history.push(`/deal/${deal._id}`)
        }else{
            dispatch({ type: GLOBALTYPES.MODAL, payload: {  typeModal: "activateSubModal", open: true } })
        }
    }

    const edit = (e) => {
        e.stopPropagation();
        dispatch({ type: GLOBALTYPES.MODAL, payload: { typeModal: "createDealModal", open: true, deal: deal } })
    }

    return (
        <>
            {deal.company && <Card onClick={(e) => redirect(e)}>
                <Left>
                    {
                        deal.images && deal.images[0] ? <img src={deal.images[0].url} />
                            : <img src="https://behandy-front.s3.eu-west-3.amazonaws.com/color-gd67b3d48d_640.png" />

                    }        </Left>
                <Center>
                    <div>
                    <h2 className={(auth.user.subscription?.status !== 'active' && !isInArray(deal.company.admins, auth.user._id)) ? 'blured' : ''} >{(deal.company && (auth.user.subscription?.status === 'active' || isInArray(deal.company.admins, auth.user._id)) )? deal.company.name : "Nom de l'entreprise"}</h2>
                        <h3>{deal.offer}</h3>
                        
                {deal.tagsDescription && <div style={{display:"flex", gap:"2px"}}>

{
    deal.tagsDescription.slice(0,2).map(tag =>(
        <Tag style={{padding:"0px 10px" , color:"#1DB4B4", borderRadius:"50px", fontSize:"0.8em"}} color="cyan" bordered={false}>{tag}</Tag>
    ))
}
</div>}

                    </div>

                    <div className='center-bottom' style={{ display:"flex", alignSelf: "end"}}>
                    {deal.localisation && <div className='localisation' >
                        <svg width="15" height="15" viewBox="0 0 304 432" xmlns="http://www.w3.org/2000/svg">
                            <path fill="#757474" d="M149 3q62 0 106 43.5T299 152q0 31-15.5 71.5t-37.5 75t-44 65t-37 48.5l-16 17q-6-6-16-18t-35.5-46.5t-45.5-67T16 224T0 152Q0 90 43.5 46.5T149 3zm0 202q22 0 38-15.5t16-37.5t-16-37.5T149 99t-37.5 15.5T96 152t15.5 37.5T149 205z" />
                        </svg>
                        { <span>{extractLocalityAndCountry(deal.localisation)}</span>
                        }                    </div>}

                    </div>


                </Center>
                <Right>
                <ButtonLike>
                        {
                            deal.company && !isInArray(deal.company.admins, auth.user._id) ?

                                <LikeButton
                                    isLike={isLike}
                                    handleLike={handleLike}
                                    handleUnLike={handleUnLike}
                                    className="icon like"
                                />
                                : <div style={{ display: 'flex', flexDirection: 'column', fontSize: "1.4em" }}>
                                    <BsThreeDotsVertical onClick={(e) => { e.stopPropagation(e); console.log("hello"); setShowOptions(!showOptions) }} />

                                    {
                                        showOptions ? <>
                                            <MdModeEdit onClick={(e) => { edit(e) }} />

                                            <MdDelete onClick={(e) => { e.stopPropagation(); dispatch({ type: GLOBALTYPES.MODAL, payload: { type: "deal", typeModal: "delete", title: "Etes-vous sûr de vouloir supprimer cette offre?", open: true, deal: deal } }) }} />
                                        </> : <></>

                                    }



                                </div>
                        }</ButtonLike>


                </Right>
            </Card>}
        </>
    )
}


const Card = styled.div`
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;    display:flex;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    background: white;
    height: 100px;
    padding: 2% 4%;
    margin-bottom: 2%;
    position:relative;

`

const Left = styled.div`

    img{
        width: 65px;
        height: 65px;
        object-fit: cover;
        border-radius: 10px;
        
    }

`

const Center = styled.div`
    display:flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 5%;
    text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
  z-index: 10;
  height:100%;
  .blured{
    filter: blur(3px);
}

    h2{
        margin:0px;
        padding: 0px;
        font-size: 0.9em;
        font-weight: 700;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: calc(80%);
    }

    h3{
        margin:0px;
        padding: 0px;
        font-size: 0.8em;
        font-weight: 400;
        color: #1DB4B4 !important;
        font-style: italic;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: calc(90%);
    }

    h4{
        margin:0px;
        padding: 0px;
        font-size: 0.7em;
        font-weight: 400;
    }

    span{
        margin:0px;
        padding: 0px;
        font-size: 0.8em;
        font-weight: 700;
        color: #757474;
        margin-left: 5px;
    }

    .icon-users{
        color:#757474;
        font-size: 1.2em;
    }

    .center-bottom{
        display:flex;
        justify-content: space-between;

        .localisation{
            align-self: end;
            display:flex;
            align-items:center;
            span{
                display:flex;
                margin:0px;
                padding: 0px;
                font-size: 0.7em;
                font-weight: 700;
                color: #757474;
                margin-left: 2px;
        
            }
        }
    }

`

const Right = styled.div`
     display:flex;
     align-items: flex-start;
     height: 100%;
     .plus {
         position: absolute;
         left: -5px;
         font-size: 1.5em;
         color:#757474;
         border-radius: 50%;
         padding: 2px;

         &:hover{
             cursor:pointer;
             background : #C0C0C0;

         }
     }
`

const ButtonLike = styled.div`
    position:absolute;
    top: 5px;
    right: 5px;
    z-index: 10;
`

const ChoiceDiv = styled.div`
    position:absolute;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    background : white;

    font-size: 1.2em;
    padding: 2px;
    border-radius: 50px;
    right: 0px;

   

`
export default DealPartnerPreviewCardMobile