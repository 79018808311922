import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import LoadIcon from '../../../../images/loading.gif'
import { getDataAPI } from '../../../../utils/fetchData'
import styled from "styled-components"
import SkillCard from './SkillCard'
import { useParams } from 'react-router-dom'
import { SKILLS_TYPES } from '../../../../redux/actions/skillAction'
import LoadMoreBtn from '../../LoadMoreBtn'

const Skills = ({id}) => {
    const { skills, auth } = useSelector(state => state)
    const dispatch = useDispatch()
    const [skillList, setSkill] = useState([])
    const [load, setLoad] = useState(false)

    useEffect(() => {
        setSkill(skills.skills)
    }, [skills.skills])

    const handleLoadMore = async () => {
        setLoad(true)
        const res = await getDataAPI(`skills/${id}?limit=${skills.page * 3}`, auth.token)

        console.log(res)
        dispatch({
            type: SKILLS_TYPES.GET_SKILLS_USER, 
            payload: {...res.data, page: skills.page + 1}
        })

        setLoad(false)
    }

    return (
        <ContainerSkills>
            {
                skillList.map(skill => (
                    <SkillCard key={skill._id} skill={skill} />
                ))
            }

            {
                load && <img src={LoadIcon} alt="loading" className="d-block mx-auto" />
            }

{/* {             <LoadMoreBtn limit={3} result={skills.result} page={skills.page}
            load={load} handleLoadMore={handleLoadMore} />}
 */}

        </ContainerSkills>
    )
}

const ContainerSkills = styled.div`
    display: flex;
    flex-direction: column;
`

export default Skills
