import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button } from 'antd'

const AdCard = ({ ad, heightCard }) => {
    const { auth } = useSelector(state => state)

    const [company, setCompany] = useState(null)
    const history = useHistory();
    const [URL, setURL] = useState("");

    const openInNewTab = (url) => {
        console.log(url)
        window.open(url);
    };



    
    return (
        <Card height={heightCard}>
           { ad && <><Top  imgBackground={ad.imgBackground}>
                    <img onClick={() => history.push(`/company/${ad.page._id}`)} src={ad.page.avatar} alt={"logo de " + ad.page.name} />
                    <h3>{ad.page.name}</h3>

    


            </Top>
            <Bottom>
               <>

                        <div>
                            <h2 >Description</h2>
                            <h3 >{ad.headerTitle}</h3>
                            {ad.description && <p>{ad.description}</p>}

                        </div>
{        ad.website &&                <Button type="default" onClick={() => openInNewTab(ad.website)}  size="large" style={{ margin: "0px auto" }}>J'en profite!</Button>
}                    </>
            




            </Bottom></>}


        </Card>
    )
}



const Card = styled.div`
    display:flex;
    flex-direction: column;
    height: 100%;
    background : transparent;
    border-radius: 10px;
    overflow: hidden;
    @media screen and (max-width: 768px) {
        min-height: 400px;
    }

    .mobile{
        display:none;
    }

    .bs-top{
        flex-direction:column;
        align-items:center;
        justify-content:center;
    }


        @media screen and (max-width: 768px) {

        .bigscreen{
            display:none;
        }
        .mobile{
            display:flex;
        }


    }
`

const Content = styled.div`
    display:flex;
    width: 100%;
    height: 100%;
    justify-content:space-around;
    align-items:center;
    flex-direction:column;

    text-align: center;
    padding: 4% 8%;



    p{
        margin:0px;
        padding: 0px;
        color: #585858;
        font-size: 1em;
        font-weight: 400;
        font-style: italic;
    }




`

const Center = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    

`
const Top = styled.div`
    position: relative;
    background-image: ${props => props.imgBackground?`url(${props.imgBackground})`: ""};
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: ${props => props.height ? props.height : "30vh"};

    display:flex;
    align-items:center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    img{
        width: 85px;
        height: 85px;
        border-radius: 50%;
        border: 2px solid white;
    }

    h3{
        color:white;
        font-weight: 800;
        font-size: 1.2em;
        padding: 0px;
        margin: 0px;
    }



    .bg-top{
        flex-direction:column;
        justify-content:center;
        align-items:center;
    }


`

const Bottom = styled.div`
    overflow-wrap: break-word;
    position: relative;
    background : #F9F9F9;
    border: 1px solid #f2f2f2;
    min-height: 15vh;
    flex: 1;
    padding: 2% 4%;
    display:flex;
    justify-content: space-between;
    flex-direction: column;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    .title{
        margin:0px;
        padding: 0px;
        font-size: 1.2em;
        font-weight: 700;

        &:hover{
            cursor: pointer;
        }
    }

    h2{
        margin:0px;
        padding: 0px;
        font-size: 1em;
        font-weight: 800;
        color: #585858;
    }

    
    h3{
        margin:0px;
        padding: 0px;
        font-size: 1.1em;
        font-weight: 900;
        color: #F87778;
    }


    h4{
        margin:0px;
        padding: 0px;
        color: #757474;

        font-size: 0.9em;
        font-weight: 400;
    }

    h5{
        margin:0px;
        padding: 0px;
        margin-top: 5%;
        color: black;
        font-size: 0.9em;
        font-weight: 700;
    }



    p{
        margin:0px;
        margin-top: 5%;
        padding: 0px;
        font-size: 1em;
        font-weight: 400;
        color: #757474;
    }

    .icon-users{
        color:#757474;
        font-size: 1.2em;
    }




        margin-bottom: '50px';


    @media screen and (max-width: 768px) {
        .img-company{
           display:none;
        }

        .title{
            display:none;
        }

        h4{
            font-size: 0.8em;
        }
    } 

`

export default AdCard