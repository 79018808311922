import { Button, Form, Input, Select } from 'antd'
import React from 'react'
import { useState } from 'react';
import styled from 'styled-components'
import {  ContentModalAccount } from '../ChoiceActivity';
import { useEffect } from 'react';
import { register } from '../../../redux/actions/authAction';
import { useDispatch, useSelector } from 'react-redux';

const SkillsForm = ({formData, setFormData, step, setStep}) => {
    
    const {datas} = useSelector(state=>state)
    const dispatch = useDispatch();





    const handleChangeInput = e => {
        const { id, value } = e.target
        setFormData({ ...formData, [id]: value })
    }




    const validateMessages = {
        required: '${label} requis!',
        types: {
            email: "${label} invalide!",
            number: '${label} is not a valid number!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    };

    const handleNext = async () => {
        console.log(formData)
        formData.fullname = formData.firstname + " " + formData.lastname
        formData.situation = 'experience'

        const isRegistered = await dispatch(register(formData));
    
        if (isRegistered) {
            setStep(2);
        } else {
            setStep(3);
        } 
    }     




    return (
        <ContentModalAccount>
            <div className='titles'>
            <h2>Bonjour {formData.firstname}, ravi de vous rencontrer !</h2>
            <h4>Quelles sont vos domaines de compétences?</h4>

            </div>

            <Form
                onFinish={() => { handleNext() }}
                layout="vertical"
                style={{ width: "100%" }}
                initialValues={{ companyName: formData.companyName, titleJob: formData.titleJob }}
                validateMessages={validateMessages}
            >

{/*                 <Form.Item
                    label="Profil"
                    name="profil"
                    onChange={(e) => handleChangeInput(e)}



                    required={true}
                    rules={[
                        {
                            validator: (_, value) =>
                                selectedTags.length > 0 ? Promise.resolve() : Promise.reject(new Error('Veuillez choisir un tag!'))
                        }
                    ]}
                >
                      <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Choississez au moins un profil"
                        onChange={handleChange}
                        options={works}
                        size="large"
                        dropdownStyle={{ zIndex: "100000" }}
                        />

                </Form.Item> */}

<Form.Item
                    label="Entreprise"
                    name="companyName"
                    onChange={(e) => handleChangeInput(e)}
    
                >
                    <Input size="large"  maxLength={150} placeholder=''/>
                </Form.Item>

                <Form.Item
                    label="Poste"
                    name="titleJob"
                    onChange={(e) => handleChangeInput(e)}
        
                >
                    <Input size="large"  maxLength={100} placeholder='Ex: Chef Pâtissier chez ...'/>
                </Form.Item>

                <Form.Item className="item-next" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button size="large" shape="round" type="primary" htmlType="submit">
                        Créer
                    </Button>
                </Form.Item>
            </Form>


            
        
        
        </ContentModalAccount>
    )
}

export const TagsChoices = styled.div`
        display: flex;
        flex-wrap:wrap;
        grid-template-columns: repeat(3, 1fr);
        gap: 5px;
        width: 100%;

        justify-content: space-around;
        @media screen and (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        } 

`

export const Tag = styled.div`
    padding: 5px 15px;
    line-height: 1.2; 
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background-color: ${props => props.selected ? 'var(--color-accent)' : 'white'};
    text-align: center;
    color: ${props => props.selected ? 'white' : ''};

    img{
        width: 20px !important; 
        height: 20px !important;
        margin-right: 5px;
    }



    &:hover{
        cursor: pointer;
        transform: scale(1);
        background: #fafafa;
        color:black;
    }

`


export default SkillsForm