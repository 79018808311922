import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import useWindowDimensions from '../../utils/useWindowsDimensions';
import { useDispatch, useSelector } from 'react-redux';
import CurriculumVitae from '../../components/profile/cv/CurriculumVitae';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getUser } from '../../redux/actions/userAction';
import { extractLocalityAndCountry } from '../../utils/function';


const UserProfile = () => {

  const [user, setUser] = useState(null);
  const { auth, users } = useSelector(state => state)
  const dispatch = useDispatch()
  const {id} = useParams();
  const {  width } = useWindowDimensions();


  useEffect(() => {
    if(users.current_user){
      setUser(users.current_user)
    }else{
      dispatch(getUser({auth, id}))
    }
  }, [users.current_user, id])
  

  return (
    <Container>

    {
      user &&<> 

       {
        width < 768 &&
        <ContentMobile>
                       <CurriculumVitae id={user._id} />

        </ContentMobile>
      }
 

 

       {
        (width >= 768) &&
        <ParentContent>
      

          <h3>Profil de {user.fullname} </h3>

         
          <Content>
            <LeftPart>
              <UserPart>
                <InfoAvatar>
                  <img src={user.avatar}
                    alt="avatar" />
               
                </InfoAvatar>
                <h2>{user.fullname}</h2>
                <h3>{user.titleJob}</h3>

                <Description>
                  <h5>Bio:</h5>
                  <p>{user.story ? user.story : "Aucune description"}</p>
                  <h5>Localisation:</h5>
                  <p>{extractLocalityAndCountry(user.address)}</p>

                </Description>
              </UserPart>
             

            </LeftPart>

            <RightPart>
         

               <CurriculumVitae id={user._id} />
              

            </RightPart>
          </Content>
        </ParentContent>
      } 
    </>
}
    </Container>
  )
}

const Container = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-left: 5%;
    padding: 2% 5%;
    .icon-left{
      position: absolute;
      top: 0px;
      left: 0px;
      color: var(--color-accent);
      font-size: 1.5em;
    }

    margin-bottom: 5%;
`;
const ParentContent = styled.div`
    display:flex;
    flex-direction:column;
    position: relative;
    width:100%;
    height: 100%;

    h3 {
      color: #585858;
      font-weight: 800;
      font-size: 1.3em;
      margin: 2% 0%;
    }
`

const Content = styled.div`
    display:flex;
    position: relative;
    width:100%;
    height: 100%;


`

const LeftPart = styled.div`
    display:flex;
    flex-direction: column;
   
    width: 25%;
    padding: 2% 0%;
    
`
const UserPart = styled.div`
display:flex;
flex-direction: column;
align-items: center;
background :#FAFAFA;

border-radius: 25px;
min-height: 70vh;
max-height: 80vh;
h2{
  margin: 0px;
  margin-top: 5%;
  font-size: 1.5em;
  font-weight: 700;
  color: #757474;
  text-align: center;
}

h3{
  margin: 0px;
  margin-top: 2%;
  font-size: 1.2em;
  font-weight: 400;
  color: #757474;
  text-align: center;

}


`

const Description = styled.div`
    display:flex;
    flex-direction: column;
    width: 100%;
    padding: 0% 5%;
    h5{
      margin: 0px;
      margin-top: 5%;
      font-size: 1em;
      font-weight: 600;
      color: var(--color-accent);
    }

    p{
      margin: 0px;
      margin-top: 0%;
      font-size: 1em;
      font-weight: 400;
      color: #757474;
    }

`

const RightPart = styled.div`
  display:flex;
  flex-direction: column;
  width: 70%;
  padding: 0% 5%;
`


const ContentMobile = styled.div`

  display:flex;
  flex-direction column;
  align-items: center;
  width: 100%;
  h1{
    margin: 0px;
    color: var(--color-accent);
    font-size: 1.1em;
    font-weight: 500;
  }

`

export const InfoAvatar = styled.div`

    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    margin: 15px auto;
    border: 1px solid #ddd;
    cursor: pointer;

    img{
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
    }

    span{
        position: absolute;
        bottom: -100%;
        left: 0;
        width: 100%;
        height: 50%;
        text-align: center;
        color: orange;
        transition: 0.3s ease-in-out;
        background: #fff5;
    }

    &:hover span{
        bottom: -15%;
    }

    #file_up{
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
    }

    @media screen and (max-width: 768px) {
        width: 80px;
        height: 80px;
    } 
    

`

export const H2Title = styled.span`
    font-size: 1em;
    margin: 0px;
    color: var(--color-accent);
    font-weight: 600;
    margin-top: 2%;

    
    @media screen and (max-width: 768px) {
      font-size: 0.9em;

    } 


`
export const InputStyled = styled.input`
    background: #FAFAFA;
    border: none;
    width: 100%;
    outline: none;
    text-indent: 5px;
    padding: 10px 10px;
`

export const InputSelected = styled.select`
    background: #FAFAFA;
    border: none;
    width: 100%;
    outline: none;
    text-indent: 5px;
    padding: 10px 10px;
`



export default UserProfile