import styled from "styled-components"

export const InputStyled = styled.input `
    background: #FAFAFA;
    border: none;
    width: 100%;
    outline: none;
    text-indent: 5px;
    padding: 10px 10px;
`

export const InputSelected = styled.select `
    background: #FAFAFA;
    border: none;
    width: 100%;
    outline: none;
    text-indent: 5px;
    padding: 10px 10px;
`



export const TextAreaStyled= styled.textarea`
    background: #FAFAFA;
    border: none;
    width: 100%;
    outline: none;
    text-indent: 5px;
    padding: 10px 10px;

    text-justify: auto;
`

export const ButtonNext = styled.button `

    display: flex;
    background: #F87778;
    justify-content: center;
    align-items:center;
    outline: none;
    border: 1px solid #ddd;
    border-radius: 25px;
    padding: 12px 35px;

    span {
        padding-left: 20px;
        font-size: 14px;
        font-weight: 600;
        color: white;
    }

    margin-top: 5%;


    @media screen and (max-width: 768px) {
        margin-top: 10%;
        margin-bottom: 10%;
    } 

`