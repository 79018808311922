import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components';
import { FaUserFriends } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { Button, Tag } from 'antd'
import { AiOutlinePhone } from 'react-icons/ai'
import ModalityContent from './ModalityContent';
import { convertIdToLabel, formatDate, isInArray } from '../../utils/function';
import { useSelector } from 'react-redux';
import { PartnerBanner } from '../home/DealPreviewCard';

const DealCard = ({ deal, heightCard }) => {
    const { datas, pages, auth} = useSelector(state => state);
    const [showOffer, setShowOffer] = useState(false);
    const [formattedDate, setFormattedDate] = useState('')
    const  [tags, setTags] = useState([])
    const history = useHistory();


    const [URL, setURL] = useState("");

    useEffect(() => {
        if (deal.date) {
            setFormattedDate(formatDate(deal.date, datas.days))
        }
        if(deal && deal.tags){
            let tagsStr = []
            deal.tags.forEach(tag => {
                tagsStr.push(convertIdToLabel(datas.filters, tag))
            });
            setTags(tagsStr);

        }
        if (process.env.REACT_APP_URL) {
            setURL(process.env.REACT_APP_URL);
        }
    }, []);



    const handleShowDeal = () => {
        setShowOffer(!showOffer)
    }
    return (
        <Card height={heightCard}>
            <Top >

                {deal.target === "partner" && <PartnerBanner>
                    PARTENAIRE
                </PartnerBanner>}
                {
                    deal.images && deal.images[0] ? <img src={deal.images[0].url} />
                        : <img src="https://behandy-front.s3.eu-west-3.amazonaws.com/color-gd67b3d48d_640.png" alt="avatar" />

                }

            </Top>

            <Bottom>
                {!showOffer && <img src={deal.company.avatar} className="img-company" alt={'logo entreprise'} onClick={() => history.push(`/company/${deal.company._id}`)} />}                <div>
                    {!showOffer && <>
                        <h2 className='title' onClick={() => history.push(`/company/${deal.company._id}`)}>{deal.company.name}</h2>
                        <h3>{deal.offer}</h3>
                        <h4>{(deal.date && deal.date.length === 7) ? 'Tous les jours' : formattedDate}</h4>
                        <div>
                            {deal.amountPersons > 0 && <><FaUserFriends className='icon-users' />
                                <span class='amount'>{deal.amountPersons} {deal.amountPersons > 1? "personnes": "personne"}</span></>}
                        </div>


                        <p>{deal.description}</p>
                        <h5>{(deal.company && deal.company.address) && deal.company.address.formatted_address}</h5>

                        {deal.tagsDescription && <div style={{display:"flex", gap:"2px" , marginTop:'5px'}}>
                        {
                            deal.tagsDescription.map(tag =>(
                                <Tag style={{padding:"1px 10px" , color:"#1DB4B4", borderRadius:"50px"}} color="cyan" bordered={false}>{tag}</Tag>
                            ))
                        }
                        </div>}

                        <div style={{display:"flex", gap:"2px" , marginTop:'5px'}}>
                        {
                            tags.map(tag =>(
                                <Tag style={{padding:"1px 10px" , color:"white", borderRadius:"50px"}} color="rgba(0, 0, 0, 0.5)" bordered={false}>{tag}</Tag>
                            ))
                        }
                        </div>
                        
                       {(isInArray(deal.company.admins, auth.user._id) && deal.target === 'partner')&&
                        <div className='partners'>
                            <h5>Partenaires:</h5>

                            <div className='partners-div'>
                                {
                                    deal.partners.map(partner => (
                                        <div className='partner'   onClick={() => window.location.href = `/company/${partner._id}`}>  <img src={partner.avatar} className='partnerAvatar' alt="avatar"/>  <span>{partner.name}</span></div>
                                    ))
                                }
                                
                            </div>


                        </div>}

                    </>}



                 


                </div>

            



                <div style={{ display: "flex", justifyContent: "center", alignSelf:"center", gap: '10px', paddingTop: '20px' }}>
                    <Button style={{ width: "100%" }} type='primary' size="large" onClick={() => handleShowDeal()}>{showOffer ? "Retour" : "Voir les modalités"}</Button>
                </div>


            </Bottom>


            {showOffer && <ModalityDiv>

                <ModalityContent deal={deal} />
            </ModalityDiv>}

        </Card>
    )
}


const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;


const Card = styled.div`
    display:flex;
    flex-direction: column;
    height: 100%;
    background : transparent;
    overflow:hidden;

    img{
        width: 100%;
        height: 100%;

        object-fit: cover;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

    }
    @media screen and (max-width: 768px) {
        min-height: 400px;
    }
`

const ModalityDiv = styled.div`
  display: flex;
  background: white;
  height: 70%;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;

  animation: ${slideUp} 0.5s forwards; // 0.5s is the duration, adjust as needed
`;



const Top = styled.div`
    position: relative;
    height: 40%;
`

const Bottom = styled.div`
    overflow-wrap: break-word;
    overflow-y: auto;
    position: relative;
    background : #F9F9F9;
    border: 1px solid #f2f2f2;
    height: 60%;
    flex: 1;
    padding: 2% 8%;
    display:flex;
    justify-content: space-between;
    flex-direction: column;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    .title{
        margin:0px;
        padding: 0px;
        font-size: 1.2em;
        font-weight: bold;
        color:#585858;
        &:hover{
            cursor: pointer;
        }
    }

    h3{
        margin:0px;
        padding: 0px;
        font-size: 1.1em;
        font-weight: 600;
        color: var(--color-accent);
        font-style: italic;
        width: 83%;
    }

    h4{
        margin:0px;
        padding: 0px;
        color: #757474;

        font-size: 0.9em;
        font-weight: 400;
    }

    h5{
        margin:0px;
        padding: 0px;
        margin-top: 5%;
        color: black;
        font-size: 0.9em;
        font-weight: 700;
    }

    .amount{
        margin:0px;
        padding: 0px;
        font-size: 0.8em;
        font-weight: 700;
        color: #757474;
        margin-left: 5px;
    }

    p{
        margin:0px;
        margin-top: 5%;
        padding: 0px;
        font-size: 0.9em;
        font-weight: 400;
        color: #757474;
    }

    .icon-users{
        color:#757474;
        font-size: 1.2em;
    }

    img{
        position: absolute;
        width: 2.2em;
        height: 2.2em;
        top: 7px;
        right: 7px;
    }

    .img-company{
        position: absolute;
        top: 10px;
        right: 8%;
        width: 3.5em;
        height: 3.5em;
        border-radius: 50%;

        &:hover{
            cursor: pointer;
        }
    }

        margin-bottom: '50px';

    .partners{
        display:flex;
        flex-direction:column;
        gap: 10px;

        .partners-div{
            display:grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;
        }
    }

    .partner{
        display:flex;
        align-items: center;
        &:hover{
            cursor: pointer;
        }
        .partnerAvatar{
            position: relative;
            top:0px;
            left: 0px;
        }
        span{
            padding: 0px;
            margin: 0px;
            margin-left: 5px;
        }
    }


    @media screen and (max-width: 768px) {

        .partners-div{
            grid-template-columns: repeat(1, 1fr) !important;
        }
        .img-company{
           display:none;
        }

        .title{
            display:none;
        }

        h4{
            font-size: 0.8em;
        }
    } 

    /* width */
    ::-webkit-scrollbar {
    width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: #F87778; 
    border-radius: 10px;
    
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background: #b30000; 
    }


`



export default DealCard