import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import LoadIcon from '../../../../images/loading.gif'
import { getDataAPI } from '../../../../utils/fetchData'
import styled from "styled-components"
import ExperienceCard from './FormationCard'
import { useParams } from 'react-router-dom'
import { FORMATIONS_TYPES } from '../../../../redux/actions/formationAction'
import LoadMoreBtn from '../../LoadMoreBtn'

const Formations = ({id}) => {
    const { formations, auth, portfolio } = useSelector(state => state)
    const dispatch = useDispatch()
    const [form, setFormation] = useState([])
    const [load, setLoad] = useState(false)

    useEffect(() => {
        setFormation(formations.formations)
    }, [formations.formations])

    const handleLoadMore = async () => {
        setLoad(true)
        const res = await getDataAPI(`user_formations/${id}?limit=${formations.page * 3}`, auth.token)

        console.log(res)
        dispatch({
            type: FORMATIONS_TYPES.GET_FORMATIONS_USER, 
            payload: {...res.data, page: formations.page + 1}
        })

        setLoad(false)
    }

    return (
        <ContainerFormations>
            {
               portfolio.portfolio ? form.slice(0,1).map(formation => (
                    <ExperienceCard key={formation._id} formation={formation} />
                )): form.map(formation => (
                    <ExperienceCard key={formation._id} formation={formation} />
                ))
            }

            {
                load && <img src={LoadIcon} alt="loading" className="d-block mx-auto" />
            }
            

            {    !portfolio.portfolio  &&       <LoadMoreBtn limit={3} result={formations.result} page={formations.page}
            load={load} handleLoadMore={handleLoadMore} />}
    

        </ContainerFormations>
    )
}

const ContainerFormations = styled.div`
    display: flex;
    flex-direction: column;
`

export default Formations
