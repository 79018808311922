import { AnimatePresence, motion } from 'framer-motion';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import { BsHeartFill } from "react-icons/bs";
import { FaPercent } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { useHistory } from 'react-router-dom';
import { logout } from '../../redux/actions/authAction';
import { MdWork } from 'react-icons/md';
import { AiFillSetting } from 'react-icons/ai';
import { AiTwotoneShop } from "react-icons/ai";

const Sidebar = () => {
  const { auth } = useSelector(state => state)
  const history = useHistory()
  const dispatch = useDispatch()
  const sideVariants = {
    closed: {
      transition: {
        staggerChildren: 0.2,
        staggerDirection: -1
      }
    },
    open: {
      transition: {
        staggerChildren: 0.2,
        staggerDirection: 1
      }
    }
  };






  return (
    <AnimatePresence>
      {

        <SidebarMenu>
          <motion.aside
            initial={{ width: 0 }}
            animate={{
              width: "70px"
            }}
            exit={{
              width: 0,
              transition: { delay: 0.7, duration: 0.3 }
            }}
          >
            <motion.div
              className="container"
              initial="closed"
              animate="open"
              exit="closed"
              variants={sideVariants}
            >

              <div className='top'>
                {/*               <div onClick={() => dispatch({type: SIDEBAR_TYPES.CLOSE_SIDEBAR})}><AiOutlineLeft className='close'/></div>
 */}
                <img onClick={() => history.push('/myaccount')} className="avatar" src={auth.user.avatar} alt="profil" />

              </div>

              <div className='middle-buttons'>
                <div onClick={() => history.push('/accounts')} ><AiTwotoneShop  className=' icon icon-like' /></div>
{/*                 <div onClick={() => history.push('/favouritesDeals')}> <BsHeartFill className=' icon icon-like' /></div>
 */}                {/*               <div onClick={() => history.push('/jobcenter')}> <MdWork className=' icon icon-like' /></div>
 */}
                <div onClick={() => history.push('/myaccount')}><AiFillSetting className=' icon icon-like'/></div>
{/*               <div className='company-button' onClick={() => history.push('/accounts')} >Espace entreprise</div>
 */}              {auth.user.role === "admin" && <div className='admin-button' onClick={() => history.push('/admin')} >Espace Admin</div>}

              </div>



              <FiLogOut className=' icon' onClick={() => dispatch(logout())} />

            </motion.div>
          </motion.aside>
        </SidebarMenu>

      }
    </AnimatePresence>
  )
}

const SidebarMenu = styled.div`
  position: fixed;
  top: 0px; 
  left: 0px;
  z-index: 10000;
  display:flex;
  align-items: center;
  justify-content: center;
  aside {
    display:flex;
    align-items:center;
    justify-content: center;
    width: 5.75vw;
    height: 100vh;
  }

  .top {
    display:flex;
    flex-direction :column;
    align-items: center;
    gap: 5px;

    .close{
      margin-bottom: 10px;
      &:hover{
        cursor: pointer;
      }
    }
  }

  .container{
    background: white;
    border-right: 1px solid #eee;
    padding: 10% 4%;
    display:flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    .avatar{
      width: 50px;
      height: 50px;
      border-radius: 50%;
      &:hover{
        cursor: pointer;
      }
    }
  }

  .container a {
    color: #f9fafb;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    background : blue;
    margin: 0px;
  }

  .middle-buttons{
    display:flex;
    flex-direction : column;
    align-items:center;

    .company-button{
      margin-top: 20%;
      display:flex;
      justify-content:center;
      align-items:center;
      text-align:center;
      padding: 5px 4px;
      background: #6076E7;
      color:white !important;
      width: 100%;
      font-size: 0.9em;

      &:hover{
        background:#6077e7d6;
        cursor: pointer;
      }
      
    }


    .admin-button{
      margin-top: 20%;
      display:flex;
      justify-content:center;
      align-items:center;
      text-align:center;
      padding: 5px 4px;
      background: #cf272d;
      color:white !important;
      width: 100%;
      font-size: 0.9em;

      &:hover{
        background:#a81e23;
        cursor: pointer;
      }
      
    }


  }

  
  .icon{
    color: var(--color-accent);
    font-size: 1.5em;
    margin-top:20px;
    margin-bottom:20px;

    &:hover{
      cursor: pointer;
    }

  }

  @media only screen and (max-width: 768px) {
    display:none;
  }

`

const TicketsButton = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;  padding: 1px 0px;
  border-radius: 50px;
  width: 100%;
  background:white;


  span{
    font-size: 0.9em;
    font-weight: 900;
    margin-right: 2px;
  }

  &:hover{
    cursor: pointer;

    background: #fafafa;
  }
  
  .ticket{
    width: 15px;
    transform: rotate(-45deg);
  }

`


const LotteryButton = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  position: relative;


  .numberSpin{
    position: absolute;
    right: -8px;
    top: -5px;
    display:flex; 
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: red;
    width: 20px;
    height: 20px;
    font-size: 0.8em;
    color: white;
    font-weight: 800;
  }



  &:hover{
    cursor: pointer;

    background: #fafafa;
  }

  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; 
  border-radius: 50%;

  
  .lottery{
    width: 35px;

  }

`
export default Sidebar