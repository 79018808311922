import React, {useState, useEffect} from 'react'
import styled from "styled-components"
import { BsPlusCircle } from 'react-icons/bs';
import AddExperience from './experience/AddExperience';
import AddFormation from './formation/AddFormation';
import AddSkill from './skill/AddSkill';

import {  getExperiencesUser } from  "../../../redux/actions/experienceAction"
import LoadIcon from '../../../images/loading.gif'
import { useParams } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
import Experiences from './experience/Experiences';
import Formations from './formation/Formations';
import Skill from './skill/Skill';

import { getFormationsUser } from '../../../redux/actions/formationAction';
import { getSkillsUser } from '../../../redux/actions/skillAction';
import AddLanguage from './languageExp/AddLanguage';
import { getLanguagesUser } from '../../../redux/actions/languageAction';
import Languages from './languageExp/Languages';
import { GLOBALTYPES } from '../../../redux/actions/globalTypes';
import NoData from './NoData';

let scroll = 0;


const CurriculumVitae = ({id}) => {
    const { modal} = useSelector(state => state)


    window.addEventListener('scroll', () => {
        if(window.location.pathname === '/'){
            scroll = window.pageYOffset
            return scroll;
        }
    })

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({top: scroll, behavior: 'smooth'})
        }, 100)
    },[])


    const [addExperience, setAddExperience] = useState(false);
    const [addFormation, setAddFormation] = useState(false);
    const [addSkill, setAddSkill] = useState(false);
    const [addLanguage, setAddLanguage] = useState(false);


    const { auth, experiences, formations, skills, languages } = useSelector(state => state)
    const dispatch = useDispatch()

    useEffect(() => {
        if(auth.token) {
          dispatch(getExperiencesUser( auth.token, id))
          dispatch(getFormationsUser(auth.token, id))
          dispatch(getSkillsUser(auth.token, id))
          dispatch(getLanguagesUser(auth.token, id))

        }
      }, [dispatch, auth.token, id])

    return (
        <Container>

            {
                modal.typeModal === 'addExperience' &&   <AddExperience />

            }

            <Section>
                <Header>
                    <h2>Expériences professionnelles</h2>
                   {id === auth.user._id && <BsPlusCircle  onClick={() => {      dispatch({ type: GLOBALTYPES.MODAL,payload: {typeModal:"addExperience", open:true}})}} style={{color:"#F87778"}} className="iconHeader"/>}
                </Header>

                {
                        experiences.loading 
                        ? <img src={LoadIcon} alt="loading" className="d-block mx-auto" />
                        : (experiences.result === 0 && experiences.experiences.length === 0)
                            ? <NoData title={"Aucune expérience renseignée"}/>
                            : <Experiences id={id} />
                }
          
            </Section>

            {
                modal.typeModal === 'addFormation' &&    <AddFormation />

            }

           

            <Section>
                <Header>
                    <h2>Formations</h2>
                   {id === auth.user._id && <BsPlusCircle onClick={() => {dispatch({ type: GLOBALTYPES.MODAL, payload: {typeModal:"addFormation", open:true}}) }} style={{color:"#F87778"}} className="iconHeader"/>}
                </Header>
                {
                        formations.loading 
                        ? <img src={LoadIcon} alt="loading" className="d-block mx-auto" />
                        : (formations.result === 0 && formations.formations.length === 0)
                            ? <NoData title={"Aucune formation renseignée"}/>
                            : <Formations id={id}/>
                }
          
            </Section>


            {
                modal.typeModal === 'addSkill' &&    <AddSkill />

            }
 
            <Section>
                <Header>
                    <h2>Compétences</h2>
                   {id === auth.user._id && <BsPlusCircle  onClick={() => {dispatch({ type: GLOBALTYPES.MODAL, payload: {typeModal:"addSkill", open:true}}) }} style={{color:"#F87778"}} className="iconHeader"/>}
                </Header>
                {
                        skills.loading 
                        ? <img src={LoadIcon} alt="loading" className="d-block mx-auto" />
                        : (skills.result === 0 && skills.skills.length === 0)
                            ? <NoData title={"Aucune compétence renseignée"}/>
                            : <Skill id={id} />
                }
          
            </Section>


            {
                modal.typeModal === 'addLanguage' &&    <AddLanguage />

            }
 
            <Section>
                <Header>
                    <h2>Langues</h2>
                   {id === auth.user._id && <BsPlusCircle  onClick={() => dispatch({ type: GLOBALTYPES.MODAL, payload: {typeModal:"addLanguage", open:true}}) } style={{color:"#F87778"}} className="iconHeader"/>}
                </Header>
                {
                        languages.loading 
                        ? <img src={LoadIcon} alt="loading" className="d-block mx-auto" />
                        : (languages.result === 0 && languages.languages.length === 0)
                            ? <NoData title={"Aucune langue renseignée"}/>
                            : <Languages id={id} />
                }
          
            </Section>

        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`

const Section = styled.div `
    display: flex;
    border-radius: 10px;
    width: 80%;
    flex-direction: column;
    background: white;
    margin-top: 25px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    @media (max-width: 768px) {
        width: 90%;
        border-radius: 0px;

        box-shadow: none;

    }


`

const Header = styled.div `
    display: flex;
    justify-content: space-between;
    align-items:center;
    color: #F87778;
    padding: 2% 3%;
    h2{
        font-size: 20px;
    }
    .iconHeader{
        font-size: 30px;
    }

    .iconHeader:hover{
        cursor: pointer;
        background: #f0f2f5;
        border-radius: 5px;
    }

    @media (max-width: 768px) {
        padding: 2% 2%;
        h2{
            font-size: 0.9em;
            color: #757474;
        }

        .iconHeader{
            font-size: 25px;
        }

    }
    
`




export default CurriculumVitae
