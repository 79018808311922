import { Button, Form, Input, Progress, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import SearchAddress from '../../utils/SearchAddress';
import CreatePartner1 from './form/CreatePartner1';
import CreatePartner2 from './form/CreatePartner2';
import CreatePartner3 from './form/CreatePartner3';

const FormCreatePartner = ({page}) => {
    const [formData, setFormData] = useState(page?page:null)

    const [step, setStep] = useState(1);

    useEffect(() => {
   
    
      return () => {
        setFormData(null)
      }
    }, [])
    

    

    return (

        <>
            <Progress percent={step*33} />
            <
            >

                {step === 1 && 
                    <CreatePartner1  formData={formData} setFormData={setFormData} step={step} setStep={setStep} />
                }

                {step === 2 && 
                    <CreatePartner2 formData={formData} setFormData={setFormData} step={step} setStep={setStep} />
                }


                {step === 3 && 
                    <CreatePartner3  page={page} formData={formData} setFormData={setFormData} step={step} setStep={setStep} />
                }
 

        
            </>

        </>
    )
}

export default FormCreatePartner