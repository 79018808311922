import { Button, Form, Input, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import TextArea from 'antd/es/input/TextArea'
import { getDataAPI } from '../../../../utils/fetchData'
import { useDispatch, useSelector } from 'react-redux'
import { CrossImage, ShowImages } from '../../../deal/CreateDealModal'
import { imageShow, videoShow } from '../../../../utils/mediaShow'
import { GLOBALTYPES } from '../../../../redux/actions/globalTypes'
import { MdPhotoLibrary } from 'react-icons/md'
import styled from 'styled-components'
import { createAds, updateAd } from '../../../../redux/actions/adsActions'
const CreateAds = ({isEdit, formData, setFormData, step, setStep }) => {
    const { auth, theme, modal } = useSelector(state => state)

    const [pages, setPages] = useState([])
    const [imgBackground, setImgBackground] = useState([])
    const [logo, setLogo] = useState([])

    const dispatch = useDispatch();

    const handleChangeImages = (e, type) => {
        const files = [...e.target.files]
        let err = ""
        let newImages = []

        files.forEach(file => {
            if (!file) return err = "File does not exist."

            if (file.size > 1024 * 1024 * 2) {
                return err = "The image/video largest is 2mb."
            }

            return newImages = [file]
        })

        if (err) dispatch({ type: GLOBALTYPES.ALERT, payload: { error: err } })
        if(type === "imgBackground"){
            setImgBackground(newImages)
        }else{
            setLogo(newImages)

        }
    }

    const deleteImages = (index, type) => {
        if(type==='imgBackground'){
            const newArr = [...imgBackground]
            newArr.splice(index, 1)
            setImgBackground(newArr)
        }else{
            const newArr = [...logo]
            newArr.splice(index, 1)
            setLogo(newArr)
        }

    }


    const handleChangeInput = e => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })

    }

    const handleSearchCompany = async (value) => {
        if (!value) return;
        const res = await getDataAPI(`pages/search?name=${value}`, auth.token)
        setPages(res.data.pages)
    }

    const handleSelectCompany = value => {
        setFormData({ ...formData, page: value })
    }

    const onFinish = () => {

        if(isEdit){
            dispatch(updateAd({id:formData._id, content:formData, logo, imgBackground, auth}))

        }else{
            dispatch(createAds({content:formData, logo, imgBackground, auth}))

        }
        
    };
    return (
        <>

            <Form
                name="basic"
                layout='vertical'
                onFinish={onFinish}
                autoComplete="off"
                initialValues={formData ? { ...formData } : null}

            >


                {!isEdit && <Form.Item
                    label="Page"
                    name="page"
                    rules={[
                        {
                            required: true,
                            message: 'Entrez le nom de l\'entreprise!',
                        },
                    ]}
                >
                    <Select
                        showSearch
                        placeholder="Sélectionnez une page"
                        optionFilterProp="children"
                        onSearch={handleSearchCompany}
                        onSelect={handleSelectCompany}
                    >
                        {pages.map(page => (
                            <Select.Option key={page._id} value={page._id}>
                                {page.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>}

                <Form.Item
                    label={"Titre"}
                    name="headerTitle"
                    onChange={(e) => handleChangeInput(e)}
                    hasFeedback

                    rules={[
                        {
                            required: true,
                            message: 'Entrez le titre!',

                        },
                        {
                            max: 120,
                            message: 'Le titre ne peut pas dépasser 120 caractères!',
                        }
                    ]}
                >
                    <Input name="headerTitle" size="large" maxLength={120} />
                </Form.Item>

                <Form.Item
                    label={"Sous Titre"}
                    name="headerSubtitle"
                    onChange={(e) => handleChangeInput(e)}
                    hasFeedback

                    rules={[
                        {
                            required: true,
                            message: 'Entrez le sous titre!',

                        },
                        {
                            max: 120,
                            message: 'Le sous titre ne peut pas dépasser 120 caractères!',
                        }
                    ]}
                >
                    <Input name="headerSubtitle" size="large" maxLength={120} />
                </Form.Item>

                <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                        {
                            required: true,
                            message: "Entrez une description!",

                        },
                        {
                            max: 500,
                            message: 'La description ne peut pas dépasser 500 caractères!',
                        }
                    ]}
                    hasFeedback
                    onChange={(e) => handleChangeInput(e)}

                >
                    <TextArea name="description" size='large' maxLength={500} showCount />
                </Form.Item>

                <Form.Item
                    label={"Site internet"}
                    name="website"
                    onChange={(e) => handleChangeInput(e)}
                    hasFeedback


                >
                    <Input name="website" size="large" maxLength={120} />
                </Form.Item>

                <Form.Item name="status" label="Statut" rules={[{ required: true, message: 'Entrez le statut!', }]}>
                    <Select onChange={(e) => { setFormData({ ...formData, ['status']: e }) }} size="large" placeholder="Sélectionnez le statut" dropdownStyle={{ zIndex: "100000" }}>
                        <Select.Option value="active">Actif</Select.Option>
                        <Select.Option value="disable">Non Actif</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Photo Background"
                    name="imgBackground"

                >
                    <>
                    <ShowImages>
                        {
                            imgBackground.map((img, index) => (
                                <div key={index} id="file_img">
                                    {
                                        img.camera ? imageShow(img.camera, theme)
                                            : img.url
                                                ? imageShow(img.url, theme)
                                                : imageShow(URL.createObjectURL(img), theme)
                                    }
                                    <CrossImage onClick={() => deleteImages(index, "imgBackground")}>&times;</CrossImage>
                                </div>
                            ))
                        }
                    </ShowImages>

                    <InputImages>
                        <>

                            <FileUpload>
                                <MdPhotoLibrary className="iconLibrary" />
                                <input type="file" name="file" id="file"
                                    multiple accept=".jpg, .jpeg, .png" onChange={(e)=>handleChangeImages(e, "imgBackground")} />
                            </FileUpload>
                        </>


                    </InputImages>

                    </>
                </Form.Item>


                <Form.Item
                    label="Logo"
                    name="photo"

                >
                    <>
                    <ShowImages>
                        {
                            logo.map((img, index) => (
                                <div key={index} id="file_img">
                                    {
                                        img.camera ? imageShow(img.camera, theme)
                                            : img.url
                                                ? imageShow(img.url, theme)
                                                : imageShow(URL.createObjectURL(img), theme)
                                    }
                                    <CrossImage onClick={() => deleteImages(index, 'logo')}>&times;</CrossImage>
                                </div>
                            ))
                        }
                    </ShowImages>

                    <InputImages>
                        <>

                            <FileUpload>
                                <MdPhotoLibrary className="iconLibrary" />
                                <input type="file" name="file" id="file"
                                    multiple accept=".jpg, .jpeg, .png" onChange={(e) => handleChangeImages(e, "logo")} />
                            </FileUpload>
                        </>


                    </InputImages>

                    </>
                </Form.Item>



                <Form.Item
                    style={{ display: 'flex', justifyContent: 'end' }}
                >

                    <Button size="large" type="primary" htmlType="submit" >
                        {isEdit ? 'Mettre a jour': 'Poster'}
                    </Button>

                </Form.Item>

            </Form>

        </>
    )
}

const InputImages = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 45px;

    .iconLibrary {
        transform: translateY(-8px);
        color: #45b061;

       
        &:hover{
            color:#6193c9;
        }
    }

    .iconPhoto {
        color: #5886b8;

        &:hover{
            color:#6193c9;
        }
    }

    

`

const FileUpload = styled.div`
    overflow: hidden;
    margin: 0 10px;
    position: relative;

    #file{
        position: absolute;
        top:0;
        left: 0;
        opacity: 0;



`

export default CreateAds