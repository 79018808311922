import { Button, Checkbox, Form, Input, Rate, Select, Upload } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { DatePicker } from 'antd';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import locale from 'antd/es/date-picker/locale/fr_FR';
import { useState } from 'react';
import { FaUserAlt } from 'react-icons/fa';
import { useEffect } from 'react';
import { HeaderModal } from './PageChoice';
import { createDeal, updateDeal } from '../../../../redux/actions/dealAction';
import { extractFileNameFromUrl, validateURL } from '../../../../utils/function';

const OfferModality = ({ step, onStepChange, formData, setFormData }) => {

    const { modal, auth, datas } = useSelector(state => state)

    const [activeMode, setActiveMode] = useState('');
    const [amountPersons, setAmountPersons] = useState(0);

    const [isPerma, setIsPerma] = useState(false);
  
    const [isNonPreciseTimeChecked, setIsNonPreciseTimeChecked] = useState(false);
    // State to manage selected files
    const [fileList, setFileList] = useState([]);


    const dispatch = useDispatch();
    useEffect(() => {

        if (modal.deal) {
            setAmountPersons(modal.deal.amountPersons);
            setActiveMode(modal.deal.mode)
            setIsPerma(modal.deal.enddate === '2099-12-31'?true:false)
            setIsNonPreciseTimeChecked(((modal.deal.starttime === '' && modal.deal.endtime === '') || (!modal.deal.starttime && !modal.deal.endtime) )?true:false)
            
            if(modal.deal.filePdf){

          
            setFileList(modal.deal.filePdf)
            let pdfs = [...modal.deal.filePdf]

            if(pdfs.length > 0 && pdfs[0].url){
                
                pdfs[0].name =extractFileNameFromUrl(pdfs[0].url)

            }
            setFormData({ ...formData, ['filePdf']:pdfs })
        }

        }
    }, [])

    


    const days = datas.days.map(day => ({ value: day.id, label: day.name }));


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [step]);



    // Function to handle file changes
    const onFileChange = ({ file, fileList: newFileList }) => {

        if(newFileList.length === 0){
            setFileList([]);
            formData.filePdf=[];
        }else{
            setFileList([file]);
            formData.filePdf=[file];
        }

    };

    const handleChangeInput = e => {
        const { id, value } = e.target
        setFormData({ ...formData, [id]: value })
        console.log(formData)
    }

    const onDateChange = (date, dateString, value) => {
        console.log(value, dateString);
        setFormData({ ...formData, [value]: dateString })

    };

    const onTimeChange = (time, timeString, value) => {
        console.log(time, timeString);
        setFormData({ ...formData, [value]: timeString })

    };

    const onSelectChange = (value, name) => {
        console.log(`selected ${value}`);
        setFormData({ ...formData, [name]: value })

    };

    const onRateChange = (value) => {
        console.log(`selected ${value}`);
        setFormData({ ...formData, amountPersons: value });

        setAmountPersons(value);

    };

    const handleClick = (choice) => {
        setActiveMode(choice);
        setFormData({ ...formData, mode: choice });
    }




    const validateMessages = {
        required: '${label} requis!',
        types: {
            email: "${label} invalide!",
            number: '${label} is not a valid number!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    };


    const handleSubmit = (e) => {

        console.log(formData)

        if(modal.deal){
           dispatch(updateDeal({id:modal.deal._id, content: formData, images:formData.images, files: fileList, auth})) 

       }else{
           dispatch(createDeal({ content: formData, images:formData.images, files: fileList,  auth})) 
       }    
       e.preventDefault()
   }

  const handleCheckboxPermaChange = (e) => {
    setIsPerma(e.target.checked);

    if(e.target.checked){
        setFormData({ ...formData,['startdate']: '', ['enddate']: '2099-12-31' })
    }
    const { startdate, ...updatedFormData } = formData;

  };
   
  const handleCheckboxTimeChange = (e) => {
    setIsNonPreciseTimeChecked(e.target.checked);
  
    const { endtime,starttime, ...updatedFormData } = formData;

  };
   
   
    return (
        <Content>

            <HeaderModal>
                {
                    formData.target === "global" && <h2>{modal.deal?'Modifier':'Créer'}  une promotion pour la communauté</h2>
                }


                <h3>Modalités de l'offre</h3>

            </HeaderModal>


            <Form
                onFinish={(e) => { handleSubmit(e) }}
                layout="vertical"
                style={{ width: "100%",display:"flex", height:"100%", flexDirection:"column", justifyContent:'space-between' }}
                validateMessages={validateMessages}
                initialValues={{ // Setting initial values here
                    startdate: modal.deal && modal.deal.startdate &&   dayjs(modal.deal.startdate),
                    enddate: modal.deal && modal.deal.startdate && dayjs(modal.deal.enddate),
                    date: modal.deal && modal.deal.date,
                    starttime: modal.deal && modal.deal.starttime && dayjs(modal.deal.starttime, "HH:mm"),
                    endtime: modal.deal && modal.deal.endtime && dayjs(modal.deal.endtime, "HH:mm"),
                    amountPersons: modal.deal && modal.deal.amountPersons,
                    use: modal.deal && modal.deal.mode,
                    modeInfos: modal.deal && modal.deal.modeInfos,
                    website: modal.deal && modal.deal.website,
                }}
            >

                <div>
                <div >
                <Form.Item label="Période de validité" style={{ marginBottom: "24px" }} rules={[
                    {
                        required: true,
                        message: 'Entrez une période!',
                    },
                ]}>
                    <Form.Item
                        name="startdate"
                        rules={[{ required: !isPerma, message: 'Entrez une date de début!', }]}

                        style={{ display: 'inline-block',marginBottom:'0px', width: 'calc(50% - 8px)' }}
                    >
                        <DatePicker onChange={(date, dateString) => onDateChange(date, dateString, 'startdate')}
                            placeholder='Début' style={{ width: "100%" }} size='large' locale={locale} />

                    </Form.Item>
                    <Form.Item
                        onChange={(e) => handleChangeInput(e)}
                        name="enddate"
                        rules={[{ required: !isPerma, message: 'Entrez une date de fin!', }]}

                        style={{ display: 'inline-block',marginBottom:'0px', width: 'calc(50% - 8px)', margin: '0 8px' }}
                    >
                        <DatePicker onChange={(date, dateString) => onDateChange(date, dateString, 'enddate')} placeholder='Fin' size='large' style={{ width: "100%" }} locale={locale} />

                    </Form.Item>
                    
                    <Checkbox defaultChecked={modal.deal && modal.deal.enddate==="2099-12-31"?true:false } onChange={handleCheckboxPermaChange}  >Promo permanente (jusqu'à sa suppression) </Checkbox>

                </Form.Item>
 
                </div>
                

                <Form.Item
                    label="Jours de validité"
                    name="date"
                    hasFeedback

                >
                    <Select
                        size='large'
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Choississez les jours"
                        initialValues={[]}
                        options={days}
                        onChange={(value) => onSelectChange(value, 'date')}
                        dropdownStyle={{ zIndex: "1000000" }}
                    />

                </Form.Item>

                <Form.Item label="Horaire de validité" style={{ marginBottom: '24px' }} rules={[
                    {
                        required: true,
                        message: 'Entrez une horaire!',
                    },
                ]}>
                    <Form.Item
                        name="starttime"
                        rules={[{ required: !isNonPreciseTimeChecked, message: 'Entrez une horaire de début!', }]}
                        style={{ display: 'inline-block', marginBottom:'0px', width: 'calc(50% - 8px)' }}
                    >
                        <TimePicker format={'HH:mm'} placeholder='Début' locale={locale} style={{ width: "100%" }} size='large' onChange={(time, timeString) => onTimeChange(time, timeString, 'starttime')} />


                    </Form.Item>
                    <Form.Item
                        name="endtime"
                        rules={[{ required: !isNonPreciseTimeChecked, message: 'Entrez une horaire de fin!' }]}
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                    >
                        <TimePicker format={'HH:mm'} placeholder='Fin' locale={locale} style={{ width: "100%" }} size='large' onChange={(time, timeString) => onTimeChange(time, timeString, 'endtime')} />

                    </Form.Item>
                    <Checkbox defaultChecked={modal.deal && modal.deal.starttime==="" &&  modal.deal.endtime===""?true:false } onChange={handleCheckboxTimeChange} >Non précisé </Checkbox>

                </Form.Item>


                <Form.Item
                    label="Nombre de personnes bénéficiant de l'offre (salarié inclus)"
                    name="amountPersons"
           
                >
                    <div className='rate'>
                        <Rate value={amountPersons} onChange={(value) => onRateChange(value)} character={<FaUserAlt />} count={8} style={{ fontSize: 20, color: 'var(--color-accent)' }} />

                    </div>
                   
                </Form.Item>


                {
                    formData.typeDeal === "partner" && <>
                              <Form.Item
                label={"Site internet"}
                placeholder={"https://votresite.com"}
                name="website"
                onChange={(e) => handleChangeInput(e)}
                hasFeedback
                rules={[
                    { 
                      required: true, 
                      message: 'Entrez une URL!' 
                    },
                    { 
                      validator: validateURL 
                    }
                  ]}

        
            >
                <Input name="website" size="large" maxLength={120} />
            </Form.Item>
</>
                }


                <Form.Item
                    label="Comment utiliser la promo (1 case max)"
                    name="use"
                    required={true}
                    rules={[
                        {
                            validator: (_, value) =>
                                activeMode ? Promise.resolve() : Promise.reject(new Error('Veuillez choisir une option!'))
                        }
                    ]}

                >
                    {formData.typeDeal === 'company' && <UsageChoice>
                        <Choice onClick={() => handleClick("noreservation")} active={activeMode === 'noreservation' ? true : false}>Pas de réservation nécessaire</Choice>
                        <Choice onClick={() => handleClick("phone")} active={activeMode === 'phone' ? true : false}>Réservation par téléphone</Choice>
                        {activeMode === 'phone' && <Form.Item
                            label="Numéro de téléphone"
                            name="modeInfos"
                            onChange={(e) => handleChangeInput(e)}
                            rules={[
                                {
                                    required: true,
                                    message: 'Entrez votre numéro de téléphone!',
                                },
                                
                            ]}
                        >
                            <Input size="large" />
                        </Form.Item>}
                        <Choice onClick={() => handleClick("email")} active={activeMode === 'email' ? true : false}>Réservation par email</Choice>
                        {activeMode === 'email' && <Form.Item
                            label="Email"
                            name="modeInfos"
                            onChange={(e) => handleChangeInput(e)}
                            rules={[
                                {
                                    required: true,
                                    message: 'Entrez votre email!',
                                },
                                {
                                    max: 140,
                                    message: 'L\'email ne peut pas dépasser 140 caractères!',
                                  }
                            ]}
                        >
                            <Input size="large" maxLength={140} />
                        </Form.Item>}
                        <Choice onClick={() => handleClick("code")} active={activeMode === 'code' ? true : false}>Code promo à utiliser en ligne</Choice>
                        {activeMode === 'code' && <Form.Item
                            label="Code de réservation"
                            name="modeInfos"
                            onChange={(e) => handleChangeInput(e)}
                            rules={[
                                {
                                    required: true,
                                    message: 'Entrez votre code de réservation!',
                                },
                                {
                                    max: 50,
                                    message: 'Le code ne peut pas dépasser 50 caractères!',
                                  }
                            ]}
                        >
                            <Input size="large" maxLength={50}/>
                        </Form.Item>}
                        <Choice onClick={() => handleClick("link")} active={activeMode === 'link' ? true : false}>Réservation via un lien</Choice>
                        {activeMode === 'link' && <Form.Item
                            label="Lien de réservation"
                            name="modeInfos"
                            onChange={(e) => handleChangeInput(e)}
                            rules={[
                                {
                                    required: true,
                                    message: 'Entrez votre lien!',
                                    
                                },
                                {
                                    max: 200,
                                    message: 'Le lien ne peut pas dépasser 200 caractères!',
                                  }
                            ]}
                        >
                            <Input size="large" maxLength={200} />
                        </Form.Item>}
                    </UsageChoice>}

                    {
                        formData.typeDeal === 'partner' && <UsageChoice>
                                                    <Choice onClick={() => handleClick("code")} active={activeMode === 'code' ? true : false}>Code promo à utiliser en ligne</Choice>
                                                    {activeMode === 'code' && <Form.Item
                            label="Code de réservation"
                            name="modeInfos"
                            onChange={(e) => handleChangeInput(e)}
                            rules={[
                                {
                                    required: true,
                                    message: 'Entrez votre code de réservation!',
                                },
                                {
                                    max: 50,
                                    message: 'Le code ne peut pas dépasser 50 caractères!',
                                  }
                            ]}
                        >
                            <Input size="large" maxLength={50}/>
                        </Form.Item>}

                        <Choice onClick={() => handleClick("link")} active={activeMode === 'link' ? true : false}>Réservation via un lien</Choice>
                        {activeMode === 'link' && <Form.Item
                            label="Lien de réservation"
                            name="modeInfos"
                            onChange={(e) => handleChangeInput(e)}
                            rules={[
                                {
                                    required: true,
                                    message: 'Entrez votre lien!',
                                    
                                },
                                {
                                    max: 200,
                                    message: 'Le lien ne peut pas dépasser 200 caractères!',
                                  }
                            ]}
                        >
                            <Input size="large" maxLength={200} />
                        </Form.Item>}
                        </UsageChoice>
                    }
                </Form.Item>

                </div>

                <Form.Item label="Ajouter un pdf" name="pdfFile" rules={[
    {
      validator: (_, value) => {
        console.log(value)
        if (!value || value.fileList.length === 0) {
            return Promise.resolve();
        }
        const file = value.file;
        const isPdf = file.type === 'application/pdf';
        const isLessThan5MB = file.size / 1024 / 1024 < 3;
        if (!isPdf) {
          return Promise.reject(new Error('Le fichier doit être un PDF.'));
        }
        if (!isLessThan5MB) {
          return Promise.reject(new Error('Le fichier ne doit pas dépasser 3MB.'));
        }
        return Promise.resolve();
      },
    },
  ]}>
                    <Upload
beforeUpload={() => false}                       
 onChange={onFileChange}
                        multiple={false} // Set to true if you want to upload multiple files
                        fileList={fileList}
                        accept=".pdf"
                    >
                        <Button >Sélectionnez un pdf</Button>
                    </Upload>
                </Form.Item>

                <Form.Item className="item-next" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button size="large" shape="round" type="primary" htmlType="submit">
                        {modal.deal?'Mettre-à-jour': 'Poster'}
                    </Button>
                </Form.Item>
            </Form>








        </Content>
    )
}

const Content = styled.div`
    display:flex;
    width: 100%;
    flex: 1;

    flex-direction: column;
    justify-content: space-around;
    align-items: center;


    .selectAll{
        display:flex;
        justify-content: end;
        width:100%;
    }

    .rate{
        span{
            margin-left: 10px;
        }
    }



`



const UsageChoice = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 10px;

`

const Choice = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 15px 0px;


    background: ${({ active }) => active ? 'var(--color-accent);' : '#F6F6F6'};
    color: ${({ active }) => active ? 'white' : '#969696'};


    &:hover{
        cursor: pointer;
    }
`
export default OfferModality