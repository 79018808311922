import { GLOBALTYPES } from './globalTypes'
import { postDataAPI, getDataAPI, patchDataAPI, deleteDataAPI } from '../../utils/fetchData'

import { imageUpload, pdfUpload } from '../../utils/imageUpload'
import { getParamsFilter } from './userAction'
import { VISITORS_TYPES } from './visitorAction'
export const DEAL_TYPES = {
    CREATE_DEAL: 'CREATE_DEAL',
    UPDATE_DEAL: 'UPDATE_DEAL',

    LOADING: 'LOADING',
    GET_DEALS:  'GET_DEALS',
    GET_MORE_DEALS:  'GET_MORE_DEALS',

    GET_DEALS_BY_COMPANY: 'GET_DEALS_BY_COMPANY',
    GET_DEAL:  'GET_DEAL',
    DELETE_DEAL: 'DELETE_DEAL'
}


export const createDeal = ({content,images,files, auth, redirect}) => async (dispatch) => {
    let media = [];
    let mediaPDF = [];
    try {
        console.log(content)
        console.log(images)
        console.log(files)
        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })
        
        try{
            if(images.length > 0 && !images[0].url){
                media = await imageUpload(images)
                content.images = media;
            } 

            if(files.length > 0  && !files[0].url){
          
                mediaPDF = await pdfUpload(files)
                content.filePdf = mediaPDF;
            }
        }catch(e){
            
        }
 

        if(content.sector){
            content.profil = content.sector
            content.sector = content.sector.join(',')
        }
        console.log("content")
        console.log(content)

        const res = await postDataAPI('deal', content, auth.token)

        dispatch({ 
            type: DEAL_TYPES.CREATE_DEAL, 
            payload: {...res.data.newDeal, user: auth.user} 
        })

        dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: false} })

        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} });

        if(redirect){
            window.location.href = redirect
        }

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const updateDeal = ({id,content,images, files, auth, redirect}) => async (dispatch) => {
    let media = [];
    let mediaPDF = [];

    try {
        


        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: true} })
        
        try{
            if(images.length > 0 && !images[0].url){
                media = await imageUpload(images)
                content.images = media;
            } 

            console.log(files)
            if(files.length > 0  && !files[0].url){
          
                mediaPDF = await pdfUpload(files)
                content.filePdf = mediaPDF;
            }
        }catch(e){
            
        }
 
    

     

        const res = await patchDataAPI('deal/'+id, content, auth.token)

        dispatch({ 
            type: DEAL_TYPES.UPDATE_DEAL, 
            payload: {...res.data.newDeal, user: auth.user} 
        })

        dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})

        dispatch({ type: GLOBALTYPES.ALERT, payload: {loading: false} })

        dispatch({ type: GLOBALTYPES.ALERT, payload: {success: res.data.msg} });

        if(redirect){
            window.location.href = redirect
        }
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const searchAll = ({filter,auth}) => async (dispatch) => {
    try {
        dispatch({type: DEAL_TYPES.LOADING, payload: true})

        let param = getParamsFilter(filter)
      
    
        const res = await getDataAPI(`/searchAll?`+ param, auth.token)


        dispatch({
            type: DEAL_TYPES.GET_DEALS,
            payload: res
        }) 
 
        dispatch({type: DEAL_TYPES.LOADING, payload: false})


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
    }
    
}


export const getDealsByCompany2 = ({auth, id, filter}) => async (dispatch) => {

    try {
        
        let param = getParamsFilter(filter)
        dispatch({type: DEAL_TYPES.LOADING, payload: true})
        const res = await getDataAPI(`/dealsByCompany/${id}?${param}`, auth.token)


        dispatch({
            type: DEAL_TYPES.GET_DEALS_BY_COMPANY,
            payload: res
        }) 
 
        dispatch({type: DEAL_TYPES.LOADING, payload: false})


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
    }
    
}


export const getDealsByUser = ({auth}) => async (dispatch) => {


    try {
        dispatch({type: DEAL_TYPES.LOADING, payload: true})
        const res = await getDataAPI(`/dealsByUser`, auth.token)


        dispatch({
            type: DEAL_TYPES.GET_DEALS_BY_COMPANY,
            payload: res
        }) 
 
        dispatch({type: DEAL_TYPES.LOADING, payload: false})


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
    }
    
}



export const getDeal = ({auth, id}) => async (dispatch) => {

    try {
        dispatch({type: DEAL_TYPES.LOADING, payload: true})
        const res = await getDataAPI(`/deal/${id}`, auth.token)

        dispatch({
            type: DEAL_TYPES.GET_DEAL,
            payload: res
        }) 
 
        dispatch({type: DEAL_TYPES.LOADING, payload: false})


    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT, 
            payload: {error: err.response.data.msg}
        })
    }
    
}

export const likeDeal = ({deal, auth, typePage}) => async (dispatch) => {
    const newDeal = {...deal, likes: [...deal.likes, auth.user]}

    if(typePage === "deals"){
        dispatch({ type: DEAL_TYPES.UPDATE_DEAL, payload: newDeal})
    }
    else{
        dispatch({ type: VISITORS_TYPES.UPDATE_VISITOR_DEALS, payload: newDeal})

    }
    console.log(newDeal)

    try {
        await patchDataAPI(`deal/${deal._id}/like`, null, auth.token)
        
    
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const unLikeDeal = ({deal, auth, typePage}) => async (dispatch) => {
    const newDeal = {...deal, likes: deal.likes.filter(like => like._id !== auth.user._id)}
    if(typePage === "deals"){
        dispatch({ type: DEAL_TYPES.UPDATE_DEAL, payload: newDeal})
    }
    else{
        dispatch({ type: VISITORS_TYPES.UPDATE_VISITOR_DEALS, payload: newDeal})

    }

    try {
        await patchDataAPI(`deal/${deal._id}/unlike`, null, auth.token)

   

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}

export const deleteDeal= ({deal, auth}) => async (dispatch) => {
    dispatch({ type: DEAL_TYPES.DELETE_DEAL, payload: deal })
    console.log(deal)
    try {
         await deleteDataAPI(`deal/${deal._id}`, auth.token)

        
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {error: err.response.data.msg}
        })
    }
}


