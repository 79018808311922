import React, { useState } from 'react';
import { Button, Modal, Table } from 'antd';
import { BiEdit } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import { deactivateSubscription } from '../../../../redux/actions/subscriptionAction';
import { useDispatch, useSelector } from 'react-redux';

const AccountsTable = ({ subscriptions }) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [currentSub, setCurrentSub] = useState(null)
  const dispatch = useDispatch();
  const {auth} = useSelector(state => state);

  const columns = [
    {
      title: 'Nom/Prénom',
      dataIndex: 'fullname',
      key: 'fullname',
      render: (_, sub) => (
        <span>{sub.user.firstname} {sub.user.lastname}</span>
      ),    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (_, sub) => (
        <span>{sub.user.email}</span>
      ),    },

    
    {
      title: 'Statut',
      dataIndex: 'status',
      key: 'status',
      className: 'hide-on-mobile', // Apply the responsive class here

    },
    {
      title: 'Actions',
      key: 'action',
      render: (text, record) => <div style={{display:"flex", gap: "5px"}}>
      <Button onClick={(e)=>{}} size="large" icon={<BiEdit />}  />

      <Button  onClick={(e)=>{setCurrentSub(record); showModalDelete()}}  type="primary" danger size="large" icon={<MdDelete />}  />

   </div>,
     
    },
  ];

  const showModalDelete = () => {
      setOpenDelete(true);
  };
  const handleDeleteOk = () => {

    setOpenDelete(false);
    dispatch(deactivateSubscription({subscription:currentSub, auth}))

    //dispatch(deleteAds({ad:currentSub, auth}))

  };

  const handleDeleteCancel = () => {
    setOpenDelete(false);
  };

  return <> 
    <Table columns={columns} dataSource={subscriptions} rowKey="accounts" pagination={false} /> 
  
    <Modal
      centered
        open={openDelete}
        title="Annuler Abonnement"
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
        footer={[
          <Button key="cancel" onClick={handleDeleteCancel}>
            Annuler
          </Button>,
          <Button key="ok" type="primary" onClick={handleDeleteOk}>
            Confirmer
          </Button>,
        ]}
      >

      Êtes-vous sûr de vouloir annuler cet abonnement?
    </Modal>
  </>;
};

export default AccountsTable;
