import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { convertIdToLabel } from '../../../utils/function'
import { Button, Tag } from 'antd'
import { GLOBALTYPES } from '../../../redux/actions/globalTypes'
const CompanyItem = ({ page }) => {
    const { datas } = useSelector(state => state);
    const dispatch = useDispatch();
 

    return (
        <Item onClick={() => dispatch({ type: GLOBALTYPES.MODAL,payload: {typeModal:"companyModal", page:page, open:true}})}>
            <Top imgBackground={page.imgBackground?page.imgBackground:"https://behandy-front.s3.eu-west-3.amazonaws.com/color-gd67b3d48d_640.png"}>
                <Avatar src={page.avatar} alt={"avatar"} />
            </Top>
            <Bottom>
                <BottomHeader>
                    <h2>{page.name}</h2>
                    <span>{convertIdToLabel(datas.companyTypes, page.sector)}</span>
                </BottomHeader>

                <BottomContent>
        
                <div style={{ display: "flex", gap: "2px" }}>

                    {
                        page.specialities.slice(0, 2).map(tag => (
                            <Tag style={{ padding: "0px 10px", color: "var(--color-accent)", borderRadius: "50px", fontSize: "0.8em", background:'#6076e730'}}  bordered={false}>{tag}</Tag>
                        ))
                    }
                </div>

                <div style={{display:'flex',justifyContent:'center', gap:"4px"}}>
                    <Button  style={{color:"white !important"}}> <>Voir plus</></Button>

                </div>

                </BottomContent>

            </Bottom>

        </Item>
    )
}

const Item = styled.div`
    display:flex;
    flex-direction:column;
    background: white;
    overflow: hidden;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 15px;

    aspect-ratio: 1/1;

`

const Top = styled.div`
    height: 30%;
    width: 100%;
    background-image: ${props => props.imgBackground ? `url(${props.imgBackground})` : ""};
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
`

const Bottom = styled.div`
    display:flex;
    flex-direction:column;
    flex:1;
    padding: 10px 10px;
    padding-top: 45px;
`

const BottomHeader = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

    h2{
        font-size: 1em;
        font-weight: 800;
        margin: 0px;
        color:var(--color-accent);
        line-height: 1.0;
    }

    span{
        font-size:0.9em;
        font-style:italic;
        color:#969696;
    }
`

const BottomContent= styled.div`

    display:flex;
    flex:1;
    flex-direction:column;
    justify-content: space-between;
    .description{
        color: #969696;
        font-style:italic;
        font-size: 0.9em;    word-break: break-word;
    }
`

const Avatar = styled.img`
    display:flex;
    justify-content:center;
    align-items:center;
    background: white;
    border-radius: 50%;
    border: 3px solid white;
    width: 60px;
    aspect-ratio: 1/1;
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);

`
export default CompanyItem