import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import DealCard from '../deal/DealCard';
import Modal from '../utils/Modal/Modal';
const DealCardModal = () => {
 
  const {modal} = useSelector(state => state)



  return (
    <Modal  modalName={"dealCardModal"}  heightModal={'85vh'} widthModal={'30vw'} >


            <Content>

                <DealCard deal={modal.deal} heightCard={"30vh"} />

            </Content>



    </Modal>

  )
}



const Content = styled.div`

    position: relative;
    width: 100%;
    height: 100%;
`




export default DealCardModal