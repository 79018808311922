import { Button, Checkbox } from 'antd'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { getDataAPI } from '../../../../../utils/fetchData'
import { ClipLoader } from 'react-spinners'
import NoData from '../../../../profile/cv/NoData'
const PartnersChoice = ({ step, onStepChange, formData, setFormData, type }) => {


    const {modal,pages, auth} = useSelector(state=>state)

    const [selectedPages, setSelectedPages] = useState([]);  // Track selected pages
    const [partners, setPartners] = useState([]);  // Track selected pages
    const [loading, setLoading] = useState(false);
    // Check if all pages are selected
    const isAllSelected = pages.pages.length === selectedPages.length;

    useEffect(async() => {
        console.log(formData)
        setLoading(true)
        const res = await getDataAPI(`/page/${formData.pages[0]}`, auth.token)
        if(res.status === 200){
            setPartners(res.data.page.partners)
        }
        setLoading(false)

        console.log(res)
    }, [])
    



    // Handle the 'Select All' checkbox
    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedPages(pages.pages.map(page => page._id));
        } else {
            setSelectedPages([]);
        }
    }

    // Handle individual page selection
    const handleSelectPage = (pageId, isChecked) => {
        if (isChecked) {
            setSelectedPages(prev => [...prev, pageId]);
        } else {
            setSelectedPages(prev => prev.filter(id => id !== pageId));
        }
    }

    const handleNext = () => {
        if (selectedPages.length === 0) return; 
        setFormData({ ...formData, partners: selectedPages });
        onStepChange(step + 1);
    }

  return (
    <Content>

        <HeaderModal>
          
            {
               <h2>{modal.reward?'Modifier':'Créer'} une promotion  pour un partenaire</h2>
            }        


            <h3>Pour quel(s) partenaire(s)?</h3>

        </HeaderModal>

        {
            loading ? <EmptyPage>
            <ClipLoader
               color={"#F87778"}
               loading={loading}
               size={30}
               aria-label="Loading Spinner"
               data-testid="loader"
           />
           
           
           </EmptyPage>: <>

       {partners && partners.filter.length > 0 &&  <div className='selectAll'>
            <Checkbox checked={isAllSelected} onChange={handleSelectAll} />
            <span>Tout sélectionner</span>
        </div>}


        { partners && partners.filter.length === 0 && 
        
        <EmptyPage>

        
            <NoData title={"Aucun partenariat"}/> 
        
       
        </EmptyPage>}

       {partners && partners.filter.length > 0 && <ChoicesDiv>
            {
                partners.map((page) => (
                    <Choice key={page.id}>
                        <div className='div-left'> 
                                <img src={page.avatar} alt="avatar"/>
                            <span>{page.name}</span>
                        </div>
                        <Checkbox
                            checked={selectedPages.includes(page._id)}
                            onChange={(e) => handleSelectPage(page._id, e.target.checked)}
                        />
                    </Choice>
                ))
            }
        </ChoicesDiv>}
        </>}
        
        <Button disabled={selectedPages.length === 0} onClick={ () => {handleNext()}} size="large" shape="round" type="primary" htmlType="submit">
            Suivant
        </Button>
        
    </Content>
  )
}

const Content = styled.div`
    display:flex;
    width: 100%;
    flex: 1;

    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    gap:20px;
    .selectAll{
        display:flex;
        justify-content: end;
        width:100%;

        span{
            margin-left: 5px;
            font-size: 0.9em;
        }
    }



`


const EmptyPage = styled.div`

    display:flex;
    flex-direction:column;
    width: 100%;
    height: 100%;
    justify-content:center;
    align-items:center;
`

export const HeaderModal = styled.div`
    display:flex;
    flex-direction: column;

    margin: 20px 0px;

    h2{
        font-size:1.4em;
        font-weight: 900;
        color: #585858;
        text-align:center;
    }

    h3{
        font-size:1.1em;
        font-weight: 500;
        color: #585858;
        text-align:center;
    }

`
const ChoicesDiv = styled.div`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
        width: 100%;
        height: 100%;

        margin-bottom: 20px;

        @media screen and (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        } 

`

const Choice = styled.div`
    
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    width: 100%;
    align-self: flex-start;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;


    .div-left{
        display:flex;
        align-items: center;
    }
    .div-icon{
        background:red;
        padding: 5px;
        border-radius: 100%;
        .icon{
            color:white;
            font-size:1.4em;
        }

    }

    img{
        width: 35px !important;
        height: 35px !important;
    }
    span{
        font-size: 0.9em;
        margin-left: 5px;
    }
    transition: all 0.2s ease-in;

    &:hover{
        cursor: pointer;
        transform: scale(1);
    }

`
export default PartnersChoice