import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from "styled-components"
import DealCard from '../../components/deal/DealCard'
import HeaderDealCompany from '../../components/deal/HeaderDealCompany'
import { getDeal } from '../../redux/actions/dealAction'
import DealPartnerCard from '../../components/home/card/DealPartnerCard'
const Deal = () => {
  const { auth, deals } = useSelector(state => state)
  const dispatch = useDispatch()

  const [deal, setDeal] = useState(null);
  const { id } = useParams()

  useEffect(() => {

      if(deals.current_deal){
        setDeal(deals.current_deal)
      }

  },[deals.current_deal])
  
  useEffect(() => {
    

     let findDeal = deals.deals.find(item => item._id === id)
    if(findDeal){
      setDeal(findDeal)
      console.log(deal)

    }else{
      dispatch(getDeal({auth, id}))
      
    } 
  },[])

  return (
    <Container>
        {
          deal ? 
           <>
           <div className='header'>
                        <HeaderDealCompany company={deal.company}/>

           </div>
           {deal.typeDeal==="company" && <DealCard deal={deal}/>}
           {deal.typeDeal==="partner" && <DealPartnerCard deal={deal}/>}

           </> 
          
          
          
          : "pas ok"
        }

    </Container>
  )
}

const Container = styled.div`
  display:flex;
  flex-direction: column;
  width: 90%;
  margin-bottom: 5%;
  @media (min-width: 768px) {
    .header{
      display:none;
    }
    justify-content: center;
    margin-bottom: 0%;

    width:40%;
    min-width: 400px;
    padding: 30px 0px;
  }  
`

export default Deal; 