import React from 'react';
import styled from 'styled-components';

import EmptyImg from '../../../assets/empty.png'
const NoData = ({title}) => {
  return <Container>
        <img src={EmptyImg} />
        <h2 className='title'>{title}</h2>

  </Container>;
};

const Container =styled.div`
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title{
        margin:0px;
        font-size: 1em;
        font-weight: 500;
        color: #7d7777;
        text-align: center;
        font-style: italic;
    }
    margin-bottom: 5%;
    img {
      width: 100px;
      height: 100px;
    }



    @media screen and (max-width: 768px) {
      img {
        width: 90px;
        height: 90px;
      }

      .title{
        font-size: 1em;
        font-weight: 500;
        color: #7d7777;
        text-align: center;
        font-style: italic;
      }
    }
`

export default NoData;
