import React from 'react'
import { useState } from 'react'

import styled from 'styled-components'
import Target from './Target'
import GlobalEvent from './Global/GlobalEvent'
import TargetedEvent from './Targeted/TargetedEvent'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'


const EventCreation = () => {

    const [formData, setFormData] = useState({});

    const {modal} = useSelector(state=>state)

    useEffect(() => {
        if(modal.event){
           setFormData(modal.event);
        }
        setFormData({ ...formData, target: 'global' });

    }, [])



      
    return (
    <Content>
        
        <GlobalEvent formData={formData} setFormData={setFormData}/>
 
    </Content>
  )
}

const Content = styled.div`
    display:flex;
    width: 100%;
    flex: 1;

    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    h2{
        font-size:1.6em;
        font-weight: 900;
        color: #585858;
        text-align:center;
    }

`

export default EventCreation