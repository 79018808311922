import React, { useEffect, useRef, useState } from "react";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import { Input } from "antd";
import styled from "styled-components";

const SearchAddress = ({ onAddressChange, address, setAddress }) => {
  const REACT_APP_API_PLACE = process.env.REACT_APP_API_PLACE;
  const [libraries] = useState(['places']);

  

  return (
    <Container>

          <Autocomplete
          onLoad={(autocomplete) => {
            console.log(process.env.REACT_APP_API_PLACE)
            autocomplete.addListener("place_changed", () => {
                let place = autocomplete.getPlace();
                place.geoFormat = {
                  type:'Point',
                  coordinates: [place.geometry.location.lng, place.geometry.location.lat]
                }
                setAddress(place.formatted_address);
                console.log(place)
                onAddressChange(place);              
            });
          }}
          >
            <Input
              size="large"
              value={address}
              placeholder="Entrez une ville"
              onChange={(e) => setAddress(e.target.value)}
            />
          </Autocomplete>
        
    </Container>
  );
};

const Container = styled.div`
  .pac-container {
    z-index: 10510 !important;
    background: red !important;
  }
`;

export default SearchAddress;
