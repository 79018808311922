import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Modal from '../utils/Modal/Modal'
import Logo from '../../assets/logo-violet.png'
import FormCreateDeal1 from './DealCreation/FormCreateDeal1'
import PromoCreation from './DealCreation/promo/PromoCreation'
import EventCreation from './DealCreation/event/EventCreation'


const CreateDealModal = () => {
    const { pages, modal } = useSelector(state => state)
    const [typeOffer, setTypeOffer] = useState('promo')
    const [step, setStep] = useState(1)

    const initialState = {
        offer: '', date: '', amountPersons: '', address: '', description: '', phone: '', email: '', website: '', localisation: '', sector: ''
    }

    const [formData, setFormData] = useState(initialState)


    useEffect(() => {
        return () => {
            setStep(1);
            setTypeOffer('')
            setFormData(initialState)
        }
    }, [])

    useEffect(() => {
        if (modal.deal) {
            setTypeOffer(modal.deal.type)
            setStep(1);

        } /* else if (modal.event) {
            setTypeOffer(modal.event.type)
            setStep(2);
        } else if (modal.reward) {
            setTypeOffer(modal.reward.type);
            setStep(2);
        }
        else if (modal.joboffer) {
            setTypeOffer('joboffer');
            setStep(2);
        } */
    }, [modal])


    const handleStepChange = (step) => {
        setStep(step);
    };

    const handleTypeOffer = (type) => {
        setTypeOffer(type)
    }


    return (
        <Modal modalName={"createDealModal"} widthModal={!(pages.pages && pages.pages.length > 0) ? "30vw" : "45vw"} heightModal={!(pages.pages && pages.pages.length > 0) ? "35vh" : "90vh"}>

            <Container>
                <img className="logo" src={Logo} alt='logo' />
                <ContentForm>
             {/*        {
                        step === 1 && <FormCreateDeal1 step={step} onStepChange={handleStepChange} typeOffer={typeOffer} onTypeOfferChange={handleTypeOffer} />
                    } */}

                    {
                        step === 1 && <>
                            {
                                typeOffer === 'promo' && <PromoCreation />
                            }
                        </>
                    }


      {/*               {
                        step === 2 && <>
                            {
                                typeOffer === 'event' && <EventCreation />
                            }
                        </>
                    }

 */}
                </ContentForm>
               

            </Container>


        </Modal>
    )
}


const ContentForm = styled.div`
    display:flex;
    flex-direction:column; 
    width: 100%;
    flex: 1;

    @media screen and (max-width: 768px) {
        padding: 3% 0%;
       

    } 
`
const Container = styled.div`
    display:flex;
    position: relative;
    flex-direction: column;
    align-items:center;
    height: 100%;
    width: 100%;
    h1{
        font-size: 1.5em;
        font-weight: bold;
        margin: 0px;
    }
    padding: 2% 5%;

    .logo{
        width: 150px;
    }
`



export const ShowImages = styled.div`

    max-height: 270px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    place-items: center;
    padding: 10px 0;
    grid-gap: 10px;

    #file_img{
        position: relative;
        width: 100%;
        height: 100%;
    }

    #file_img img, #file_img video{
        display: block;
        object-fit: contain;
        width: 100%;
        height: 100%;
        max-height: 100px;
    }
`

export const CrossImage = styled.div`
    position: absolute;
    top: -1px;
    right: -2px;
    z-index: 4;
    background: white;
    color: crimson;
    padding: 3px 7px;
    border: 1px solid crimson;
    border-radius: 50%;
    font-size: 10px;
    font-weight: bolder;
    cursor: pointer;

`
export const Stream = styled.div`
    position: relative;
`

export const CrossStream = styled.div`
    position: absolute;
    top: -10px;
    right: 5px;
    color: crimson;
    font-size: 2rem;
    font-weight: 900;
    cursor: pointer;
`

export default CreateDealModal