import React, { useEffect } from 'react'
import Modal from '../utils/Modal/Modal'
import styled from 'styled-components'
import InfoConnection from '../../assets/info_connection.json'
import { Player } from '@lottiefiles/react-lottie-player';

const FirstConnectionModal = () => {


    
  return (
    <Modal  modalName={"firstConnectionModal"}> 
        
        <Content>
            <div>
            <Player
              autoplay
              loop
              src={InfoConnection}
              style={{ height: '40%', width: '40%' }}
            >
            </Player>
            </div>
      
            <p>Bienvenue sur Behandy ! <br/> L'application est en cours de lancement. De nouvelles offres apparaîtront dans les prochains jours. Restez connecté! <br/> <b>L'équipe Behandy</b> </p>

        </Content>
</Modal>
  )
}


const Content = styled.div`

    position: relative;
    
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;

    p{
        margin: 0px;
        margin-top: 5%;
        font-size: 1.2em;
        color: #757474;
        text-align: center;
    }

    @media (max-width: 768px) {
        p{
            font-size: 1em;
        }

    }
`


export default FirstConnectionModal