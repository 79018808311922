import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import CompanyCard from './CompanyCard'
import Modal from '../../utils/Modal/Modal'
const CompanyModal = () => {
 
  const {modal} = useSelector(state => state)



  return (
    <Modal  modalName={"companyModal"}  heightModal={'85vh'} widthModal={'30vw'} >


            <Content>

                <CompanyCard page={modal.page} heightCard={"30vh"} />

            </Content>



    </Modal>

  )
}



const Content = styled.div`

    position: relative;
    width: 100%;
    height: 100%;
`




export default CompanyModal