import React from 'react'
import Modal from './Modal'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { GLOBALTYPES } from '../../../redux/actions/globalTypes'
import { deleteDeal } from '../../../redux/actions/dealAction'
import useWindowDimensions from '../../../utils/useWindowsDimensions'
import { deleteAdmin, deletePage } from '../../../redux/actions/pageAction'
import { useHistory } from 'react-router-dom'
import { deleteUser } from '../../../redux/actions/profileAction'
import { deleteEvent } from '../../../redux/actions/eventAction'
import { deleteVisitor } from '../../../redux/actions/visitorAction'
import { deactivateSubscription } from '../../../redux/actions/subscriptionAction'
import { deletePartnership } from '../../../redux/actions/partnershipActions'
const ModalDelete = ({auth}) => {
    const dispatch = useDispatch();
    const {modal} = useSelector(state=>state);
    const {  width } = useWindowDimensions();
    const history = useHistory()
    const actionToDo = () => {
        if(modal.type === "deal"){
            dispatch(deleteDeal({deal:modal.deal, auth}))
        }
        else if(modal.type === "event"){
            dispatch(deleteEvent({event:modal.event, auth}))
        }

        else if(modal.type === "page"){
            dispatch(deletePage({id:modal.page._id, auth}))
            history.push('/accounts')
        }else if(modal.type === "user"){
            dispatch(deleteUser({auth: modal.auth}))
        }else if(modal.type === "admin"){
            dispatch(deleteAdmin({page: modal.page, userId: modal.userId, auth}))
        }
        else if(modal.type === "eventVisitor"){
            dispatch(deleteVisitor({visitor:modal.visitor, auth}))
        }
        else if(modal.type === "subscription"){
            dispatch(deactivateSubscription({subscription:modal.subscription, auth}))
        }
        else if(modal.type === "invitationPartner"){
            console.log("kkkkk")
            dispatch(deletePartnership({invitation: modal.invitation, auth}))
        }
        else if(modal.type === "deleteSub"){
            dispatch(deactivateSubscription({subscription:modal.subscription, auth}))
        }
        
        
        
    }
    return (
    <Modal heightModal={width > 768 ?"25vh":"20vh"} widthModal={width > 768 ?"30vw":"95vw"} modalName={"delete"} showOnMobile={true} >
       
         <Container>
            <p>{modal.title}</p>
            <Buttons>

                <Button onClick={(e)=> {e.stopPropagation(); dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})}}>Annuler</Button>
                <Button background="#F87778" color="white" onClick={(e)=> {e.stopPropagation(); actionToDo();dispatch({ type: GLOBALTYPES.MODAL, payload: {open:false}})}}>Confirmer</Button>

            </Buttons>
        </Container> 


    </Modal>
  )
}

const Container = styled.div`
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content:center;
    height: 100%;
    text-align:center;
    @media screen and (max-width: 768px) {
        p{
            font-size: 0.9em;
        }
    } 


`

const Buttons = styled.div`
    display:flex;
`

const Button = styled.div`
    display:flex;
    align-items:center;
    justify-content: center;
    color: ${props => props.color ? props.color : "black"};
    background: ${props => props.background ? props.background : "white"};
    border: 1px solid #C4C4C4;
    border-radius: 7px;
    padding: 2% 4%;
    min-width: 100px;
    margin-left: 2%;
    margin-right: 2%;

    @media screen and (max-width: 768px) {
        font-size: 0.9em;
    } 

    &:hover{
        cursor: pointer;
    }
`


export default ModalDelete