import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from '../../utils/Modal/Modal'
import Logo from '../../../assets/logo-violet.png'
import { Button, Checkbox, Form, Input, Select } from 'antd'

import { useDispatch, useSelector } from 'react-redux'
import { getDataAPI, postDataAPI } from '../../../utils/fetchData'
import TextArea from 'antd/es/input/TextArea'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { GLOBALTYPES } from '../../../redux/actions/globalTypes'
const ModalSendPartnerInvitation = () => {

    const { auth, pages } = useSelector(state => state);
    const dispatch = useDispatch();


    const { id } = useParams();
    const [formData, setFormData] = useState({});
    const [loading, isLoading] = useState(false);
    const [pages_, setPages] = useState([])

    const [isDisabled, setIsDisabled] = useState(false);

    const handleCheckboxChange = (e) => {
        setIsDisabled(e.target.checked);
    };

    const handleChangeInput = e => {
        const { id, value } = e.target
        setFormData({ ...formData, [id]: value })
    }

    const handleSearchCompany = async (value) => {
        if (!value) return;
        const res = await getDataAPI(`pages/search?name=${value}`, auth.token)
        setPages(res.data.pages)
    }

    const handleSelectCompany = value => {
        setFormData({ ...formData, receiverId: value })
    }




    const handleSubmit = async () => {
        formData.senderId = id;
        formData.senderName = pages.activePage.name;
       /*  if(!isDisabled){
            formData.companyName = 
        } */
        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } })

        try {
            if(!isDisabled){
                const res = await postDataAPI(`partnershipInvitation`, { ...formData }, auth.token)
                dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } })
                dispatch({ type: GLOBALTYPES.ALERT, payload: { success: res.data.msg } });
            }else{
                const res = await postDataAPI(`partnershipInvitationNonExistingCompany`, { ...formData }, auth.token)
                dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } })
                dispatch({ type: GLOBALTYPES.fALERT, payload: { success: res.data.msg } });
            }


        } catch (err) {
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: { error: err.response.data.msg }
            })
        }
        dispatch({ type: GLOBALTYPES.MODAL, payload: {} })
        console.log(formData)

    }

    return (
        <Modal modalName={"createModalSendInvitation"}  >

            <Container>
                <img className="logo" src={Logo} alt='logo' />

                <h2>Ajouter un partenaire</h2>

                <ContentForm>

                    <Form
                        onFinish={() => { handleSubmit() }}
                        layout="vertical"
                        style={{ width: "100%", height: '100%', display: 'flex', flexDirection: "column", justifyContent: 'space-evenly' }}
                    >


                        <div>

                            {


                                <Form.Item
                                    label="Entreprise"
                                    name="receiverId"
                                    style={{marginBottom:'0px'}}
                                    rules={[
                                        {
                                            required: isDisabled ? false : true,
                                            message: 'Entrez le nom de l\'entreprise!',
                                        },
                                    ]}
                                >
                                    <>
                                    <Select
                                        showSearch
                                        placeholder="Sélectionnez une entreprise"
                                        optionFilterProp="children"
                                        onSearch={handleSearchCompany}
                                        onSelect={handleSelectCompany}
                                        dropdownStyle={{ zIndex: "100000" }}
                                        size='large'
                                        disabled={isDisabled}
                                    >
                                        {pages_.map(page => (
                                            <Select.Option key={page._id} value={page._id}>
                                                {page.name}
                                            </Select.Option>
                                        ))}
                                    </Select>

                               
                                    </>
                                </Form.Item>
                            }

<Form.Item>

                                <Checkbox style={{marginTop: "-20px"}} onChange={handleCheckboxChange}>
                                        L'entreprise ne possède pas encore de page Behandy?
                                    </Checkbox>

                                    </Form.Item>


                            { isDisabled &&
                                <Form.Item
                                    label="Nom de l'entreprise"
                                    name="companyName"
                                    onChange={(e) => handleChangeInput(e)}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Entrez le nom de l\'entreprise',
                                        },
                                        {
                                            max: 140,
                                            message: 'Le nom ne peut pas dépasser 140 caractères!',
                                        }
                                    ]}
                                >
                                    <Input size="large" maxLength={140} />
                                </Form.Item>
                            }

                            <Form.Item
                                label="Email"
                                name="email"
                                onChange={(e) => handleChangeInput(e)}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Entrez l\'email!',
                                    },
                                    {
                                        max: 140,
                                        message: 'L\'email ne peut pas dépasser 140 caractères!',
                                    }
                                ]}
                            >
                                <Input size="large" maxLength={140} />
                            </Form.Item>

                            <Form.Item
                                label="Description"
                                name="message"
                                rules={[
                                    {
                                        required: true,
                                        message: "Entrez une description!",

                                    },
                                    {
                                        max: 500,
                                        message: 'La description ne peut pas dépasser 500 caractères!',
                                    }
                                ]}
                                hasFeedback
                                onChange={(e) => handleChangeInput(e)}

                            >
                                <TextArea name="description" size='large' maxLength={500} showCount />
                            </Form.Item>



                        </div>

                        <Form.Item className="item-next" style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button size="large" shape="round" type="primary" htmlType="submit">
                                {/*                                 {stripe.subscriptions && stripe.subscriptions.data.length > 0 ?  "Valider":"Payer"}
 */}
                                Valider
                            </Button>
                        </Form.Item>
                    </Form>

                </ContentForm>


            </Container>
        </Modal>
    )
}

const Container = styled.div`
    display:flex;
    position: relative;
    flex-direction: column;
    align-items:center;
    height: 100%;
    width: 100%;
  
    padding: 2% 5%;

    h2{
        font-size:1.2em;
        font-weight: 900;
        color: #585858;
        text-align:center;
        margin-top: 20px;
        margin-bottom: 30px;

    }

    .logo{
        width: 150px;
    }
`

const ContentForm = styled.div`
    display:flex;
    flex-direction:column; 
    width: 100%;
    flex: 1;

    @media screen and (max-width: 768px) {
        padding: 3% 0%;
       

    
`
export default ModalSendPartnerInvitation